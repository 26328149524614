import { useCallback, useEffect, useState } from "react";
import PaymentHistoryService from "../../../../../../../services/accounts/paymentHistory";
import { usePrevious } from "../../../../../../../lib/hooks/previous";

export const useFetchYears = (
  accountId: string,
  setSelectValue: React.Dispatch<React.SetStateAction<number | undefined>>
) => {
  const [years, setYears] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const prevAccountId = usePrevious(accountId);

  const fetch = useCallback(() => {
    setIsLoading(true);

    PaymentHistoryService.fetchYears({ contractId: accountId })
      .then((res: number[]) => {
        const options = res.map((year) => ({
          label: `Year: ${year}`,
          value: year,
        }));

        setSelectValue(options[options.length - 1].value);
        setYears(options);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [accountId, setSelectValue]);

  useEffect(() => {
    if (!prevAccountId) {
      fetch();
    }
  }, [fetch, prevAccountId]);

  return { years, isLoading };
};
