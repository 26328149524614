import { useCallback, useEffect, useState } from "react";
import { useGetRole } from "../../../lib/hooks/getRole";
import AccountsService from "../../../services/accounts";
import ContractsService from "../../../services/accounts/contracts";
import { Account } from "../../../types/Account";

export const useFetchData = (request: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  const [data, setData] = useState<Account[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback((): void => {
    setIsLoading(true);
    !isAdmin
      ? AccountsService.fetchAccounts(request).then((res: any) => {
          res &&
            setData(res.map((item: any, i: number) => ({ ...item, id: i })));
          setIsLoading(false);
        })
      : ContractsService.fetchAll(request).then((res: any) => {
          setData(res);
          setIsLoading(false);
        });
  }, [request, isAdmin]);

  useEffect(() => fetchData(), [fetchData]);

  return { data, isLoading, fetchData };
};
