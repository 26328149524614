import { useCallback } from "react";

export const useCalcInterestBalance = () => {
  const calcInterestBalance = useCallback((history: any) => {
    const { interestAmount, breakdown } = history;

    const interestAfter = +interestAmount - +breakdown.interestAmount;

    history.breakdown.interestAfter = interestAfter.toFixed(2);
  }, []);

  return { calcInterestBalance };
};
