import { Button, Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { IBatchGet } from "../../../../../types/Batches";
import { useGetTypeIcon } from "../../../../Processing/hooks/useGetTypeIcon";
import { ReactNode, useCallback, useMemo } from "react";
import { useGetProcessingState } from "../../../../Processing/hooks/useGetProcessingState";
import { CreatePaymentDropdown } from "./CreatePaymentDropdown";
import { ActionsDropDown } from "./ActionsDropDown";
import { useValidateBatch } from "../../../../Processing/hooks/useValidateBatch";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ROUTES from "../../../../../configs/routesConfig";

type HeaderProps = {
  data: IBatchGet;
  setIsOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  isWarehouse: boolean;
  openCreatePayment: (isCreatePaymentAutoOpen: boolean) => void;
  fetchData: () => void;
};

export const Header = ({
  data,
  setIsOpenEditModal,
  isWarehouse,
  openCreatePayment,
  fetchData,
}: HeaderProps) => {
  const navigate: NavigateFunction = useNavigate();

  const { getIcon } = useGetTypeIcon();

  const { getState } = useGetProcessingState();

  const { validate, isLoading } = useValidateBatch(fetchData);

  const onValidateCLick = useCallback((): void => {
    validate(data.id);
  }, [data, validate]);

  const name: string = useMemo(() => getIcon(data.type).name, [data, getIcon]);

  const back = useCallback((): void => {
    const location: any = window.history.length === 1 ? ROUTES.PROCESSING : -1;
    navigate(location);
  }, [navigate]);

  const processingState: ReactNode = useMemo(
    () => getState(data, false),
    [data, getState]
  );

  const createPaymentButton: ReactNode = useMemo(() => {
    return data.type === 2 ? (
      <CreatePaymentDropdown openCreatePayment={openCreatePayment} />
    ) : (
      <Button
        shape="round"
        type="primary"
        className="ml-4"
        onClick={() => openCreatePayment(false)}
      >
        Create Payment
      </Button>
    );
  }, [data, openCreatePayment]);

  return (
    <Row className="py-5 px-8 align-center title justify-space-between header-container processing-table">
      <Col className="d-flex">
        <Button
          shape="circle"
          icon={<ArrowLeftOutlined />}
          size="large"
          className="back-btn mr-6"
          onClick={back}
        />
        <h3 className="d-flex align-center h h3">
          {name}: {data.batchNumber}
        </h3>
        <Row className="ml-6 align-center">{processingState}</Row>
      </Col>
      <Col className="d-flex">
        <Button shape="round" disabled={isLoading} onClick={onValidateCLick}>
          Validate
        </Button>
        <ActionsDropDown id={data.id} setIsOpenEditModal={setIsOpenEditModal} />
        {!isWarehouse && createPaymentButton}
      </Col>
    </Row>
  );
};
