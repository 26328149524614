import { ReactNode, useCallback } from "react";
import { USER_EVENT_TYPES } from "../../../../../../lib/constants";
import { getFormatted } from "../../../../../../lib/PhoneFormatter";

const LABELS: any = {
  FirstName: "First Name",
  LastName: "Last Name",
  MiddleName: "Middle Name",
  Email: "Email",
  Phone: "Phone",
  AccountNumber: "Account Number",
  TaxID: "Tax ID",
  TaxIdDigits: "Tax ID",
  TradeRole: "Trade Role",
  RegistrationType: "Trade Role",
  Role: "Role",
  Username: "Username",
  UserName: "Username",
};

export const useGetCollapseHeader = () => {
  const updatedCondition = useCallback((type: number): boolean => {
    return [
      USER_EVENT_TYPES.UPDATE_PROFILE_BY_SELF,
      USER_EVENT_TYPES.UPDATE_PROFILE_BY_ADMIN,
      USER_EVENT_TYPES.CHANGED_USERNAME,
      USER_EVENT_TYPES.CHANGED_EMAIL,
      USER_EVENT_TYPES.CHANGED_PHONE,
      USER_EVENT_TYPES.CHANGED_ROLE,
      USER_EVENT_TYPES.CHANGED_FULLNAME,
    ].includes(type);
  }, []);

  const portalAccessToggled = useCallback((type: number): boolean => {
    return [
      USER_EVENT_TYPES.PORTAL_ACCESS_ADDED,
      USER_EVENT_TYPES.PORTAL_ACCESS_REMOVED,
    ].includes(type);
  }, []);

  const getHeader = useCallback(
    (item: any): ReactNode => {
      let headerText;
      const { type, createdByUserFullName: createdByName } = item;

      if (type === USER_EVENT_TYPES.BLOCKED) {
        headerText = `Blocked by ${createdByName || "the system"}`;
      }

      if (type === USER_EVENT_TYPES.UNBLOCKED) {
        headerText = `Unblocked by ${createdByName || "the system"}`;
      }

      if (updatedCondition(type) || portalAccessToggled(type)) {
        headerText = `Profile was updated by ${createdByName || "the system"}`;
      }

      if (
        type === USER_EVENT_TYPES.UPDATE_PASSWORD_BY_SELF ||
        type === USER_EVENT_TYPES.RESET_PASSWROD
      ) {
        headerText = `Password was updated by ${createdByName || "the system"}`;
      }

      if (
        type === USER_EVENT_TYPES.CREATED ||
        type === USER_EVENT_TYPES.CREATED_BY_PREREGISTRATION
      ) {
        headerText = `Created by ${createdByName || "the system"}`;
      }

      if (type === USER_EVENT_TYPES.ACCESS_REQUESTED) {
        headerText = "Access requested";
      }

      if (type === USER_EVENT_TYPES.CREATED_BY_IMPORT) {
        headerText = "Created by the system";
      }

      if (type === USER_EVENT_TYPES.ACCESS_GRANTED) {
        headerText = `Access granted by ${createdByName || "the system"}`;
      }

      if (type === USER_EVENT_TYPES.ACTIVATED) {
        headerText = `Access granted`;
      }

      if (type === USER_EVENT_TYPES.ACCESS_DENIED) {
        headerText = `Access rejected by ${createdByName || "the system"}`;
      }

      return (
        <div className="collapse-header">
          <p
            className="text-md elipsis my-0"
            title={`${createdByName || "The system"}`}
          >
            {headerText}
          </p>
          <p className="my-0 text-sm time">{item.createdAt}</p>
        </div>
      );
    },
    [updatedCondition, portalAccessToggled]
  );

  const getBody = useCallback(
    (item: any): ReactNode => {
      let content = <div />;
      const { type, fields } = item;

      if (
        [
          USER_EVENT_TYPES.BLOCKED,
          USER_EVENT_TYPES.UNBLOCKED,
          USER_EVENT_TYPES.ACCESS_DENIED,
        ].includes(type)
      ) {
        content = (
          <div className="mb-6 mx-6 content-box">
            <span className="text-md h pr-1">Reason:</span>
            <span className="text-md pre-line">{item.reason || "-"}</span>
          </div>
        );
      }

      if (updatedCondition(type)) {
        content = (
          <div className="mb-6 mx-6 content-box">
            <div className="text-md h mb-2">Updated Data:</div>
            <div>
              {fields.map((item: any, i: number) => {
                const { name, oldValue, newValue } = item;

                const value = ["Phone", "PhoneNumber"].includes(name)
                  ? `Phone: from ${
                      getFormatted(oldValue).slice(2) || "-"
                    } to ${getFormatted(newValue).slice(2)}`
                  : `${LABELS[name]}: from ${oldValue || "-"} to ${
                      newValue || "-"
                    }`;

                return (
                  <div key={i} className="mb-2 word-break ">
                    {value}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }

      if (
        [
          USER_EVENT_TYPES.CREATED,
          USER_EVENT_TYPES.ACCESS_REQUESTED,
          USER_EVENT_TYPES.CREATED_BY_IMPORT,
          USER_EVENT_TYPES.CREATED_BY_PREREGISTRATION,
          USER_EVENT_TYPES.ACTIVATED,
        ].includes(type)
      ) {
        content = (
          <div className="mb-6 mx-6 content-box">
            <div className="text-md h mb-2">Provided Data:</div>
            <div>
              {fields.map((item: any, i: number) => {
                const value = `${
                  item.name === "Phone"
                    ? getFormatted(item.newValue).slice(2) || "-"
                    : item.newValue || "-"
                }`;
                return (
                  <div key={i} className="mb-2 elipsis" title={value}>
                    {`${LABELS[item.name]}: ${value}`}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }

      if (type === USER_EVENT_TYPES.ACCESS_GRANTED) {
        content = (
          <div className="mb-6 mx-6 content-box">
            <span className="text-md h pr-1">Access Number:</span>
            <span className="text-md pre-line">
              {fields[0].newValue || "-"}
            </span>
          </div>
        );
      }

      if (
        [
          USER_EVENT_TYPES.PORTAL_ACCESS_ADDED,
          USER_EVENT_TYPES.PORTAL_ACCESS_REMOVED,
        ].includes(type)
      ) {
        content = (
          <div className="mb-6 mx-6 content-box">
            <div className="text-md h mb-2">Updated Data:</div>
            <div>Portal access</div>
            <div
              className={`${
                type === USER_EVENT_TYPES.PORTAL_ACCESS_REMOVED
                  ? "line-through"
                  : ""
              } word-break`}
            >
              {fields[0].newValue}: {fields[1].newValue}
            </div>
          </div>
        );
      }

      return content;
    },
    [updatedCondition]
  );

  return [getHeader, getBody];
};
