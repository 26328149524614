import qs from "qs";
import { API_URL } from "../../../configs/apiUrl";
import authAxios, { authHeader } from "../../data";
import { IImpDistributionPost } from "../../../types/Distribuions";

const fetchAll = async (request: any) => {
  const response = await authAxios.get(API_URL + "ImpoundDistributions", {
    params: request,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (request: { impoundId: string | null }) => {
  const response = await authAxios.get(API_URL + "ImpoundDistributions/Count", {
    params: request,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const deleteOne = async (id: string) => {
  const response = await authAxios.delete(
    API_URL + "ImpoundDistributions/" + id,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const create = async (request: IImpDistributionPost) => {
  const response = await authAxios.post(
    API_URL + "ImpoundDistributions",
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const DistributionService = {
  fetchAll,
  fetchCount,
  deleteOne,
  create,
};

export default DistributionService;
