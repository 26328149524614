import { Row, Table } from "antd";
import { useMemo } from "react";
import { useGetAccountsTableColumns } from "./hooks/getAccountsTableColumns";
import { useLoadData } from "./hooks/loadData";

type PortalAccessProps = {
  customerId: string;
};

export const Accounts = ({ customerId }: PortalAccessProps) => {
  const { columns } = useGetAccountsTableColumns();

  const { data, total, isLoading } = useLoadData(customerId);

  const scroll = useMemo(() => {
    return data.length > 5 ? { y: 185 } : undefined;
  }, [data]);

  const paginationProps: false | any = useMemo(() => {
    return total <= 10
      ? false
      : {
          size: "small",
        };
  }, [total]);

  return (
    <Row className="justify-space-between align-center">
      <h5 className="h h5 card-header">Accounts</h5>
      <Row className="mt-6 table-small">
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          pagination={paginationProps}
          className="overflow-y-auto"
          rowKey="id"
          sticky
          scroll={scroll}
        />
      </Row>
    </Row>
  );
};
