import { useAppDispatch } from "../../../../../../store";
import { removePortalAccess } from "../../../../../../acions/customers/portalAccesses";

export const useRemovePortalAccess = () => {
  const dispatch = useAppDispatch();

  const remove = (
    portalAccessId: number,
    onRemoveClicked: (value: boolean) => void,
    onRemoved: () => void
  ) => {
    onRemoveClicked(true);
    Promise.resolve(dispatch(removePortalAccess(portalAccessId)))
      .then(() => {
        onRemoveClicked(false);
        onRemoved();
      })
      .catch(() => onRemoveClicked(false));
  };

  return { remove };
};
