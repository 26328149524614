import { Button, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useMemo } from "react";

type EditPanelProps = {
  isDisabled: boolean;
  update: () => void;
  className: string;
  onCancelChanges: () => void;
};

export const EditPanel = ({
  isDisabled,
  update,
  className,
  onCancelChanges,
}: EditPanelProps) => {
  const classNameProp = useMemo(
    () =>
      `py-2 px-8 z-10 d-flex justify-end edit-panel-container position-absolute ${className}`,
    [className]
  );

  return (
    <div className={classNameProp}>
      <Form.Item className="mb-0">
        <Button
          type="primary"
          shape="round"
          className="mr-4"
          disabled={isDisabled}
          onClick={update}
        >
          Save Changes
          {isDisabled && <LoadingOutlined />}
        </Button>
      </Form.Item>

      <Button shape="round" onClick={onCancelChanges} disabled={isDisabled}>
        Cancel
      </Button>
    </div>
  );
};
