import { Form, Input, InputRef } from "antd";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  EMAIL_REQUIRED,
  MAX_EMAIL,
  SELECT_OR_CREATE_USER,
  WRONG_EMAIL_FORMAT,
} from "../../../../../../lib/constants";
import { EMAIL_RULE } from "../../../../../../lib/validationRules";
import CustomerPortalAccessesService from "../../../../../../services/customers/portalAccesses";
import { CloseCircleFilled } from "@ant-design/icons";
import { FormInstance } from "rc-field-form";

type SearchEmailProps = {
  form: FormInstance;
  customerId: string | null | undefined;
  search: string;
  debouncedSearch: string;
  isLoading: boolean;
  activeTab: string;
  tabNumber: "1" | "2";
  setIsLoading: (value: boolean) => void;
  onDataFetched: (value: any[], show: boolean) => void;
  onSearchChange: (e: any) => void;
  onClearSearch: () => void;
  showMessage: boolean;
  hideLabel?: boolean;
};

export const SearchEmail = ({
  form,
  customerId,
  search,
  debouncedSearch,
  isLoading,
  activeTab,
  tabNumber,
  setIsLoading,
  onSearchChange,
  onClearSearch,
  onDataFetched,
  showMessage,
  hideLabel,
}: SearchEmailProps) => {
  useEffect(() => {
    if (form.getFieldValue(["newUser", "email"]) === "") {
      onClearSearch();
    }
  }, [onClearSearch, form]);

  const ref = useRef<InputRef>(null);

  useEffect(() => {
    if (!isLoading) {
      ref.current?.focus();
    }
  }, [isLoading, activeTab]);

  useEffect(() => {
    if (debouncedSearch && customerId && activeTab === tabNumber) {
      if (debouncedSearch.length > 2) {
        setIsLoading(true);
        CustomerPortalAccessesService.fetchExistingUsers(
          debouncedSearch,
          customerId
        )
          .then((res: any) => {
            setIsLoading(false);
            onDataFetched(res, true);
          })
          .catch(() => setIsLoading(false));
      }
    } else {
      onDataFetched([], false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const onClearEmail = useCallback((): void => {
    onClearSearch();
    form.setFieldValue(["newUser", "email"], undefined);
  }, [form, onClearSearch]);

  const rules = useMemo(
    () => [
      {
        pattern: EMAIL_RULE,
        message: activeTab === "1" ? WRONG_EMAIL_FORMAT : SELECT_OR_CREATE_USER,
        validateTrigger: "onSubmit",
      },
      {
        max: 100,
        message: activeTab === "1" ? MAX_EMAIL : SELECT_OR_CREATE_USER,
        validateTrigger: "onSubmit",
      },
      {
        required: true,
        message: EMAIL_REQUIRED,
        validateTrigger: "onSubmit",
      },
    ],
    [activeTab]
  );

  const statusProps = useMemo(
    () => (showMessage ? "error" : ""),
    [showMessage]
  );

  const suffixProps = useMemo(
    () =>
      search !== "" ? (
        <CloseCircleFilled className="search-icon" onClick={onClearEmail} />
      ) : (
        <div />
      ),
    [onClearEmail, search]
  );

  const placeholder = useMemo(
    () => (hideLabel ? "Search by email" : ""),
    [hideLabel]
  );

  const label = useMemo(() => (hideLabel ? undefined : "Email"), [hideLabel]);

  return (
    <Form.Item
      name={["newUser", "email"]}
      label={label}
      className="w-100 email-search-form-item"
      rules={rules}
    >
      <Input
        placeholder={placeholder}
        className="search-users-input"
        onChange={onSearchChange}
        value={search}
        ref={ref}
        disabled={isLoading}
        suffix={suffixProps}
        status={statusProps}
      />
    </Form.Item>
  );
};
