import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../actionsCreators/messageActions";
import {
  DELETE_USER_SUCCESS,
  UPDATE_RECORD_SUCCESS,
  BATCH_CREATED,
  DAY_INITIALIZED,
  BATCH_WAS_CALCULATED,
} from "../../lib/constants";
import BatchesService from "../../services/processing/batches";
import { IBatchPost } from "../../types/Batches";

export const deleteBatch =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await BatchesService.remove(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const editBatch =
  (
    entry: IBatchPost,
    id: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await BatchesService.edit(entry, id);

      dispatch(setSuccessMessage(UPDATE_RECORD_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const createBatch =
  (entry: IBatchPost): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await BatchesService.create(entry);

      dispatch(setSuccessMessage(BATCH_CREATED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const initializeDay =
  (req: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await BatchesService.initializeDate(req);

      dispatch(setSuccessMessage(DAY_INITIALIZED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const validateBatch =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await BatchesService.validate(id);

      dispatch(setSuccessMessage(BATCH_WAS_CALCULATED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
