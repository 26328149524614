import { Form, Input } from "antd";

type NameFormItemProps = {
  path: string;
  label: string;
  name: string;
  rules: any[];
};

export const NameFormItem = ({
  path,
  label,
  name,
  rules,
}: NameFormItemProps) => {
  return (
    <Form.Item
      className="mb-0 w-100"
      name={[path, name]}
      label={label}
      rules={rules}
    >
      <Input />
    </Form.Item>
  );
};
