import { useMemo } from "react";

export const useGetUSAStates = (isShortLabel: boolean) => {
  const statesList = useMemo(() => {
    const UsaStates = require("usa-states").UsaStates;
    const usStates = new UsaStates();

    return usStates.states.map((item: any) => {
      const value = `${item.name} (${item.abbreviation})`;

      return {
        label: isShortLabel ? `${item.abbreviation}` : value,
        value,
      };
    });
  }, [isShortLabel]);

  return { statesList };
}