import {
  Card,
  Form,
  FormInstance,
  Row,
  Input,
  InputNumber,
  Checkbox,
  Dropdown,
  Button,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { EditPanel } from "../Details/components/EditPanel";
import { useUpdateData } from "./hooks/useEditData";
import { useDropdown } from "./hooks/useDropdown";
import { ILegal } from "../../../../../../types/Legal";

const { TextArea } = Input;

type TLegalProps = {
  contractId: string;
  primarySellerId: string;
  primaryBuyerId: string;
  active: string;
  data: ILegal | null;
  isLoading: boolean;
};

export const Legal = ({
  contractId,
  primaryBuyerId,
  primarySellerId,
  active,
  data,
  isLoading,
}: TLegalProps) => {
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [isDisabled, setIsDisabled] = useState<boolean>(isLoading);

  const [form]: FormInstance[] = Form.useForm();

  const { items, isLoading: isCustomersLoading } = useDropdown({
    primaryBuyerId,
    primarySellerId,
    form,
    setHasChanges,
  });
  const onFieldsChange = useCallback(() => setHasChanges(true), []);

  const reloadData = useCallback(() => {
    setHasChanges(false);
    setIsDisabled(isLoading);
  }, [isLoading]);

  const { update } = useUpdateData({ setIsDisabled, form, contractId });

  const handleUpdate = useCallback(() => {
    update(reloadData);
  }, [reloadData, update]);

  const onCancelChanges = useCallback(() => {
    form.resetFields();
    setHasChanges(false);
  }, [form]);

  const initialValues = useMemo(
    () =>
      data
        ? data
        : {
            numberOfSecuringProperties: 1,
            isSecuringPropertyAddressTheSame: true,
          },
    [data]
  );

  useEffect(() => {
    data && form.setFieldsValue(data);

    setIsDisabled(isLoading);
  }, [data, form, initialValues, isLoading]);

  return (
    <Row className="w-100 px-8 py-6">
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className="legal-form"
        disabled={isDisabled}
        onFieldsChange={onFieldsChange}
        initialValues={initialValues}
      >
        <Card className="w-100 mb-4">
          <h5 className="h h5 ant-table-title mb-6">Property Being Sold</h5>
          <Row className="w-100 d-flex">
            <Form.Item
              label="Property Address"
              name="propertyAddress"
              className="flex-grow-1 pr-2 mb-4"
            >
              <TextArea className="legal-form-textarea" />
            </Form.Item>
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              className="mt-6"
              disabled={isCustomersLoading || isDisabled}
            >
              <Button icon={<MoreOutlined />} shape="circle" />
            </Dropdown>
          </Row>
          <Form.Item
            label="Additional Description"
            name="propertyDescription"
            className="mb-4"
          >
            <TextArea className="legal-form-textarea" rows={4} />
          </Form.Item>
        </Card>
        <Card className="w-100">
          <h5 className="h h5 ant-table-title mb-6">
            1098 Mortgage Address Information
          </h5>
          <Form.Item
            label="Number of Properties Securing the Mortgage"
            name="numberOfSecuringProperties"
            className="mb-4"
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            className="mb-4"
            name="isSecuringPropertyAddressTheSame"
            valuePropName="checked"
          >
            <Checkbox>
              Property address securing the mortgage is the same as primary
              buyer address
            </Checkbox>
          </Form.Item>
          <Form.Item
            label="Property Address Securing the Mortgage (specify if not the primary buyer address)"
            name="securingPropertyAddress"
            className="mb-4"
          >
            <TextArea className="legal-form-textarea" />
          </Form.Item>
        </Card>
      </Form>
      {hasChanges && active === "6" && (
        <>
          <EditPanel
            isDisabled={isDisabled}
            update={handleUpdate}
            className="edit-panel-container-top-zero"
            onCancelChanges={onCancelChanges}
          />
        </>
      )}
    </Row>
  );
};
