import { useCallback } from "react";

export const useCalcUnpaidInterestBefore = (
  calcInterestBalance: (history: any) => void,
  calcPrincipalPaid: (history: any) => void,
  calcPrincipalBalance: (history: any) => void
) => {
  const calcUnpaidInterestBefore = useCallback(
    (history: any) => {
      const { breakdown, contract } = history;

      const { interestAmount, principalAmount, interestAfter } = breakdown;

      calcInterestBalance(history);

      if (interestAfter < 0 && contract.paymentType !== 2) {
        history.breakdown.interestAmount = +interestAmount + +interestAfter;

        calcInterestBalance(history);
      }

      calcPrincipalPaid(history);

      if (+principalAmount < 0) {
        history.breakdown.interestAmount = +interestAmount + +principalAmount;

        calcInterestBalance(history);
        calcPrincipalPaid(history);
      }

      calcPrincipalBalance(history);
    },
    [calcInterestBalance, calcPrincipalBalance, calcPrincipalPaid]
  );

  return { calcUnpaidInterestBefore };
};
