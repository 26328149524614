import { useCallback, useState } from "react";
import ContractsService from "../../../../../services/accounts/contracts";

export const useSearchCustomer = (
  name: string[],
  setFieldError: (errorMessages: string[]) => void,
  onCustomerFound: (number: number | null, name: string[], customerName?: string) => void
) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [customer, setCustomer] = useState<any>(null);

  const search = useCallback(
    (number: number): void => {
      setIsDisabled(true);

      ContractsService.searchCustomerByNumber(number)
        .then((res: any) => {
          setFieldError([]);
          setIsDisabled(false);

          if (res && res.length > 0) {
            const customer = res[0];
            setCustomer(customer);
            onCustomerFound(customer.id, name, customer.name);
          } else {
            setFieldError(["Customer was not found"]);
            setCustomer(null);
            onCustomerFound(null, name);
          }
        })
        .catch((error) => {
          setIsDisabled(false);
          setCustomer(null);

          const { response } = error;
          const { data } = response;

          setFieldError([data.title]);
        });
    },
    [name, onCustomerFound, setFieldError]
  );

  return { customer, setCustomer, isDisabled, search };
};
