import { Form, Row, Col, Input, Select } from "antd";
import { PhoneInput } from "../../../../../components/PhoneInput";
import { useValidateOnBlur } from "../../../../../lib/hooks/validateOnBlur";
import { useMemo, useState, useEffect } from "react";
import { useGetRole } from "../../../../../lib/hooks/getRole";
import { useAppDispatch } from "../../../../../store";
import { updateUser } from "../../../../../acions/users";
import { getFormatted } from "../../../../../lib/PhoneFormatter";
import { updateUserData } from "../../../../../acions/auth";
import { FullNameFormItems } from "../../FullNameFormItems";
import { useGetEmailFormRules } from "../../../../../lib/hooks/getEmailFormRules";

type EditProfileProps = {
  user: any;
  roles: any[];
  isSaveClicked: boolean;
  setIsSaveClicked: (value: boolean) => void;
  reloadData: () => void;
};

export const EditProfile = ({
  user,
  roles,
  isSaveClicked,
  setIsSaveClicked,
  reloadData,
}: EditProfileProps) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const [validateOnBlur] = useValidateOnBlur(form);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [role, _, data] = useGetRole();

  const [isRoleSelectDisabled, setIsRoleSelectDisabled] = useState(false);

  const [selectedRole, setSelectedRole] = useState<any>(undefined);

  const [isDataDisabled, setIsDataDisabled] = useState(false);

  const [phone, setPhone] = useState<string | null>(null);

  const { rules: emailRules } = useGetEmailFormRules();

  const formattedRoles = useMemo(() => {
    let value = roles;

    if (role === "Admin") {
      setIsRoleSelectDisabled(true);
    } else {
      if (user?.role === "Admin" || user?.role === "SuperAdmin") {
        value = roles.filter((item) => item.label !== "User");
      }
    }

    if (user?.role === "User") {
      setIsRoleSelectDisabled(true);
    }

    return value;
  }, [roles, user, role]);

  useEffect(() => {
    if (isSaveClicked) {
      form
        .validateFields()
        .then(() => {
          const request = {
            ...form.getFieldsValue(),
            phone: `1${form.getFieldValue("phone").replace(/[\s-()]/g, "")}`,
            roleId: selectedRole,
          };

          setIsDataDisabled(true);
          setIsRoleSelectDisabled(true);

          const isMe: boolean = data.uid === user?.id;

          Promise.resolve(
            dispatch(updateUser(request, user?.id, roles, isMe, user?.role))
          )
            .then(() => {
              isMe &&
                dispatch(
                  updateUserData({
                    firstName: request.firstName,
                    lastName: request.lastName,
                  })
                );

              setIsSaveClicked(false);
              reloadData();
            })
            .catch(() => {
              setIsSaveClicked(false);
              reloadData();
            });
        })
        .catch(() => setIsSaveClicked(false));
    }
  }, [
    isSaveClicked,
    form,
    selectedRole,
    setIsSaveClicked,
    dispatch,
    user,
    reloadData,
    data.uid,
    roles,
  ]);

  useEffect(() => {
    const initialRole = roles.find((item) => item.label === user?.role);

    setPhone(getFormatted(user?.phone).slice(2));

    if (initialRole) {
      setSelectedRole(initialRole.value);
    }
  }, [roles, user]);

  useEffect(() => {
    if (role === "Admin") {
      user?.role === "SuperAdmin" && setIsDataDisabled(true);

      user?.role === "Admin" &&
        user?.id !== data.uid &&
        setIsDataDisabled(true);
    }
  }, [role, user, data]);

  return (
    <div className="mt-8 mx-6">
      <Form
        autoComplete="off"
        requiredMark={false}
        layout="vertical"
        form={form}
        className="user-management-form"
        onFinishFailed={() => setIsSaveClicked(false)}
      >
        <FullNameFormItems
          form={form}
          initialValues={user}
          isDisabled={isDataDisabled}
        />

        <Row className="align-center mb-4">
          <Col span={8}>
            <span className="text-md title">Email</span>
          </Col>
          <Col span={16}>
            <Form.Item
              name="email"
              className="mb-0"
              initialValue={user?.email}
              rules={emailRules}
            >
              <Input
                onBlur={(e) => validateOnBlur(e, "email")}
                disabled={isDataDisabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="align-center mb-4">
          <Col span={8}>
            <span className="text-md title">Phone</span>
          </Col>
          <Col span={16}>
            {phone !== null && (
              <PhoneInput
                form={form}
                size="middle"
                formItemMargin="mb-0"
                initialValue={phone}
                disabled={isDataDisabled}
              />
            )}
          </Col>
        </Row>

        <Row className="align-center mb-4">
          <Col span={8}>
            <span className="text-md title">Role</span>
          </Col>
          <Col span={16}>
            <div className="role-selector">
              <Select
                options={formattedRoles}
                defaultValue={user?.role}
                disabled={isRoleSelectDisabled}
                onChange={(value) => setSelectedRole(value)}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
