import { useCallback, useMemo } from "react";
import { useFetchCustomerData } from "../../Payoff/components/PayoffForm/hooks/useFetchCustomerData";
import { TFormattedCustomerData } from "../../Payoff/types";
import { FormInstance, MenuProps } from "antd";

type TDropdownParams = {
  primaryBuyerId: string;
  primarySellerId: string;
  form: FormInstance;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useDropdown = ({
  primaryBuyerId,
  primarySellerId,
  form,
  setHasChanges,
}: TDropdownParams) => {
  const { isLoading, data } = useFetchCustomerData(
    primaryBuyerId,
    primarySellerId
  );

  const onDropdownChange = useCallback(
    (value?: TFormattedCustomerData) => {
      form.setFieldValue("propertyAddress", value?.receiver.addressLine);
      setHasChanges(true);
    },
    [form, setHasChanges]
  );

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: (
          <div onClick={() => onDropdownChange(data?.primaryBuyer)}>
            Set Primary Buyer Address
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div onClick={() => onDropdownChange(data?.primarySeller)}>
            Set Primary Seller Address
          </div>
        ),
      },
    ],
    [data, onDropdownChange]
  );

  return { items, isLoading };
};
