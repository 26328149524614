import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../store";
import { initializeDay } from "../../../acions/processing/batches";

export const useInitializeDay = (reloadData: () => void) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onInitializeDayClick = useCallback(async () => {
    try {
      const request = { day: dayjs() };

      setIsLoading(true);
      await Promise.resolve(dispatch(initializeDay(request)));

      setIsLoading(false);
      reloadData();
    } catch (error) {
      setIsLoading(false);
    }
  }, [dispatch, reloadData]);

  return { isLoading, onInitializeDayClick };
};
