import { ReactNode } from "react";
import { getFormattedCurrency } from "../../../../lib/currencyFormatter";

export function formatNumberValuesOnRender(value: number): ReactNode {
  const result = getFormattedCurrency(value);

  return (
    <div className="cell-elipsis" title={result}>
      {result}
    </div>
  );
}
