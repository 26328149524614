import { Tag } from "antd";
import { getTagColorProps } from "../../../lib/accountsStatusColor";
import { ACCOUNT_STATUSES, NO_STATUS_LABEL } from "../../../lib/constants";
import { useGetRole } from "../../../lib/hooks/getRole";

export const useStatusTag = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  const getStatus = (value: string | number) => {
    if (value === 7) {
      return NO_STATUS_LABEL;
    }

    const val = isAdmin
      ? ACCOUNT_STATUSES.find((item: any) => {
          return item.value === value;
        })?.label
      : value;

    return value || value === 0 ? (
      <Tag {...getTagColorProps(value)}>{val || NO_STATUS_LABEL}</Tag>
    ) : (
      NO_STATUS_LABEL
    );
  };

  return { getStatus };
};
