import "./styles.scss";
import { Card, Row } from "antd";
import { CustomModal } from "../../lib/CustomModal";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

type HelpModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};

export const HelpModal = ({ isModalOpen, setIsModalOpen }: HelpModalProps) => {
  return (
    <CustomModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      titleText={"We're here to assist"}
      width={450}
    >
      <div className="mt-10 mr-3 mb-3 ml-3">
        <Row>
          <Card style={{ width: "100%" }} className="mb-6">
            <h5 className="h h5 mb-4">Call Us</h5>
            <div className="mb-8">
              <a href="tel:1-505-237-2225" className="card-link">
                <div className="d-flex align-center">
                  <PhoneOutlined className="contact-icon mr-4" />
                  <h4 className="h">1-505-237-2225</h4>
                </div>
              </a>
            </div>
            <p className="my-0 card-hint">
              Available Monday–Friday: 9AM–4PM (MDT)
            </p>
          </Card>

          <Card style={{ width: "100%" }}>
            <h5 className="h h5 mb-4">Email Us</h5>
            <div className="mb-8">
              <a
                href="mailto:info@sunwestescrow.com"
                className="card-link underline"
              >
                <div className="d-flex align-center">
                  <MailOutlined className="contact-icon mr-4" />
                  <h4 className="h">info@sunwestescrow.com</h4>
                </div>
              </a>
            </div>
            <p className="my-0 card-hint">
              Respond Monday–Friday within 24–48 hours
            </p>
          </Card>
        </Row>
      </div>
    </CustomModal>
  );
};
