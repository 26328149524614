import { useEffect, useState } from "react";
import { UsersService } from "../../../services/users";

export const useFetchUserRoles = () => {
  const [roles, setRoles] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect((): void => {
    UsersService.fetchRolesList()
      .then((res) => {
        setIsLoading(false);

        const val = res.map((role: any) => ({
          label: role.name,
          value: role.id,
        }));

        setRoles(val);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return { roles, isLoading };
};
