import { useCallback } from "react";
import { deleteUserCustomer } from "../../../../../../../acions/users/usersCustomers";
import { useDefaultDeleteModal } from "../../../../../../../lib/hooks/defaultDeleteModal";

export const useButtonHandlers = (reloadData: () => void) => {
  const { openDeleteModal } = useDefaultDeleteModal(
    deleteUserCustomer,
    reloadData
  );

  const onDeleteBtnClick = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Portal Access");
    },
    [openDeleteModal]
  );

  return { onDeleteBtnClick };
};
