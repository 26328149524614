import { Input, InputRef } from "antd";
import { useCallback, useEffect, useMemo, useRef } from "react";

type SearchWithClearProps = {
  isLoading: boolean;
  search?: string;
  setSearch: (value: string) => void;
};

const { Search } = Input;

export const SearchWithClear = ({
  isLoading,
  setSearch,
  search,
}: SearchWithClearProps) => {
  const ref = useRef<InputRef>(null);

  useEffect(() => {
    if (!isLoading) {
      ref.current?.focus();
    }
  }, [isLoading]);

  const onSearch = useCallback(
    (val: any) => {
      if (!val) {
        setSearch("");
      } else {
        const value = ref.current?.input?.value;

        setSearch(value || "");
      }
    },
    [setSearch]
  );

  const key: string = useMemo(() => `${search}Search`, [search]);

  return (
    <Search
      key={key}
      placeholder="Search"
      ref={ref}
      onPressEnter={onSearch}
      allowClear
      onSearch={onSearch}
      loading={isLoading}
      defaultValue={search || ""}
    />
  );
};
