import { useEffect, useState } from "react";
import { useGetDashboardData } from "./getDashboardData";
import { DashboardService } from "../../../services/dashboard";

export const useFetchData = () => {
  const [data, setData] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [noKpi, setNoKpi] = useState<boolean>(false);

  const [opened, closed, other, draft] = useGetDashboardData(data);

  useEffect(() => {
    DashboardService.fetchDashboardData()
      .then((res) => {
        setData(res);

        if (res && res.kpiValues.length === 0) {
          setNoKpi(true);
        }
      })
      .catch(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (opened && closed && other !== null) {
      setIsLoading(false);
    }
  }, [closed, opened, other]);

  return {
    opened,
    closed,
    other,
    draft,
    isLoading,
    data,
    noKpi,
  };
};
