import { ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const useOpenDataBySearchParam = (
  onOpenSideBar: (
    header: string | ReactNode,
    userId: string,
    isEdit?: boolean
  ) => void,
  key: string
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const id: string | null = searchParams.get(key);

    if (id) {
      onOpenSideBar("", id);
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  }, [onOpenSideBar, searchParams, setSearchParams, key]);
};
