import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";

const initialize = async (req: { batchId: string; contractId: string }) => {
  const result = await authAxios.get(API_URL + "BatchPayments/Initialize", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetchAll = async (req: {
  batchId: string;
  pageSize: number;
  pageNumber: number;
  status?: number;
  orderBy?: number;
}) => {
  const result = await authAxios.get(API_URL + "BatchPayments", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetchCount = async (req: { batchId: string; status?: number }) => {
  const result = await authAxios.get(API_URL + "BatchPayments/Count", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetch = async (id: string) => {
  const result = await authAxios.get(API_URL + "BatchPayments/" + id, {
    headers: authHeader(),
  });

  return result.data;
};

const remove = async (id: string) => {
  const result = await authAxios.delete(API_URL + "BatchPayments/" + id, {
    headers: authHeader(),
  });

  return result.data;
};

const calculate = async (req: any) => {
  const result = await authAxios.put(API_URL + "BatchPayments/Calculate", req, {
    headers: authHeader(),
  });

  return result.data;
};

const saveAsDraft = async (req: any) => {
  const result = await authAxios.post(API_URL + "BatchPayments", req, {
    headers: authHeader(),
  });

  return result.data;
};

const update = async (id: string, req: any) => {
  const result = await authAxios.put(`${API_URL}BatchPayments/${id}/`, req, {
    headers: authHeader(),
  });

  return result.data;
};

const toggle = async (id: string, isPost: boolean) => {
  const result = await authAxios.put(
    `${API_URL}BatchPayments/${id}/${isPost ? "" : "un"}post`,
    {},
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const BatchPaymentsService = {
  initialize,
  fetchAll,
  fetchCount,
  fetch,
  remove,
  calculate,
  saveAsDraft,
  toggle,
  update,
};

export default BatchPaymentsService;
