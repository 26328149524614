import { API_URL } from "../configs/apiUrl";
import authAxios, { authHeader } from "./data";

const getProgress = async (id: string) => {
  const result = await authAxios.get(`${API_URL}BackgroundTask/${id}`, {
    headers: authHeader(),
  });

  return result.data;
};

const cancelTask = async (id: string) => {
  const result = await authAxios.post(
    `${API_URL}BackgroundTask/cancel/${id}`,
    null,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const BackGroundService = {
  getProgress,
  cancelTask,
};

export default BackGroundService;
