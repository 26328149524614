import { useCallback } from "react";
import { IImpoundsGet } from "../../../../../../../../../types/Impounds";
import { useAppDispatch } from "../../../../../../../../../store";
import { createPayeeDisb } from "../../../../../../../../../acions/accounts/payeeDisbursements";
import {
  DISBURSEMENTS_METHOD_TYPES,
  DISBURSEMENTS_STATUS,
  PAYEE_TYPES,
} from "../../../../../../../../../lib/constants";

type TCreateImpoundDisbursementProps = {
  setShouldPayeeDisbUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  contractId?: string;
};

export const useCreateImpoundDisbursement = ({
  setShouldPayeeDisbUpdate,
  setIsDisabled,
  contractId,
}: TCreateImpoundDisbursementProps) => {
  const dispatch = useAppDispatch();

  const createImpoundDisbursement = useCallback(
    async (entry: IImpoundsGet) => {
      try {
        setIsDisabled(true);

        const request: any = {
          enableForPayments: true,
          amount: entry.amount,
          percentage: null,
          payeeName: entry.payeeName,
          payeeId: entry.payeeId,
          payeeNumber: entry.payeeNumber,
          contractId,
          impoundId: entry.id,
          type: PAYEE_TYPES[2].value,
          method: DISBURSEMENTS_METHOD_TYPES[2].value,
          status: DISBURSEMENTS_STATUS[0].value,
          isAlternate: false,
          description: "Impound",
        };

        await Promise.resolve(dispatch(createPayeeDisb(request)));

        setShouldPayeeDisbUpdate(true);
        setIsDisabled(false);
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [contractId, dispatch, setIsDisabled, setShouldPayeeDisbUpdate]
  );

  return { createImpoundDisbursement };
};
