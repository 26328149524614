import { Button, Row } from "antd";
import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../../../lib/constants";
import { getFormattedCurrency } from "../../../../../../lib/currencyFormatter";
import { BankOutlined } from "@ant-design/icons";
import { HowToPayModal } from "./HowToPayModal";

type NextPaymentProps = {
  data: any;
};

export const NextPayment = ({ data }: NextPaymentProps) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const date = useMemo(() => {
    const { paymentDate } = data;

    const value = paymentDate ? dayjs(paymentDate).format(DATE_FORMAT) : "-";

    const isPast = paymentDate ? dayjs().isAfter(dayjs(paymentDate)) : false;

    return {
      value,
      isPast,
    };
  }, [data]);

  const openModal = useCallback(() => setIsOpenModal(true), []);

  return (
    <>
      <h5 className="h h5 header-text">Next Payment</h5>
      {data.amount > 0 ? (
        <Row className="d-block">
          <Row className="justify-space-between mt-6">
            <p className="text my-0">Payment Date</p>
            <p className={`${date.isPast ? "error" : ""} text my-0 w-500`}>
              {date.value}
            </p>
          </Row>
          <Row className="justify-space-between mt-1">
            <p className="text my-0">Payment Amount</p>
            <p className="text my-0 w-500">
              {getFormattedCurrency(data.amount)}
            </p>
          </Row>
          <Button
            className="mt-8 mb-2"
            type="primary"
            shape="round"
            size="large"
            block
            onClick={openModal}
          >
            Make Payment
          </Button>
          <Row className="justify-space-between mb-6">
            <Row className="align-center">
              <BankOutlined className="text-md title mr-1" />
              <p
                className="mb-0 text-sm title w-500"
                style={{ marginTop: "2px" }}
              >
                Bank transfers only
              </p>
            </Row>
            <Button
              type="link"
              className="px-0 py-0 text-sm w-500"
              onClick={openModal}
            >
              How to Pay?
            </Button>
          </Row>
          <Row className="justify-center">
            <Button
              type="link"
              className="py-0"
              style={{ height: "24px" }}
              onClick={window.print}
            >
              Print Coupon
            </Button>
          </Row>
          <HowToPayModal
            isModalOpen={isOpenModal}
            setIsModalOpen={setIsOpenModal}
          />
        </Row>
      ) : (
        <div className="mt-4 text" style={{ textAlign: "center" }}>
          No next payments
        </div>
      )}
    </>
  );
};
