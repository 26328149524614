import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";
import qs from "qs";

const fetchAll = async (parentId: string | null ) => {
  const result = await authAxios.get(API_URL + "UsersCustomers", {
    params: { parentId },
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const remove = async (id: string) => {
  const result = await authAxios.delete(API_URL + "UsersCustomers/" + id, {
    headers: authHeader(),
  });

  return result.data;
};

const UserCustomersService = {
  fetchAll,
  remove,
};

export default UserCustomersService;