import "./styles.scss";
import ROUTES from "../../../configs/routesConfig";
import { Row, Button } from "antd";
import logo from "../../../images/logo.svg";
import { UserOutlined, SettingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useCallback, useState, MouseEvent } from "react";
import { HelpModal } from "../../HelpModal";
import { logout } from "../../../acions/auth";
import { useAppDispatch } from "../../../store";
import { useNavigate, NavigateFunction, Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useLocation, Location } from "react-router-dom";
import { useLogoRedirectPath } from "../../../lib/hooks/useLogoRedirectPath";
import { useGetRole } from "../../../lib/hooks/getRole";
import { NavItems } from "./NavItems";
import { setLocation } from "../../../acions/actionsCreators/nextLocation";
import { MobileMenu } from "./MobileMenu";

export const Header = () => {
  const dispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  const user: any = useSelector((state: any) => state.auth.user);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { logoRedirectPath } = useLogoRedirectPath();

  const onLogoutClicked = useCallback((): void => {
    Promise.resolve(dispatch(logout()))
      .then(() => navigate(ROUTES.LOGIN))
      .catch(() => {});
  }, [dispatch, navigate]);

  const setNextLocation = useCallback(
    (e: MouseEvent<HTMLAnchorElement>): void => {
      const path = e.currentTarget.id;

      dispatch(setLocation(path));
    },
    [dispatch]
  );

  const openModal = useCallback(() => setIsModalOpen(true), []);

  return (
    <>
      <Row className="justify-space-between logged-header__container">
        <div className="d-flex">
          <Link
            className="py-5 mr-8"
            to={logoRedirectPath}
            id={logoRedirectPath}
            onClick={setNextLocation}
          >
            <img
              src={logo}
              alt="Sunwest Escrow"
              className="logo-cursor"
              width={102}
              height={24}
            />
          </Link>
          {isAdmin && (
            <NavItems
              location={location}
              navigate={navigate}
              setNextLocation={setNextLocation}
            />
          )}
        </div>
        <div className="d-flex">
          <Link
            className={`
              align-center desktop-btn header-button py-0 px-4 ${
                location.pathname === ROUTES.PROFILE ? "active" : ""
              }`}
            to={ROUTES.PROFILE}
            id={ROUTES.PROFILE}
            onClick={setNextLocation}
          >
            <UserOutlined className="mr-2" />
            <span className="user-header-label elipsis">{`${user.firstName} ${user.lastName}`}</span>
          </Link>
          {isAdmin && (
            <Link
              className={`d-flex align-center header-button py-0 px-4 ${
                location.pathname === ROUTES.ADMINISTRATION ? "active" : ""
              }`}
              to={ROUTES.ADMINISTRATION}
              id={ROUTES.ADMINISTRATION}
              onClick={setNextLocation}
            >
              <SettingOutlined />
            </Link>
          )}
          <Button type="text" className="header-button" onClick={openModal}>
            Help
          </Button>
          <Button
            type="text"
            className="header-button desktop-btn"
            onClick={onLogoutClicked}
          >
            Log out
          </Button>
          <MobileMenu onLogoutClicked={onLogoutClicked} />
        </div>

        <HelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </Row>

      <Outlet />
    </>
  );
};
