import { useCallback, useEffect, useState } from "react";
import ContractLateChargesService from "../../../../../services/accounts/lateCharges";

export const useFetchTotal = (contractId: string) => {
  const [data, setData] = useState<any>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback((): void => {
    setIsLoading(true);
    ContractLateChargesService.fetchTotal(contractId)
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [contractId]);

  useEffect(() => fetchData(), [fetchData]);

  return { data, isLoading, fetchData };
};
