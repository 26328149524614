import ROUTES from "../../configs/routesConfig";

import { Navigate } from "react-router-dom";
import AuthService from "../../services/auth";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { resetUser } from "../../acions/auth";
import { PrivateRouteContainer } from "./PrivateRouteContainer";

export const PrivateRoutes = () => {
  const dispatch = useAppDispatch();

  const isLoggedIn = AuthService.isLoggedIn();

  const userData: any = useSelector((state: any) => state.auth.user);

  if (isLoggedIn && !userData) {
    dispatch(resetUser());
  }

  return isLoggedIn ? (
    <PrivateRouteContainer userData={userData} />
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
};
