import { Row, Col, Button, Form, Checkbox, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Timer } from "../../lib/Timer";
import { ENTER_6_DIGITS } from "../../lib/constants";
import { useAppDispatch } from "../../store";
import { useState, useMemo, useCallback, ReactNode } from "react";
import { ListLinks } from "../Guest/ListLinks";
import { GuestLink } from "../../types/GuestLink";
import MaskedInput from "react-text-mask";
import { Header } from "./Header";
import {
  SECURITY_CODE_MASK_RULE,
  SECURITY_CODE_RULE,
} from "../../lib/validationRules";
import { useValidateOnBlur } from "../../lib/hooks/validateOnBlur";
import { clearMessage } from "../../acions/actionsCreators/messageActions";
import "./styles.scss";

type SecurityCheckProps = {
  header: string;
  setStep: () => void;
  isRememberCode: boolean;
  isTimer: boolean;
  subTitle?: ReactNode;
  onConfirm: (value?: string) => void;
  type: "email" | "SMS";
  removeBackButton?: boolean;
  isConfirmDisabled: boolean;
  onResend: () => Promise<any>;
};

export const SecurityCheck = ({
  header,
  setStep,
  isRememberCode,
  isTimer,
  subTitle,
  onConfirm,
  type,
  removeBackButton,
  isConfirmDisabled,
  onResend,
}: SecurityCheckProps) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const [timerKey, setTimerKey] = useState<boolean>(false);

  const [checked, setChecked] = useState<boolean>(true);

  const [validateOnBlur] = useValidateOnBlur(form);

  const sendCode = useCallback((): void => {
    onResend &&
      onResend().then(() => {
        setTimerKey(!timerKey);
      });
  }, [onResend, timerKey]);

  const links: GuestLink[] = useMemo(
    (): Array<GuestLink> => [
      {
        title: "Please check your spam folder or resend code",
        node: (
          <div className="check-links__container ">
            {type === "email" && (
              <span>Please check your spam folder or&ensp;</span>
            )}
            <Button
              type="link"
              className="useful-link item px-0 py-0"
              disabled={isConfirmDisabled}
              onClick={() => sendCode()}
            >
              Resend code
            </Button>
          </div>
        ),
      },
    ],
    [isConfirmDisabled, sendCode, type]
  );

  const onConfirmClicked = (values: any): void => {
    onConfirm({ ...values, rememberCode: checked });
  };

  const onBackButtonClicked = (): void => {
    setStep();
    dispatch(clearMessage());
  };

  return (
    <>
      <Row className="mt-16 justify-center">
        <Col className="security-check-content mb-8">
          <Header
            header={header}
            onBackClicked={onBackButtonClicked}
            removeBackButton={removeBackButton}
          />
          {subTitle && subTitle}
          <Row className="d-flex justify-center">
            <Col className="form-content">
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                requiredMark={false}
                onFinish={onConfirmClicked}
                disabled={isConfirmDisabled}
                className="form__container"
              >
                <div className="input__container mb-8">
                  <Form.Item
                    name="securityCode"
                    className="mb-5"
                    rules={[
                      {
                        pattern: SECURITY_CODE_RULE,
                        message: ENTER_6_DIGITS,
                        validateTrigger: "onSubmit",
                      },
                      {
                        required: true,
                        message: ENTER_6_DIGITS,
                        validateTrigger: "onSubmit",
                      },
                    ]}
                    label={
                      <div className="d-flex justify-space-between align-center">
                        <div>Your 6-digit code</div>
                        {isTimer && (
                          <Timer
                            time={5}
                            onResendClick={sendCode}
                            key={`timer-${timerKey}`}
                          />
                        )}
                      </div>
                    }
                  >
                    <MaskedInput
                      mask={SECURITY_CODE_MASK_RULE}
                      guide={false}
                      showMask
                      render={(ref, props) => {
                        const value = form.getFieldValue("securityCode");

                        const newProps = {
                          ...props,
                          onBlur: (e: any) => validateOnBlur(e, "securityCode"),
                        };

                        return (
                          <Input
                            value={value}
                            size="large"
                            ref={(input: any) => ref(input && input.input)}
                            {...newProps}
                          />
                        );
                      }}
                    />
                  </Form.Item>
                  {isRememberCode && (
                    <Form.Item name="rememberCode" noStyle>
                      <Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      >
                        Don’t ask for code again on this device for 30 days
                      </Checkbox>
                    </Form.Item>
                  )}
                  <Form.Item className="mt-8">
                    <Button
                      type="primary"
                      shape="round"
                      size="large"
                      htmlType="submit"
                      block
                    >
                      Confirm
                      {isConfirmDisabled && <LoadingOutlined />}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
              <div className="mt-12 d-flex justify-space-around bottom-links">
                <ListLinks
                  header={`I didn’t receive ${type}`}
                  links={links}
                  isComplex={true}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
