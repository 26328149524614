import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import { IPayeeDisbursementsBase } from "../../types/PayeeDisbursements";
import authAxios, { authHeader } from "../data";

const fetchAll = async (request: {
  pageNumber: number;
  pageSize: number;
  contractId: string;
}) => {
  const result = await authAxios(API_URL + "ContractDisbursements", {
    params: request,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetch = async (id: string) => {
  const result = await authAxios.get(API_URL + "ContractDisbursements/" + id, {
    headers: authHeader(),
  });

  return result.data;
};

const fetchCount = async (request: { contractId: string }) => {
  const result = await authAxios.get(API_URL + "ContractDisbursements/Count", {
    params: request,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const remove = async (id: string) => {
  const result = await authAxios.delete(
    API_URL + "ContractDisbursements/" + id,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const create = async (request: IPayeeDisbursementsBase) => {
  const result = await authAxios.post(
    API_URL + "ContractDisbursements",
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const edit = async (id: string, request: IPayeeDisbursementsBase) => {
  const result = await authAxios.put(
    API_URL + "ContractDisbursements/" + id,
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const PayeeDisbursementsService = {
  fetchAll,
  fetch,
  fetchCount,
  remove,
  create,
  edit,
};

export default PayeeDisbursementsService;
