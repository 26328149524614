import "./styles.scss";
import { Row, Col, Modal } from "antd";
import { ReactNode } from "react";
import { MODAL_MASK_COLOR } from "../constants";

type CustomModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  titleText: string;
  children: ReactNode;
  width: number;
  headerClass?: string;
  modalClass?: string;
  destroyOnClose?: boolean;
};

export const CustomModal = ({
  isModalOpen,
  setIsModalOpen,
  titleText,
  children,
  width,
  headerClass,
  modalClass,
  destroyOnClose,
}: CustomModalProps) => {
  return (
    <Modal
      title={[
        <Row justify="start" key={"modal-title"}>
          <Col>
            <div className={headerClass || "header ml-3"}>{titleText}</div>
          </Col>
        </Row>,
      ]}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      width={width}
      centered
      maskStyle={{ backgroundColor: MODAL_MASK_COLOR }}
      className={modalClass || ""}
      wrapClassName="z-1000 position-fixed"
      destroyOnClose={destroyOnClose || false}
    >
      {children}
    </Modal>
  );
};
