import { Card, Col, Form, FormInstance, Row } from "antd";
import { useCallback } from "react";
import { BankAccounts } from "./BankAccounts";
import { ContactsInfo } from "./ContactsInfo";
import { GeneralInfo } from "./GeneralInfo";
import { Accounts } from "./Accounts";
import { useFetchCustomer } from "../../../hooks/useFetchCustomer";

type ProfileProps = {
  form: FormInstance;
  types: any[];
  isFormDisabled: boolean;
  selectedItemId: string;
  setInitialData: (value: any) => void;
  setHasChanges?: (value: boolean) => void;
};

export const Profile = ({
  form,
  types,
  isFormDisabled,
  selectedItemId,
  setInitialData,
  setHasChanges,
}: ProfileProps) => {
  const { isLoading } = useFetchCustomer(selectedItemId, form, setInitialData);

  const onFormFieldsChange = useCallback(() => {
    selectedItemId && setHasChanges && setHasChanges(true);
  }, [selectedItemId, setHasChanges]);

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      className="w-100 px-8 py-6"
      initialValues={{}}
      requiredMark={false}
      disabled={isFormDisabled}
      onFieldsChange={onFormFieldsChange}
    >
      <Row className="mb-4 fixed-container justify-center">
        <div className="d-flex">
          <Col span={12} className="pr-2">
            <Card className="customer-info-card h-100 top-card ">
              <GeneralInfo form={form} isLoading={isLoading} types={types} />
            </Card>
          </Col>
          <Col span={12} className="pl-2">
            <Card className="customer-info-card h-100 top-card ">
              <ContactsInfo
                form={form}
                isFormDisabled={isFormDisabled}
                isLoading={isLoading}
                selectedItemId={selectedItemId}
              />
            </Card>
          </Col>
        </div>
      </Row>
      {selectedItemId !== "create" && (
        <Row className="fixed-container justify-center">
          <div className="d-flex">
            <Col span={16} className="pr-2">
              <Card className="customer-info-card bottom-card h-100">
                <Accounts customerId={selectedItemId} />
              </Card>
            </Col>
            <Col span={8} className="pl-2">
              <Card className="customer-info-card bottom-card h-100">
                <BankAccounts selectedItemId={selectedItemId} />
              </Card>
            </Col>
          </div>
        </Row>
      )}
    </Form>
  );
};
