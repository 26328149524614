import { ReactNode, useMemo, useState } from "react";
import { SideBarContainer } from "../../../../../../../components/SideBarContainer";
import { PayoffForm } from "../components/PayoffForm";

type TOpenSidebarProps = {
  id: string | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  reloadData: () => void;
  parentId: string | undefined;
  primaryBuyerId: string;
  primarySellerId: string;
  customSidebarClass?: string;
};

export const useOpenSidebar = ({
  id,
  setId,
  reloadData,
  parentId,
  customSidebarClass,
  primaryBuyerId,
  primarySellerId,
}: TOpenSidebarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const formWrapper: ReactNode = useMemo(() => {
    const formTitle = id !== null ? "Payoff Quote Details" : "Add Payoff Quote";

    return (
      <SideBarContainer
        title={formTitle}
        setShowSideBar={setIsOpen}
        setCurrentId={setId}
        customClass={customSidebarClass}
      >
        <PayoffForm
          id={id}
          setId={setId}
          setIsOpen={setIsOpen}
          reloadData={reloadData}
          parentId={parentId}
          primaryBuyerId={primaryBuyerId}
          primarySellerId={primarySellerId}
        />
      </SideBarContainer>
    );
  }, [
    customSidebarClass,
    id,
    parentId,
    primaryBuyerId,
    primarySellerId,
    reloadData,
    setId,
  ]);

  return { formWrapper, isOpen, setIsOpen };
};
