import { useCallback, useEffect, useState } from "react";
import {
  sendVerificationCode,
  confirmVerificationCode,
} from "../../../acions/signUp";
import { useAppDispatch } from "../../../store";

export const useVerificationCode = (
  email: string,
  onSendCodeSuccess: (value: string) => void
) => {
  const dispatch = useAppDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const sendCode = useCallback(async () => {
    setIsDisabled(true);
    try {
      const res: any = await Promise.resolve(
        dispatch(sendVerificationCode(email))
      );
      setIsDisabled(false);
      onSendCodeSuccess(res.verificationCodeId);
    } catch {
      return setIsDisabled(false);
    }
  }, [dispatch, email, onSendCodeSuccess]);

  const confirm = (request: any, onSuccess: (values: any) => void) => {
    setIsDisabled(true);

    Promise.resolve(dispatch(confirmVerificationCode(request)))
      .then((res: any) => {
        setIsDisabled(false);
        onSuccess(res);
      })
      .catch(() => setIsDisabled(false));
  };

  useEffect(() => {
    sendCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { sendCode, confirm, isDisabled };
};
