import { deleteBankAccount } from "../../../../../../../../acions/customers/bankAccounts";
import { useAppDispatch } from "../../../../../../../../store";

export const useDeleteHandler = () => {
  const dispatch = useAppDispatch();

  const onRemoveClicked = (
    id: string,
    disableFunc: (value: boolean) => void,
    reload: () => void
  ) => {
    disableFunc(true);

    Promise.resolve(dispatch(deleteBankAccount(id)))
      .then(() => {
        disableFunc(false);
        reload();
      })
      .catch(() => disableFunc(false));
  };

  return { onRemoveClicked };
};
