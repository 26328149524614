import { useCallback } from "react";
import { IPayeeDisbursementsBase } from "../../../../../../../../types/PayeeDisbursements";
import { calculateSum } from "../../../../../../../../lib/calculateSum";

export const useCalcTotals = () => {
  const calcTotalPayment = useCallback((history: any) => {
    const { amount, breakdown } = history;

    history.totalPayment = +amount + +breakdown.fromImpoundToBalance;
  }, []);

  const calcTotalToDisburse = useCallback((history: any) => {
    const { payeeFee, payerFee, disbursements } = history;

    const disbAmounts = disbursements.map(
      (disb: IPayeeDisbursementsBase) => disb.amount
    );

    const disbSum = calculateSum(disbAmounts);

    history.totalToDisburse = +payeeFee + +payerFee + disbSum;
  }, []);

  return { calcTotalPayment, calcTotalToDisburse };
};
