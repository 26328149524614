import { useCallback } from "react";
import { IPayeeDisbursementsBase } from "../../../../../../../../types/PayeeDisbursements";
import { calculateSum } from "../../../../../../../../lib/calculateSum";

export const useCalcPrincipalPaid = (
  calcInterestBalance: (history: any) => void
) => {
  const calcPrincipalPaid = useCallback(
    (history: any) => {
      const { method, breakdown, amount, payerFee, disbursements, contract } =
        history;

      if (method === 10) return;

      const disbursementsAmounts = disbursements
        .filter((disb: IPayeeDisbursementsBase) => disb.type === 3)
        .map((disb: IPayeeDisbursementsBase) => disb.amount);

      const payeeImpoundAmount = calculateSum(disbursementsAmounts);

      const {
        mortgagePassthru,
        fromImpoundAmount,
        fromImpoundToBalance,
        lateChargeAmount,
        interestAmount,
      } = breakdown;

      const principalAmount =
        +amount -
        +mortgagePassthru -
        +payerFee -
        +fromImpoundAmount +
        +payeeImpoundAmount +
        +fromImpoundToBalance -
        +lateChargeAmount -
        +interestAmount;

      history.breakdown.principalAmount = principalAmount;

      if (contract.paymentType === 2 && principalAmount !== 0) {
        history.breakdown.interestAmount = interestAmount + principalAmount;
        history.breakdown.principalAmount = 0;

        calcInterestBalance(history);
      }
    },
    [calcInterestBalance]
  );

  return { calcPrincipalPaid };
};
