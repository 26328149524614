import { useCallback } from "react";
import { IPayeeDisbursementsBase } from "../../../../../types/PayeeDisbursements";

export const useResetInstance = (
  calculate: (value?: { name: string; value: any }) => Promise<0 | 1>
) => {
  const resetByLateCharge = useCallback(
    async (value: any) => {
      return await calculate({ name: "breakdown", value });
    },
    [calculate]
  );

  const resetByDisbursements = useCallback(
    async (value: IPayeeDisbursementsBase[]) => {
      return await calculate({ name: "disbursements", value });
    },
    [calculate]
  );

  return { resetByLateCharge, resetByDisbursements };
};
