import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Form,
  FormInstance,
  InputRef,
  MenuProps,
  Row,
  Select,
} from "antd";
import { CustomerInputSearch } from "../../../../../../../Accounts/components/Filters/CustomerInputSearch";
import { useGetInpurSearchIds } from "../../../../../../../Accounts/components/Filters/hooks/getInputSearchIds";
import { FloatNumberInput } from "../../../../../../../../components/FloatNumberInput";
import {
  DATE_FORMAT,
  PAYER_STATUS,
  PERCENT_LIMIT,
} from "../../../../../../../../lib/constants";
import { useCallback, useMemo, useRef } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { useButtonHandlers } from "./hooks/useButtonHandlers";
import { FormButtons } from "../../../../../../../../components/DefaultFormButtons";
import { Rule } from "antd/es/form";
import { useGetInitialValue } from "./hooks/useGetInitialValue";
import { Loader } from "../../../../../../../../components/Loader";

type TPayersFormProps = {
  primaryBuyerNumber: number;
  setId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  id: string | null | undefined;
  reloadData: () => void;
  parentId: string;
};

export const PayersForm = ({
  id,
  reloadData,
  setId,
  parentId,
  primaryBuyerNumber,
}: TPayersFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const { hasPayeeId, customerIds, onCustomerFound } = useGetInpurSearchIds();

  const customerInputRef = useRef<InputRef>(null);

  const onDropdownChange = useCallback(() => {
    form.setFieldValue(["payeeId"], primaryBuyerNumber);
    customerInputRef.current && customerInputRef.current.focus();
  }, [form, primaryBuyerNumber]);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <div onClick={onDropdownChange}>Set Primary Buyer</div>,
      },
    ],
    [onDropdownChange]
  );

  const { onCancelBtnClick, onDeleteBtnClick, onSaveBtnClick, isDisabled } =
    useButtonHandlers({ reloadData, setId, parentId, form });

  const percentageRules: Rule[] = useMemo(
    () => [
      { required: true },
      {
        type: "number",
        transform: (value: any) => +value,
        max: 100,
        message: PERCENT_LIMIT,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  const { isLoading } = useGetInitialValue(id, form);

  return (
    <>
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <>
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            className="payers-form form-with-labels px-6 py-8"
            disabled={isDisabled}
          >
            <Row className="d-flex align-start">
              <CustomerInputSearch
                form={form}
                label="Payer"
                name={["payeeId"]}
                hasId={hasPayeeId}
                isFormDisabled={isDisabled}
                onCustomerFound={onCustomerFound}
                additionalClass="flex-grow-1"
                customRef={customerInputRef}
                placeholder="Input Payer#, “Enter” to confirm"
              />
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                className="ml-2 mt-6"
              >
                <Button icon={<MoreOutlined />} shape="circle" />
              </Dropdown>
            </Row>
            <FloatNumberInput
              form={form}
              name="percentage"
              label="Percentage"
              prefix="%"
              decimalLimit={4}
              className="mb-4 payers-form-percentage-input"
              rules={percentageRules}
            />
            <p className="text-md w-500 ma-0">Status</p>
            <Form.Item
              name="status"
              label="Status"
              className="my-4 pr-2 payers-form-status-input"
            >
              <Select options={PAYER_STATUS} />
            </Form.Item>
            <Row className="w-100 d-flex">
              <Form.Item
                name="assumedDate"
                label="Assumed Date"
                className="flex-grow-1 mb-4"
                normalize={(val) => val || null}
              >
                <DatePicker format={DATE_FORMAT} className="w-100" />
              </Form.Item>
              <Form.Item
                name="soldDate"
                label="Sold Date"
                className="flex-grow-1 ml-2 mb-4"
                normalize={(val) => val || null}
              >
                <DatePicker format={DATE_FORMAT} className="w-100" />
              </Form.Item>
            </Row>
            <Form.Item
              name="trackFor1098"
              valuePropName="checked"
              className="mb-4"
            >
              <Checkbox>Track payments for 1098</Checkbox>
            </Form.Item>
          </Form>
          {id !== undefined && (
            <FormButtons
              currentId={id}
              onSaveClick={onSaveBtnClick}
              onCancelClick={onCancelBtnClick}
              onDeleteClick={onDeleteBtnClick}
              isFormDisabled={isDisabled}
              customerIds={customerIds}
            />
          )}
        </>
      )}
    </>
  );
};
