import { Checkbox, DatePicker, Form, Select } from "antd";
import { DATE_FORMAT, DISBURSEMENTS_STATUS } from "../../../../../../../../../lib/constants";
import { useMemo } from "react";

export const StatusPart = () => {
  const options = useMemo(
    () => DISBURSEMENTS_STATUS.filter(option => option.value !== 3)
    , []);

  return (
    <div className="pb-4">
      <p className="mb-4 mt-0 text-md w-500">Status</p>
        <div className="d-flex">
          <Form.Item
            label="Status"
            name="status"
            className="w-100 mb-4"
          >
            <Select options={options}/>
          </Form.Item>
          <Form.Item
            name="enableForPayments"
            className="w-100 ml-2 mt-6"
            valuePropName="checked"
          >
            <Checkbox>Enable for Payments</Checkbox>
          </Form.Item>
        </div>
        <div className="d-flex">
          <Form.Item
            label="Beginning Date"
            name="beginningDate"
            className="flex-grow-1 mb-4"
          >
            <DatePicker format={DATE_FORMAT} className="w-100" placeholder="" />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="endDate"
            className="ml-2 mb-4 flex-grow-1"
          >
            <DatePicker format={DATE_FORMAT} className="w-100" placeholder="" />
          </Form.Item>
        </div>
    </div>
  );
}