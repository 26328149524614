import { Form, FormInstance } from "antd";
import { useMemo } from "react";
import { DATE_FORMAT } from "../../../../../../lib/constants";
import { Dayjs } from "dayjs";

export const useGetDisbursementsDate = (form: FormInstance) => {
  const date: Dayjs = Form.useWatch(["date"], {
    form,
    preserve: true,
  });

  const holdDays: number = Form.useWatch(["holdDays"], {
    form,
    preserve: true,
  });

  const dateValue: string = useMemo(() => {
    if (date) {
      return date.add(holdDays || 0, "d").format(DATE_FORMAT);
    } else {
      return "";
    }
  }, [date, holdDays]);

  return { dateValue };
};
