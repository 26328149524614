import { useEffect, useMemo, useState } from "react";
import { ITicklerExtended } from "../../../types/Tickler";
import TicklersService from "../../../services/accounts/ticklers";

export const useFetchTicklersData = (accountInfo: any) => {
  const [data, setData] = useState<ITicklerExtended[] | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchTicklers = (request: any): void => {
    setData(null);

    TicklersService.fetchAll(request)
      .then((res: ITicklerExtended[]) => {
        setData(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    if (accountInfo) {
      const request = {
        pageNumber: 1,
        pageSize: 9999,
        contractId: accountInfo.id,
        orderBy: 8,
      };

      fetchTicklers(request);
    }
  }, [accountInfo]);

  const paymentAlertTicklers: ITicklerExtended[] = useMemo(() => {
    return data
      ? data.filter((item: ITicklerExtended) => item.paymentAlert)
      : [];
  }, [data]);

  return { data, isLoading, paymentAlertTicklers };
};
