import { Col, Row, Form, Input, Button } from "antd";
import { Header } from "../../../components/SecurityCheck/Header";
import { ENTER_PASSWORD, MAX_PASSWORD } from "../../../lib/constants";
import { useValidateOnBlur } from "../../../lib/hooks/validateOnBlur";
import { LoadingOutlined } from "@ant-design/icons";
import { useCallback, useMemo, useState } from "react";
import {
  useToggleCapsLock,
  CapsLockHint,
} from "../../../components/CapsLockHint";
import { useAppDispatch } from "../../../store";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../../acions/profiles";
import { PasswordInput } from "../../../components/PasswordInput";
import ROUTES from "../../../configs/routesConfig";
import { Rule } from "antd/es/form";

type SecondStepProps = {
  onBackClicked: () => void;
};

export const SecondStep = ({ onBackClicked }: SecondStepProps) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const [isCapsLockOnOld, onPasswordTypeOld] = useToggleCapsLock(false);

  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const [validateOnBlur] = useValidateOnBlur(form);

  const onUpdatePassword = (values: any) => {
    if (isPasswordValid) {
      setIsFormDisabled(true);

      const request = {
        oldPassword: values.oldPassword,
        newPassword: values.password,
      };

      Promise.resolve(dispatch(changePassword(request)))
        .then(() => {
          setIsFormDisabled(false);
          navigate(ROUTES.PROFILE);
        })
        .catch(() => {
          setIsFormDisabled(false);
        });
    }
  };

  const oldPasswordRules: Rule[] = useMemo(
    () => [
      {
        required: true,
        message: ENTER_PASSWORD,
        validateTrigger: "onSubmit",
      },
      {
        max: 30,
        message: MAX_PASSWORD,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  const onBlur = useCallback(
    (e: any) => validateOnBlur(e, "oldPassword"),
    [validateOnBlur]
  );

  return (
    <Row className="mt-16 justify-center">
      <Col className="security-check-content mb-8">
        <Header header="Update your password" onBackClicked={onBackClicked} />

        <Row className="mt-10 d-flex justify-center">
          <Col className="form-content">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              requiredMark={false}
              disabled={isFormDisabled}
              className="form__container"
              onFinish={onUpdatePassword}
            >
              <div className="position-relative">
                <Form.Item
                  name="oldPassword"
                  label="Old Password"
                  className="mb-5"
                  rules={oldPasswordRules}
                >
                  <Input.Password
                    size="large"
                    onBlur={onBlur}
                    onKeyUp={onPasswordTypeOld}
                  />
                </Form.Item>

                {isCapsLockOnOld && <CapsLockHint />}
              </div>

              <div className="position-relative">
                <PasswordInput
                  label="New Password"
                  form={form}
                  setIsPasswordValid={setIsPasswordValid}
                />
              </div>

              <Form.Item className="mt-3 mb-12">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  disabled={!isPasswordValid || isFormDisabled}
                  block
                >
                  Update password
                  {isFormDisabled && <LoadingOutlined />}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
