import { useEffect, useState } from "react";
import { ProfilesService } from "../../../services/profiles";

export const useFetichTimeZone = () => {
  const [timezone, setTimezone] = useState<null | string>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    ProfilesService.fetchTimeZone()
      .then((res: string) => {
        setTimezone(res);
        setIsLoading(false);
      })
      .then(() => setIsLoading(false));
  }, []);

  return { timezone, isLoading };
};
