import authAxios, { authHeader } from "../data";
import { API_URL } from "../../configs/apiUrl";

const fetchBankAccounts = async (customerId: string) => {
  const response = await authAxios.get(
    API_URL + "CustomersBankAccounts?parentId=" + customerId,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const save = async (request: any) => {
  const response = await authAxios.post(
    API_URL + "CustomersBankAccounts",
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const update = async (id: string, request: any) => {
  const response = await authAxios.put(
    `${API_URL}CustomersBankAccounts/${id}`,
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const remove = async (id: string) => {
  const response = await authAxios.delete(
    `${API_URL}CustomersBankAccounts/${id}`,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const CustomerBankAccountsService = {
  fetchBankAccounts,
  save,
  update,
  remove,
};

export default CustomerBankAccountsService;
