import { ColumnsType } from "antd/es/table";
import { IImpoundsTypeExtended } from "../../../../types/Impounds";
import { useMemo } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ImpoundsForm } from "../ImpoundsForm";
import { FormButtons } from "../components/FormButtons";
import { FormInstance } from "antd";

type TTableColumnsProps = {
  onDeleteClick: (id: string) => void;
  onSaveClick: (id: string | null) => Promise<void>;
  onCloseClick: () => void;
  entryId: string | null;
  form: FormInstance;
  isFormDisabled: boolean;
  data: IImpoundsTypeExtended[];
  openToEdit: (id: string) => void;
};

export const useGetColumns = ({
  onDeleteClick,
  onSaveClick,
  onCloseClick,
  entryId,
  form,
  isFormDisabled,
  data,
  openToEdit,
}: TTableColumnsProps) => {
  const columns: ColumnsType<IImpoundsTypeExtended> = useMemo(
    () => [
      {
        dataIndex: "name",
        width: "67%",
        key: "name",
        editable: true,
        render: (value: string, record: IImpoundsTypeExtended) => {
          return record.id === entryId ? (
            <ImpoundsForm
              onSaveClick={onSaveClick}
              onCloseClick={onCloseClick}
              id={entryId}
              form={form}
              isFormDisabled={isFormDisabled}
              isEditForm={true}
              data={data}
            />
          ) : (
            <span className="impounds-table-value">{value}</span>
          );
        },
      },
      {
        width: "33%",
        key: "actions",
        editable: false,
        render: (_: any, record: IImpoundsTypeExtended) => {
          const { id } = record;

          return id === entryId ? (
            <FormButtons
              onCloseClick={onCloseClick}
              onSaveClick={onSaveClick}
              id={entryId}
              isEditForm={true}
            />
          ) : (
            <div
              className="w-100 h-100 d-flex justify-end ant-table-actions"
              onClick={(e) => e.stopPropagation()}
            >
              <EditOutlined
                className="ant-table-btn logo-cursor"
                onClick={() => openToEdit(id)}
              />
              <DeleteOutlined
                className="ant-table-btn logo-cursor mr-1"
                onClick={() => onDeleteClick(id)}
              />
            </div>
          );
        },
      },
    ],
    [
      entryId,
      onSaveClick,
      onCloseClick,
      form,
      isFormDisabled,
      data,
      openToEdit,
      onDeleteClick,
    ]
  );

  return { columns };
};
