import { Dayjs } from "dayjs";
import { IPayoffQuoteGetOne, IPayoffQuotePost } from "../../types/PayoffQute";
import authAxios, { authHeader } from "../data";
import { API_URL } from "../../configs/apiUrl";
import qs from "qs";

const fetchAll = async (req: {
  contractId: string;
  pageNumber: number;
  pageSize: number;
}) => {
  const result = await authAxios.get(API_URL + "Payoffs", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetchCount = async (req: { contractId: string }) => {
  const result = await authAxios.get(API_URL + "Payoffs/Count", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetch = async (id: string): Promise<IPayoffQuoteGetOne> => {
  const result = await authAxios.get(API_URL + "Payoffs/" + id, {
    headers: authHeader(),
  });

  return result.data;
};

const create = async (request: IPayoffQuotePost) => {
  const result = await authAxios.post(API_URL + "Payoffs", request, {
    headers: authHeader(),
  });

  return result.data;
};

const edit = async (id: string, request: IPayoffQuotePost) => {
  const result = await authAxios.put(API_URL + "Payoffs/" + id, request, {
    headers: authHeader(),
  });

  return result.data;
};

const remove = async (id: string) => {
  const result = await authAxios.delete(API_URL + "Payoffs/" + id, {
    headers: authHeader(),
  });

  return result.data;
};

const initialize = async (req: {
  date: string | Date | Dayjs;
  contractId: string;
}) => {
  const result = await authAxios.get(API_URL + "Payoffs/Initialize", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const PayoffQuoteService = {
  fetchAll,
  fetchCount,
  fetch,
  edit,
  create,
  remove,
  initialize,
};

export default PayoffQuoteService;
