import { Row, Form, DatePicker, FormInstance, Button } from "antd";
import { useCallback } from "react";
import { DATE_FORMAT } from "../../../../lib/constants";
import { CustomModal } from "../../../../lib/CustomModal";
import { CustomerInputSearch } from "./CustomerInputSearch";
import { useCreateAccount } from "./hooks/createAccount";
import { useGetInpurSearchIds } from "./hooks/getInputSearchIds";
import { useGetBeginningDateRules } from "./hooks/useGetBeginningDateRules";
import { NavigateFunction, useNavigate } from "react-router-dom";

type CreateAccountModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};

export const CreateAccountModal = ({
  isModalOpen,
  setIsModalOpen,
}: CreateAccountModalProps) => {
  const navigate: NavigateFunction = useNavigate();

  const [form]: FormInstance[] = Form.useForm();

  const { rules: beginningDateRules } = useGetBeginningDateRules();

  const {
    customerIds,
    onCustomerFound,
    hasPrimaryBuyerId,
    hasPrimarySellerId,
  } = useGetInpurSearchIds();

  const onSaved = useCallback(
    (res: any): void => {
      navigate(res.id);
      form.resetFields();
      setIsModalOpen(false);
    },
    [form, navigate, setIsModalOpen]
  );

  const { isLoading, setIsLoading, save } = useCreateAccount(
    customerIds,
    onSaved
  );

  const onCloseModal = useCallback(
    (value: boolean): void => {
      form.resetFields();
      setIsModalOpen(value);
      setIsLoading(false);
    },
    [form, setIsModalOpen, setIsLoading]
  );

  const onCreateClicked = async () => {
    try {
      const res = await form.validateFields();

      if (customerIds.primaryBuyerNumber && customerIds.primarySellerNumber) {
        save(res);
      }
    } catch (e: any) {}
  };

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      setIsModalOpen={onCloseModal}
      titleText="Create Account"
      width={300}
      headerClass="text w-500"
      modalClass="create-modal"
      destroyOnClose
    >
      <Row className="pt-8 d-block">
        <Form
          form={form}
          requiredMark={false}
          autoComplete="off"
          layout="vertical"
          disabled={isLoading}
        >
          <Form.Item
            label="Beginning Date"
            name="beginningDate"
            rules={beginningDateRules}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>

          <CustomerInputSearch
            form={form}
            label="Primary Buyer"
            name={["primaryBuyerNumber"]}
            isFormDisabled={isLoading}
            onCustomerFound={onCustomerFound}
            hasId={hasPrimaryBuyerId}
          />

          <CustomerInputSearch
            form={form}
            label="Primary Seller"
            name={["primarySellerNumber"]}
            isFormDisabled={isLoading}
            onCustomerFound={onCustomerFound}
            lastSearch
            hasId={hasPrimarySellerId}
          />

          <Form.Item className="mt-10 mb-0 pt-4">
            <Row className="justify-end">
              <Button
                type="primary"
                shape="round"
                className="mr-2"
                onClick={onCreateClicked}
              >
                Create
              </Button>
              <Button
                shape="round"
                className="mr-2"
                onClick={() => onCloseModal(false)}
              >
                Cancel
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Row>
    </CustomModal>
  );
};
