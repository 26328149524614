import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";
import { IBatchPost } from "../../types/Batches";
import dayjs from "dayjs";

const fetchAll = async (req: {
  pageSize: number;
  pageNumber: number;
  from: string | dayjs.Dayjs;
  to: string | dayjs.Dayjs;
  type?: number;
}) => {
  const result = await authAxios.get(API_URL + "Batches", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetchCount = async (req: { from: string; to: string }) => {
  const result = await authAxios.get(API_URL + "Batches/Count", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetch = async (id: string) => {
  const result = await authAxios.get(API_URL + "Batches/" + id, {
    headers: authHeader(),
  });

  return result.data;
};

const remove = async (id: string) => {
  const result = await authAxios.delete(API_URL + "Batches/" + id, {
    headers: authHeader(),
  });

  return result.data;
};

const create = async (request: IBatchPost) => {
  const result = await authAxios.post(API_URL + "Batches", request, {
    headers: authHeader(),
  });

  return result.data;
};

const edit = async (request: IBatchPost, id: string) => {
  const result = await authAxios.put(API_URL + "Batches/" + id, request, {
    headers: authHeader(),
  });

  return result.data;
};

const initializeDate = async (requset: string) => {
  const result = await authAxios.post(API_URL + "Batches/Initialize", requset, {
    headers: authHeader(),
  });

  return result.data;
};

const validate = async (id: string) => {
  const result = await authAxios.put(
    API_URL + `Batches/${id}/calculate`,
    {},
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const postAll = async (id: string) => {
  const result = await authAxios.put(`${API_URL}Batches/${id}/postAll`, null, {
    headers: authHeader(),
  });

  return result.data;
};

const BatchesService = {
  fetchAll,
  fetchCount,
  fetch,
  remove,
  create,
  edit,
  initializeDate,
  validate,
  postAll,
};

export default BatchesService;
