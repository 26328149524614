import { useCallback, useMemo, useState } from "react";
import { Button, Card, Row, Table } from "antd";
import FeesService from "../../../services/administration/fees";
import { useGetFeesTableColumns } from "./hooks/useGetFeesTableColumns";
import { SimpleTablePagination } from "../../../components/SimpleTablePagination";
import { useFetchDataCount } from "../hooks/useFetchDataCount";
import { useDefaultTableScrollProp } from "../../Customers/hooks/defaultTableScrollProp";
import { createPortal } from "react-dom";
import { useDefaultFormSidebar } from "../../../lib/hooks/defaultFormSidebar";
import { FeeForm } from "./FeeForm";
import { useFetchData } from "./hooks/useFetchData";
import { useContainerClasses } from "./hooks/useContainerClasses";
import { Loader } from "../../../components/Loader";
import ContractFeesService from "../../../services/accounts/fees";
import { useDefualtOnRow } from "../../../lib/hooks/useDefaultOnRow";

type FeesProps = {
  accountId?: string;
  onSuccess?: () => void;
};

export const Fees = ({ accountId, onSuccess }: FeesProps) => {
  const [id, setId] = useState<string | null>(null);

  const { upperClass, lowerClass } = useContainerClasses(accountId);

  const service: any = useMemo(() => {
    return accountId ? ContractFeesService : FeesService;
  }, [accountId]);

  const {
    request,
    isLoading: isCountLoading,
    total,
    onPageChange,
    fetchDataCount,
  } = useFetchDataCount(service.fetchCount, 10, accountId);

  const { data, isLoading, fetchData } = useFetchData(
    accountId,
    request,
    service
  );

  const { scroll } = useDefaultTableScrollProp(data, 280);

  const scrollProp = useMemo(
    () => (accountId ? undefined : scroll),
    [accountId, scroll]
  );

  const reloadData = useCallback(() => {
    fetchDataCount();
    fetchData();
    onSuccess && onSuccess();
  }, [fetchDataCount, fetchData, onSuccess]);

  const { formWrapper, isOpen, setIsOpen } = useDefaultFormSidebar({
    id,
    setId,
    reloadData,
    title: accountId ? "Fee" : "Fee Type",
    FormElem: FeeForm,
    parentId: accountId,
  });

  const onAddClick = useCallback(() => setIsOpen(true), [setIsOpen]);

  const openSideBar = useCallback(
    (id: string): void => {
      setIsOpen(true);
      setId(id);
    },
    [setId, setIsOpen]
  );

  const { columns } = useGetFeesTableColumns({
    accountId,
    reloadData,
    setIsOpen,
    openSideBar,
  });

  const header: string = useMemo(
    () => (accountId ? "Fees" : "Fee Types"),
    [accountId]
  );

  const { onRow } = useDefualtOnRow(openSideBar);

  return (
    <Row className={upperClass}>
      <Row className={lowerClass}>
        <Card>
          <Row className="w-100 d-flex justify-space-between">
            <h5 className="h h5 ant-table-title">{header}</h5>
            <Button shape="round" onClick={onAddClick}>
              Add New
            </Button>
          </Row>
          {accountId && isLoading && data.length === 0 && (
            <Row className="d-block w-100 mt-6 table__container">
              <Loader fontSize="64px" />
            </Row>
          )}
          {(!accountId || data.length !== 0) && (
            <Row className="d-block w-100 mt-6 table__container">
              <Table
                dataSource={data}
                columns={columns}
                loading={isLoading}
                pagination={false}
                rowKey="id"
                className="overflow-y-auto"
                scroll={scrollProp}
                onRow={onRow}
              />
              {total > 10 && (
                <SimpleTablePagination
                  isLoading={isCountLoading}
                  total={total}
                  pageNumber={request.pageNumber}
                  onPageChange={onPageChange}
                />
              )}
            </Row>
          )}
        </Card>
      </Row>
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
