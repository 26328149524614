import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../store";
import { setErrorMessage } from "../../../acions/actionsCreators/messageActions";
import { PAYMENT_CANT_BE_CREATED } from "../../../lib/constants";
import { App } from "antd";
import { useWarnPopupProps } from "./useWarnPopupProps";
import ROUTES from "../../../configs/routesConfig";

export const useWarningPopups = (data: any, accountInfo: any) => {
  const dispatch = useAppDispatch();

  const [closed, setClosed] = useState<boolean>(false);

  const { warnPopupProps } = useWarnPopupProps(false);

  const { modal } = App.useApp();

  const link: ReactNode = useMemo(() => {
    return accountInfo ? (
      <a
        href={`${ROUTES.ACCOUNTS}/${accountInfo.id}`}
        rel="noreferrer"
        target="_blank"
        className="text-md w-500"
      >
        {accountInfo.name}
      </a>
    ) : (
      <div />
    );
  }, [accountInfo]);

  const modalContent: any = useMemo(() => {
    return {
      4: {
        title: "Payment Already Posted",
        content: (
          <>
            <div className="text-md mb-3">
              Payment for account {link} already posted.
            </div>
            <div className="text-md mb-1">
              Do you want to create an additional payment for this account?
            </div>
          </>
        ),
      },
      1: {
        title: "Payment Restrictions",
        content: (
          <>
            <div className="text-md mb-3">
              The account {link} is restricted for posting payments.
            </div>
            <div className="text-md mb-1">
              Do you want to create payment anyway?
            </div>
          </>
        ),
      },
      3: {
        title: "Payment Restrictions",
        content: (
          <>
            <div className="text-md mb-3">
              The account {link} is restricted for posting payments: NSF Item
              Outstanding (Freeze).
            </div>
            <div className="text-md mb-1">
              Do you want to create payment anyway?
            </div>
          </>
        ),
      },
    };
  }, [link]);

  const openModal = useCallback(
    (num: number | undefined, warnings: number[]) => {
      num &&
        modal.confirm({
          ...modalContent[num],
          ...warnPopupProps,
          cancelText: "Create Payment",
          onCancel: () => {
            const val: number[] = [...warnings];

            const index = val.indexOf(num);

            index > -1 && val.splice(index, 1);

            val.length > 0 ? openModal(val[0], val) : setClosed(true);
          },
        });
    },
    [modal, modalContent, warnPopupProps]
  );

  useEffect(() => {
    if (data) {
      if (data.warnings.includes(2)) {
        dispatch(setErrorMessage(PAYMENT_CANT_BE_CREATED, true));
      } else {
        const { warnings }: { warnings: number[] } = data;

        const warnsSorted = [];

        warnings.includes(4) && warnsSorted.push(4);
        warnings.includes(1) && warnsSorted.push(1);
        warnings.includes(3) && warnsSorted.push(3);

        if (warnings.length > 0) {
          openModal(warnings[0], warnings);
          setClosed(false);
        } else {
          setClosed(true);
        }
      }
    } else {
      setClosed(false);
    }
  }, [data, dispatch, openModal]);

  return { closed };
};
