import { Checkbox, Col, DatePicker, Form } from "antd";
import { FormInstance, Input, Row, Select } from "antd";
import {
  ACCOUNT_PAYMENT_METHODS,
  DATE_FORMAT,
} from "../../../../../../../../../lib/constants";
import { useEffect, useMemo } from "react";
import { Rule } from "antd/es/form";
import { DAY_OF_MONTH_RULE } from "../../../../../../../../../lib/validationRules";

type PaymentFormItemsProps = {
  form: FormInstance;
};

export const PaymentFormItems = ({ form }: PaymentFormItemsProps) => {
  const autoChargeEnabled = Form.useWatch("autoChargeEnabled", { form });

  const method = Form.useWatch("autoChargeMethodType", { form });

  const bankAccount = Form.useWatch("bankAccountNumber", { form });

  useEffect(() => {
    form.setFields([
      {
        name: "bankAccountNumber",
        errors: [],
      },
    ]);
  }, [form, method]);

  const bankAccountPlaceholder: string = useMemo(() => {
    if (!bankAccount) {
      return [2, 3].includes(method)
        ? "Set a bank account on buyer`s profile"
        : "";
    } else {
      return "";
    }
  }, [bankAccount, method]);

  const bankAccRules: Rule[] = useMemo(
    () => [
      {
        required: !bankAccount && [2, 3].includes(method),
        message: "",
      },
    ],
    [bankAccount, method]
  );

  const getDayOfMonthRule: Rule[] = useMemo(
    () => [
      {
        pattern: DAY_OF_MONTH_RULE,
        message: "",
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  return (
    <Row className="d-block">
      <Row className="align-center">
        <Col className="general-info-col mr-7">
          <Form.Item
            className="select mb-4"
            label="Payment Method"
            name="autoChargeMethodType"
          >
            <Select options={ACCOUNT_PAYMENT_METHODS} />
          </Form.Item>
        </Col>
        <Col className="ml-7">
          <Form.Item
            className="mb-4 mt-4"
            label=""
            name="autoChargeEnabled"
            valuePropName="checked"
          >
            <Checkbox>Autocharge</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {autoChargeEnabled && (
        <Row className="align-center">
          <Col className="general-info-col mr-7">
            <Form.Item
              label="Bank Account Number"
              name="bankAccountNumber"
              className="disabled-input mb-4"
              rules={bankAccRules}
            >
              <Input disabled placeholder={bankAccountPlaceholder} />
            </Form.Item>
          </Col>
          <Col className="ml-7 general-info-col">
            <Row className="schedule-select-container">
              <Form.Item
                className="mb-4"
                label="Next Autocharge Date"
                name="nextAutoChargeDate"
              >
                <DatePicker format={DATE_FORMAT} style={{ width: 132 }} />
              </Form.Item>
              <Form.Item
                className="day-input mb-4 ml-2"
                label="Day"
                name="autoDay"
                rules={getDayOfMonthRule}
                normalize={(value: string) => value || null}
              >
                <Input />
              </Form.Item>
            </Row>
          </Col>
        </Row>
      )}
    </Row>
  );
};
