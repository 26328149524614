import { Row, Table } from "antd";
import { useFetchDataCount } from "../../../../../Administration/hooks/useFetchDataCount";
import ContractLateChargesService from "../../../../../../services/accounts/lateCharges";
import { useGetData } from "../../../../../Administration/LateChargesTab/hooks/useGetData";
import { useGetColumns } from "./hooks/useGetColumns";
import { SimpleTablePagination } from "../../../../../../components/SimpleTablePagination";
import { Totals } from "./Totals";

type GeneralConfigurationProps = {
  contractId: string;
};

export const GeneralConfiguration = ({
  contractId,
}: GeneralConfigurationProps) => {
  const {
    request,
    isLoading: isCountLoading,
    total,
    onPageChange,
  } = useFetchDataCount(ContractLateChargesService.fetchCount, 10, contractId);

  const { data, isLoading } = useGetData(
    request,
    contractId,
    ContractLateChargesService
  );

  const { columns } = useGetColumns();

  return (
    <Row className="d-block mt-8 w-100">
      <p className="mt-0 mb-4 text-md w-500">General Configuration</p>
      <Row className="w-100">
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          pagination={false}
          rowKey="id"
          className="w-100"
        />
        {total > 10 && (
          <SimpleTablePagination
            isLoading={isCountLoading}
            total={total}
            pageNumber={request.pageNumber}
            onPageChange={onPageChange}
          />
        )}
      </Row>
      <Totals contractId={contractId} />
    </Row>
  );
};
