export const formatTaxId = (value: string, type: 1 | 2): string => {
  if (value) {
    if (type === 1) {
      return value.slice(0, 3) + "-" + value.slice(3, 5) + "-" + value.slice(5);
    } else {
      return value.slice(0, 2) + "-" + value.slice(2);
    }
  } else {
    return "";
  }
};
