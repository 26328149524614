import { ColumnsType } from "antd/es/table";
import { ReactNode, useCallback, useMemo } from "react";
import { IDocumentExtended } from "../../../../../../../types/Documents";
import dayjs from "dayjs";
import {
  DATE_FORMAT,
  PRIMARY_6_COLOR,
} from "../../../../../../../lib/constants";
import {
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useButtonHandlers } from "./useButtonHandlers";
import { useDownloadFile } from "./useDownloadFile";

type TTableColumnsProps = {
  reloadData: () => void;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openDocument: (id: string) => void;
};

export const useGetTableColumns = ({
  reloadData,
  setId,
  setIsOpen,
  openDocument,
}: TTableColumnsProps) => {
  const { onDeleteBtnClick } = useButtonHandlers({
    reloadData,
    setIsOpen,
    setId,
  });

  const { isLoading, download } = useDownloadFile();

  const onEdit = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      openDocument(e.currentTarget.id);
    },
    [openDocument]
  );

  const onDownload = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, fileName: string) => {
      e.stopPropagation();
      download(e.currentTarget.id, fileName);
    },
    [download]
  );

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      onDeleteBtnClick(e.currentTarget.id);
    },
    [onDeleteBtnClick]
  );

  const columns: ColumnsType<IDocumentExtended> = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: "8%",
        sorter: true,
        render: (value: string): string => dayjs(value).format(DATE_FORMAT),
      },
      {
        title: "Document#",
        dataIndex: "number",
        key: "number",
        width: "10%",
        sorter: true,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "30%",
        sorter: true,
        ellipsis: true,
        render: (value: string, record: IDocumentExtended): ReactNode => {
          const iconColor = record.uploaded ? PRIMARY_6_COLOR : "#00000073";

          return (
            <div className="elipsis">
              <FileTextOutlined style={{ color: iconColor }} className="mr-2" />
              <span>{value}</span>
            </div>
          );
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        ellipsis: true,
        width: "30%",
      },
      {
        title: "Holding Original",
        dataIndex: "isHolding",
        key: "isHolding",
        width: "12%",
        sorter: true,
        render: (value: boolean): string => (value ? "Yes" : "No"),
      },
      {
        key: "actions",
        width: "10%",
        render: (_: unknown, record: IDocumentExtended): ReactNode => {
          const { id, uploaded, fileName } = record;

          return (
            <div className="w-100 h-100 d-flex justify-end ant-table-actions">
              {uploaded && (
                <DownloadOutlined
                  id={id}
                  className="ant-table-btn logo-cursor"
                  onClick={(e) => onDownload(e, fileName)}
                />
              )}
              <EditOutlined
                id={id}
                className="ant-table-btn logo-cursor"
                onClick={onEdit}
                disabled={isLoading}
              />
              <DeleteOutlined
                id={id}
                className="ant-table-btn logo-cursor mr-1"
                onClick={onDelete}
                disabled={isLoading}
              />
            </div>
          );
        },
      },
    ],
    [onEdit, isLoading, onDownload, onDelete]
  );

  return { columns };
};
