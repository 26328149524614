import { Row } from "antd";
import { useMemo } from "react";
import { getFormattedCurrency } from "../../../../../../../../../../lib/currencyFormatter";

type HeaderToolsProps = {
  balanceBeginningYear: number | null;
};

export const HeaderTools = ({ balanceBeginningYear }: HeaderToolsProps) => {
  const balance: string = useMemo(() => {
    return balanceBeginningYear
      ? getFormattedCurrency(balanceBeginningYear)
      : "-";
  }, [balanceBeginningYear]);

  return (
    <Row className="w-100 justify-end align-center pt-6 pb-4 px-6">
      <Row>
        <p className="my-0 text-md">Balance Beginning of Year:&nbsp;</p>
        <p className="my-0 text-md w-500">{balance}</p>
      </Row>
    </Row>
  );
};
