import { Form, FormInstance, Row } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useCustomerFormHandlers } from "../hooks/customerFormHandlers";
import { Profile } from "./Tabs/Profile";
import { TabsList } from "./TabsList";
import { LeaveModal } from "../../../components/LeaveModal";
import { EditPanel } from "../../Account/components/View/Admin/Details/components/EditPanel";
import { Header } from "./Header";
import { NavigateFunction, useNavigate } from "react-router-dom";

type MainProps = {
  selectedItemId: string;
  types: any[];
  backLocation: any;
  reduceBackStep: () => void;
};

export const Main = ({
  selectedItemId,
  types,
  backLocation,
  reduceBackStep,
}: MainProps) => {
  const navigate: NavigateFunction = useNavigate();

  const [form]: FormInstance[] = Form.useForm();

  const [initialData, setInitialData] = useState<any>(null);

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const isCreate = useMemo(() => selectedItemId === "create", [selectedItemId]);

  const { isFormDisabled, onSaveClicked, onCancelClicked } =
    useCustomerFormHandlers(
      selectedItemId,
      form,
      initialData,
      hasChanges,
      setInitialData,
      reduceBackStep
    );

  const onCloseView = useCallback(
    () => onCancelClicked(false, () => navigate(-1)),
    [navigate, onCancelClicked]
  );

  const onLeavePage = useCallback(
    (onSuccess: () => void) => onSaveClicked(false, onSuccess),
    [onSaveClicked]
  );

  const onSavePanel = useCallback(
    () => onSaveClicked(false, () => setHasChanges(false)),
    [onSaveClicked, setHasChanges]
  );

  const resetFields = useCallback((): void => {
    form.setFieldsValue(initialData);
    setHasChanges(false);
  }, [form, initialData, setHasChanges]);

  return (
    <Row className="customer d-block">
      <Header
        initialData={initialData}
        isCreate={isCreate}
        isFormDisabled={isFormDisabled}
        onCloseView={onCloseView}
        onSaveClicked={onSaveClicked}
        hasChanges={hasChanges}
        backLocation={backLocation}
        reduceBackStep={reduceBackStep}
      />
      {hasChanges && (
        <>
          <EditPanel
            isDisabled={isFormDisabled}
            update={onSavePanel}
            className="edit-panel-container-top-customers"
            onCancelChanges={resetFields}
          />
          <LeaveModal hasChanges={hasChanges} onSave={onLeavePage} />
        </>
      )}
      {!isCreate ? (
        <TabsList
          form={form}
          types={types}
          isFormDisabled={isFormDisabled}
          selectedItemId={selectedItemId}
          setInitialData={setInitialData}
          onLeaveFirstTab={onCancelClicked}
          initialData={initialData}
          setHasChanges={setHasChanges}
        />
      ) : (
        <Profile
          form={form}
          types={types}
          isFormDisabled={isFormDisabled}
          selectedItemId={selectedItemId}
          setInitialData={setInitialData}
        />
      )}
    </Row>
  );
};
