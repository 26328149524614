import { CLEAR_MESSAGE, SET_MESSAGE } from "../types";

export const setSuccessMessage = (message: string) => {
  return {
    type: SET_MESSAGE,
    payload: { type: "success", message },
  };
};

export const setInfoMessage = (message: string) => {
  return {
    type: SET_MESSAGE,
    payload: { type: "info", message },
  };
};

export const setErrorMessage = (message: any, shouldDisapear: boolean) => {
  return {
    type: SET_MESSAGE,
    payload: {
      type: "error",
      message,
      shouldDisapear
    }
  }
};

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});