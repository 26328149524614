import { useCallback, useEffect, useState } from "react";
import { IBatchGet, IBatchesGet } from "../../../types/Batches";
import BatchesService from "../../../services/processing/batches";

export const useFetchData = (request: {
  pageSize: number;
  pageNumber: number;
  from: string;
  to: string;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [data, setData] = useState<IBatchesGet[]>([]);

  const fetchData = useCallback(() => {
    if (request) {
      setIsLoading(true);
      BatchesService.fetchAll(request)
        .then((res: { date: string | Date; batches: IBatchGet[] }[]) => {
          const modifiedData = res.map((item: any, index: number) => ({
            id: index.toString(),
            totalAmount: undefined,
            postedPaymentsCount: undefined,
            date: item.date,
            children: item.batches,
          }));
          setData(modifiedData);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [request]);

  useEffect(() => fetchData(), [fetchData]);

  return { isLoading, fetchData, data };
};
