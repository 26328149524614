import { API_URL } from "../../configs/apiUrl";
import { ILegal } from "../../types/Legal";
import authAxios, { authHeader } from "../data";

const edit = async (contactId: string, req: ILegal) => {
  const result = await authAxios.put(
    API_URL + "Contracts/" + contactId + "/legal",
    req,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const LegalService = {
  fetch,
  edit,
};

export default LegalService;
