import { Button, Modal, Row } from "antd";
import { ReactNode, useMemo } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import image from "../../../../../../images/bank-payment.svg";
type HowToPayModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};
export const HowToPayModal = ({
  isModalOpen,
  setIsModalOpen,
}: HowToPayModalProps) => {
  const title: ReactNode = useMemo(() => {
    return (
      <Row className="pt-6 px-8">
        <h3 className="h h3 w-500">How to Pay Online?</h3>
      </Row>
    );
  }, []);
  const footer: ReactNode = useMemo(() => {
    return (
      <Row className="justify-end px-6 py-6">
        <Button
          type="primary"
          shape="round"
          href="https://app.fluidpay.com/checkout/02b8f980-6c4a-42d0-8675-c0bb34c7ef78"
          target="_blank"
        >
          Proceed to Payment
        </Button>
        <Button
          className="ml2"
          shape="round"
          onClick={() => setIsModalOpen(false)}
        >
          Close
        </Button>
      </Row>
    );
  }, [setIsModalOpen]);
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={632}
      className="how-to-pay__modal"
      footer={footer}
    >
      <Row className="d-block pt-8 px-8 pb-3">
        <p className="my-0 text-md">
          Online payments can only be made using a checking or savings account,
          we do not accept credit cards or debit cards.
        </p>
        <Row className="my-6 align-center mb-2">
          <ExclamationCircleOutlined className="text important-label mr-1" />
          <p className="my-0 text-md w-500 important-label mr-1">IMPORTANT:</p>
          <p className="my-0 text-md">
            Before you hit the “Complete Purchase” button please be sure:
          </p>
        </Row>
        <Row className="d-block">
          <ol className="pl-4 text-md my-0">
            <li>Your account number with Sunwest Escrow is included.</li>
            <li>
              Any additional instructions we need in order to process your
              payment correctly are included (e.g., how many payments are paid).
            </li>
            <li>
              All of the information is correct including the payment amount you
              are making.
            </li>
          </ol>
        </Row>
        <p className="mb-0 mt-2 text-md">
          Once the payment is submitted, the transaction cannot be reversed and
          you will receive an email receipt. However, the payment will not be
          deducted from your bank account for up to 3 business days.
        </p>
        <div className="my-6  pick-container">
          <img src={image} alt="bank-img" />
          <Row className="d-block mx-4" style={{ flex: 1 }}>
            <p className="mt-0 mb-4 text-sm title">
              A sample of a check in order to get your routing number for your
              bank and the account number with your bank.
            </p>
            <p className="my-0 text-sm title">
              If you are unsure of any of these numbers, please contact your
              bank before continuing.
            </p>
          </Row>
        </div>
        <p className="my-0 text-md">
          If you have any questions or concerns, please don't hesitate to
          contact our customer support team.
        </p>
      </Row>
    </Modal>
  );
};
