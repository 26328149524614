import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getFormatted } from "../../../lib/PhoneFormatter";
import { Button, Row } from "antd";

type CardType = {
  email: string;
  data: string | undefined;
  label: string;
  isBorder?: boolean;
  isNotChange?: boolean;
  path?: string;
};

export const CardData = ({
  email,
  data,
  label,
  isBorder,
  isNotChange,
  path,
}: CardType) => {
  const navigate = useNavigate();

  const value = useMemo(
    () => (label === "Phone" ? getFormatted(data) : data),
    [data, label]
  );

  const onChangeClicked = useCallback(() => {
    path &&
      navigate(path, {
        state: { email },
        replace: true,
      });
  }, [email, navigate, path]);

  const containerClass: string = useMemo(() => {
    return `d-block ${isBorder && "bordered"} mb-4`;
  }, [isBorder]);

  return (
    <Row className={containerClass}>
      <p className="label text my-0">{label}</p>
      <div className="d-flex justify-space-between item">
        <div className="value__container">
          <p title={value} className="text text-primary mb-4 mt-1">
            {value}
          </p>
        </div>
        {!isNotChange && path && (
          <Button type="link" className="pr-2" onClick={onChangeClicked}>
            Change
          </Button>
        )}
      </div>
    </Row>
  );
};
