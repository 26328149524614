import { Checkbox, DatePicker, Form, Input, Row, FormInstance } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useButtonHandlers } from "../hooks/useButtonHandlers";
import { FormButtons } from "../../../../../../../components/DefaultFormButtons";
import {
  DATE_FORMAT,
  MAX_DOC_DESCRIPTION,
  MAX_DOC_NAME,
} from "../../../../../../../lib/constants";
import { useGetInitialValues } from "../hooks/useGetInitialValues";
import { Loader } from "../../../../../../../components/Loader";
import { UploadFileFormItem } from "./UploadFileFormItem";

type TFormProps = {
  id: string | null;
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  parentId?: string;
};

export const DocumentsForm = ({
  id,
  reloadData,
  setIsOpen,
  setId,
  parentId,
}: TFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const { isFormDisabled, onCancelBtnClick, onSaveBtnClick, onDeleteBtnClick } =
    useButtonHandlers({ reloadData, setIsOpen, setId, form, parentId });

  const { isLoading } = useGetInitialValues(id, form);

  return (
    <Row className="documents-form h-100 d-flex position-relative flex-column">
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <>
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            className="pa-6"
            disabled={isFormDisabled}
          >
            <UploadFileFormItem />
            <Form.Item
              name="date"
              label="Date"
              className="mb-4 w-100"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  type: "date",
                },
              ]}
            >
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>
            <Form.Item
              name="name"
              label="Name"
              className="mb-4 w-100"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                },
                {
                  max: 100,
                  message: MAX_DOC_NAME,
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="mb-4 w-100"
              rules={[
                {
                  max: 300,
                  message: MAX_DOC_DESCRIPTION,
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <TextArea className="documents-form-textarea" rows={1} />
            </Form.Item>
            <Form.Item
              name="isHolding"
              valuePropName="checked"
              className="mb-4 w-100"
            >
              <Checkbox>Holding Original</Checkbox>
            </Form.Item>
          </Form>
          <FormButtons
            currentId={id}
            onSaveClick={onSaveBtnClick}
            onCancelClick={onCancelBtnClick}
            onDeleteClick={onDeleteBtnClick}
            isFormDisabled={isFormDisabled}
          />
        </>
      )}
    </Row>
  );
};
