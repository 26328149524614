import "./styles.scss";
import { Button, Row, Tag } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getTagColorProps } from "../../../lib/accountsStatusColor";
import { useMemo } from "react";

type TitleProps = {
  title: string;
  path: string;
  status?: string;
  rightText?: string;
  isHideBack?: boolean;
};

export const Title = ({
  title,
  path,
  status,
  rightText,
  isHideBack,
}: TitleProps) => {
  const navigate = useNavigate();

  const statusProps = useMemo(() => {
    return status ? getTagColorProps(status) : {};
  }, [status]);

  return (
    <Row className="py-5 px-8 align-center title justify-space-between">
      <div className="d-flex align-center">
        {!isHideBack && (
          <Button
            shape="circle"
            icon={<ArrowLeftOutlined />}
            size="large"
            className="back-btn mr-6"
            onClick={() => navigate(path)}
          />
        )}
        <h3 className="h h3">{title}</h3>
        {status && (
          <Tag className="ml-4" {...statusProps}>
            {status}
          </Tag>
        )}
      </div>
      {rightText && (
        <div className="sub-title__container">
          <p className="my-0">{rightText}</p>
        </div>
      )}
    </Row>
  );
};
