import { useCallback, useEffect, useState } from "react";
import ImpoundsService from "../../../../../../../../../services/accounts/impounds";

type ImpoundsAcc = {
  id: string;
  number: number;
};

export const useFetchImpoundAccounts = (contractId: string) => {
  const [impoundsAccs, setImpoundsAccs] = useState<
    { value: string; label: string }[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    ImpoundsService.fetchByNumber({ contractId })
      .then((res: ImpoundsAcc[]) => {
        const data = res.map((item) => {
          return {
            value: item.id,
            label: item.number.toString(),
          };
        });

        setImpoundsAccs(data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [contractId]);

  useEffect(() => fetchData(), [fetchData]);

  return { impoundsAccs, isLoading };
};
