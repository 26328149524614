import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { StepsNavigation } from "./StepsNavigation";
import { FirstStep } from "./FirstStep";
import { SecondStep } from "./SecondStep";
import { Description } from "./Description";

type SignUpStepsProps = {
  email: string;
  status: 0 | 1 | 2 | 3 | null;
  userInitialData: any;
  verificationCodeId: string | null;
  onNextClicked: (value: any) => void;
};

export const SignUpSteps = ({
  email,
  status,
  userInitialData,
  verificationCodeId,
  onNextClicked,
}: SignUpStepsProps) => {
  const [isFirst, setIsFirst] = useState<boolean>(true);

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  useEffect(() => {
    setIsFirst(status !== 2);
  }, [status]);

  return (
    <Row className="mt-16 justify-center">
      <Col className="signup">
        <Row>
          <Description />
          <Col className="sign-right__container">
            <div className="d-flex justify-space-between align-center mb-6">
              <h2 className="header h h2">Sign up</h2>
              <div>
                <StepsNavigation
                  isFirst={isFirst}
                  setIsFirst={setIsFirst}
                  isFormDisabled={isFormDisabled}
                />
              </div>
            </div>

            {isFirst ? (
              <FirstStep email={email} onNextClicked={onNextClicked} />
            ) : (
              <SecondStep
                email={email}
                userInitialData={userInitialData}
                verificationCodeId={verificationCodeId}
                isFormDisabled={isFormDisabled}
                setIsFormDisabled={setIsFormDisabled}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
