import { useEffect, useState } from "react";
import { UsersService } from "../../../../../services/users";
import { Loader } from "../../../../../components/Loader";
import dayjs from "dayjs";
import { UserEvents } from "./UserEvents";
import { DEFAULT_TIME_FORMAT } from "../../../../../lib/constants";

type HistoryTabProps = {
  userId: string | null;
};

export const HistoryTab = ({ userId }: HistoryTabProps) => {
  const [data, setData] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      UsersService.fetchUserHistoryEvents(userId)
        .then((res) => {
          setData(
            res.map((item: any) => ({
              ...item,
              createdAt: dayjs(item.createdAt).format(DEFAULT_TIME_FORMAT),
            }))
          );
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [userId]);

  return (
    <>
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <div className="py-8 px-6">
          <UserEvents data={data} />
        </div>
      )}
    </>
  );
};
