import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetRole } from "../../../lib/hooks/getRole";
import { usePrevious } from "../../../lib/hooks/previous";
import AccountsService from "../../../services/accounts";
import ContractsService from "../../../services/accounts/contracts";
import { useSearchParams } from "react-router-dom";

export const useFetchDataCount = (isOther: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  const [isLoading, setIsLoading] = useState(false);

  const [total, setTotal] = useState<number>(1);

  const onSuccess = useCallback((res: any) => {
    setIsLoading(false);
    setTotal(res);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const request = useMemo(() => {
    const val: any = {
      pageNumber: +(searchParams.get("pageNumber") || 1),
      pageSize: +(searchParams.get("pageSize") || 20),
    };

    const dateFilter = searchParams.get("dateFilter");
    const dateFilterNextPayment = searchParams.get("dateFilterNextPayment");
    const search = searchParams.get("search");
    const order = searchParams.get("order");
    const statusFilter = searchParams.getAll("statusFilter");

    if (dateFilter) {
      val.dateFilter = +dateFilter;
    }

    if (dateFilterNextPayment) {
      val.dateFilterNextPayment = +dateFilterNextPayment;
    }

    if (search) {
      val.search = search;
    }

    if (order) {
      val.order = order;
    }

    if (statusFilter && statusFilter.length > 0) {
      val.statusFilter = statusFilter.map((i: string) => +i);
    }

    if (isOther) {
      val.isOther = true;
    }

    return val;
  }, [searchParams, isOther]);

  const prevRequest = usePrevious(request);

  const fetchDataCount = useCallback(() => {
    setIsLoading(true);
    !isAdmin
      ? AccountsService.fetchAccountsCount(request).then(onSuccess)
      : ContractsService.fetchCount(request).then(onSuccess);
  }, [isAdmin, request, onSuccess]);

  useEffect(() => {
    if (
      !prevRequest ||
      prevRequest.statusFilter !== request.statusFilter ||
      prevRequest.search !== request.search ||
      prevRequest.dateFilter !== request.dateFilter ||
      prevRequest.dateFilterNextPayment !== request.dateFilterNextPayment ||
      prevRequest.isOther !== request.isOther
    ) {
      fetchDataCount();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataCount]);

  return { total, isLoading, request, fetchDataCount, setSearchParams };
};
