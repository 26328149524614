import { FormInstance, Row } from "antd";
import { Loader } from "../../../../../../components/Loader";
import { SearchEmail } from "./SeacrhEmail";
import { SearchMessage } from "./SearchMessage";
import { UsersList } from "./UsersList";

type SelectFormExistingFormProps = {
  form: FormInstance;
  customerId: string | null | undefined;
  activeTab: string;
  showMessage: boolean;
  searchData: any[];
  search: string;
  debouncedSearch: string;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  onSearchChange: (e: any) => void;
  onClearSearch: () => void;
  onDataFetched: (value: any[], show: boolean) => void;
  onChangeTab: (key: string) => void;
  setSelectedUser: (value: any) => void;
};

export const SelectFormExistingForm = ({
  form,
  customerId,
  activeTab,
  showMessage,
  searchData,
  search,
  debouncedSearch,
  isLoading,
  setIsLoading,
  onSearchChange,
  onClearSearch,
  onDataFetched,
  onChangeTab,
  setSelectedUser,
}: SelectFormExistingFormProps) => {
  return (
    <Row className="pt-8 d-block h-100 overflow-hidden">
      <div className="px-6">
        <p className="my-0 pb-4 text-md w-500">Email for login</p>
        <Row>
          <div className="position-relative w-100 search-email">
            <SearchEmail
              form={form}
              tabNumber="2"
              customerId={customerId}
              onDataFetched={onDataFetched}
              hideLabel
              search={search}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              debouncedSearch={debouncedSearch}
              onSearchChange={onSearchChange}
              onClearSearch={onClearSearch}
              activeTab={activeTab}
              showMessage={showMessage}
            />
            {showMessage && (
              <SearchMessage activeTab={activeTab} onChangeTab={onChangeTab} />
            )}
          </div>
        </Row>
      </div>
      <Row className="d-block user-search-list__container overflow-y-auto px-6">
        {!isLoading ? (
          <UsersList
            form={form}
            searchData={searchData}
            setSelectedUser={setSelectedUser}
          />
        ) : (
          <Row className="mt-4 justify-center">
            <Loader fontSize="32px" />
          </Row>
        )}
      </Row>
    </Row>
  );
};
