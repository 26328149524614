import { Form, FormInstance } from "antd";
import { useMemo } from "react";
import { calculateSum } from "../../../../../../../../../lib/calculateSum";

export const useCalculateTotals = (form: FormInstance) => {
  const buyerFields = Form.useWatch(["buyer"], form);

  const sellerFields = Form.useWatch(["seller"], form);

  const subtrahendFields = Form.useWatch(["subtrahend"], form);

  const buyerSubtotal = useMemo(
    () => buyerFields && calculateSum(Object.values(buyerFields)),
    [buyerFields]
  );

  const sellerFeeTotal = useMemo(
    () => sellerFields && calculateSum(Object.values(sellerFields)),
    [sellerFields]
  );

  const buyerTotal = useMemo(() => {
    if (subtrahendFields) {
      const subtrahend = calculateSum(Object.values(subtrahendFields));
      return buyerSubtotal - subtrahend;
    }

    return 0;
  }, [buyerSubtotal, subtrahendFields]);

  return { buyerSubtotal, sellerFeeTotal, buyerTotal };
};
