import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";

type StepsNavigationProps = {
  isFirst: boolean;
  setIsFirst: (value: boolean) => void;
  isFormDisabled: boolean;
};

export const StepsNavigation = ({
  isFirst,
  setIsFirst,
  isFormDisabled,
}: StepsNavigationProps) => {
  return (
    <div className="d-flex align-center">
      <Button
        className={`h h5 pr-3 py-0 pl-0 ${
          !isFirst ? "link-step" : "active-step"
        }`}
        style={{ letterSpacing: 0.5 }}
        onClick={() => setIsFirst(true)}
        disabled={isFormDisabled}
        type="link"
      >
        Step 1
      </Button>
      <RightOutlined />
      <h5 className={`h h5 pl-3 ${isFirst ? "unactive-step" : ""}`}>Step 2</h5>
    </div>
  );
};
