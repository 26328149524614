import { DISBURSEMENTS_STATUS } from "../../../../../../../../../lib/constants";
import { Tag } from "antd";

export const useGetStatusTag = () => {
  const getTag = (value: number) => {
    const tag = DISBURSEMENTS_STATUS.find(item => item.value === value);

    return (
      <Tag color={tag?.color}>{tag?.label}</Tag> 
    );
  };

  return { getTag };
};
