import AuthService from "../../services/auth";
import ROUTES from "../../configs/routesConfig";
import { useGetRole } from "./getRole";
import { useMemo } from "react";

export const useLogoRedirectPath = () => {
  const [role] = useGetRole();

  const logoRedirectPath: string = useMemo(() => {
    const isLoggedIn = AuthService.isLoggedIn();

    let path: string;
    if (isLoggedIn && role) {
      path = role === "User" ? ROUTES.ACCOUNTS : ROUTES.DASHBOARD;
    } else {
      path = ROUTES.LOGIN;
    }

    return path;
  }, [role]);

  return { logoRedirectPath };
};
