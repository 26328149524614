import { useMemo, useState } from "react";
import { useAppDispatch } from "../../../store";
import { SecurityCheck as SecurityCheckBase } from "../../../components/SecurityCheck";
import { login, confirmVerificationCode } from "../../../acions/auth";
import { useRedirectToPortal } from "../hooks/redirectToPortal";
import {
  setErrorMessage,
  clearMessage,
} from "../../../acions/actionsCreators/messageActions";
import jwtDecode from "jwt-decode";
import { useAcceptTerms } from "../hooks/useAcceptTerms";
import { TermsAndConditionsModal } from "../../../components/TermsAndConditionsModal";

type SecurityCheckProps = {
  data: any;
  firstStepData: any;
  onLoginButtonClicked: (value: any) => Promise<any>;
  setIsChecking: (value: boolean) => void;
};

export const SecurityCheck = ({
  data,
  firstStepData,
  onLoginButtonClicked,
  setIsChecking,
}: SecurityCheckProps) => {
  const dispatch = useAppDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { redirect } = useRedirectToPortal();

  const securityCheckSubTitle = useMemo(
    () => (
      <div className="sub-title__container mb-10">
        <p className="justify-center my-0">We just want to be sure it’s you.</p>
        <p className="justify-center my-0">
          Enter the code we’ve just sent to
          <span className="email-text">&ensp;{firstStepData.email}</span>
        </p>
      </div>
    ),
    [firstStepData]
  );

  const onResend = async () => {
    try {
      setIsDisabled(true);
      await onLoginButtonClicked({ ...data, rememberDeviceCode: null });
      setIsDisabled(false);
    } catch {
      setIsDisabled(false);
    }
  };

  const {
    termsTabId,
    setTermsTabId,
    accept,
    acceptButton,
    setAcceptButton,
    isLoading,
  } = useAcceptTerms();

  const onConfirm = (values: any) => {
    const code = values.securityCode.replace(/-/g, "");

    dispatch(clearMessage());
    setIsDisabled(true);

    const request: any = {
      code,
      verificationCodeId: firstStepData.verificationCodeId,
      userId: firstStepData.userId,
      shouldRememeberMe: !!values.rememberCode,
    };

    Promise.resolve(dispatch(confirmVerificationCode("Auth", request)))
      .then(() => {
        Promise.resolve(
          dispatch(
            login({
              password: data.password,
              userId: firstStepData.userId,
              verificationCodeId: firstStepData.verificationCodeId,
            })
          )
        )
          .then((res: any) => {
            const tokenData: any = res.token ? jwtDecode(res.token) : {};

            if (tokenData.IsAgreementsConfirmationNeeded === "True") {
              setTermsTabId("1");

              setAcceptButton({
                label: "Accept",
                action: () => accept(() => redirect(res.role)),
              });
            } else {
              redirect(res.role);
            }
          })
          .catch(() => setIsDisabled(false));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.response.data.title, false));
        setIsDisabled(false);
      });
  };

  const onCloseTerms = (): void => {
    setIsChecking(false);
    setTermsTabId(undefined);
  };

  return (
    <>
      <SecurityCheckBase
        header={"Quick security check"}
        setStep={() => setIsChecking(false)}
        isRememberCode={true}
        isTimer={true}
        subTitle={securityCheckSubTitle}
        onConfirm={(value) => onConfirm(value)}
        type="email"
        isConfirmDisabled={isDisabled}
        onResend={() => onResend()}
      />
      <TermsAndConditionsModal
        termsTabId={termsTabId}
        setTermsTabId={setTermsTabId}
        acceptButton={acceptButton}
        isLoading={isLoading}
        onClose={onCloseTerms}
        closeLabel="Reject"
      />
    </>
  );
};
