import { useMemo } from "react";

export const useContainerClasses = (accountId: string | undefined) => {
  const upperClass: string = useMemo(() => {
    return !accountId ? "table-page-content" : "w-100";
  }, [accountId]);

  const lowerClass: string = useMemo(() => {
    return `d-block w-100 ${
      !accountId ? "late-charges-wrapper py-6 pr-8 pl-2" : "pb-4"
    }`;
  }, [accountId]);

  return { upperClass, lowerClass };
};
