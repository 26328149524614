import { useCallback } from "react";
import { updatePortalAccess } from "../../../../../../acions/customers/portalAccesses";
import { useAppDispatch } from "../../../../../../store";

export const useUpdatePortalAccess = (
  reloadData: () => void,
  setIsDisabled: (value: boolean) => void
) => {
  const dispatch = useAppDispatch();

  const onUpdate = useCallback(
    (id: number, value: boolean) => {
      setIsDisabled(true);
      Promise.resolve(dispatch(updatePortalAccess(id, { isPrimary: value })))
        .then(() => {
          setIsDisabled(false);
          reloadData();
        })
        .catch(() => setIsDisabled(false));
    },
    [dispatch, reloadData, setIsDisabled]
  );

  return { onUpdate };
};
