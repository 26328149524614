import { useMemo } from "react";
import { IPayeeDisbursementsBase } from "../../../types/PayeeDisbursements";

export const useIsTransfer = (instance: any) => {
  const isTransfer: boolean = useMemo(() => {
    if (instance) {
      const transferDisb = instance.disbursements.findIndex(
        (item: IPayeeDisbursementsBase) => item.method === 8
      );

      return transferDisb !== -1;
    }

    return false;
  }, [instance]);

  return { isTransfer };
};
