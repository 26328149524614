import iconAutoCharges from "../../../images/icons/AutoCharges.svg";
import iconWarehouse from "../../../images/icons/Warehouse.svg";
import iconMiscTransactions from "../../../images/icons/MiscTransactions.svg";
import {
  CreditCardOutlined,
  DollarOutlined,
  HddOutlined,
} from "@ant-design/icons";
import { ReactNode } from "react";

type TBatchIcon = {
  value: number;
  icon: ReactNode;
  name: string;
};

const BATCHES_ICONS: TBatchIcon[] = [
  { value: 0, icon: <div />, name: "" },
  { value: 1, icon: <DollarOutlined className="mr-3" />, name: "Payments" },
  {
    value: 2,
    icon: <img src={iconAutoCharges} alt="AutoCharges" className="mr-3" />,
    name: "AutoCharges",
  },
  {
    value: 3,
    icon: <img src={iconWarehouse} alt="Warehouse" className="mr-3" />,
    name: "Warehouse",
  },
  { value: 4, icon: <HddOutlined className="mr-3" />, name: "LockBox 1" },
  { value: 5, icon: <HddOutlined className="mr-3" />, name: "LockBox 2" },
  {
    value: 6,
    icon: <CreditCardOutlined className="mr-3" />,
    name: "Credit Card",
  },
  {
    value: 7,
    icon: (
      <img
        src={iconMiscTransactions}
        alt="Misc Transactions"
        className="mr-3"
      />
    ),
    name: "Misc Transactions",
  },
];

export const useGetTypeIcon = () => {
  const getIcon = (type: number) => {
    return (
      BATCHES_ICONS.find((icon: TBatchIcon) => icon.value === type) ||
      BATCHES_ICONS[0]
    );
  };

  return { getIcon };
};
