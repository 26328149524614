import { Row, Skeleton } from "antd";
import { useFetichTimeZone } from "./hooks/useFetchTimeZone";
import { useMemo } from "react";

export const TimeZone = () => {
  const { timezone, isLoading } = useFetichTimeZone();

  const paragraphProps: any = useMemo(() => ({ rows: 1, width: "100%" }), []);

  return (
    <Row className="d-block info">
      <h5 className="h h5 mb-1">Time Zone</h5>
      {!isLoading ? (
        <p className="text ls-5 my-0 elipsis">{timezone}</p>
      ) : (
        <Skeleton
          className="mt-2"
          active
          round
          paragraph={paragraphProps}
          title={false}
        />
      )}
    </Row>
  );
};
