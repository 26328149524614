export const useGetUserRoleTagProps = () => {
  const getRoleTagProps = (value: string): any => {
    let color: string | undefined;

    if (value === "Admin") {
      color = "blue";
    } else if (value === "SuperAdmin") {
      color = "purple";
    }

    const props: any = {};

    if (color) {
      props.color = color;
    }

    return props;
  };

  return { getRoleTagProps };
};
