import {
  CHART_BLUE_5,
  CHART_CYAN_5,
  CHART_GOLD_5,
  CHART_GREEN_5,
  CHART_PURPLE_5,
  CHART_RED_7,
  CHART_VOLCANO_5,
} from "../../../lib/constants";
import { useState, useEffect, useCallback } from "react";
import { KPI_CODES } from "../kpiCodes";

export const useGetDashboardData = (data: any) => {
  const getValueByCode = useCallback(
    (code: string) => {
      return data.kpiValues.find((item: any) => item.code === code)?.value;
    },
    [data]
  );

  const [opened, setOpened] = useState<any>(null);

  const [closed, setClosed] = useState<any>(null);

  const [other, setOther] = useState<number | null>(null);

  const [draft, setDraft] = useState<number | null>(null);

  const parseData = useCallback(() => {
    const openedValue = [
      {
        type: "Healthy",
        color: CHART_GREEN_5,
        value: getValueByCode(KPI_CODES.ACCOUNTS_HEALTHY),
      },
      {
        type: "Semi-active",
        color: CHART_GOLD_5,
        value: getValueByCode(KPI_CODES.ACCOUNTS_SEMIACTIVE),
      },
      {
        type: "Inactive",
        color: CHART_VOLCANO_5,
        value: getValueByCode(KPI_CODES.ACCOUNTS_INACTIVE),
      },
      {
        type: "Default",
        color: CHART_RED_7,
        value: getValueByCode(KPI_CODES.ACCOUNTS_DEFAULT),
      },
    ];

    setOpened({
      values: openedValue,
      colors: [CHART_GREEN_5, CHART_GOLD_5, CHART_VOLCANO_5, CHART_RED_7],
    });

    const closedValue = [
      {
        type: "Paid Off",
        color: CHART_CYAN_5,
        value: getValueByCode(KPI_CODES.ACCOUNTS_PAYDOFF),
      },
      {
        type: "Replaced",
        color: CHART_BLUE_5,
        value: getValueByCode(KPI_CODES.ACCOUNTS_REPLACED),
      },
      {
        type: "Cancelled",
        color: CHART_PURPLE_5,
        value: getValueByCode(KPI_CODES.ACCOUNTS_CANCELED),
      },
      {
        type: "Foreclosed",
        color: CHART_VOLCANO_5,
        value: getValueByCode(KPI_CODES.ACCOUNTS_FORCLOSED),
      },
    ];

    setClosed({
      values: closedValue,
      colors: [CHART_CYAN_5, CHART_BLUE_5, CHART_PURPLE_5, CHART_VOLCANO_5],
    });

    const otherValue = getValueByCode(KPI_CODES.ACCOUNTS_OTHER);

    const draftValue = getValueByCode(KPI_CODES.ACCOUNTS_DRAFT);

    setOther(otherValue);
    setDraft(draftValue);
  }, [getValueByCode]);

  useEffect(() => {
    if (data) {
      parseData();
    }
  }, [data, parseData]);

  return [opened, closed, other, draft];
};
