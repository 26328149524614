import { SET_LOCATION, CLEAR_LOCATION } from "../types";

export const setLocation = (path: string | number) => ({
  type: SET_LOCATION,
  payload: path,
});

export const clearLocation = () => ({
  type: CLEAR_LOCATION,
});
