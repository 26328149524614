import { useCallback } from "react";
import ROUTES from "../../../configs/routesConfig";
import { NavigateFunction, useNavigate } from "react-router-dom";

export const useRedirectToPortal = () => {
  const navigate: NavigateFunction = useNavigate();

  const redirect = useCallback(
    (role: string): void => {
      navigate(role === "User" ? ROUTES.ACCOUNTS : ROUTES.DASHBOARD);
    },
    [navigate]
  );

  return { redirect };
};
