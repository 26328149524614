import { useCallback, useEffect, useMemo, useState } from "react"
import { ITicklerTypeExtended } from "../../../../types/Tickler"
import TicklersTypeService from "../../../../services/administration/ticklers";

export const useGetInitialValues = (
  id: string | null,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [entry, setEntry] = useState<ITicklerTypeExtended | null>(null);

  const fetchEntry = useCallback(() => {
    if(id !== null) {
      setIsLoading(true);
      TicklersTypeService.fetch(id)
        .then((res: any) => {
          setEntry(res);
          setIsLoading(false);
        });
    }
  }, [id, setIsLoading]);

  useEffect(() => {
    fetchEntry();
  }, [fetchEntry, id]);

  return useMemo(() => {
    return entry !== null
      ? {
        typeName: entry.typeName,
        defaultText: entry.defaultText
      }
      : {
        typeName: "",
        defaultText: ""
      }
  }, [entry]);
}