import { Input, FormInstance, Form } from "antd";
import { Rule } from "antd/es/form";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

type FloatNumberInputProps = {
  name: string | string[];
  label: string;
  form: FormInstance;
  decimalLimit?: number;
  className?: string;
  disabled?: boolean;
  prefix?: string;
  onChange?: () => void;
  rules?: Rule[];
  allowDecimal?: boolean;
  allowNegative?: boolean;
  onPressEnter?: () => void;
  customRef?: any;
};

export const FloatNumberInput = ({
  name,
  label,
  form,
  className,
  disabled,
  prefix = "$",
  onChange,
  decimalLimit = 2,
  rules,
  allowDecimal = true,
  allowNegative = false,
  onPressEnter,
  customRef,
}: FloatNumberInputProps) => {
  const numberMask = createNumberMask({
    prefix: "",
    allowDecimal,
    includeThousandsSeparator: false,
    decimalLimit,
    allowNegative,
  });

  return (
    <Form.Item
      name={name}
      label={label}
      className={className || "mb-5"}
      rules={rules}
      normalize={(value: string) => value || null}
    >
      <MaskedInput
        mask={numberMask}
        guide={false}
        showMask
        ref={customRef}
        render={(ref, props) => {
          const value = form.getFieldValue(name);

          const newProps = {
            ...props,
            onChange: (e: any) => {
              props.onChange(e);
              onChange && onChange();
            },
            onPressEnter: () => {
              onPressEnter && onPressEnter();
            },
          };

          return (
            <Input
              disabled={disabled}
              value={value}
              prefix={prefix}
              ref={(input: any) => ref(input && input.input)}
              {...newProps}
            />
          );
        }}
      />
    </Form.Item>
  );
};
