import { saveAs } from "file-saver";
import { useCallback, useState } from "react";
import ContractsService from "../../../services/accounts/contracts";

export const useExportExcel = (request: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onExportExcelButtonClicked = useCallback((): void => {
    setIsLoading(true);
    ContractsService.getExcel(request)
      .then((res) => {
        const blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "accounts.xlsx");
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [request]);

  return { onExportExcelButtonClicked, isLoading };
};
