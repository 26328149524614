import { Form, FormInstance, Input, Radio, Row } from "antd";
import {
  FLOAT_NUMBER_MSG,
  LATE_CHARGES_ASSESS_METHOD,
  LATE_CHARGES_TYPE,
  LATE_CHARGE_DATE_LIMIT,
} from "../../../lib/constants";
import { useButtonHandlers } from "./hooks/useButtonHandlers";
import { FormButtons } from "../components/FormButtons";
import { useEffect, useMemo, useState } from "react";
import { useGetInitialValues } from "./hooks/useGetInitalValues";
import { Loader } from "../../../components/Loader";
import { useSetAmountInput } from "./hooks/useSetAmountInput";
import { AmountInput } from "./FormElems/AmountInput";
import { LATE_CHARGE_DATE_REQUIRED } from "../../../lib/constants";
import { TFormProps } from "../../../lib/hooks/defaultFormSidebar/types";
import { Rule } from "antd/es/form";

export const LateChargeForm = ({
  id,
  reloadData,
  setIsOpen,
  setId,
  parentId,
}: TFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(!!id);

  const { onSaveClick, onCancelClick, onDeleteBtnClick, isFormDisabled } =
    useButtonHandlers({ reloadData, setIsOpen, form, setId, parentId });

  const initialValues = useGetInitialValues({ id, setIsLoading, parentId });

  const type = Form.useWatch("type", {
    form,
    preserve: true,
  });

  const { isDollarInput } = useSetAmountInput(form, type);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const assessAfterDaysRules: Rule[] = useMemo(
    () => [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: string) {
          if (value === undefined || value.length === 0) {
            return Promise.reject(new Error(LATE_CHARGE_DATE_REQUIRED));
          }

          const formatted = +value;

          if (isNaN(formatted)) {
            return Promise.reject(new Error(FLOAT_NUMBER_MSG));
          }
          if (!value || formatted <= 999) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(LATE_CHARGE_DATE_LIMIT));
        },
      }),
    ],
    []
  );

  return (
    <Row className="late-charge-form h-100 d-flex position-relative flex-column">
      {isLoading ? (
        <Loader fontSize="64" />
      ) : (
        <Form
          form={form}
          className="pa-6"
          layout="vertical"
          requiredMark={false}
          disabled={isFormDisabled}
          initialValues={initialValues}
        >
          <Form.Item
            label="Assess after"
            name="assessAfterDays"
            className="assess-after-input d-flex align-end mb-4"
            rules={assessAfterDaysRules}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Type" name="type" className="mb-4">
            <Radio.Group
              options={LATE_CHARGES_TYPE}
              className="w-100 d-flex flex-column"
            />
          </Form.Item>
          <AmountInput isDollarInput={isDollarInput} form={form} />
          <Form.Item label="Assess Method" name="assessMethod" className="mb-4">
            <Radio.Group
              options={LATE_CHARGES_ASSESS_METHOD}
              className="w-100 d-flex flex-column"
            />
          </Form.Item>
        </Form>
      )}
      <FormButtons
        currentId={id}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
        onDeleteClick={onDeleteBtnClick}
        isFormDisabled={isFormDisabled}
      />
    </Row>
  );
};
