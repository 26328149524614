import { useCallback } from "react";

export const useCalcPrincipalBalance = () => {
  const calcPrincipalBalance = useCallback((history: any) => {
    const { breakdown } = history;

    const { principalAmount, principalToBalance, principalBefore } = breakdown;

    const principalAfter =
      +principalBefore - +principalAmount + +principalToBalance;

    history.breakdown.principalAfter = principalAfter;
  }, []);

  return { calcPrincipalBalance };
};
