import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import { clearLocation } from "../../acions/actionsCreators/nextLocation";
import { useAppDispatch } from "../../store";
import { ModalContext } from "./ModalContext";

type LeaveModalProps = {
  hasChanges: boolean;
  onSave: (onSaved: () => void) => void;
};

export const LeaveModal = ({ onSave, hasChanges }: LeaveModalProps) => {
  const dispacth = useAppDispatch();

  const [isActiveModal, setIsActiveModal] = useState<boolean>(true);

  const nextLocation: any = useSelector((state: any) => state.nextLocation);

  const navigate = useNavigate();

  const navigateToNext = useCallback(() => {
    const path = nextLocation.path;

    dispacth(clearLocation());
    navigate(path);
  }, [dispacth, navigate, nextLocation]);

  const beforeCancel = useCallback(
    async () => await onSave(() => setIsActiveModal(false)),
    [onSave]
  );

  const beforeConfirm = useCallback(
    async () => navigateToNext(),
    [navigateToNext]
  );

  useEffect(() => {
    !isActiveModal && navigateToNext();
  }, [isActiveModal, navigateToNext]);

  const isDirty: boolean = useMemo(() => {
    return isActiveModal && hasChanges;
  }, [hasChanges, isActiveModal]);

  return (
    <ReactRouterPrompt
      when={isDirty}
      beforeConfirm={beforeConfirm}
      beforeCancel={beforeCancel}
    >
      {({ isActive, onConfirm, onCancel }) =>
        isActive && <ModalContext onConfirm={onConfirm} onCancel={onCancel} />
      }
    </ReactRouterPrompt>
  );
};
