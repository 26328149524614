import ROUTES from "../../configs/routesConfig";
import { Header } from "../../components/SecurityCheck/Header";
import { Row, Col, Card, Button } from "antd";
import { useNavigate } from "react-router-dom";

type ThirdStepProps = {
  setStep: (value: string) => void;
  data: any;
};

export const ThirdStep = ({ setStep, data }: ThirdStepProps) => {
  const navigate = useNavigate();

  const onLoginFunctionClicked = () => {
    navigate(ROUTES.LOGIN, {
      state: { email: data.userName || data.email },
      replace: true,
    });
  };

  return (
    <Row className="mt-16 justify-center mx-4">
      <Col className="forgot-acc-form">
        <Header
          header="We found a match"
          onBackClicked={() => setStep("second")}
        />

        <Row className="justify-center d-flex mt-10">
          <Card className="user-card">
            <div className="d-flex justify-center">
              <div className="pr-2 d-flex flex-column align-end">
                {data.userName && <p className="my-0 text">Username:</p>}
                <p className="my-0 text">Email:</p>
              </div>

              <div>
                {data.userName && (
                  <p className="my-0 text w-500 elipsis">{data.userName}</p>
                )}
                <p className="my-0 text w-500 elipsis">{data.email || "-"}</p>
              </div>
            </div>
          </Card>
        </Row>

        <Row className="mt-8 d-flex justify-center login-btn">
          <Button
            type="primary"
            shape="round"
            size="large"
            htmlType="submit"
            block
            style={{ width: "360px" }}
            onClick={onLoginFunctionClicked}
          >
            Login with this {data.userName ? "Username" : "Email"}
          </Button>
        </Row>
      </Col>
    </Row>
  );
};
