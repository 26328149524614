import { FormInstance, Tabs, TabsProps } from "antd";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Payments } from "./Tabs/Payments";
import { PortalAccess } from "./Tabs/PortalAccess";
import { Profile } from "./Tabs/Profile";
import { useAppDispatch } from "../../../store";
import { setErrorMessage } from "../../../acions/actionsCreators/messageActions";
import dayjs from "dayjs";
import { DEFAULT_TIME_FORMAT } from "../../../lib/constants";

type TabsListProps = {
  form: FormInstance;
  types: any[];
  isFormDisabled: boolean;
  selectedItemId: string;
  initialData: any;
  setHasChanges: (value: boolean) => void;
  setInitialData: (value: any) => void;
  onLeaveFirstTab: (
    isNew: boolean,
    onClose: () => void,
    onSaved: () => void,
    onValidationFailed?: () => void
  ) => void;
};

export const TabsList = ({
  form,
  types,
  isFormDisabled,
  selectedItemId,
  initialData,
  setHasChanges,
  setInitialData,
  onLeaveFirstTab,
}: TabsListProps) => {
  const dispatch = useAppDispatch();

  const [lastUpdate, setLastUpdate] = useState<string | null>(null);

  const [active, setActive] = useState<string>("1");

  const lastUpdateLabel: ReactNode = useMemo(() => {
    return (
      <div className="mr-8">
        {lastUpdate ? (
          <p className="my-0 text-md title">Last Updated: {lastUpdate}</p>
        ) : (
          <div />
        )}
      </div>
    );
  }, [lastUpdate]);

  useEffect(() => {
    if (initialData) {
      const { lastModifiedAt } = initialData;

      setLastUpdate(
        lastModifiedAt
          ? dayjs(lastModifiedAt).format(DEFAULT_TIME_FORMAT)
          : null
      );
    }
  }, [initialData]);

  const onCloseWarnPopup = useCallback(
    (key: string, shouldResetForm: boolean) => {
      setActive(key);
      shouldResetForm && form.setFieldsValue(initialData);
      setHasChanges(false);
    },
    [form, initialData, setHasChanges]
  );

  const onValidationFailed = useCallback(() => {
    dispatch(setErrorMessage("Please fix validation errors", true));
  }, [dispatch]);

  const onChangeTab = useCallback(
    (key: string) => {
      if (active !== "1") {
        setActive(key);
      } else {
        onLeaveFirstTab(
          false,
          () => onCloseWarnPopup(key, true),
          () => onCloseWarnPopup(key, false),
          onValidationFailed
        );
      }
    },
    [active, onCloseWarnPopup, onLeaveFirstTab, onValidationFailed]
  );

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Profile",
      children: (
        <Profile
          form={form}
          isFormDisabled={isFormDisabled}
          selectedItemId={selectedItemId}
          setInitialData={setInitialData}
          types={types}
          setHasChanges={setHasChanges}
        />
      ),
    },
    {
      key: "2",
      label: "Payments",
      children: <Payments customerId={selectedItemId} />,
    },
    {
      key: "3",
      label: "Portal Access",
      children: <PortalAccess customerId={selectedItemId} />,
    },
  ];

  return (
    <Tabs
      activeKey={active}
      items={items}
      tabBarExtraContent={lastUpdateLabel}
      onTabClick={onChangeTab}
    />
  );
};
