import { App } from "antd";
import { useGetDeleteDialogProps } from "../../../../../../lib/hooks/getDeleteDialogProps";
import { useCallback } from "react";
import { IPayeeDisbursementsBase } from "../../../../../../types/PayeeDisbursements";

export const useDeleteModal = (
  disbursements: IPayeeDisbursementsBase[],
  reset: (value: IPayeeDisbursementsBase[]) => Promise<0 | 1>
) => {
  const { modal } = App.useApp();

  const [options] = useGetDeleteDialogProps(false);

  const remove = useCallback(
    async (id: string) => {
      const indexToDelete = disbursements.findIndex(
        (item: IPayeeDisbursementsBase) => item.index === id
      );

      disbursements.splice(indexToDelete, 1);

      await reset(disbursements);
    },
    [disbursements, reset]
  );

  const openDeleteModal = useCallback(
    (id: string, onDeleted?: () => void): void => {
      modal.confirm({
        ...options,
        title: "Delete a Payee Disbursement",
        content: (
          <div className="text-md mb-1">Are you sure you want to delete?</div>
        ),
        onOk: async () => {
          await remove(id);
          onDeleted && onDeleted();
        },
      });
    },
    [modal, options, remove]
  );

  return { openDeleteModal };
};
