import { Row, Form, Input, Switch, Button, FormInstance } from "antd";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import {
  BANK_ACCOUNT_NUMBER_MIN_MAX,
  BANK_ACCOUNT_NUMBER_REQUIRED,
  BANK_TRANSIT_ROUTING_REQUIRED,
  WRONG_FORMAT_BANK_ACCOUNT_NUMBER,
  WRONG_FORMAT_BANK_TRANSIT_ROUTING,
} from "../../../../../../lib/constants";
import { useFormHandlers } from "./hooks/formHandlers/editAddHandlers";
import { LoadingOutlined } from "@ant-design/icons";
import { useDeleteHandler } from "./hooks/formHandlers/deleteHandler";

type BankAccountFormProps = {
  selectedItem: any;
  isPrimaryDisabled: boolean;
  showAddAndNewButton: boolean;
  customerId: string;
  form: FormInstance;
  isPrimary: boolean;
  setIsPrimary: (value: boolean) => void;
  onClose: (value: boolean) => void;
  reload: () => void;
  setSelectedItem: (value: any) => void;
  setShowSideBar: (value: boolean) => void;
};

export const BankAccountForm = ({
  onClose,
  selectedItem,
  customerId,
  form,
  isPrimary,
  setIsPrimary,
  showAddAndNewButton,
  reload,
  setSelectedItem,
  setShowSideBar,
  isPrimaryDisabled,
}: BankAccountFormProps) => {
  useEffect(() => {
    if (isPrimaryDisabled) {
      setIsPrimary(true);
    } else {
      const value = selectedItem ? !!selectedItem.isPrimary : false;

      setIsPrimary(value);
    }
  }, [selectedItem, isPrimaryDisabled, setIsPrimary]);

  const refresh = () => {
    setSelectedItem(null);
    reload();
    setIsPrimary(false);
  };

  const { onRemoveClicked } = useDeleteHandler();

  const reloadOnSaved = useCallback(() => {
    reload();
    setShowSideBar(false);
  }, [reload, setShowSideBar]);

  const { onAddClicked, isFormDisabled, setIsFormDisabled } = useFormHandlers(
    form,
    isPrimary,
    customerId,
    selectedItem,
    reloadOnSaved,
    refresh
  );

  useEffect(() => form.setFieldsValue(selectedItem), [selectedItem, form]);

  const removeClickHandler = useCallback(
    () => onRemoveClicked(selectedItem.id, setIsFormDisabled, reloadOnSaved),
    [onRemoveClicked, reloadOnSaved, selectedItem, setIsFormDisabled]
  );

  const buttonGroup: ReactNode = useMemo(() => {
    return (
      <Row
        className={`divider px-6 py-6 ${
          selectedItem ? "justify-space-between" : ""
        }`}
      >
        <Row>
          <Button
            shape="round"
            type="primary"
            className="mr-3"
            onClick={() => onAddClicked(false)}
            disabled={isFormDisabled}
          >
            {selectedItem ? "Save" : "Add"}
            {isFormDisabled && <LoadingOutlined />}
          </Button>
          {showAddAndNewButton && !selectedItem && (
            <Button
              shape="round"
              className="mr-3"
              disabled={isFormDisabled}
              onClick={() => onAddClicked(true)}
            >
              Add & New
              {isFormDisabled && <LoadingOutlined />}
            </Button>
          )}
          <Button
            shape="round"
            className="mr-3"
            onClick={() => onClose(false)}
            disabled={isFormDisabled}
          >
            Cancel
          </Button>
          {selectedItem && (
            <Button
              type="link"
              onClick={removeClickHandler}
              disabled={isFormDisabled}
            >
              Remove
            </Button>
          )}
        </Row>
      </Row>
    );
  }, [
    selectedItem,
    isFormDisabled,
    showAddAndNewButton,
    removeClickHandler,
    onAddClicked,
    onClose,
  ]);

  const onChangePrimary = () => setIsPrimary(!isPrimary);

  return (
    <>
      <Row className="py-8 px-6 d-block sidebar-body bank-account-form  user-content">
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="w-100"
          requiredMark={false}
          disabled={isFormDisabled}
        >
          <Row className="mb-4 w-100">
            <Form.Item
              className="mb-0 w-100"
              name="bankAccountNumber"
              label="Bank Account Number"
              rules={[
                {
                  max: 17,
                  message: BANK_ACCOUNT_NUMBER_MIN_MAX,
                  validateTrigger: "onSubmit",
                },
                {
                  min: 6,
                  message: BANK_ACCOUNT_NUMBER_MIN_MAX,
                  validateTrigger: "onSubmit",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: WRONG_FORMAT_BANK_ACCOUNT_NUMBER,
                  validateTrigger: "onSubmit",
                },
                {
                  required: true,
                  message: BANK_ACCOUNT_NUMBER_REQUIRED,
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Row>
          <Row className="mb-4 w-100">
            <Form.Item
              className="mb-0 w-100"
              name="bankTransitRouting"
              label="Bank Transit/Routing"
              rules={[
                {
                  pattern: /^\d{9}$/,
                  message: WRONG_FORMAT_BANK_TRANSIT_ROUTING,
                  validateTrigger: "onSubmit",
                },
                {
                  required: true,
                  message: BANK_TRANSIT_ROUTING_REQUIRED,
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Row>
          <Row className="mb-4 w-100">
            <Form.Item className="mb-0 w-100" name="isPrimary" label="Primary">
              <Switch
                checked={isPrimary}
                onChange={onChangePrimary}
                disabled={isPrimaryDisabled || isFormDisabled}
              />
            </Form.Item>
          </Row>
        </Form>
      </Row>
      {buttonGroup}
    </>
  );
};
