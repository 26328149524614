import dayjs from "dayjs";
import { useCallback } from "react";

export const useGetFormData = (parentId: string | undefined) => {
  const getFormData = useCallback(
    (entry: any, id: string | null): FormData => {
      const formData = new FormData();

      for (const [key, value] of Object.entries(entry)) {
        if (value === undefined || value === null) {
          continue;
        }

        if (key === "file") {
          if (entry[key].length > 0) {
            formData.append(key, entry[key][0]?.originFileObj);
          } else {
            formData.append("deleteFile", "true");
          }
          continue;
        }

        if (key === "date") {
          formData.append(key, dayjs(entry[key]).format());
          continue;
        }

        formData.append(key, entry[key]);
      }

      if (!id && parentId) {
        formData.append("contractId", parentId);
      }

      return formData;
    },
    [parentId]
  );

  return { getFormData };
};
