import axios from "axios";
import Cookies from "js-cookie";
import { BLOCK_ERROR_MESSGAE } from "../lib/constants";
import ROUTES from "../configs/routesConfig";

const authAxios = axios.create({});

axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response.status === 500) {
      window.location.href = ROUTES.INTERNAL_SERVER_ERROR;
    }
    return Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response.status === 401) {
      localStorage.removeItem("userData");
      window.location.reload();
    }

    if (error.response.status === 500) {
      window.location.href = ROUTES.INTERNAL_SERVER_ERROR;
    }

    if (
      error.response.status === 400 &&
      error.response.data.title.includes(BLOCK_ERROR_MESSGAE)
    ) {
      localStorage.removeItem("userData");
      window.location.href = ROUTES.LOGIN;
      Cookies.set("info", error.response.data.title);
    }

    return Promise.reject(error);
  }
);

export default authAxios;

export const authHeader = () => {
  const userData = localStorage.getItem("userData");
  const user = userData ? JSON.parse(userData || "") : false;

  return user && user.token ? { Authorization: `Bearer ${user.token}` } : {};
};
