import { useCallback, useEffect, useState } from "react";
import { IFeeTypeExtended } from "../../../../types/Fees";

export const useFetchData = (
  accountId: string | undefined,
  request: any,
  service: any
) => {
  const [data, setData] = useState<IFeeTypeExtended[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    if (
      (accountId && request.contractId && accountId === request.contractId) ||
      !accountId
    ) {
      setIsLoading(true);

      const req = { ...request };
      if (accountId) {
        req.contractId = accountId;
      }

      service
        .fetchAll(req)
        .then((res: any) => {
          setData(res);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [request, accountId, service]);

  useEffect(() => fetchData(), [fetchData]);

  return { data, isLoading, fetchData };
};
