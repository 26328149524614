import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
} from "antd";
import { useFetch } from "../hooks/useFetch";
import { DATE_FORMAT } from "../../../../../../../../../../../lib/constants";
import { FloatNumberInput } from "../../../../../../../../../../../components/FloatNumberInput";
import { ACTIVITY_TYPES } from "../../../../../../../../../../../lib/constants";
import { useCallback, useMemo, useState } from "react";
import { Rule } from "antd/es/form";
import { useUpdate } from "../hooks/useUpdate";

type ActivityFormProps = {
  id: string | null;
  closeModal: () => void;
  reloadData: () => void;
};

export const ActivityForm = ({
  id,
  closeModal,
  reloadData,
}: ActivityFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const { TextArea } = Input;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useFetch(id, form, setIsLoading);

  const formItemRules: Rule[] = useMemo(() => [{ required: true }], []);

  const closeAfterSave = useCallback((): void => {
    closeModal();
    reloadData();
  }, [closeModal, reloadData]);

  const { onSave } = useUpdate(id, form, setIsLoading, closeAfterSave);

  return (
    <Row className="d-flex flex-column">
      <Form
        form={form}
        className="w-100 mt-6 distribution-form"
        layout="vertical"
        requiredMark={false}
        disabled={isLoading}
      >
        <Form.Item
          label="Date"
          name="date"
          className="mb-4"
          rules={[
            { type: "date", required: true, validateTrigger: "onSubmit" },
          ]}
        >
          <DatePicker format={DATE_FORMAT} />
        </Form.Item>
        <FloatNumberInput
          name="auditNumber"
          label="Audit"
          form={form}
          className="w-100 mb-4"
          prefix=""
          disabled={isLoading}
          rules={formItemRules}
        />
        <Form.Item label="Type" name="direction" className="mb-4 type-group">
          <Radio.Group
            options={ACTIVITY_TYPES}
            className="w-100 d-flex flex-column"
          />
        </Form.Item>
        <FloatNumberInput
          name="amount"
          label="Amount"
          form={form}
          className="w-100 mb-4"
          disabled={isLoading}
          rules={formItemRules}
        />
        <Form.Item label="Description" name="description" className="mb-4">
          <TextArea rows={2} className="area-resize-disabled" />
        </Form.Item>
        <FloatNumberInput
          name="balance"
          label="Balance"
          form={form}
          className="w-100 mb-6"
          disabled={isLoading}
          rules={formItemRules}
          allowNegative
        />
      </Form>
      <Row className="distribution-form-footer-wrapper justify-end align-center mt-6">
        <Button
          shape="round"
          type="primary"
          className="mr-2"
          onClick={onSave}
          loading={isLoading}
        >
          Save
        </Button>
        <Button shape="round" onClick={closeModal} disabled={isLoading}>
          Cancel
        </Button>
      </Row>
    </Row>
  );
};
