import { useCallback, useEffect, useState } from "react";
import PayeeDisbursementsService from "../../../../../../../../../services/accounts/payeeDisbutsements";
import {
  IPayeeDisbursements,
} from "../../../../../../../../../types/PayeeDisbursements";

type TFetchData = {
  request: {
    pageNumber: number;
    pageSize: number;
    contractId: string;
  };
  setShouldPayeeDisbUpdate: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useFetchData = ({
  request,
  setShouldPayeeDisbUpdate,
}: TFetchData) => {
  const [data, setData] = useState<IPayeeDisbursements[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);

    PayeeDisbursementsService.fetchAll(request)
      .then((res: any) => {
        const data = res.map((entry: IPayeeDisbursements) => {
          return {
            ...entry,
            isAlternate: !(entry.alternativePayeeId === null),
          };
        });

        setData(data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false))
      .finally(() => setShouldPayeeDisbUpdate(false));
  }, [request, setShouldPayeeDisbUpdate]);

  useEffect(() => fetchData(), [fetchData]);

  return { data, isLoading, fetchData };
};
