import { ColumnsType } from "antd/es/table";
import { useCallback, useMemo, MouseEvent } from "react";
import { IBatchGet } from "../../../types/Batches";
import { useGetTypeIcon } from "./useGetTypeIcon";
import { getFormattedCurrency } from "../../../lib/currencyFormatter";
import { useGetProcessingState } from "./useGetProcessingState";
import { FileDoneOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, Tooltip } from "antd";
import { useButtonHandlers } from "./useButtonHandlers";
import { DATE_FORMAT } from "../../../lib/constants";
import dayjs from "dayjs";
import { useValidateBatch } from "./useValidateBatch";
import ROUTES from "../../../configs/routesConfig";
import { Link } from "react-router-dom";

type TTableColumns = {
  setIsOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  reloadData: () => void;
  navigateToBatch: (id: string) => void;
};

export const useGetBatchTableColumns = ({
  setIsOpenEditModal,
  setId,
  reloadData,
  navigateToBatch,
}: TTableColumns) => {
  const { validate } = useValidateBatch(reloadData);

  const { getIcon } = useGetTypeIcon();

  const { getState } = useGetProcessingState();

  const { onDeleteBtnClick } = useButtonHandlers({
    setIsOpenEditModal,
    onEditSuccess: reloadData,
  });

  const onEditBtnClick = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      setIsOpenEditModal(true);
      setId(e.currentTarget.id);
    },
    [setId, setIsOpenEditModal]
  );

  const onOpenView = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      navigateToBatch(e.currentTarget.id);
    },
    [navigateToBatch]
  );

  const onValidate = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      validate(e.currentTarget.id);
    },
    [validate]
  );

  const onDelete = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      onDeleteBtnClick(e.currentTarget.id);
    },
    [onDeleteBtnClick]
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Batch#",
        key: "batchNumber",
        dataIndex: "batchNumber",
        width: "13%",
        render: (value: number, record: IBatchGet) => {
          if (!value) {
            return dayjs(record.date).format(DATE_FORMAT);
          }

          const { id } = record;

          return (
            <Link
              className="cell-elipsis pa-0"
              to={`${ROUTES.PROCESSING}/${id}`}
            >
              <span className="link-btn">{value}</span>
            </Link>
          );
        },
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        width: "18%",
        render: (value: number, _: IBatchGet) => {
          if (!value) return <></>;

          const { icon, name } = getIcon(value);

          return (
            <div className="d-flex align-center">
              {icon}
              {name}
            </div>
          );
        },
      },
      {
        title: "Total Amount",
        key: "totalAmount",
        dataIndex: "totalAmount",
        width: "18%",
        align: "right",
        render: (value: number) => {
          const formattedValue = getFormattedCurrency(value);

          return value !== undefined ? formattedValue : "";
        },
      },
      {
        title: "Posted Payments",
        key: "postedPaymentsCount",
        dataIndex: "postedPaymentsCount",
        width: "14%",
        align: "right",
        render: (value: number, record: IBatchGet) => {
          if (value === undefined) return <></>;

          const { paymentsCount, type } = record;

          const className: string =
            value < paymentsCount && type !== 3
              ? "processing-posted-payments"
              : "";

          return (
            <div className={className}>
              {type === 3 ? "-" : `${value}/${paymentsCount}`}
            </div>
          );
        },
      },
      {
        title: "Processing State",
        key: "isValidated",
        dataIndex: "isValidated",
        width: "14%",
        render: (value: boolean, record: IBatchGet) => {
          if (value === undefined) return <></>;

          return getState(record);
        },
      },
      {
        title: "Created by",
        key: "createdByUser",
        dataIndex: "createdByUser",
        width: "15%",
        ellipsis: true,
      },
      {
        title: "Action",
        key: "actions",
        width: "8%",
        render: (_: any, record: IBatchGet) => {
          if (!record.batchNumber) return <></>;

          const { id } = record;

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <div id={id} onClick={onOpenView}>
                  View Batch
                </div>
              ),
            },
            {
              key: "2",
              label: (
                <div id={id} onClick={onEditBtnClick}>
                  Edit Batch Details
                </div>
              ),
            },
            {
              key: "3",
              label: (
                <div id={id} onClick={onDelete}>
                  Delete Batch
                </div>
              ),
            },
          ];

          return (
            <>
              <Tooltip title="Recalculate and validate batch" trigger={"hover"}>
                <FileDoneOutlined
                  id={id}
                  className="ant-table-btn-size ant-table-btn mr-4 logo-cursor"
                  onClick={onValidate}
                />
              </Tooltip>
              <Dropdown menu={{ items }} trigger={["click"]}>
                <EllipsisOutlined
                  className="ant-table-btn-size ant-table-btn"
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            </>
          );
        },
      },
    ],
    [getIcon, getState, onDelete, onEditBtnClick, onOpenView, onValidate]
  );

  return { columns };
};
