import { Progress } from "antd";
import { useGetTaskStatus } from "./hooks/useGetTaskStatus";

type ProgressBarProps = {
  id: string;
  errorMessage: string;
  onSuccess: () => void;
};

export const ProgressBar = ({
  id,
  errorMessage,
  onSuccess,
}: ProgressBarProps) => {
  const { percent } = useGetTaskStatus(id, errorMessage, onSuccess);

  return <Progress percent={percent} />;
};
