import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import { ILateCharge } from "../../types/LateCharges";
import authAxios, { authHeader } from "../data";

const fetch = async (id: string) => {
  const response = await authAxios.get(API_URL + "ContractLateCharges/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const fetchAll = async (req: any) => {
  const response = await authAxios.get(API_URL + "ContractLateCharges", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (req: any) => {
  const response = await authAxios.get(API_URL + "ContractLateCharges/Count", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const create = async (req: ILateCharge) => {
  const response = await authAxios.post(API_URL + "ContractLateCharges", req, {
    headers: authHeader(),
  });

  return response.data;
};

const edit = async (req: ILateCharge, id: string) => {
  const response = await authAxios.put(
    API_URL + "ContractLateCharges/" + id,
    req,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const remove = async (id: string) => {
  const response = await authAxios.delete(
    API_URL + "ContractLateCharges/" + id,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchTotal = async (contractId: string) => {
  const response = await authAxios.get(
    API_URL + "ContractLateCharges/total?contractId=" + contractId,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const updateTotal = async (req: any, id: string) => {
  const response = await authAxios.put(
    API_URL + "ContractLateCharges/total?contractId=" + id,
    req,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const ContractLateChargesService = {
  fetch,
  fetchAll,
  fetchCount,
  create,
  edit,
  remove,
  fetchTotal,
  updateTotal,
};

export default ContractLateChargesService;
