import qs from "qs";
import { API_URL } from "../../../configs/apiUrl";
import authAxios, { authHeader } from "../../data";
import { IImpoundsPost } from "../../../types/Impounds";

const fetchAll = async (request: any) => {
  const response = await authAxios.get(API_URL + "Impounds", {
    params: request,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const fetch = async (id: string) => {
  const response = await authAxios.get(API_URL + "Impounds/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (request: { contactId: string }) => {
  const response = await authAxios.get(API_URL + "Impounds/Count", {
    params: request,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const fetchByNumber = async (request: { contractId: string }) => {
  const response = await authAxios.get(API_URL + "Impounds/ByNumber", {
    params: request,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const deleteOne = async (id: string) => {
  const response = await authAxios.delete(API_URL + "Impounds/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const create = async (request: IImpoundsPost) => {
  const response = await authAxios.post(API_URL + "Impounds", request, {
    headers: authHeader(),
  });

  return response.data;
};

const edit = async (request: IImpoundsPost, id: string) => {
  const response = await authAxios.put(API_URL + "Impounds/" + id, request, {
    headers: authHeader(),
  });

  return response.data;
};

const ImpoundsService = {
  fetchAll,
  fetch,
  fetchCount,
  deleteOne,
  create,
  edit,
  fetchByNumber,
};

export default ImpoundsService;
