import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../actionsCreators/messageActions";
import {
  DELETE_USER_SUCCESS,
  UPDATE_RECORD_SUCCESS,
  TICKLER_CREATED,
} from "../../lib/constants";
import { ITickler } from "../../types/Tickler";
import TicklersService from "../../services/accounts/ticklers";

export const deleteTickler =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await TicklersService.deleteEntry(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const editTickler =
  (
    entry: ITickler,
    id: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await TicklersService.editEntry(id, entry);

      dispatch(setSuccessMessage(UPDATE_RECORD_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const createTickler =
  (entry: ITickler): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await TicklersService.createEntry(entry);

      dispatch(setSuccessMessage(TICKLER_CREATED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
