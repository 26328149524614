import authAxios, { authHeader } from "../data";
import { API_URL } from "../../configs/apiUrl";

const create = async (batchId: string, request: any) => {
  const result = await authAxios.post(
    `${API_URL}batches/${batchId}/autocharges/create`,
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const AutoChargesService = {
  create,
};

export default AutoChargesService;
