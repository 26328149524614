import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { CHANGES_WERE_SAVED, DELETE_USER_SUCCESS } from "../../lib/constants";
import CustomerBankAccountsService from "../../services/customers/bankAccounts";
import { RootState } from "../../store";
import { setSuccessMessage, setErrorMessage } from "../actionsCreators/messageActions";

export const saveBankAccount =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await CustomerBankAccountsService.save(request);

      dispatch(setSuccessMessage(CHANGES_WERE_SAVED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const updateBankAccount =
  (
    id: string,
    request: any
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await CustomerBankAccountsService.update(id, request);

      dispatch(setSuccessMessage(CHANGES_WERE_SAVED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const deleteBankAccount =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await CustomerBankAccountsService.remove(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };
