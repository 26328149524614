import { SET_LOCATION, CLEAR_LOCATION } from "../acions/types";

const initialState = {};

export default function nextLocation(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOCATION:
      return { path: payload };
    case CLEAR_LOCATION:
      return initialState;
    default:
      return state;
  }
}
