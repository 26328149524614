import { useCallback, useMemo } from "react";
import { CustomModal } from "../../../lib/CustomModal";
import { BatchForm } from "./BatchForm";
import { useButtonHandlers } from "../hooks/useButtonHandlers";
import "./styles.scss";

type TBatchModal = {
  id: string | null;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId?: React.Dispatch<React.SetStateAction<string | null>>;
  onEditSuccess: () => void;
};

export const BatchModal = ({
  id,
  isOpen,
  setIsOpen,
  onEditSuccess,
  setId,
}: TBatchModal) => {
  const title = useMemo(
    () => (id !== null ? "Edit Batch" : "Create Batch"),
    [id]
  );

  const { onCancelBtnClick } = useButtonHandlers({
    setIsOpenEditModal: setIsOpen,
    onEditSuccess,
  });

  const onClose = useCallback((): void => {
    setId && setId(null);
    onCancelBtnClick();
  }, [onCancelBtnClick, setId]);

  return (
    <CustomModal
      isModalOpen={isOpen}
      setIsModalOpen={onClose}
      titleText={title}
      width={300}
      destroyOnClose
    >
      <BatchForm
        id={id}
        setId={setId}
        setIsOpenEditModal={setIsOpen}
        onEditSuccess={onEditSuccess}
        onClose={onClose}
      />
    </CustomModal>
  );
};
