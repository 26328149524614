import { Row, Col, Form, Input, Button } from "antd";
import { Header } from "../../components/SecurityCheck/Header";
import { useNavigate } from "react-router-dom";
import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  MAX_FIRSTNAME,
  MAX_LASTNAME,
  WRONG_LAST_NAME,
  WRONG_FIRST_NAME,
} from "../../lib/constants";
import { LoadingOutlined } from "@ant-design/icons";

import { PhoneInput } from "../../components/PhoneInput";
import { NAME_RULE } from "../../lib/validationRules";
import { useValidateOnBlur } from "../../lib/hooks/validateOnBlur";
import "./styles.scss";

type FirstStepProps = {
  data: any;
  isFormDisabled: boolean;
  onSwitchToSecondStep: (values: any) => void;
};

export const FirstStep = ({
  data,
  isFormDisabled,
  onSwitchToSecondStep,
}: FirstStepProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onNextButtonClicked = (values: any) => {
    onSwitchToSecondStep(values);
  };

  const [validateOnBlur] = useValidateOnBlur(form);

  return (
    <Row className="mt-16 justify-center">
      <Col className="forgot-acc-form mx-4">
        <Header
          header="Forgot email/username?"
          onBackClicked={() => navigate(-1)}
        />

        <Row className="mt-2 d-flex justify-center">
          <div>
            <p className="my-0 text-center">
              It happens. Fill the form and we’ll try to find it.
            </p>
          </div>
        </Row>

        <Row className="mt-10 d-flex justify-center">
          <Form
            form={form}
            className="form__container"
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
            onFinish={onNextButtonClicked}
            disabled={isFormDisabled}
            initialValues={data}
          >
            <Form.Item
              className="mb-5"
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: FIRST_NAME_REQUIRED,
                  validateTrigger: "onSubmit",
                },
                {
                  pattern: NAME_RULE,
                  message: WRONG_FIRST_NAME,
                  validateTrigger: "onSubmit",
                },
                {
                  max: 50,
                  message: MAX_FIRSTNAME,
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <Input
                size="large"
                onBlur={(e) => validateOnBlur(e, "firstName")}
              />
            </Form.Item>

            <Form.Item
              className="mb-5"
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: LAST_NAME_REQUIRED,
                  validateTrigger: "onSubmit",
                },
                {
                  pattern: NAME_RULE,
                  message: WRONG_LAST_NAME,
                  validateTrigger: "onSubmit",
                },
                {
                  max: 50,
                  message: MAX_LASTNAME,
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <Input
                size="large"
                onBlur={(e) => validateOnBlur(e, "lastName")}
              />
            </Form.Item>

            <PhoneInput form={form} />

            <Form.Item className="mt-3 mb-12">
              <Button
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                block
              >
                Next
                {isFormDisabled && <LoadingOutlined />}
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};
