import { Modal } from "antd";
import { useEffect } from "react";
import { useHasChangesModalOptions } from "../../pages/Customer/hooks/hasChangesModalOptions";

type ModalContextProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export const ModalContext = ({ onConfirm, onCancel }: ModalContextProps) => {
  const [modal, contextHolder] = Modal.useModal();

  const { options } = useHasChangesModalOptions(false);

  useEffect(() => {
    modal.confirm({
      ...options,
      onCancel: () => onCancel(),
      onOk: () => onConfirm(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return contextHolder;
};
