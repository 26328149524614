import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useTableSorter = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const setOrederByType = useCallback(
    (
      newRequest: any,
      sorter: any,
      ascValue: number,
      descValue: number
    ): void => {
      if (sorter.order === "ascend") {
        newRequest.userOrder = ascValue;
      }

      if (sorter.order === "descend") {
        newRequest.userOrder = descValue;
      }
    },
    []
  );

  const onSortChange = useCallback(
    (sorter: any, request: any): void => {
      const newRequest = { ...request };

      if (!sorter.order) {
        delete newRequest.userOrder;
      }

      switch (sorter.field) {
        case "firstName":
          setOrederByType(newRequest, sorter, 0, 1);
          break;
        case "role":
          setOrederByType(newRequest, sorter, 2, 3);
          break;
        case "status":
          setOrederByType(newRequest, sorter, 4, 5);
          break;
        default:
          break;
      }

      setSearchParams(newRequest);
    },
    [setOrederByType, setSearchParams]
  );

  return { onSortChange };
};
