import dayjs, { Dayjs } from "dayjs";
import { useCallback, useState } from "react";
import { editBatch } from "../../../../../../../acions/processing/batches";
import { useAppDispatch } from "../../../../../../../store";
import { IBatchGet } from "../../../../../../../types/Batches";

export const useUpdatePaymentDate = (
  onCloseEdit: () => void,
  datePickerStatus: undefined | "error",
  updateDataPaymentDate: (value: string) => void,
  data: IBatchGet
) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const update = useCallback(
    (value: Dayjs | null, id: string) => {
      if (!datePickerStatus) {
        setIsLoading(true);

        const request = {
          date: data.date,
          paymentsDate: dayjs(value).format(),
          isCreatorPaymentOnly: data.isCreatorPaymentOnly,
          type: data.type,
        };

        Promise.resolve(dispatch(editBatch(request, id)))
          .then(() => {
            setIsLoading(false);

            updateDataPaymentDate &&
              updateDataPaymentDate(request.paymentsDate);

            onCloseEdit();
          })
          .catch(() => {
            setIsLoading(false);
            onCloseEdit();
          });
      }
    },
    [data, dispatch, onCloseEdit, updateDataPaymentDate, datePickerStatus]
  );

  return { isLoading, update };
};
