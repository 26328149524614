import { Tag } from "antd";
import { useCallback } from "react";

export const useGetStatusLabel = () => {
  const getStatusLabel = useCallback((value: boolean) => {
    const tagColor = value ? "success" : "blue";
    const text = value ? "Posted" : "Draft";

    return <Tag color={tagColor}>{text}</Tag>;
  }, []);

  return { getStatusLabel };
};
