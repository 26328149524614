import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";

const fetchAll = async (request: any) => {
  const response = await authAxios.get(
    API_URL + "PaymentHistories/ByCustomer",
    {
      params: request,
      paramsSerializer: (params) => qs.stringify(params),
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchCount = async (request: any) => {
  const response = await authAxios.get(
    API_URL + "PaymentHistories/ByCustomer/count",
    {
      params: request,
      paramsSerializer: (params) => qs.stringify(params),
      headers: authHeader(),
    }
  );

  return response.data;
};

const CustomerPaymentsService = {
  fetchAll,
  fetchCount,
};

export default CustomerPaymentsService;
