import { Row } from "antd";
import { MultiSelect } from "../../../../components/MultiSelect";
import { SearchWithClear } from "../../../../components/SearchWithClear";
import {
  ACCOUNT_STATUSES,
  LAST_PAYMENT_OPTIONS,
  NEXT_PAYMENT_OPTIONS,
} from "../../../../lib/constants";
import { useCallback, useMemo } from "react";
import { useGetRole } from "../../../../lib/hooks/getRole";
import { AdminActions } from "./AdminActions";
import "./styles.scss";
import { SingleSelect } from "../../../../components/SingleSelect";
import { useSearchParams } from "react-router-dom";

type FiltersProps = {
  request: any;
  isLoading: boolean;
  isOther: boolean;
};

export const Filters = ({ request, isLoading, isOther }: FiltersProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, setSearchParams] = useSearchParams();

  const accountStatuses: any[] = useMemo(() => {
    const value: any[] = structuredClone(ACCOUNT_STATUSES);

    !isAdmin && value.shift();

    return value;
  }, [isAdmin]);

  const setSearch = useCallback(
    (value: string) => {
      const params = { ...request, pageNumber: 1 };

      if (!value) {
        delete params.search;
      } else {
        params.search = value;
      }

      setSearchParams(params);
    },
    [request, setSearchParams]
  );

  const setStatuses = useCallback(
    (value: string[]) => {
      const params = { ...request, pageNumber: 1 };

      if (value[0] === "All") {
        delete params.statusFilter;
      } else {
        params.statusFilter = value;
      }

      setSearchParams(params);
    },
    [request, setSearchParams]
  );

  const setDateFilter = useCallback(
    (value: string | number, name: string) => {
      const params = { ...request, pageNumber: 1 };

      if (value === 0) {
        delete params[name];
      } else {
        params[name] = value;
      }

      setSearchParams(params);
    },
    [request, setSearchParams]
  );

  return (
    <Row className="d-block width-100">
      <Row className="my-6">
        <h3 className="h h3">{isOther && "Other"} Accounts</h3>
      </Row>
      <Row className={`mb-4 ${isAdmin ? "justify-space-between" : ""}`}>
        <div className="d-flex filters">
          {isAdmin && (
            <>
              <div className="mr-4 date-selector">
                <SearchWithClear
                  isLoading={isLoading}
                  search={request.search || ""}
                  setSearch={setSearch}
                />
              </div>
              {!isOther && (
                <>
                  <div className="mr-4 payment-select">
                    <SingleSelect
                      options={LAST_PAYMENT_OPTIONS}
                      label="Last Payment"
                      value={request.dateFilter || 0}
                      onChange={(val) => setDateFilter(val, "dateFilter")}
                      loading={isLoading}
                    />
                  </div>
                  <div className="mr-4 payment-select">
                    <SingleSelect
                      options={NEXT_PAYMENT_OPTIONS}
                      label="Next Payment"
                      value={request.dateFilterNextPayment || 0}
                      onChange={(val) =>
                        setDateFilter(val, "dateFilterNextPayment")
                      }
                      loading={isLoading}
                    />
                  </div>
                </>
              )}
            </>
          )}
          {!isOther && (
            <MultiSelect
              options={accountStatuses}
              isLoading={isLoading}
              onChange={setStatuses}
              label="Status"
              defaultValue={request.statusFilter || undefined}
              maxTagCount={1}
            />
          )}
        </div>
        {isAdmin && <AdminActions request={request} isOther={isOther} />}
      </Row>
    </Row>
  );
};
