import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";

const fetchAll = async (req: {
  batchId: string;
  pageSize: number;
  pageNumber: number;
  method: number | null;
  orderBy?: number;
}) => {
  const result = await authAxios.get(API_URL + "BatchDisbursements", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetchCount = async (req: {
  batchId: string;
  method: number | null;
  orderBy?: number;
}) => {
  const result = await authAxios.get(API_URL + "BatchDisbursements/Count", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const BatchDisbursementsService = {
  fetchAll,
  fetchCount,
};

export default BatchDisbursementsService;
