import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../../../../../../store";
import {
  saveBankAccount,
  updateBankAccount,
} from "../../../../../../../../acions/customers/bankAccounts";
import { FormInstance } from "antd";

export const useFormHandlers = (
  form: FormInstance,
  isPrimary: boolean,
  customerId: string,
  selectedItem: any | null,
  reloadOnSaved: () => void,
  refresh: () => void
) => {
  const dispatch = useAppDispatch();

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const onAddClicked = useCallback(
    async (isNew: boolean) => {
      try {
        const res = await form.validateFields();
        const request = { ...res, isPrimary, customerId };

        setIsFormDisabled(true);
        try {
          await Promise.resolve(
            dispatch(
              !selectedItem
                ? saveBankAccount(request)
                : updateBankAccount(selectedItem.id, request)
            )
          );
          form.resetFields();
          setIsFormDisabled(false);
          isNew ? refresh() : reloadOnSaved();
        } catch {
          return setIsFormDisabled(false);
        }
      } catch {}
    },
    [
      form,
      isPrimary,
      dispatch,
      reloadOnSaved,
      refresh,
      customerId,
      selectedItem,
    ]
  );

  return { onAddClicked, isFormDisabled, setIsFormDisabled };
};
