import { useState, useEffect } from "react";
import { ValidationStep } from "../components/VerificationStep";
import { SecondStep } from "./SecondStep";
import { changePhone } from "../../../acions/profiles";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { useGetEmailFromLocation } from "../../../lib/hooks/getEmailfromLocation";
import ROUTES from "../../../configs/routesConfig";

export const ChangePhone = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [step, setStep] = useState<"first" | "second" | "third">("first");

  const [newPhone, setNewPhone] = useState<string>("");

  const [email] = useGetEmailFromLocation();

  useEffect(() => {
    newPhone && setStep("third");
  }, [newPhone]);

  const onConfirmSuccess = () => {
    const formattedPhone = `1${newPhone.replace(/[\s-()]/g, "")}`;

    return Promise.resolve(dispatch(changePhone(formattedPhone)));
  };

  return (
    <>
      {step === "first" && (
        <ValidationStep
          setStep={() => setStep("second")}
          type="email"
          onBack={() => navigate(ROUTES.PROFILE)}
          subHeaderText="We just want to be sure it’s you."
          headerText="Quick security check"
          email={email}
        />
      )}
      {step === "second" && (
        <SecondStep
          onBackClicked={() => setStep("first")}
          setNewPhone={setNewPhone}
        />
      )}
      {step === "third" && (
        <ValidationStep
          setStep={() => navigate(ROUTES.PROFILE)}
          onBack={() => setStep("second")}
          type="SMS"
          onConfirmSuccess={onConfirmSuccess}
          phone={newPhone}
          subHeaderText="Let us know this new phone belongs to you."
          headerText="Enter the code from your phone"
          email={email}
        />
      )}
    </>
  );
};
