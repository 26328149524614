import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import SignUpService from "../services/signUp";
import { CODE_SEND, LOGIN_CREATED } from "../lib/constants";
import { setSuccessMessage, setErrorMessage } from "./actionsCreators/messageActions";

export const sendVerificationCode =
  (email: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await SignUpService.sendVerificationCode(email);

      dispatch(setSuccessMessage(CODE_SEND));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const confirmVerificationCode =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await SignUpService.confirmVerificationCode(request);

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const register =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await SignUpService.register(request);

      dispatch(setSuccessMessage(LOGIN_CREATED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
