import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";

const fetchAll = async (req: any) => {
  const response = await authAxios.get(API_URL + "ContractDocuments", {
    params: req,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (req: any) => {
  const response = await authAxios.get(API_URL + "ContractDocuments/Count", {
    params: req,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetch = async (id: string) => {
  const response = await authAxios.get(API_URL + "ContractDocuments/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const remove = async (id: string) => {
  const response = await authAxios.delete(`${API_URL}ContractDocuments/${id}`, {
    headers: authHeader(),
  });

  return response.data;
};

const create = async (entry: FormData) => {
  const response = await authAxios.post(API_URL + "ContractDocuments", entry, {
    headers: authHeader(),
  });

  return response.data;
};

const edit = async (id: string, entry: FormData) => {
  const response = await authAxios.put(
    `${API_URL}ContractDocuments/${id}`,
    entry,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const downloadFile = async (id: string) => {
  const response = await authAxios.get(
    API_URL + `ContractDocuments/${id}/download`,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      headers: authHeader(),
      responseType: "blob",
    }
  );

  return response.data;
};

const DocumentsService = {
  fetchAll,
  fetchCount,
  fetch,
  remove,
  create,
  edit,
  downloadFile,
};

export default DocumentsService;
