import { ReactNode, useMemo, useState } from "react";
import { SideBarContainer } from "../../../../../../../../../components/SideBarContainer";
import { ImpoundsTabs } from "../components";

type TOpenSidebar = {
  id: string | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  reloadData: () => void;
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  };
  activeKey: string;
  customSidebarClass: string;
  parentId: string;
  isDistributionModalOpen: boolean;
  setIsDistributionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  impoundCurrentBalance: number;
};

export const useOpenSidebar = ({
  id,
  setId,
  reloadData,
  payeesNumber,
  activeKey,
  customSidebarClass,
  parentId,
  isDistributionModalOpen,
  setIsDistributionModalOpen,
  impoundCurrentBalance,
}: TOpenSidebar
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const formWrapper: ReactNode = useMemo(() => {
    const formTitle = id !== null ? `Impound Details` : `Add Impound`;

    return (
      <SideBarContainer
        title={formTitle}
        setShowSideBar={setIsOpen}
        setCurrentId={setId}
        customClass={customSidebarClass}
      >
        <ImpoundsTabs
          id={id}
          reloadData={reloadData}
          setIsOpen={setIsOpen}
          setId={setId}
          parentId={parentId}
          payeesNumber={payeesNumber}
          activeKey={activeKey}
          isDistributionModalOpen={isDistributionModalOpen}
          setIsDistributionModalOpen={setIsDistributionModalOpen}
          impoundCurrentBalance={impoundCurrentBalance}
        />
      </SideBarContainer>
    )
  },
  [
    activeKey,
    customSidebarClass,
    id,
    isDistributionModalOpen,
    parentId,
    payeesNumber,
    reloadData,
    setId,
    setIsDistributionModalOpen,
    impoundCurrentBalance
  ]);

  return { formWrapper, isOpen, setIsOpen };
}