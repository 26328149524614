import { ThunkAction } from "redux-thunk";
import { RootState } from "../../../store";
import { AnyAction } from "redux";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../actionsCreators/messageActions";
import {
  CHANGES_WERE_SAVED,
  DELETE_USER_SUCCESS,
  CALC_BALANCES_TO_LAST_TRANS,
  UPDATE_IMPOUND,
} from "../../../lib/constants";
import ImpoundActivitiesService from "../../../services/accounts/impounds/activities";
import { IImpoundActivity } from "../../../types/ImpoundActivity";

export const deleteImpoundActivity =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await ImpoundActivitiesService.remove(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const updateImpoundActivity =
  (
    id: string,
    request: IImpoundActivity
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await ImpoundActivitiesService.update(id, request);

      dispatch(setSuccessMessage(CHANGES_WERE_SAVED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const calculateBalancesToLastTrans =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await ImpoundActivitiesService.calculateBalances(id);

      dispatch(setSuccessMessage(CALC_BALANCES_TO_LAST_TRANS));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const updateImpoundWithHistBalance =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await ImpoundActivitiesService.updateImpoundWithBalance(id);

      dispatch(setSuccessMessage(UPDATE_IMPOUND));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const updateImpoundWithYTDInAndOut =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await ImpoundActivitiesService.updateImpoundWithYTD(id);

      dispatch(setSuccessMessage(UPDATE_IMPOUND));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };
