import { ReactNode, useMemo, useState } from "react";
import { SideBarContainer } from "../../../components/SideBarContainer";
import { TDefaultFormSidebarProps } from "./types";

export const useDefaultFormSidebar = ({
  id,
  setId,
  reloadData,
  title,
  FormElem,
  customSidebarClass,
  parentId,
}: TDefaultFormSidebarProps
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const formWrapper: ReactNode = useMemo(() => {
    const formTitle = id !== null ? `${title} Details` : `Add ${title}`;

    return (
      <SideBarContainer
        title={formTitle}
        setShowSideBar={setIsOpen}
        setCurrentId={setId}
        customClass={customSidebarClass}
      >
        <FormElem
          id={id}
          reloadData={reloadData}
          setIsOpen={setIsOpen}
          setId={setId}
          parentId={parentId}
        />
      </SideBarContainer>
    )
  },
  [FormElem, customSidebarClass, id, parentId, reloadData, setId, title]);

  return { formWrapper, isOpen, setIsOpen };
}