import AccountsService from "../../services/accounts";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../constants";

export const useGetLastUpdate = () => {
  const [lastUpdate, setLastUpdate] = useState<string | null>(null);

  useEffect(() => {
    AccountsService.getLastUpdate().then((res) => {
      const date = dayjs(res).format(DATE_FORMAT);

      setLastUpdate(date);
    });
  }, []);

  return { lastUpdate };
};
