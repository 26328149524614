import { Form, FormInstance, Row, Select } from "antd";
import { LATE_CHARGES_ASSESS_METHOD } from "../../../../../../lib/constants";
import { InputRow } from "./InputRow";

type ConfigFormProps = {
  form: FormInstance;
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
};

export const ConfigForm = ({
  form,
  isFormDisabled,
  isPaymentPosted,
}: ConfigFormProps) => {
  return (
    <Form form={form} disabled={isFormDisabled || isPaymentPosted}>
      <Row className="d-block">
        <p className="mt-0 mb-4 text-md w-500">
          Configuration for This Payment
        </p>
        <Row className="align-center mb-2 justify-space-between">
          <p className="my-0 text-md title">Assess Method</p>
          <Form.Item
            className="mb-0 late-charge-input"
            label=""
            name="assessMethod"
          >
            <Select options={LATE_CHARGES_ASSESS_METHOD} />
          </Form.Item>
        </Row>
        <InputRow form={form} label="Assessesd" name="assessed" />
        <InputRow form={form} label="Paid" name="paid" />
        <InputRow form={form} label="Added to Unpaid" name="addedToUnpaid" />
        <InputRow
          form={form}
          label="Added to Principal"
          name="addedToPrincipal"
        />
      </Row>
    </Form>
  );
};
