import { useCallback } from "react";

export const useDefualtOnRow = (openSideBar: (id: string) => void) => {
  const onRow = useCallback(
    (record: any) => ({
      onClick: () => openSideBar(record.id),
    }),
    [openSideBar]
  );

  return { onRow };
};
