import { FormInstance, Row } from "antd";
import { FloatNumberInput } from "../../../../../../components/FloatNumberInput";

type InputRowProps = {
  form: FormInstance;
  label: string;
  name: string;
};

export const InputRow = ({ label, name, form }: InputRowProps) => {
  return (
    <Row className="align-center mb-2 justify-space-between">
      <p className="my-0 text-md title">{label}</p>
      <FloatNumberInput
        name={name}
        label=""
        form={form}
        className="late-charge-input mb-0"
        allowNegative
      />
    </Row>
  );
};
