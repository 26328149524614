import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { BatchDetails } from "./components/BatchDetails";
import { useCallback, useState } from "react";
import { BatchModal } from "../Processing/BatchModal";
import "./styles.scss";

export const Batch = () => {
  const navigate: NavigateFunction = useNavigate();

  const { batchId } = useParams();

  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const onEditSuccess = useCallback(() => navigate(-1), [navigate]);

  return (
    <>
      <BatchDetails
        id={batchId || ""}
        setIsOpenEditModal={setIsOpenEditModal}
      />

      <BatchModal
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        id={batchId || ""}
        onEditSuccess={onEditSuccess}
      />
    </>
  );
};
