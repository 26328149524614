import { TablePaginationConfig } from "antd";
import { TRequest } from "../types";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { IBatchDisbursements } from "../../../../../../../types/PayeeDisbursements";

const DISBURSEMENT_SORTING = {
  date: {
    ascend: 1,
    descend: 2,
  },
  contractNumber: {
    ascend: 3,
    descend: 4,
  },
  payeeName: {
    ascend: 5,
    descend: 6,
  },
  type: {
    ascend: 7,
    descend: 8,
  },
  amount: {
    ascend: 9,
    descend: 10,
  },
  method: {
    ascend: 11,
    descend: 12,
  },
};

export const useOnSorterChange = (
  request: TRequest,
  setRequest: React.Dispatch<React.SetStateAction<TRequest>>
) => {
  const handleOnSorterChange = (
    sorter: SorterResult<IBatchDisbursements> | SorterResult<IBatchDisbursements>[]
  ): void => {
    const { field, order } = sorter as SorterResult<IBatchDisbursements>;

    const newRequest = { ...request };

    if (!order) {
      newRequest["orderBy"] = 1;
    } else {
      const column = DISBURSEMENT_SORTING[field as keyof typeof DISBURSEMENT_SORTING];

      const reqOrder = column[order as keyof typeof column];

      newRequest["orderBy"] = reqOrder;
    }

    setRequest(newRequest);
  };

  const onSorterChange = (
    _pagination: TablePaginationConfig,
    _filter: Record<string, FilterValue | null>,
    sorter: SorterResult<IBatchDisbursements> | SorterResult<IBatchDisbursements>[]
  ): void => {
    handleOnSorterChange(sorter);
  };

  return { onSorterChange };
};
