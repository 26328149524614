import { useCallback } from "react";
import PaymentHistoryService from "../../../../../../../../services/accounts/paymentHistory";
import dayjs from "dayjs";

export const useCalcInterestForScreen = (
  contractId: string,
  calcInterestBalance: (history: any) => void,
  calcPrincipalPaid: (history: any) => void,
  calcPrincipalBalance: (history: any) => void
) => {
  const calcInterestForScreen = useCallback(
    async (history: any) => {
      const { interesetDateFrom, interestDateTo, breakdown, contract } =
        history;

      const { interestAmount, principalAmount, interestBefore, interestAfter } =
        breakdown;

      const interest = await PaymentHistoryService.calculateInterest({
        contractId,
        interestDateBefore: dayjs(interesetDateFrom).format(),
        interestDayAfter: dayjs(interestDateTo).format(),
        principalBefore: +breakdown.principalBefore,
      });

      history.interestAmount = interest;
      history.breakdown.interestAmount = interestBefore + interest;

      calcInterestBalance(history);

      if (+interestAfter < 0 && contract.paymentType !== 2) {
        history.breakdown.interestAmount = +interestAmount + +interestAfter;

        calcInterestBalance(history);
      }

      calcPrincipalPaid(history);

      if (+principalAmount < 0) {
        history.breakdown.interestAmount = +interestAmount + +principalAmount;

        calcInterestBalance(history);
        calcPrincipalPaid(history);
      }

      calcPrincipalBalance(history);
    },
    [calcInterestBalance, calcPrincipalBalance, calcPrincipalPaid, contractId]
  );

  return { calcInterestForScreen };
};
