import { useCallback, useState } from "react";
import { useDefaultDeleteModal } from "../../../../../../../lib/hooks/defaultDeleteModal";
import {
  deleteDocument,
  createDocument,
  editDocument,
} from "../../../../../../../acions/accounts/documents";
import { useAppDispatch } from "../../../../../../../store";
import { FormInstance } from "antd";
import { useGetFormData } from "./useGetFormData";

type TButtonHandlersProps = {
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  form?: FormInstance;
  parentId?: string;
};

export const useButtonHandlers = ({
  reloadData,
  setIsOpen,
  setId,
  form,
  parentId,
}: TButtonHandlersProps) => {
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const { getFormData } = useGetFormData(parentId);

  const { openDeleteModal } = useDefaultDeleteModal(
    deleteDocument,
    reloadData,
    setIsOpen
  );

  const dispatch = useAppDispatch();

  const onDeleteBtnClick = useCallback(
    (id: string): void => {
      openDeleteModal(id, "Delete Document");
    },
    [openDeleteModal]
  );

  const onCancelBtnClick = useCallback((): void => {
    setIsOpen(false);
    setId(null);
  }, [setId, setIsOpen]);

  const create = useCallback(
    async (formData: FormData, isNew: boolean) => {
      try {
        await Promise.resolve(dispatch(createDocument(formData)));

        reloadData();
        setIsFormDisabled(false);
        setId(null);
        setIsOpen(isNew);
        form?.resetFields();
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, form, reloadData, setId, setIsOpen]
  );

  const edit = useCallback(
    async (formData: FormData, id: string) => {
      try {
        await Promise.resolve(dispatch(editDocument(formData, id)));

        reloadData();
        setIsFormDisabled(false);
        setId(null);
        setIsOpen(false);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, reloadData, setId, setIsOpen]
  );

  const onSaveBtnClick = useCallback(
    async (isNew: boolean, id: string | null) => {
      try {
        const request = await form?.validateFields();

        const formData = getFormData(request, id);

        setIsFormDisabled(true);

        id !== null ? edit(formData, id) : create(formData, isNew);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [create, edit, form, getFormData]
  );

  return {
    isFormDisabled,
    onDeleteBtnClick,
    onCancelBtnClick,
    onSaveBtnClick,
  };
};
