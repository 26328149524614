import { Row } from "antd";
import { useMemo } from "react";
import { MAX_COMPANY_NAME } from "../../../../../../lib/constants";
import { useGetFullNameFormRules } from "../../../../../../lib/hooks/getFullNameFormRules";
import { NameFormItem } from "./NameFormItem";

type FullNameFormItemsProps = {
  isIndividual: boolean;
};

export const FullNameFormItems = ({ isIndividual }: FullNameFormItemsProps) => {
  const { formOptions } = useGetFullNameFormRules();

  const path: string = useMemo(() => {
    return isIndividual ? "customerIndividualData" : "customerCompanyData";
  }, [isIndividual]);

  const companyNameRules = useMemo(
    () => [
      {
        max: 50,
        message: MAX_COMPANY_NAME,
        validateTrigger: "onSubmit",
      },
      {
        required: true,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  return (
    <>
      {isIndividual ? (
        formOptions.map((item: any, i: number) => (
          <Row className="mb-4" key={i}>
            <NameFormItem
              path={path}
              name={item.name}
              label={item.label}
              rules={item.rules}
            />
          </Row>
        ))
      ) : (
        <Row className="mb-4">
          <NameFormItem
            path={path}
            name="companyName"
            label="Company Name"
            rules={companyNameRules}
          />
        </Row>
      )}
    </>
  );
};
