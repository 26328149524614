import authAxios, { authHeader } from "../data";
import { API_URL } from "../../configs/apiUrl";
import qs from "qs";

const fetchData = async (customerId: string) => {
  const response = await authAxios.get(
    API_URL + "CustomersUsers?ParentId=" + customerId,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const removeItem = async (id: number) => {
  const response = await authAxios.delete(`${API_URL}CustomersUsers/${id}`, {
    headers: authHeader(),
  });

  return response.data;
};

const update = async (id: number, request: any) => {
  const response = await authAxios.put(
    `${API_URL}CustomersUsers/${id}`,
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchExistingUsers = async (search: string, customerId: string) => {
  const response = await authAxios.get(API_URL + "CustomersUsers/Users", {
    params: { search, customerId },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const create = async (request: any) => {
  const response = await authAxios.post(API_URL + "CustomersUsers", request, {
    headers: authHeader(),
  });

  return response.data;
};

const CustomerPortalAccessesService = {
  fetchData,
  removeItem,
  update,
  fetchExistingUsers,
  create,
};

export default CustomerPortalAccessesService;
