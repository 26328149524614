import { Row, Col, Button } from "antd";
import { useMemo } from "react";
import { Link, NavigateFunction } from "react-router-dom";
import { Header } from "../../../components/SecurityCheck/Header";
import {
  EMAIL_WAS_ACTIVATED,
  NO_USER_WITH_SUCH_EMAIL,
} from "../../../lib/constants";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../configs/routesConfig";

type RegisterTemplateMessageProps = {
  status: 0 | 1 | 2 | 3 | null;
  email: string;
  setStatus: (value: 0 | 1 | 2 | 3 | null) => void;
};

export const RegisterTemplateMessage = ({
  status,
  email,
  setStatus,
}: RegisterTemplateMessageProps) => {
  const navigate: NavigateFunction = useNavigate();

  const title = useMemo(() => {
    switch (status) {
      case 0: {
        return NO_USER_WITH_SUCH_EMAIL;
      }
      case 3: {
        return EMAIL_WAS_ACTIVATED;
      }
      default:
        return "";
    }
  }, [status]);

  const onBottomButtonClicked = () => {
    status === 3
      ? navigate(ROUTES.LOGIN, {
          state: { email },
          replace: true,
        })
      : setStatus(null);
  };

  const subTitle = useMemo(() => {
    return (
      <div className="sub-title__container mb-10 register-subtitle ">
        {status === 0 && (
          <>
            <p className="justify-center my-0">
              Email <span className="email-text">&ensp;{email}&ensp;</span> was
              not found.
            </p>
            <p className="justify-center my-0">
              Try another email you might provided for your escrow account
              creation or
              <Link to="mailto:info@sunwestescrow.com">
                &ensp;contact out support
              </Link>
              .
            </p>
          </>
        )}
        {status === 3 && (
          <p className="justify-center my-0">
            You are welcome to log in with the email
            <span className="email-text">&ensp;{email}&ensp;</span>or your
            username.
          </p>
        )}
      </div>
    );
  }, [email, status]);

  return (
    <Row className="mt-16 register-cases">
      <Col span={16} offset={4}>
        <Header header={title} onBackClicked={() => {}} removeBackButton />
        {subTitle}
        <Row className="justify-center">
          <Button
            type="primary"
            shape="round"
            size="large"
            htmlType="submit"
            onClick={onBottomButtonClicked}
          >
            {status === 3 ? "Log in" : "Use another email"}
          </Button>
        </Row>
      </Col>
    </Row>
  );
};
