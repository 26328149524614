import { useCallback } from "react";

export const useCalcImpoundBalance = () => {
  const calcImpoundBalance = useCallback((history: any) => {
    const { breakdown } = history;

    const { fromImpoundAmount, fromImpoundToBalance, fromImpoundBefore } =
      breakdown;

    const fromImpoundAfter =
      +fromImpoundBefore + +fromImpoundAmount - +fromImpoundToBalance;

    history.breakdown.fromImpoundAfter = fromImpoundAfter;
  }, []);

  return { calcImpoundBalance };
};
