import { Card, Col, Row, Tooltip } from "antd";
import { QuestionCircleOutlined, RightOutlined } from "@ant-design/icons";
import { useMemo } from "react";
import { Loader } from "./Loader";

type DefaultWidgetCardProps = {
  label: string;
  value: number;
  showTooltip?: boolean;
  isLoading: boolean;
  redirectTo: () => void;
};

export const DefaultWidgetCard = ({
  label,
  value,
  showTooltip,
  isLoading,
  redirectTo,
}: DefaultWidgetCardProps) => {
  const helpText: string =
    "The Accounts which don’t have statuses or with wrong Last Payment/Next Payment dates.";

  const tooltipStyle: any = useMemo(
    () => ({
      maxWidth: "532px",
    }),
    []
  );

  return (
    <Col span={8} className="pr-4">
      <Card className="dashboard-card payments">
        {!isLoading ? (
          <Row className="d-block filters">
            <Row className="justify-space-between mb-2">
              <Row className="align-center logo-cursor" onClick={redirectTo}>
                <p className="my-0 text-md">{label}</p>
                <RightOutlined className="ml-2 search-icon" />
              </Row>
              {showTooltip && (
                <Tooltip
                  placement="top"
                  title={helpText}
                  overlayStyle={tooltipStyle}
                  overlayClassName="text-sm"
                >
                  <QuestionCircleOutlined className="search-icon logo-cursor" />
                </Tooltip>
              )}
            </Row>
            <Row>
              <h3 className="my-0 h3">{value.toLocaleString()}</h3>
            </Row>
          </Row>
        ) : (
          <Loader />
        )}
      </Card>
    </Col>
  );
};
