import { CustomModal } from "../../../../../../../../../../lib/CustomModal";
import { DistributionForm } from "./DistributionForm";
import { useButtonHandlers } from "./hooks/useButtonHandlers";

type TDistributionModal = {
  isOpen: boolean;
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parentId: string | null;
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  };
  impoundCurrentBalance: number;
};

export const DistributionModal = ({
  isOpen,
  reloadData,
  setIsOpen,
  parentId,
  payeesNumber,
  impoundCurrentBalance,
}: TDistributionModal) => {
  const { onCancelBtnClick } = useButtonHandlers({
    reloadData,
    setIsOpen,
    parentId,
  });

  return (
    <CustomModal
      isModalOpen={isOpen}
      setIsModalOpen={onCancelBtnClick}
      titleText="Pay From Impound"
      width={318}
      headerClass="text w-500"
      modalClass="create-modal"
      destroyOnClose
    >
      <DistributionForm
        reloadData={reloadData}
        setIsOpen={setIsOpen}
        parentId={parentId}
        primaryBuyerNumber={payeesNumber.primaryBuyerNumber}
        primarySellerNumber={payeesNumber.primarySellerNumber}
        impoundCurrentBalance={impoundCurrentBalance}
      />
    </CustomModal>
  );
};
