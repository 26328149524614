import { Row, Col, Button } from "antd";
import { useState, useCallback } from "react";
import logo from "../../../images/logo.svg";
import { Link, Outlet } from "react-router-dom";
import { HelpModal } from "../../HelpModal";
import { useLogoRedirectPath } from "../../../lib/hooks/useLogoRedirectPath";
import "./styles.scss";

export const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { logoRedirectPath } = useLogoRedirectPath();

  const openHelp = useCallback(() => setIsModalOpen(true), []);

  return (
    <>
      <Row className="guest-header justify-space-between" align="middle">
        <Link to={logoRedirectPath}>
          <img
            src={logo}
            alt="logo"
            className="logo-cursor"
            style={{ height: "32px" }}
          />
        </Link>
        <Row justify="end">
          <Col>
            <Button
              type="link"
              className="useful-link z-10 px-0"
              onClick={openHelp}
            >
              Help
            </Button>

            <HelpModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          </Col>
        </Row>
      </Row>

      <Outlet />
    </>
  );
};
