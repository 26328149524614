import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../store";
import {
  togglePayment,
  savePaymentAsDraft,
  updatePayment,
} from "../../../acions/processing/payments";
import { FormInstance } from "antd";
import { useValidatePayment } from "./useValidatePayment";
import { NavigateFunction, useNavigate } from "react-router-dom";

export const useSave = (
  instance: any,
  form: FormInstance,
  manualUpdated: {
    principalAmount: boolean;
    interestAmount: boolean;
  }
) => {
  const navigate: NavigateFunction = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { validate } = useValidatePayment();

  const transformRequest = useCallback(
    (request: any): void => {
      ["date", "interestDateTo"].forEach((item: string) => {
        request[item] = request[item]
          ? request[item].startOf("D").format()
          : null;
      });

      const { breakdown, disbursements, contractId, batchId } = instance;

      request.disbursements = disbursements;
      request.contractId = contractId;
      request.batchId = batchId;
      request.breakdown = {
        ...request.breakdown,
        interestAfter: +breakdown.interestAfter,
        lateCharge: breakdown.lateCharge
      }
    },
    [instance]
  );

  const update = useCallback(
    async (id: string) => {
      try {
        const request = await form.validateFields();

        if (validate(instance, "save")) {
          setIsLoading(true);

          transformRequest(request);

          const formattedRequest = {
            ...request,
            manualUpdated,
          };

          await Promise.resolve(
            dispatch(updatePayment(id, formattedRequest, true))
          );

          setIsLoading(false);

          return true;
        }

        return false;
      } catch {
        setIsLoading(false);

        return false;
      }
    },
    [dispatch, form, instance, manualUpdated, transformRequest, validate]
  );

  const post = useCallback(
    async (id: string) => {
      try {
        const request = await form.validateFields();

        if (validate(instance, "post")) {
          setIsLoading(true);

          transformRequest(request);

          await Promise.resolve(
            dispatch(updatePayment(id, { ...request, manualUpdated }, false))
          );

          await Promise.resolve(dispatch(togglePayment(id, true)));

          navigate(-1);
        }
      } catch {
        setIsLoading(false);
      }
    },
    [
      dispatch,
      form,
      instance,
      manualUpdated,
      navigate,
      transformRequest,
      validate,
    ]
  );

  const save = useCallback(
    async (action: string, onSaved: () => void) => {
      try {
        const request = await form.validateFields();

        const showMessage: boolean = action === "save";

        if (validate(instance, action)) {
          setIsLoading(true);

          transformRequest(request);

          const formattedRequest = {
            ...request,
            manualUpdated,
          };

          const res: any = await Promise.resolve(
            dispatch(savePaymentAsDraft(formattedRequest, showMessage))
          );

          if (!showMessage) {
            await Promise.resolve(dispatch(togglePayment(res.id, true)));
          }

          setIsLoading(false);

          onSaved && onSaved();

          return 1;
        }

        return false;
      } catch {
        setIsLoading(false);

        return false;
      }
    },
    [form, validate, instance, transformRequest, manualUpdated, dispatch]
  );

  return { save, isLoading, setIsLoading, post, update };
};
