import { Form, FormInstance, Input } from "antd";
import { useMemo } from "react";
import MaskedInput from "react-text-mask";
import {
  WRONG_EIN_FORMAT,
  WRONG_SSN_FORMAT,
} from "../../../../../../lib/constants";
import {
  SSN_INPUT_MASK_RULE,
  SSN_INPUT_RULE,
  EIN_INPUT_MASK_RULE,
  EIN_INPUT_RULE,
} from "../../../../../../lib/validationRules";

type TaxIdInputProps = {
  isIndividual: boolean;
  form: FormInstance;
};

export const TaxIdInput = ({ isIndividual, form }: TaxIdInputProps) => {
  const rules = useMemo(() => {
    return [
      {
        pattern: isIndividual ? SSN_INPUT_RULE : EIN_INPUT_RULE,
        validateTrigger: "onSubmit",
        message: isIndividual ? WRONG_SSN_FORMAT : WRONG_EIN_FORMAT,
      },
    ];
  }, [isIndividual]);

  return (
    <Form.Item
      name={["taxId"]}
      label="Tax ID"
      rules={rules}
      className="mb-0 w-100"
    >
      <MaskedInput
        mask={isIndividual ? SSN_INPUT_MASK_RULE : EIN_INPUT_MASK_RULE}
        guide={false}
        showMask
        render={(ref, props) => {
          const value = form.getFieldValue(["taxId"]);

          return (
            <Input
              addonBefore={isIndividual ? "SSN" : "EIN"}
              value={value}
              ref={(input: any) => ref(input && input.input)}
              {...props}
            />
          );
        }}
      />
    </Form.Item>
  );
};
