import { Form, FormInstance, Input, Row, Select } from "antd";
import { Rule } from "antd/es/form";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DAY_IS_NOT_VALID,
  PAYMENT_SCHEDULE_OPTIONS,
} from "../../../../../../../../../lib/constants";
import { DAY_OF_MONTH_RULE } from "../../../../../../../../../lib/validationRules";

type PaymmentScheduleProps = {
  form: FormInstance;
  name: string;
  label: string;
  className?: string;
};

export const PaymmentSchedule = ({
  form,
  name,
  label,
  className,
}: PaymmentScheduleProps) => {
  const getDayOfMonthRule = useCallback((isSmall: boolean): any => {
    return {
      pattern: DAY_OF_MONTH_RULE,
      message: isSmall ? "" : DAY_IS_NOT_VALID,
      validateTrigger: "onSubmit",
    };
  }, []);

  const semimonthlyFormItems = useMemo(() => {
    const itemProps = [
      {
        name: `${name}Day`,
        label: "Day 1",
        rules: [getDayOfMonthRule(true)],
      },
      {
        name: `${name}Day2`,
        label: "Day 2",
        rules: [
          getDayOfMonthRule(true),
          ({ getFieldValue }: FormInstance) => ({
            validateTrigger: "onSubmit",
            validator(_: Rule, value: string) {
              if (!value || getFieldValue(`${name}Day`) <= value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(""));
            },
          }),
        ],
      },
    ];

    return itemProps.map((item: any, i: number) => (
      <Form.Item
        key={i}
        className={`day-input small mb-4 ${i === 0 ? "mr-1" : ""}`}
        label={item.label}
        name={item.name}
        rules={item.rules}
        normalize={(value: string) => value || null}
      >
        <Input />
      </Form.Item>
    ));
  }, [getDayOfMonthRule, name]);

  const [selectedPaymentSchedule, setSelectedPaymentSchedule] =
    useState<number>(4);

  useEffect(() => {
    const value: number | undefined = form.getFieldValue(name);

    if (value || value === 0) {
      setSelectedPaymentSchedule(value);
    }
  }, [form, name]);

  const onPaymentScheduleChange = useCallback((e: number) => {
    setSelectedPaymentSchedule(e);
  }, []);

  return (
    <Row
      className={`align-center schedule-select-container ${className || ""}`}
    >
      <Form.Item className="select mb-4" label={label} name={name}>
        <Select
          options={PAYMENT_SCHEDULE_OPTIONS}
          onChange={onPaymentScheduleChange}
        />
      </Form.Item>

      <span className="mx-2">on</span>

      {selectedPaymentSchedule === 24 ? (
        <Row>{semimonthlyFormItems}</Row>
      ) : (
        <Form.Item
          className="day-input mb-4"
          label="Day"
          name={`${name}Day`}
          rules={[getDayOfMonthRule(false)]}
        >
          <Input />
        </Form.Item>
      )}
    </Row>
  );
};
