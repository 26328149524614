import { useState, useEffect } from "react";
import CustomersService from "../../../services/customers";

export const useGetTypes = () => {
  const [isTypesLoading, setIsTypesLoading] = useState<boolean>(false);

  const [types, setTypes] = useState([]);

  useEffect(() => {
    setIsTypesLoading(true);

    CustomersService.fetchTypes()
      .then((res) => {
        setIsTypesLoading(false);

        if (res) {
          const data = res.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));

          setTypes(data);
        }
      })
      .catch(() => setIsTypesLoading(false));
  }, []);

  return { types, isTypesLoading };
};
