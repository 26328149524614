import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useStatusTag } from "../../Accounts/hooks/statusTag";
import { useGetRole } from "../../../lib/hooks/getRole";
import { SearchInput } from "../../../components/SearchInput";
import { useSearchByNumber } from "../../../lib/hooks/searchByNumber";
import { searchByNumber } from "../../../acions/accounts/contracts";
import { useHeaderInfo } from "./View/Admin/Details/hooks/useHeaderInfo";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ROUTES from "../../../configs/routesConfig";

type SearchHeaderProps = {
  data: any;
  onBackButtonClicked: () => void;
  reduceBackStep?: () => void;
};

export const SearchHeader = ({
  data,
  onBackButtonClicked,
  reduceBackStep,
}: SearchHeaderProps) => {
  const navigate: NavigateFunction = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  const { headerInfo } = useHeaderInfo(data);

  const [isSearch, setIsSearch] = useState<boolean>(false);

  const toggleIsSearch = useCallback(() => setIsSearch(!isSearch), [isSearch]);

  const onSearchSuccess = useCallback(
    (value: any): void => {
      if (value && value.id) {
        navigate(`${ROUTES.ACCOUNTS}/${value.id}`);
        reduceBackStep && reduceBackStep();
        toggleIsSearch();
      }
    },
    [navigate, toggleIsSearch, reduceBackStep]
  );

  const title: string = useMemo(
    () => `Account: ${headerInfo?.accountNumber}`,
    [headerInfo]
  );

  const { search, isLoading } = useSearchByNumber(
    onSearchSuccess,
    searchByNumber
  );

  const { getStatus } = useStatusTag();

  const status: string | ReactNode = useMemo(
    () => getStatus(headerInfo?.status),
    [getStatus, headerInfo]
  );

  return (
    <div className="d-flex align-center">
      <Button
        shape="circle"
        icon={<ArrowLeftOutlined />}
        size="large"
        className="back-btn mr-6"
        onClick={onBackButtonClicked}
      />
      {headerInfo && (
        <>
          {isAdmin ? (
            <SearchInput
              title={title}
              isLoading={isLoading}
              isSearch={isSearch}
              search={search}
              setIsSearch={setIsSearch}
              toggleIsSearch={toggleIsSearch}
              initialValue={headerInfo.accountNumber}
            />
          ) : (
            <h3 className="h h3 mr-8">
              <span className="desktop-label">Account:</span>{" "}
              {headerInfo.accountNumber}
            </h3>
          )}
          {!isSearch && (
            <>
              {status}
              {headerInfo.xRef && (
                <div className="ml-2 text-md w-400">
                  X-Ref: {headerInfo.xRef}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
