import { Button, Row, Table } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import CustomerBankAccountsService from "../../../../../../services/customers/bankAccounts";
import { Loader } from "../../../../../../components/Loader";
import { useGetBankAccountsTableColumns } from "./hooks/getBankAccountsTableColumns";
import { useOpenSideBar } from "./hooks/formHandlers/openSideBar";

type BankAccountsProps = {
  selectedItemId: string;
};

export const BankAccounts = ({ selectedItemId }: BankAccountsProps) => {
  const [isPrimary, setIsPrimary] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<any[]>([]);

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const isPrimaryDisabled = useMemo(() => {
    if (selectedItem && data.length === 1) {
      return true;
    }
    return data.length === 0;
  }, [data, selectedItem]);

  const showAddAndNewButton = useMemo(() => data.length < 1, [data]);

  const fetchData = useCallback(() => {
    setIsLoading(true);

    CustomerBankAccountsService.fetchBankAccounts(selectedItemId)
      .then((res: any) => {
        setData(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [selectedItemId]);

  useEffect(() => fetchData(), [fetchData]);

  const { sideBar, showSideBar, setShowSideBar } = useOpenSideBar(
    selectedItem,
    showAddAndNewButton,
    isPrimaryDisabled,
    selectedItemId,
    isPrimary,
    fetchData,
    setSelectedItem,
    setIsPrimary
  );

  const onOpenSideBar = (value: any | null): void => {
    setSelectedItem(value);
    setShowSideBar(true);
  };

  const [columns] = useGetBankAccountsTableColumns(onOpenSideBar, fetchData);

  const onAddNewClicked = (): void => onOpenSideBar(null);

  return (
    <>
      {!isLoading ? (
        <Row>
          <Row className="card-header justify-space-between align-center w-100">
            <h5 className="h h5">Bank Accounts</h5>
            <Button
              shape="round"
              onClick={onAddNewClicked}
              disabled={data.length === 2}
              className="btn"
            >
              Add New
            </Button>
          </Row>
          {data.length > 0 && (
            <Row className="d-block width-100 pt-6 table-small">
              <Table
                dataSource={data}
                columns={columns}
                loading={isLoading}
                pagination={false}
                className="overflow-y-auto"
                rowKey="bankAccountNumber"
              />
            </Row>
          )}
        </Row>
      ) : (
        <Loader fontSize="32px" />
      )}
      {showSideBar && createPortal(sideBar, document.body)}
    </>
  );
};
