import { Col } from "antd";
import { PRIMARY_6_COLOR } from "../../../../lib/constants";
import { CheckOutlined } from "@ant-design/icons";
import image from "../../../../images/register.svg";
import { useMemo } from "react";

export const Description = () => {
  const titles: string[] = useMemo(() => {
    return [
      "Get up-to-date account information",
      "View transactions history",
      "Make secure payments",
    ];
  }, []);

  return (
    <Col className="singup-left__container">
      <h5 className="h h5 mb-10">Creating trust between strangers</h5>
      {titles.map((item: string, i: number) => (
        <div className="d-flex mb-4" key={i}>
          <CheckOutlined style={{ color: PRIMARY_6_COLOR }} />
          <p className="pl-4 my-0">{item}</p>
        </div>
      ))}
      <div className="mt-3">
        <img src={image} alt="register" />
      </div>
    </Col>
  );
};
