import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../store";
import { validateBatch } from "../../../acions/processing/batches";

export const useValidateBatch = (onSuccess: () => void) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validate = useCallback(
    async (id: string) => {
      if (!isLoading) {
        try {
          setIsLoading(true);

          await Promise.resolve(dispatch(validateBatch(id)));

          onSuccess();

          setIsLoading(false);
        } catch {
          setIsLoading(false);
        }
      }
    },
    [dispatch, onSuccess, isLoading]
  );

  return { validate, isLoading };
};
