import { ColumnsType } from "antd/es/table";
import { useCallback, useMemo } from "react";
import {
  DISBURSEMENTS_METHOD_TYPES,
  PAYEE_TYPES,
} from "../../../../../../../../../lib/constants";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useGetPayeeIcon } from "./useGetPayeeIcon";
import { useGetStatusTag } from "./useGetStatusTag";
import { Link } from "react-router-dom";
import ROUTES from "../../../../../../../../../configs/routesConfig";
import { useGetMethodColumnDescription } from "./useGetMethodColumnDescription";
import { IPayeeDisbursements } from "../../../../../../../../../types/PayeeDisbursements";
import { useButtonHandlers } from "./useButtonHandlers";
import { TRecord } from "../types";
import { getFormattedCurrency } from "../../../../../../../../../lib/currencyFormatter";
import { getFormattedPercentage } from "../../../../../../../../../lib/percentageFormatter";
import iconPayeeRelation from "../../../../../../../../../images/icons/PayeeRelation.svg";
import { Row } from "antd";

type TTableColumnsProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reloadData: () => void;
  setRecord: React.Dispatch<React.SetStateAction<TRecord>>;
  openDisb: (id: string) => void;
};

export const useGetTableColumns = ({
  setIsOpen,
  reloadData,
  setRecord,
  openDisb,
}: TTableColumnsProps) => {
  const { getIcon } = useGetPayeeIcon();

  const { getTag } = useGetStatusTag();

  const { getLine } = useGetMethodColumnDescription();

  const { onDeleteBtnClick } = useButtonHandlers({
    reloadData,
    setIsOpen,
    setRecord,
  });

  const onEdit = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      openDisb(e.currentTarget.id);
    },
    [openDisb]
  );

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      onDeleteBtnClick(e.currentTarget.id);
    },
    [onDeleteBtnClick]
  );

  const columns: ColumnsType<IPayeeDisbursements> = useMemo(
    () => [
      {
        title: "Payee",
        width: "20%",
        dataIndex: "payeeName",
        key: "payeeName",
        ellipsis: true,
        render: (value: string, record: IPayeeDisbursements) => {
          const { type, payeeId, isAlternate, alternativePayeeName } = record;

          const payeeType = PAYEE_TYPES.find((item) => item.value === type);

          const icon = getIcon(payeeType);

          return (
            <div className="d-flex flex-column elipsis">
              <Link
                className="d-flex align-center link-text-color"
                to={`${ROUTES.CUSTOMERS}/${payeeId}`}
                target="_blank"
                title={value}
                onClick={(e) => e.stopPropagation()}
              >
                {icon ? icon : ""} <span className="ml-3 elipsis">{value}</span>
              </Link>
              {isAlternate && (
                <Row className="d-flex flex-nowrap">
                  <img
                    src={iconPayeeRelation}
                    alt="Alternative Payee"
                    className="ml-2"
                  />
                  <span className="ml-3 description-line elipsis">
                    {alternativePayeeName}
                  </span>
                </Row>
              )}
            </div>
          );
        },
      },
      {
        title: "Type",
        width: "13%",
        dataIndex: "type",
        key: "type",
        ellipsis: true,
        render: (value: number) => {
          const type = PAYEE_TYPES.find((item) => item.value === value)?.label;

          return (
            <div className="elipsis" title={type}>
              {type}
            </div>
          );
        },
      },
      {
        title: "Amount",
        width: "11%",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        render: (value: number, record: IPayeeDisbursements) => {
          const { percentage } = record;

          const finalValue =
            percentage || value === null
              ? getFormattedPercentage(percentage)
              : getFormattedCurrency(value);

          return <div>{finalValue} </div>;
        },
      },
      {
        title: "Method",
        width: "16%",
        dataIndex: "method",
        key: "method",
        ellipsis: true,
        render: (value: number, record: IPayeeDisbursements) => {
          const { bankAccountNumber, accountNumber } = record;

          const method = DISBURSEMENTS_METHOD_TYPES.find(
            (item) => item.value === value
          )?.label;

          const line = getLine({
            methodType: value,
            bankAccountNumber,
            accountNumber,
          });

          return (
            <div className="d-flex flex-column elipsis">
              {method} <span className="description-line">{line}</span>
            </div>
          );
        },
      },
      {
        title: "Description",
        width: "23%",
        dataIndex: "description",
        key: "description",
        ellipsis: true,
        render: (value: string) => (
          <div className="elipsis" title={value}>
            {value}
          </div>
        ),
      },
      {
        title: "Status",
        width: "8%",
        dataIndex: "status",
        key: "status",
        render: (value: number) => getTag(value),
      },
      {
        width: "9%",
        dataIndex: "actions",
        key: "actions",
        render: (_: any, record: IPayeeDisbursements) => {
          const { id } = record;

          return (
            <div className="d-flex justify-end ant-table-actions">
              <EditOutlined
                className="ant-table-btn"
                id={id}
                onClick={onEdit}
              />
              <DeleteOutlined
                className="ant-table-btn"
                id={id}
                onClick={onDelete}
              />
            </div>
          );
        },
      },
    ],
    [getIcon, getLine, getTag, onDelete, onEdit]
  );

  return { columns };
};
