import { API_URL } from "../configs/apiUrl";
import authAxios, { authHeader } from "./data";

const fetchYears = async () => {
  const response = await authAxios.get(API_URL + "info/years", {
    headers: authHeader(),
  });

  return response.data;
};

const InfoService = {
  fetchYears,
};

export default InfoService;
