import { Button, Row } from "antd";
import { MultiSelect } from "../../components/MultiSelect";
import { SearchWithClear } from "../../components/SearchWithClear";
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useCallback, useMemo } from "react";
import ROUTES from "../../configs/routesConfig";

type FiltersProps = {
  isLoading: boolean;
  types: any[];
  isTypesLoading: boolean;
  request: any;
};

export const Filters = ({
  isLoading,
  types,
  request,
  isTypesLoading,
}: FiltersProps) => {
  const navigate: NavigateFunction = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const onCreateClicked = useCallback(() => {
    navigate(`${ROUTES.CUSTOMERS}/create`);
  }, [navigate]);

  const setType = useCallback(
    (value: (string | number)[]) => {
      const params = { ...request, pageNumber: 1 };

      if (value.includes("All")) {
        delete params.typeId;
      } else {
        params.typeId = value;
      }

      setSearchParams(params);
    },
    [request, setSearchParams]
  );

  const setSearch = useCallback(
    (value: string) => {
      const params = { ...request, pageNumber: 1 };

      if (!value) {
        delete params.search;
      } else {
        params.search = value;
      }

      setSearchParams(params);
    },
    [request, setSearchParams]
  );

  const defualtType: number[] | undefined = useMemo(() => {
    const { typeId } = request;

    return typeId ? [typeId] : undefined;
  }, [request]);

  return (
    <Row className="d-block width-100 mx-8 ">
      <Row className="my-6">
        <h3 className="h h3">Customers</h3>
      </Row>
      <Row className="mb-4 justify-space-between">
        <div className="d-flex filters">
          <div className="mr-4 date-selector">
            <SearchWithClear
              isLoading={isLoading}
              search={request.search || ""}
              setSearch={setSearch}
            />
          </div>
          <div className="mr-4 type-selector">
            <MultiSelect
              options={types}
              isLoading={isTypesLoading || isLoading}
              onChange={setType}
              label="Type"
              defaultValue={defualtType}
              maxTagCount={1}
            />
          </div>
        </div>
        <Button shape="round" type="primary" onClick={onCreateClicked}>
          Create Customer
        </Button>
      </Row>
    </Row>
  );
};
