import { useAppDispatch } from "../../../store";
import { register } from "../../../acions/signUp";
import { useNavigate, NavigateFunction } from "react-router-dom";
import ROUTES from "../../../configs/routesConfig";

export const useRegisterUser = (
  email: string,
  setIsFormDisabled: (value: boolean) => void
) => {
  const dispatch = useAppDispatch();

  const navigate: NavigateFunction = useNavigate();

  const onRegister = (
    values: any,
    id: string,
    verificationCodeId: string | null
  ): void => {
    setIsFormDisabled(true);

    const request: any = {
      ...values,
      id,
      email,
      phone: `1${values.phone.replace(/[\s-()]/g, "")}`,
      verificationCodeId,
    };

    Promise.resolve(dispatch(register(request)))
      .then(() => {
        setIsFormDisabled(false);
        navigate(ROUTES.LOGIN, {
          state: { email },
          replace: true,
        });
      })
      .catch(() => setIsFormDisabled(false));
  };

  return { onRegister };
};
