import { ReactNode, useMemo } from "react";
import { SearchHeader } from "../components/Tabs/SearchHeader";

export const useCustomerHeader = (
  initialData: any,
  isCreate: boolean,
  hasChanges: boolean,
  backLocation: any,
  reduceBackStep: () => void
) => {
  const headerTitle: string = useMemo(() => {
    if (initialData) {
      const { typeId, customerIndividualData, customerCompanyData, number } =
        initialData;

      return `${number || ""}: ${
        typeId === 1
          ? (customerIndividualData.firstName || "") +
            " " +
            (customerIndividualData.middleName || "") +
            " " +
            (customerIndividualData.lastName || "")
          : customerCompanyData.companyName || ""
      }`;
    }
    if (!initialData && isCreate) {
      return "Create Customer";
    }
    return "";
  }, [initialData, isCreate]);

  const header: ReactNode | string = useMemo(() => {
    return !isCreate ? (
      <SearchHeader
        title={headerTitle}
        hasChanges={hasChanges}
        backLocation={backLocation}
        reduceBackStep={reduceBackStep}
      />
    ) : (
      <h3 className="h h3">{headerTitle}</h3>
    );
  }, [isCreate, headerTitle, hasChanges, backLocation, reduceBackStep]);

  return { header };
};
