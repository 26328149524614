import { Dispatch, SetStateAction, useMemo } from "react";
import {
  FEE_ALLOCATION_OPTIONS,
  FEE_ASSESSED_TYPE,
} from "../../../../lib/constants";
import { formatNumberValuesOnRender } from "../../hooks/helpers/formatNumberValues";
import { ColumnsType } from "antd/es/table";
import { EditOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { IFeeTypeExtended } from "../../../../types/Fees";
import { useButtonHandlers } from "./buttonHandlers";

type TFeeTableColumnsProps = {
  accountId: string | undefined;
  reloadData: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  openSideBar: (id: string) => void;
};

export const useGetFeesTableColumns = ({
  accountId,
  reloadData,
  setIsOpen,
  openSideBar,
}: TFeeTableColumnsProps) => {
  const { onDeleteBtnClick } = useButtonHandlers({
    parentId: accountId,
    reloadData,
    setIsOpen,
  });

  const columns: ColumnsType<IFeeTypeExtended> = useMemo(() => {
    const defaultColumns: ColumnsType<IFeeTypeExtended> = [
      {
        title: accountId ? "Fee Type" : "Fee Type Name",
        dataIndex: "feeType",
        width: `${accountId ? "20" : "22"}%`,
        key: "feeType",
      },
      {
        title: "Allocation",
        dataIndex: "feeAllocation",
        width: "10%",
        key: "feeAllocation",
        render: (value: number) => {
          const finalValue = FEE_ALLOCATION_OPTIONS.find(
            (option) => option.value === value
          )?.label;

          return (
            <div className="cell-elipsis" title={finalValue}>
              {finalValue}
            </div>
          );
        },
      },
      {
        title: "Buyer Fee",
        dataIndex: "buyerFee",
        width: "9%",
        key: "buyerFee",
        align: "right",
        render: (value: number) => formatNumberValuesOnRender(value),
      },
      {
        title: "Buyer Tax",
        dataIndex: "buyerTax",
        width: "9%",
        key: "buyerTax",
        align: "right",
        render: (value: number) => formatNumberValuesOnRender(value),
      },
      {
        title: "Seller Fee",
        dataIndex: "sellerFee",
        width: "9%",
        key: "sellerFee",
        align: "right",
        render: (value: number) => formatNumberValuesOnRender(value),
      },
      {
        title: "Seller Tax",
        dataIndex: "sellerTax",
        width: "9%",
        key: "sellerTax",
        align: "right",
        render: (value: number) => formatNumberValuesOnRender(value),
      },
      {
        title: "Assessed",
        dataIndex: "feeAssessed",
        width: `${accountId ? "20" : "22"}%`,
        key: "feeAssessed",
        render: (value: number) => {
          const finalValue = FEE_ASSESSED_TYPE.find(
            (type) => type.value === value
          )?.label;
          const textFormater = value === 0 ? "text-color-red" : "";

          return (
            <div className="cell-elipsis" title={finalValue}>
              <span className={textFormater}>{finalValue}</span>
            </div>
          );
        },
      },
    ];

    if (accountId) {
      defaultColumns.splice(1, 1);

      defaultColumns.push({
        title: "Apply Global Changes",
        dataIndex: "globalAdjustmentAllowed",
        key: "globalAdjustmentAllowed",
        width: "14%",
        render: (value: boolean) => (value ? "Yes" : "No"),
      });
    }

    defaultColumns.push({
      title: "",
      width: "10%",
      key: "actions",
      render: (_: any, record: IFeeTypeExtended) => {
        const { id, references } = record;

        const iconProps: any = {
          className: "ant-table-btn mr-1 logo-cursor",
          onClick: () => onDeleteBtnClick(id, references),
        };

        return (
          <div
            className="w-100 h-100 d-flex justify-end ant-table-actions"
            onClick={(e) => e.stopPropagation()}
          >
            <EditOutlined
              className="ant-table-btn logo-cursor"
              onClick={() => openSideBar(id)}
            />
            {accountId ? (
              <CloseOutlined {...iconProps} />
            ) : (
              <DeleteOutlined {...iconProps} />
            )}
          </div>
        );
      },
    });

    return defaultColumns;
  }, [onDeleteBtnClick, openSideBar, accountId]);

  return { columns };
};
