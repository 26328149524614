import { FormInstance } from "antd";
import { useCallback } from "react";

export const useSave = (
  form: FormInstance,
  reset: (value: any) => Promise<0 | 1>,
  closeModal: () => void
) => {
  const onSaveClicked = useCallback(async () => {
    try {
      const res = await form.validateFields();
      await reset(res);
      closeModal();
    } catch {}
  }, [form, reset, closeModal]);

  return { onSaveClicked };
};
