import { Row, Table } from "antd";
import { useFetchData } from "../../../../../../lib/hooks/useDefaultFetchData";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import "./styles.scss";
import { useCallback, useMemo } from "react";
import { IUserCustomer } from "../../../../../../types/User";
import UserCustomersService from "../../../../../../services/userManagement/userCustomers";

type TPortalAccessProps = {
  parentId: string | null;
  parentStatus: number;
};

const SUBTITLE_TEXT = [
  {
    value: 1,
    text: (
      <div className="text-md portal-access-subtitle title p">
        The user{" "}
        <span className="portal-access-subtitle-highlight">has access</span> to
        the web portal and can see accounts of the linked customers:
      </div>
    ),
  },
  {
    value: 2,
    text: (
      <div className="text-md portal-access-subtitle title p">
        The user is blocked and{" "}
        <span className="portal-access-subtitle-highlight">
          does not have access
        </span>{" "}
        to the web portal
      </div>
    ),
  },
  {
    value: 3,
    text: (
      <div className="text-md portal-access-subtitle title p">
        The user{" "}
        <span className="portal-access-subtitle-highlight">has access</span> to
        the web portal and after registration will see accounts of the linked
        customers:
      </div>
    ),
  },
];

export const PortalAccess = ({
  parentId,
  parentStatus,
}: TPortalAccessProps) => {
  const { data, isLoading, fetchData } = useFetchData<
    string | null,
    IUserCustomer
  >(UserCustomersService, parentId);

  const reloadData = useCallback(() => fetchData(), [fetchData]);

  const { columns } = useGetTableColumns(reloadData);

  const subtitle = useMemo(
    () => SUBTITLE_TEXT.find((item) => item.value === parentStatus)?.text,
    [parentStatus]
  );

  return (
    <Row className="w-100 pt-10">
      <Row>
        <p className="text-md w-500 pb-2 ma-0">Portal Access</p>
        {subtitle}
      </Row>
      <Row className="w-100 pt-2">
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          pagination={false}
          className="w-100 portal-access-table"
          rowKey="id"
        />
      </Row>
    </Row>
  );
};
