import {
  Checkbox,
  Form,
  FormInstance,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Typography,
} from "antd";
import { FeeTypeInput } from "./FormElems/FeeTypeinput";
import {
  FEE_ALLOCATION_OPTIONS,
  FEE_ASSESSED_TYPE,
  FEE_PAYER_OPTIONS,
} from "../../../lib/constants";
import { Amount } from "./FormElems/Amount";
import { useEffect, useState } from "react";
import { useDefineAmountState } from "./hooks/useDefineAmountState";
import { useGetInitialValues } from "./hooks/useGetInitialValues";
import { useButtonHandlers } from "./hooks/buttonHandlers";
import { Loader } from "../../../components/Loader";
import { FormButtons } from "../components/FormButtons";
import { TFormProps } from "../../../lib/hooks/defaultFormSidebar/types";
import { getFormattedCurrency } from "../../../lib/currencyFormatter";
import { ApplyGlobalCheckBox } from "./FormElems/ApplyGlobalCheckBox";
import { FeeTypeSelect } from "./FormElems/FeeTypeSelect";

const { Text } = Typography;

export const FeeForm = ({
  id,
  reloadData,
  setIsOpen,
  setId,
  parentId,
}: TFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const allocation = Form.useWatch("feeAllocation", {
    form,
    preserve: true,
  });

  const [buyerTotal, setBuyerTotal] = useState<number>(0);

  const [sellerTotal, setSellerTotal] = useState<number>(0);

  const [total, setTotal] = useState<number>(0);

  const { onSaveClick, onCancelClick, onDeleteBtnClick, isFormDisabled } =
    useButtonHandlers({ parentId, reloadData, setIsOpen, form, setId });

  const { isSellerDisabled, isBuyerDisabled, calctTaxAndFee } =
    useDefineAmountState(form, allocation);

  const { isLoading, references } = useGetInitialValues({
    id,
    form,
    parentId,
  });

  useEffect(() => {
    setTotal(buyerTotal + sellerTotal);
  }, [buyerTotal, sellerTotal]);

  const onDeleteBtnClicked = (id: string): void => {
    onDeleteBtnClick(id, references);
  };

  const onFeeAllocationClicked = (e: RadioChangeEvent): void => {
    calctTaxAndFee(e.target.value);
  };

  return (
    <Row className="fee-form h-100 d-flex position-relative flex-column">
      {isLoading ? (
        <Loader fontSize="64" />
      ) : (
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          className="pa-6"
          disabled={isFormDisabled}
        >
          {parentId ? <FeeTypeSelect form={form} id={id} /> : <FeeTypeInput />}
          <Form.Item
            label="Assessed"
            className="w-100 mb-4"
            name="feeAssessed"
            initialValue={FEE_ASSESSED_TYPE[1].value}
          >
            <Select options={FEE_ASSESSED_TYPE} />
          </Form.Item>
          <Form.Item
            label="Allocation"
            className="w-100 mb-4"
            name="feeAllocation"
            initialValue={FEE_ALLOCATION_OPTIONS[0].value}
          >
            <Radio.Group
              options={FEE_ALLOCATION_OPTIONS}
              optionType="button"
              className="w-100 d-flex"
              onChange={onFeeAllocationClicked}
            />
          </Form.Item>
          <div className="d-flex justify-space-between amount-wrapper mb-4">
            <Amount
              form={form}
              payer={FEE_PAYER_OPTIONS.BUYER}
              isDisabled={isBuyerDisabled || isFormDisabled}
              total={buyerTotal}
              setTotal={setBuyerTotal}
            />
            <Amount
              form={form}
              payer={FEE_PAYER_OPTIONS.SELLER}
              isDisabled={isSellerDisabled || isFormDisabled}
              total={sellerTotal}
              setTotal={setSellerTotal}
            />
          </div>
          <Text className="d-block w-100 fee-form-total">
            Total: {getFormattedCurrency(total)}
          </Text>
          {id !== null && !parentId && (
            <Form.Item
              className="w-100 pt-5"
              name="adjustmentAllowed"
              valuePropName="checked"
            >
              <Checkbox>
                Apply changes of allocation, amounts and when it should be
                assessed to the fee type in accounts where global fee type
                changes are allowed.
              </Checkbox>
            </Form.Item>
          )}
          {parentId && <ApplyGlobalCheckBox />}
        </Form>
      )}
      <FormButtons
        currentId={id}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
        onDeleteClick={onDeleteBtnClicked}
        isFormDisabled={isFormDisabled}
      />
    </Row>
  );
};
