import { Form, FormInstance, Row, Select } from "antd";
import { useGetInitialValues } from "./hooks/useGetInitialValues";
import { TFormProps } from "../../../../../../../../../../lib/hooks/defaultFormSidebar/types";
import { Loader } from "../../../../../../../../../../components/Loader";
import { FormButtons } from "../../../../../../../../../../components/DefaultFormButtons";
import { useButtonHandlers } from "../../hooks/useButtonHandlers";
import { CustomerInputSearch } from "../../../../../../../../../Accounts/components/Filters/CustomerInputSearch";
import { useGetInpurSearchIds } from "../../../../../../../../../Accounts/components/Filters/hooks/getInputSearchIds";
import { FloatNumberInput } from "../../../../../../../../../../components/FloatNumberInput";

export const ImpoundForm = ({
  id,
  reloadData,
  setIsOpen,
  setId,
  parentId,
  props,
}: TFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const { onCancelBtnClick, onDeleteBtnClick, onSaveBtnClick, isDisabled } =
    useButtonHandlers({ reloadData, setIsOpen, setId, form, parentId });

  const { onCustomerFound, hasPayeeId, customerIds } = useGetInpurSearchIds();

  const { isLoading, types, isTypesLoading } = useGetInitialValues(
    id,
    form,
    props
  );

  return (
    <>
      {isLoading ? (
        <Row className="w-100 h-100 d-flex justify-center align-center">
          <Loader fontSize="64px" />
        </Row>
      ) : (
        <Row className="impound-form">
          <Form
            form={form}
            disabled={isDisabled}
            className="pa-6 w-100"
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
          >
            <FloatNumberInput
              name="impoundAccount"
              label="Impound Account"
              form={form}
              disabled={isDisabled}
              rules={[{ required: true, validateTrigger: "onSubmit" }]}
              decimalLimit={0}
              prefix=""
              allowDecimal={false}
            />
            <Form.Item
              label="Type"
              name="impoundTypeId"
              rules={[{ required: true, validateTrigger: "onSubmit" }]}
            >
              <Select
                placeholder="Select Type"
                options={types}
                disabled={isTypesLoading || isDisabled}
              />
            </Form.Item>
            <FloatNumberInput
              name="amount"
              label="Payment Amount to Impound"
              form={form}
              disabled={isDisabled}
              rules={[{ required: true, validateTrigger: "onSubmit" }]}
            />
            <CustomerInputSearch
              form={form}
              label="Payee (Impound Account)"
              name={["payeeId"]}
              isFormDisabled={isDisabled}
              onCustomerFound={onCustomerFound}
              hasId={hasPayeeId}
              placeholder="Input Payee#, “Enter” to confirm"
            />
          </Form>
          <FormButtons
            onCancelClick={onCancelBtnClick}
            onDeleteClick={onDeleteBtnClick}
            onSaveClick={onSaveBtnClick}
            isFormDisabled={isDisabled}
            currentId={id}
            customerIds={customerIds}
          />
        </Row>
      )}
    </>
  );
};
