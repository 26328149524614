import { ColumnsType } from "antd/es/table";
import { CustomerItem } from "../../../types/CustomersList";
import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import companyIcon from "../../../images/building.svg";
import { MenuProps, Dropdown } from "antd";
import { useMemo, useCallback, MouseEvent } from "react";
import { deleteCustomer } from "../../../acions/customers";
import { formatTaxId } from "./getFormatTaxId";
import { useDefaultDeleteModal } from "../../../lib/hooks/defaultDeleteModal";
import { Link, useSearchParams } from "react-router-dom";
import ROUTES from "../../../configs/routesConfig";
import { useGetColumnSortValue } from "../../../lib/hooks/useGetColumnSortValue";
import { CopyButton } from "../../../components/CopyButton";
import { COPIED_CUSTOMER_NUMBER } from "../../../lib/constants";

export const useGetCustomerTableColumns = (
  reloadData: () => void,
  navigateToCustomer: (id: string) => void
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const { openDeleteModal } = useDefaultDeleteModal(deleteCustomer, reloadData);

  const onEdit = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      navigateToCustomer(e.currentTarget.id);
    },
    [navigateToCustomer]
  );

  const onDelete = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      openDeleteModal(e.currentTarget.id, "Delete a customer");
    },
    [openDeleteModal]
  );

  const { getColumnSortValue } = useGetColumnSortValue();

  const onSortChange = useCallback(
    (sorter: any, request: any): void => {
      const newRequest = { ...request };
      if (!sorter.order) {
        delete newRequest["order"];
      }

      if (sorter.field === "number") {
        if (sorter.order === "ascend") {
          newRequest["order"] = 0;
        }

        if (sorter.order === "descend") {
          newRequest["order"] = 1;
        }
      }

      if (sorter.field === "fullName") {
        if (sorter.order === "ascend") {
          newRequest["order"] = 2;
        }

        if (sorter.order === "descend") {
          newRequest["order"] = 3;
        }
      }

      setSearchParams(newRequest);
    },
    [setSearchParams]
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Customer Number",
        dataIndex: "number",
        sorter: true,
        width: "14%",
        sortOrder: (() => getColumnSortValue("0", "1"))(),
        render: (value: string) => (
          <>
            {value}
            <CopyButton value={value} message={COPIED_CUSTOMER_NUMBER} />
          </>
        ),
      },
      {
        title: "Name",
        dataIndex: "fullName",
        sorter: true,
        ellipsis: true,
        width: "27%",
        sortOrder: (() => getColumnSortValue("2", "3"))(),
        render: (value: string, record: CustomerItem) => {
          const { typeId, id } = record;

          return (
            <Link
              className="cell-elipsis d-flex align-center"
              to={`${ROUTES.CUSTOMERS}/${id}`}
              onClick={(e) => e.stopPropagation()}
            >
              {typeId === 1 ? (
                <UserOutlined className="mr-1 link-btn" />
              ) : (
                <img src={companyIcon} alt="company" className="mr-1" />
              )}
              <span className="link-btn cell-elipsis">{value}</span>
            </Link>
          );
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        ellipsis: true,
        width: "21%",
        render: (value: string) => value || "-",
      },
      {
        title: "Address",
        dataIndex: "fullPrimaryAddress",
        width: "22%",
        ellipsis: true,
        render: (value: string) => value.replace(/,\s*$/, "") || "-",
      },
      {
        title: "Tax ID",
        dataIndex: "taxId",
        ellipsis: true,
        render: (value: string, record: any) =>
          value ? formatTaxId(value, record.typeId) : "-",
        width: "9%",
      },
      {
        title: "Action",
        render: (_: unknown, record: CustomerItem) => {
          const { id } = record;

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <div id={id} onClick={onEdit}>
                  Edit
                </div>
              ),
            },
            {
              key: "2",
              label: (
                <div id={id} onClick={onDelete}>
                  Delete
                </div>
              ),
            },
          ];

          return (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <EllipsisOutlined onClick={(e) => e.stopPropagation()} />
            </Dropdown>
          );
        },
        width: "7%",
      },
    ],
    [getColumnSortValue, onEdit, onDelete]
  );

  return { columns, onSortChange };
};
