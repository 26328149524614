import { Col, Row, Radio, RadioChangeEvent, FormInstance } from "antd";
import { useState } from "react";
import { getFormatted } from "../../../../../../lib/PhoneFormatter";
import "../styles.scss";

type UsersListPorps = {
  form: FormInstance;
  searchData: any[];
  setSelectedUser: (value: any) => void;
};

export const UsersList = ({
  form,
  searchData,
  setSelectedUser,
}: UsersListPorps) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const resetValidation = (): void => {
    form.setFields([
      {
        name: ["newUser", "email"],
        errors: [],
      },
    ]);
  };

  const selectItem = (e: RadioChangeEvent) => {
    const index: number = e.target.value;
    setSelectedIndex(index);

    resetValidation();

    const user = searchData.find((_item: any, i: number) => i === index);
    setSelectedUser(user);
  };

  return (
    <Radio.Group className="d-block" onChange={selectItem}>
      {searchData.map((item: any, i: number) => (
        <Row
          className={`align-center py-2 user-search-list-item ${
            selectedIndex === i && "active"
          }`}
          key={i}
        >
          <Col span={4}>
            <Row className="align-center justify-center">
              {item.isAdded ? (
                <span className="text-sm success-marked">Added</span>
              ) : (
                <Radio value={i} />
              )}
            </Row>
          </Col>
          <Col span={20}>
            <Row className="px-2 py-2 d-block">
              <p className="my-0 pb-1 text-md w-500">{item.name}</p>
              <p className="my-0 pb-1 text-sm">{item.email}</p>
              <p className="my-0 pb-1 text-sm">
                {item.phoneNumber
                  ? getFormatted(item.phoneNumber).slice(2)
                  : "-"}
              </p>
            </Row>
          </Col>
        </Row>
      ))}
    </Radio.Group>
  );
};
