import { useMemo } from "react";
import { MAX_FEE_TYPE, FEE_TYPE_REQUIRED } from "../../../../lib/constants";
import { Form, Input } from "antd";
import { Rule } from "antd/es/form";

export const FeeTypeInput = () => {
  const rules: Rule[] = useMemo(
    () => [
      {
        max: 20,
        message: MAX_FEE_TYPE,
        validateTrigger: "onSubmit",
      },
      {
        required: true,
        message: FEE_TYPE_REQUIRED,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  return (
    <Form.Item
      name="feeType"
      label="Fee Type Name"
      rules={rules}
      className="mb-4"
    >
      <Input />
    </Form.Item>
  );
};
