import { useCallback, useEffect, useState } from "react";
import BatchesService from "../../../../../../../../../../../services/processing/batches";
import dayjs from "dayjs";
import { IBatchGet } from "../../../../../../../../../../../types/Batches";
import { FormInstance } from "antd";
import { DATE_FORMAT } from "../../../../../../../../../../../lib/constants";

export const useFetchBatches = (form: FormInstance) => {
  const [options, setOptions] = useState<
    { value: string; label: string; props: any }[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchBatches = useCallback(() => {
    const request = {
      pageSize: 10000,
      pageNumber: 1,
      type: 7,
      from: dayjs().startOf("D").format(DATE_FORMAT),
      to: dayjs().startOf("D").format(DATE_FORMAT),
    };

    setIsLoading(true);

    BatchesService.fetchAll(request)
      .then((res: { date: string | Date; batches: IBatchGet[] }[]) => {
        if (res.length !== 0) {
          const batchOptions = res[0].batches.map((batch) => {
            return {
              value: batch.id,
              label: `${batch.batchNumber}: Misc Transactions`,
              props: batch,
            };
          });

          setOptions(batchOptions);

          form.setFieldValue("batchId", batchOptions[0].value);
        }
      })
      .catch(() => setIsLoading(false))
      .finally(() => setIsLoading(false));
  }, [form]);

  useEffect(() => fetchBatches(), [fetchBatches]);

  return { options, isLoading };
};
