import { DatePicker, Row } from "antd";
import { DATE_FORMAT } from "../../../../../../lib/constants";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useUpdatePaymentDate } from "./hooks/useUpdatePaymentDate";
import { IBatchGet } from "../../../../../../types/Batches";

type PaymentDateInputProps = {
  value: string;
  data: IBatchGet;
  onCloseEdit: () => void;
  updateDataPaymentDate: (value: string) => void;
};

export const PaymentDateInput = ({
  data,
  value,
  onCloseEdit,
  updateDataPaymentDate,
}: PaymentDateInputProps) => {
  const [date, setDate] = useState<Dayjs | null>(dayjs(value));

  const datePickerStatus: undefined | "error" = useMemo(
    () => (date ? undefined : "error"),
    [date]
  );

  const { isLoading, update } = useUpdatePaymentDate(
    onCloseEdit,
    datePickerStatus,
    updateDataPaymentDate,
    data
  );

  const onSave = () => data.id && update(date, data.id);

  const onChange = (e: Dayjs | null) => setDate(e);
  return (
    <Row className="align-center">
      <DatePicker
        size="small"
        className="ml-1"
        format={DATE_FORMAT}
        value={date}
        disabled={isLoading}
        status={datePickerStatus}
        onChange={onChange}
      />
      <CheckOutlined
        className="ml-2 control logo-cursor"
        disabled={isLoading}
        onClick={onSave}
      />
      <CloseOutlined
        className="ml-2 control logo-cursor"
        onClick={onCloseEdit}
        disabled={isLoading}
      />
    </Row>
  );
};
