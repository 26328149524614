import { Button, Form, FormInstance, Row, Tabs, TabsProps } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SELECT_OR_CREATE_USER } from "../../../../../../lib/constants";
import { useSearch } from "../../../../../../lib/hooks/search";
import { CreateNewForm } from "./CreateNewForm";
import { useCreatePortalAccess } from "./hooks/createPortalAcess";
import { SelectFormExistingForm } from "./SelectFromExistingForm";

type SidebarProps = {
  customerId: string | null | undefined;
  isNextPrimary: boolean;
  closeSideBar: () => void;
  reloadData: () => void;
};

export const Sidebar = ({
  customerId,
  isNextPrimary,
  closeSideBar,
  reloadData,
}: SidebarProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [search, debouncedSearch, onSearchChange, onClearSearch] =
    useSearch(900);

  const [activeTab, setActiveTab] = useState<string>("1");

  const [searchData, setSearchData] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showMessage, setShowMessage] = useState(false);

  const [selectedUser, setSelectedUser] = useState<any>(null);

  const onChangeTab = useCallback(
    (key: string): void => {
      const value = searchData.length !== 0 ? key === "1" : false;

      const errors = form.getFieldError(["newUser", "email"]);

      if (errors.length > 0 && errors[0] === SELECT_OR_CREATE_USER) {
        form.setFields([
          {
            name: ["newUser", "email"],
            errors: [],
          },
        ]);
      }

      setShowMessage(value);
      setActiveTab(key);
    },
    [searchData, form]
  );

  useEffect(() => {
    setSelectedUser({});
  }, [debouncedSearch]);

  const resetData = () => {
    setSelectedUser({});
    onClearSearch();
    form.resetFields();
    setShowMessage(false);
  };

  const {
    saveNew,
    selectExisting,
    isLoading: isFormDisabled,
  } = useCreatePortalAccess(
    form,
    customerId,
    setIsLoading,
    setShowMessage,
    closeSideBar,
    resetData,
    reloadData
  );

  const onAddClicked = (shouldResetForm: boolean): void => {
    activeTab === "1"
      ? saveNew(shouldResetForm, isNextPrimary)
      : selectExisting(shouldResetForm, selectedUser.id, isNextPrimary);
  };

  const onDataFetched = useCallback(
    (value: any[], isEmpty: boolean) => {
      setSearchData(value);

      let newShowMessage = false;
      if (isEmpty) {
        newShowMessage =
          activeTab === "1" ? value.length > 0 : value.length === 0;
      }
      setShowMessage(newShowMessage);
    },
    [activeTab]
  );

  const tabs: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: "Create new",
        children: (
          <CreateNewForm
            form={form}
            customerId={customerId}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onChangeTab={onChangeTab}
            activeTab={activeTab}
            showMessage={showMessage}
            onDataFetched={onDataFetched}
            search={search}
            debouncedSearch={debouncedSearch}
            onSearchChange={onSearchChange}
            onClearSearch={onClearSearch}
            isFormDisabled={isFormDisabled}
          />
        ),
      },
      {
        key: "2",
        label: "Select from existing",
        children: (
          <SelectFormExistingForm
            form={form}
            customerId={customerId}
            activeTab={activeTab}
            searchData={searchData}
            showMessage={showMessage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onChangeTab={onChangeTab}
            onDataFetched={onDataFetched}
            search={search}
            debouncedSearch={debouncedSearch}
            onSearchChange={onSearchChange}
            onClearSearch={onClearSearch}
            setSelectedUser={setSelectedUser}
          />
        ),
      },
    ],
    [
      activeTab,
      debouncedSearch,
      form,
      customerId,
      isLoading,
      onChangeTab,
      onClearSearch,
      onDataFetched,
      onSearchChange,
      search,
      searchData,
      showMessage,
      isFormDisabled,
    ]
  );

  return (
    <>
      <Row className="d-block sidebar-body user-content bank-account-form">
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          autoComplete="off"
          disabled={isFormDisabled}
          className="h-100 overflow-hidden portal-access-form"
        >
          <Tabs activeKey={activeTab} items={tabs} onChange={onChangeTab} />
        </Form>
      </Row>
      <Row className="divider px-6 py-6">
        <Button
          shape="round"
          type="primary"
          className="mr-4"
          onClick={() => onAddClicked(false)}
          disabled={isFormDisabled}
        >
          Add
        </Button>
        <Button
          shape="round"
          className="mr-4"
          disabled={isFormDisabled}
          onClick={() => onAddClicked(true)}
        >
          Add & New
        </Button>

        <Button
          shape="round"
          className="mr-4"
          disabled={isFormDisabled}
          onClick={closeSideBar}
        >
          Cancel
        </Button>
      </Row>
    </>
  );
};
