import ROUTES from "./routesConfig";

import { Footer as GuestFooter } from "../components/Guest/Footer";
import { Header as GuestHeader } from "../components/Guest/Header";
import { PrivateRoutes } from "../lib/PrivateRoutes";
import { Header as LoggedInHeader } from "../components/LoggedIn/Header";
import { Footer as LoggedInFooter } from "../components/LoggedIn/Footer";
import { AdminScope } from "../components/AdminScope";
import { RedirectHome } from "../lib/RedirectHome";

import { NotFound } from "../pages/NotFound";
import { Login } from "../pages/Login";
import { SignUp } from "../pages/SignUp";
import { ForgotPassword } from "../pages/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword";
import { ForgotAccount } from "../pages/ForgotAccount";
import { Accounts } from "../pages/Accounts";
import { Profile } from "../pages/Profile";
import { ChangePassword } from "../pages/Profile/ChangePassword";
import { ChangePhone } from "../pages/Profile/ChangePhone";
import { ChangeEmail } from "../pages/Profile/ChangeEmail";
import { UserManagement } from "../pages/UserManagement";
import { InternalServerError } from "../pages/InternalServerError";
import { Dashboard } from "../pages/Dashboard";
import { Customers } from "../pages/Customers";
import { Administration } from "../pages/Administration";
import { Processing } from "../pages/Processing";
import { Customer } from "../pages/Customer";
import { Batch } from "../pages/Batch";
import { Payment } from "../pages/Payment";
import { Account } from "../pages/Account";

export const routes = [
  {
    element: <RedirectHome />,
    children: [
      {
        path: ROUTES.NOT_FOUND,
        element: <NotFound />,
      },
      {
        path: ROUTES.HOME,
        element: <RedirectHome />,
      },
      {
        path: ROUTES.INTERNAL_SERVER_ERROR,
        element: <InternalServerError />,
      },
      {
        element: <GuestHeader />,
        children: [
          {
            element: <GuestFooter />,
            children: [
              {
                path: ROUTES.LOGIN,
                element: <Login />,
              },
              {
                path: ROUTES.SIGN_UP,
                element: <SignUp />,
              },
              {
                path: ROUTES.FORGOT_PASSWORD,
                element: <ForgotPassword />,
              },
              {
                path: ROUTES.RESET_PASSWORD,
                element: <ResetPassword />,
              },
              {
                path: ROUTES.FORGOT_ACCOUNT,
                element: <ForgotAccount />,
              },
            ],
          },
        ],
      },
      {
        element: <PrivateRoutes />,
        children: [
          {
            element: <LoggedInHeader />,
            children: [
              {
                element: <LoggedInFooter />,
                children: [
                  {
                    path: ROUTES.ACCOUNTS,
                    element: <Accounts />,
                  },
                  {
                    path: ROUTES.OTHER_ACCOUNTS,
                    element: <Accounts />,
                  },
                  {
                    path: ROUTES.ACCOUNT,
                    element: <Account />,
                  },
                  {
                    path: ROUTES.PROFILE,
                    element: <Profile />,
                  },
                ],
              },
            ],
          },
          {
            element: <AdminScope />,
            children: [
              {
                element: <LoggedInHeader />,
                children: [
                  {
                    element: <LoggedInFooter />,
                    children: [
                      {
                        path: ROUTES.DASHBOARD,
                        element: <Dashboard />,
                      },
                      {
                        path: ROUTES.USER_MANAGEMENT,
                        element: <UserManagement />,
                      },
                      {
                        path: ROUTES.CUSTOMERS,
                        element: <Customers />,
                      },
                      {
                        path: ROUTES.CUSTOMER,
                        element: <Customer />,
                      },
                      {
                        path: ROUTES.ADMINISTRATION,
                        element: <Administration />,
                      },
                      {
                        path: ROUTES.PROCESSING,
                        element: <Processing />,
                      },
                    ],
                  },
                  {
                    path: ROUTES.BATCH,
                    element: <Batch />,
                  },
                ],
              },
              {
                path: ROUTES.PAYMENT,
                element: <Payment />,
              },
            ],
          },

          {
            element: <GuestHeader />,
            children: [
              {
                element: <GuestFooter />,
                children: [
                  {
                    path: ROUTES.PROFILE_CHANGE_PASSWORD,
                    element: <ChangePassword />,
                  },
                  {
                    path: ROUTES.PROFILE_CHANGE_PHONE,
                    element: <ChangePhone />,
                  },
                  {
                    path: ROUTES.PROFILE_CHANGE_EMAIL,
                    element: <ChangeEmail />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
