import { Button, Form } from "antd";
import { Rule } from "antd/es/form";
import { useMemo, useState } from "react";
import {
  FILE_SIZE_EXCEED,
  MAX_FILE_SIZE,
  ACCEPT_FILE_TYPES,
  ACCEPT_FILE_ERROR_MSG,
} from "../../../../../../../lib/constants";
import Upload, { UploadFile } from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";

export const UploadFileFormItem = () => {
  const [hasError, setHasError] = useState<boolean>(false);

  const resetHasError = (): void => {
    setHasError(false);
  };

  const fileRule: Rule[] = useMemo(() => {
    return [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: UploadFile[]) {
          if (!value || value.length === 0) {
            return Promise.resolve();
          } else {
            const { type, size, name } = value[0];

            if (
              !type ||
              !ACCEPT_FILE_TYPES.includes(type) ||
              name.split(".").pop() === "jpeg"
            ) {
              setHasError(true);
              return Promise.reject(new Error(ACCEPT_FILE_ERROR_MSG));
            }

            if (!size || size > MAX_FILE_SIZE) {
              setHasError(true);
              return Promise.reject(new Error(FILE_SIZE_EXCEED));
            }
          }

          return Promise.resolve();
        },
      }),
    ];
  }, []);

  return (
    <Form.Item
      name="file"
      className="mb-4 w-100"
      valuePropName="fileList"
      getValueFromEvent={(e) => e.fileList}
      rules={fileRule}
    >
      <Upload
        listType="picture"
        beforeUpload={() => false}
        maxCount={1}
        previewFile={async () => ""}
        onChange={resetHasError}
      >
        <Button icon={<UploadOutlined />} shape="round" danger={hasError}>
          Upload file
        </Button>
      </Upload>
    </Form.Item>
  );
};
