import { Col, Row, DatePicker, FormInstance, Form } from "antd";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { DATE_FORMAT } from "../../../../lib/constants";
import { useUpdate } from "./hooks/useUpdate";
import { getFormattedCurrency } from "../../../../lib/currencyFormatter";

type TotalItemsProps = {
  data: any;
  accountId: string;
  reloadData: () => void;
};

export const TotalItems = ({
  data,
  accountId,
  reloadData,
}: TotalItemsProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const { onChange, isDisabled } = useUpdate(data, form, accountId, reloadData);

  const getReadOnlyItem = (
    label: string,
    value: string | number | null,
    isFirst?: boolean,
    isDate?: boolean
  ): ReactNode => {
    let formattedValue: string;

    if (isDate) {
      formattedValue = value ? dayjs(value).format(DATE_FORMAT) : "-";
    } else {
      formattedValue = value ? getFormattedCurrency(+value) : "-";
    }

    return (
      <Row className={`d-block ${isFirst && "mb-6"}`}>
        <Row className="mb-2">
          <span className="text-md title">{label}</span>
        </Row>
        <span className="text-md w-500">{formattedValue}</span>
      </Row>
    );
  };

  return (
    <Row className="mt-6 justify-space-between">
      <Col>
        {getReadOnlyItem("Standard Late Charge", data.standatdLateCharge, true)}
        {getReadOnlyItem(
          "Current Late Charge to be assessed",
          data.currentLateCharge
        )}
      </Col>
      <Col>
        {getReadOnlyItem(
          "Total Late Charges not yet Assessed",
          data.totalNotAssessed,
          true
        )}
        {getReadOnlyItem("Unpaid Assessed Late Charges", data.unpaidAssessed)}
      </Col>
      <Col>
        {getReadOnlyItem(
          "Late Notice sent to Buyer",
          data.lateNoticeSentToPayer,
          true,
          true
        )}
        {getReadOnlyItem(
          "Late Notice sent to Seller",
          data.lateNoticeSentToPayee,
          false,
          true
        )}
      </Col>
      <Col>
        <Form
          form={form}
          layout="vertical"
          className="totals-form"
          onFieldsChange={onChange}
          disabled={isDisabled}
        >
          <Form.Item
            label="Late Charge Last Assessed"
            name="lateChargeLastAssessed"
            className="mb-4"
          >
            <DatePicker format={DATE_FORMAT} placeholder="" />
          </Form.Item>
          <Form.Item
            label="Assessed Late Charge Calculated to"
            name="lateChargeAssessedCalculatedTo"
          >
            <DatePicker format={DATE_FORMAT} placeholder="" />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
