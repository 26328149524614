import { Col, Form, FormInstance, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useMemo } from "react";
import { CustomerInputSearch } from "../../../../../../../Accounts/components/Filters/CustomerInputSearch";
import { LeftFormCol } from "./LeftFormCol";
import { RightFormCol } from "./RightFormCol/Index";
import { PaymentFormItems } from "./RightFormCol/PaymentFormItems";

type GeneralInfoProps = {
  form: FormInstance;
  isDisabled: boolean;
  hasPrimaryBuyerId: boolean;
  hasPrimarySellerId: boolean;
  onCustomerFound: (number: number | null, name: string[]) => void;
};

export const GeneralInfo = ({
  form,
  isDisabled,
  hasPrimaryBuyerId,
  hasPrimarySellerId,
  onCustomerFound,
}: GeneralInfoProps) => {
  const contractNames = useMemo(
    () => [
      {
        name: ["primaryBuyerNumber"],
        label: "Primary Buyer",
        hasId: hasPrimaryBuyerId,
      },
      {
        name: ["primarySellerNumber"],
        label: "Primary Seller",
        hasId: hasPrimarySellerId,
      },
    ],
    [hasPrimaryBuyerId, hasPrimarySellerId]
  );

  const notesRule = useMemo(() => {
    return [
      {
        max: 500,
      },
    ];
  }, []);

  return (
    <>
      <h5 className="h h5 card-header">General</h5>
      <Row className="mt-6">
        {contractNames.map((item: any, i: number) => (
          <Col
            className={`edit-account-contract-input ${
              i === 0 ? "pr-7" : "pl-7"
            }`}
            key={i}
          >
            <CustomerInputSearch
              form={form}
              label={item.label}
              name={item.name}
              isFormDisabled={isDisabled}
              onCustomerFound={onCustomerFound}
              hasId={item.hasId}
            />
          </Col>
        ))}
      </Row>
      <Row className="mt-6">
        <div className="w-100 divider" />
      </Row>
      <Row className="my-10">
        <LeftFormCol form={form} />
        <RightFormCol form={form} />
      </Row>
      <Row className="mb-10">
        <div className="w-100 divider" />
      </Row>
      <PaymentFormItems form={form} />
      <Row className="mb-10 mt-10">
        <div className="w-100 divider" />
      </Row>
      <Row>
        <Form.Item
          label="Notes"
          name={"notes"}
          className="notes"
          rules={notesRule}
        >
          <TextArea />
        </Form.Item>
      </Row>
    </>
  );
};
