import { useCallback, useEffect, useMemo, useState } from "react";
import { IImpoundsTypeExtended } from "../../../../types/Impounds";

type TInitialValuesProps = {
  id: string | null;
  data?: IImpoundsTypeExtended[];
};

export const useGetInitialValues = ({ id, data }: TInitialValuesProps) => {
  const [entry, setEntry] = useState<IImpoundsTypeExtended | null>(null);

  const getImpoundTypeForEdit = useCallback(() => {
    if (data) {
      const impoundType = data.find((item) => item.id === id);
      setEntry(impoundType ? impoundType : null);
    }
  }, [data, id]);

  useEffect(() => {
    getImpoundTypeForEdit();
  }, [getImpoundTypeForEdit, id]);

  return useMemo(() => {
    return { name: entry !== null ? entry.name : "" };
  }, [entry]);
};
