import { useCallback, useEffect, useState } from "react";
import { ILateChargeExtended } from "../../../../types/LateCharges";

export const useGetData = (
  request: any,
  accountId: string | undefined,
  service: any
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<ILateChargeExtended[]>([]);

  const fetchData = useCallback(() => {
    if (
      (accountId && request.contractId && accountId === request.contractId) ||
      !accountId
    ) {
      setIsLoading(true);

      service
        .fetchAll(request)
        .then((res: any) => {
          setData(res);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [request, service, accountId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading, fetchData };
};
