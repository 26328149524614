import { useMemo } from "react";
import ROUTES from "../../../configs/routesConfig";
import { NavigateFunction, useNavigate } from "react-router-dom";

export const useWidgetProps = (draft: any, other: any, isLoading: boolean) => {
  const navigate: NavigateFunction = useNavigate();

  const draftProps = useMemo(() => {
    return {
      value: draft,
      label: "Draft Accounts",
      isLoading,
      redirectTo: () => navigate(`${ROUTES.ACCOUNTS}?statusFilter=8`),
    };
  }, [draft, navigate, isLoading]);

  const otherProps = useMemo(() => {
    return {
      value: other,
      label: "Other Accounts",
      isLoading,
      redirectTo: () => navigate(ROUTES.OTHER_ACCOUNTS),
    };
  }, [navigate, other, isLoading]);

  return { draftProps, otherProps };
};
