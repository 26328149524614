import { IPayeeDisbursementsBase } from "../../../../../../types/PayeeDisbursements";
import { useDeleteModal } from "./useDeleteModal";
import { useSaveRecord } from "./useSaveRecord";

export const useActions = (
  disbursements: IPayeeDisbursementsBase[],
  reset: (value: IPayeeDisbursementsBase[]) => Promise<0 | 1>
) => {
  const { openDeleteModal } = useDeleteModal(disbursements, reset);

  const { saveRecord } = useSaveRecord(disbursements, reset);

  return { openDeleteModal, saveRecord };
};
