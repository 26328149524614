import { Button, Row } from "antd";
import "../../../../../../../../../components/DefaultFormButtons/style.scss";
import { TRecord } from "../types";

type TBtnsProps = {
  onSaveClick: (isNew: boolean, record: TRecord, customerIds?: any) => Promise<void>;
  onCancelClick: () => void;
  onDeleteClick: (id: string) => void;
  isFormDisabled: boolean;
  record: TRecord;
  customerIds?: any;
};

export const FormButtons = ({
  onSaveClick,
  onCancelClick,
  onDeleteClick,
  isFormDisabled,
  record,
  customerIds,
}: TBtnsProps) => {
  return (
    <Row className="default-form-buttons w-100 pa-6 divider d-flex justify-space-between position-absolute">
      <div className="d-flex default-form-buttons-main">
        <Button
          shape="round"
          type="primary"
          disabled={isFormDisabled}
          loading={isFormDisabled}
          onClick={() => onSaveClick(false, record, customerIds)}
        >
          {record.id !== null ? "Save" : "Add"}
        </Button>
        {record.id === null && <Button
          shape="round"
          disabled={isFormDisabled}
          loading={isFormDisabled}
          onClick={() => onSaveClick(true, record, customerIds)}
        >
          Add & New
        </Button>}
        <Button
          shape="round"
          disabled={isFormDisabled}
          onClick={() => onCancelClick()}
        >
          Cancel
        </Button>
      </div>
      <div>
        {record.id !== null && (
          <Button
            type="link"
            disabled={isFormDisabled}
            onClick={() => onDeleteClick(record.id || "")}
          >
            Delete
          </Button>
        )}
      </div>
    </Row>
  )
}