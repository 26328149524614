import { Row, Card, Button, Table } from "antd";
import { useCallback, useMemo } from "react";
import { useLoadData } from "./hooks/loadData";
import { useGetTableColumns } from "./hooks/getTableColumns";
import { useDefaultTableScrollProp } from "../../../../Customers/hooks/defaultTableScrollProp";
import { useOpenSidebar } from "./hooks/openSidebar";
import { createPortal } from "react-dom";

type PortalAccessProps = {
  customerId: string | null | undefined;
};

export const PortalAccess = ({ customerId }: PortalAccessProps) => {
  const { data, count: total, isLoading, loadData } = useLoadData(customerId);

  const reloadData = useCallback(() => loadData(), [loadData]);

  const { columns } = useGetTableColumns(reloadData);

  const { scroll } = useDefaultTableScrollProp(data);

  const isNextPrimary = useMemo(() => total === 0, [total]);

  const { sideBar, isOpen, setOpen } = useOpenSidebar(
    customerId,
    isNextPrimary,
    reloadData
  );

  const onAddClicked = () => setOpen(true);

  return (
    <Row className="py-6 px-8">
      <Card className="w-100">
        <Row className="w-100 justify-space-between customer-info-card align-center card-header">
          <h5 className="h h5">Portal Access</h5>
          <Button shape="round" onClick={onAddClicked}>
            Add New
          </Button>
        </Row>
        <Row className="mt-6">
          <Table
            dataSource={data}
            columns={columns}
            loading={isLoading}
            pagination={false}
            rowKey="id"
            scroll={scroll}
            sticky
          />
        </Row>
      </Card>
      {isOpen && createPortal(sideBar, document.body)}
    </Row>
  );
};
