type TMethodColumnDescriptionProps = {
  methodType: number;
  bankAccountNumber: number | null | string;
  accountNumber: number;
  checkNumber?: number;
};

export const useGetMethodColumnDescription = () => {
  const getLine = ({
    methodType,
    bankAccountNumber,
    accountNumber,
    checkNumber
  }: TMethodColumnDescriptionProps) => {
    let line = "";

    if ([2, 5, 6, 7].includes(methodType) && bankAccountNumber) {
      line = `Bank Account#: ${bankAccountNumber}`;
    } else if (methodType === 8 && accountNumber) {
      line = `Account#: ${accountNumber}`;
    } else if(methodType === 1 && checkNumber) {
      line = `Check#: ${checkNumber}`
    }

    return line;
  };

  return { getLine };
};
