import { useCallback } from "react";
import { Button, Row, Table } from "antd";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { Footer } from "../common/Footer";
import { useDefaultTableScrollProp } from "../../../../../../../../../Customers/hooks/defaultTableScrollProp";
import { useFetchDataCount } from "../../../../../../../../../../lib/hooks/useDefaultFetchDataCount";
import { SimpleTablePagination } from "../../../../../../../../../../components/SimpleTablePagination";
import { useFetchData } from "../../../../../../../../../../lib/hooks/useDefaultFetchData";
import { DistributionModal } from "./DistributionModal";
import DistributionService from "../../../../../../../../../../services/accounts/impounds/distributions";
import { TRequest } from "../common/types";
import { IImpDistributionGet } from "../../../../../../../../../../types/Distribuions";

type TDistributions = {
  impoundId: string | null;
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  };
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setParentId: React.Dispatch<React.SetStateAction<string | null>>;
  isDistributionModalOpen: boolean;
  setIsDistributionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  impoundCurrentBalance: number;
};

export const Distributions = ({
  impoundId,
  payeesNumber,
  setIsOpen,
  setParentId,
  isDistributionModalOpen,
  setIsDistributionModalOpen,
  impoundCurrentBalance,
}: TDistributions) => {
  const {
    isLoading: isCountLoading,
    total,
    request,
    onPageChange,
    fetchCount,
  } = useFetchDataCount<{ impoundId: string | null }>(DistributionService, {
    impoundId,
  });

  const { isLoading, data, fetchData } = useFetchData<
    TRequest,
    IImpDistributionGet
  >(DistributionService, request);

  const { scroll } = useDefaultTableScrollProp(data, 350);

  const reloadData = useCallback(() => {
    fetchCount();
    fetchData();
  }, [fetchCount, fetchData]);

  const { columns } = useGetTableColumns({
    setIsModalOpen: setIsDistributionModalOpen,
    reloadData,
    parentId: impoundId,
  });

  return (
    <Row>
      <Row className="pa-6">
        <Button
          className="mb-4"
          shape="round"
          onClick={() => setIsDistributionModalOpen(true)}
        >
          Pay From Impound
        </Button>
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          rowKey="id"
          scroll={scroll}
          loading={isLoading}
        />
        {total > 20 && (
          <SimpleTablePagination
            isLoading={isCountLoading}
            total={total}
            pageNumber={request.pageNumber}
            onPageChange={onPageChange}
            pageSize={request.pageSize}
          />
        )}
      </Row>
      <Footer setId={setParentId} setIsOpen={setIsOpen} />
      <DistributionModal
        isOpen={isDistributionModalOpen}
        reloadData={reloadData}
        setIsOpen={setIsDistributionModalOpen}
        parentId={impoundId}
        payeesNumber={payeesNumber}
        impoundCurrentBalance={impoundCurrentBalance}
      />
    </Row>
  );
};
