import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import {
  LATE_CHARGES_CALCULATIONS_CHANGED,
  DELETE_USER_SUCCESS,
  CREATE_LATE_CHARGE_MSG,
  UPDATE_RECORD_SUCCESS,
} from "../../lib/constants";
import LateChargesService from "../../services/administration/latecharges";
import { ILateCharge } from "../../types/LateCharges";
import {
  setSuccessMessage,
  setErrorMessage,
} from "../actionsCreators/messageActions";
import ContractLateChargesService from "../../services/accounts/lateCharges";

export const changeLateChargesCalc =
  (entry: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await LateChargesService.editLateChargeCalc(entry);

      dispatch(setSuccessMessage(LATE_CHARGES_CALCULATIONS_CHANGED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const deleteLateCharge =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await LateChargesService.remove(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const createLateCharge =
  (
    entry: ILateCharge,
    contractId: string | undefined
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const srevice = contractId
        ? ContractLateChargesService
        : LateChargesService;

      const request = contractId ? { ...entry, contractId } : entry;

      const res = await srevice.create(request);

      dispatch(setSuccessMessage(CREATE_LATE_CHARGE_MSG));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const editLateCharge =
  (
    entry: ILateCharge,
    id: string,
    contractId: string | undefined
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const srevice = contractId
        ? ContractLateChargesService
        : LateChargesService;

      const res = await srevice.edit(entry, id);

      dispatch(setSuccessMessage(UPDATE_RECORD_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
