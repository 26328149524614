import { useCallback, useEffect, useMemo, useState } from "react";
import CustomersService from "../../../../../../../../../services/customers";
import { TCustomersData, TFormattedCustomerData } from "../../../types";
import { useFormatData } from "./useFormatData";

export const useFetchCustomerData = (
  primaryBuyerId: string,
  primarySellerId: string
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<{
    primaryBuyer: TFormattedCustomerData;
    primarySeller: TFormattedCustomerData;
  }>();

  const fetchData = useMemo(
    () =>
      [primaryBuyerId, primarySellerId].map((id) => {
        return id ? CustomersService.get(id) : "";
      }),
    [primaryBuyerId, primarySellerId]
  );

  const { getFormattedCustomersData } = useFormatData();

  const fetch = useCallback(() => {
    setIsLoading(true);
    Promise.all(fetchData)
      .then((res: TCustomersData[]) => {
        const customersData = res.map((item) => (getFormattedCustomersData(item)));

        setData({
          primaryBuyer: customersData[0],
          primarySeller: customersData[1],
        });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [fetchData, getFormattedCustomersData]);

  useEffect(() => fetch(), [fetch]);

  return { isLoading, data };
};
