import { ColumnsType } from "antd/es/table";
import {
  CHART_VALUE_COLOR,
  DATE_FORMAT,
  DISBURSEMENTS_METHOD_TYPES_HISTORY,
  PAYEE_TYPES,
} from "../../../../../../../lib/constants";
import dayjs from "dayjs";
import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";
import { useGetPayeeIcon } from "../../Details/components/PayeeDisbursements/hooks/useGetPayeeIcon";
import { IPayeeDisbursementsBase } from "../../../../../../../types/PayeeDisbursements";
import { useGetMethodColumnDescription } from "../../Details/components/PayeeDisbursements/hooks/useGetMethodColumnDescription";
import { useMemo } from "react";

export const useGetNestedTableColumns = () => {
  const { getIcon } = useGetPayeeIcon(CHART_VALUE_COLOR);

  const { getLine } = useGetMethodColumnDescription();

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Disbursement Date",
        dataIndex: "date",
        key: "date",
        width: 178,
        render: (value: string | Date) => dayjs(value).format(DATE_FORMAT),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: 140,
        align: "right",
        render: (value: number, record: any) => {
          const { isConsolidatedCheck, total } = record;

          return (
            <div className="d-flex flex-column">
              {getFormattedCurrency(value)}
              {isConsolidatedCheck && (
                <span className="table-description-line">
                  Total: {getFormattedCurrency(total)}
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: "Payee",
        dataIndex: "payeeName",
        key: "payeeName",
        width: 160,
        ellipsis: true,
        render: (value: string, record: IPayeeDisbursementsBase) => {
          const { type } = record;

          const payee = PAYEE_TYPES.find(
            (payeeType) => payeeType.value === type
          );

          const icon = getIcon(payee);

          return (
            <div className="d-flex align-center">
              {icon}
              <span className="ml-3 elipsis">{value}</span>
            </div>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 160,
        render: (value: number) =>
          PAYEE_TYPES.find((type) => type.value === value)?.label,
      },
      {
        title: "Method",
        dataIndex: "method",
        key: "method",
        width: 188,
        render: (value: number, record: any) => {
          const { bankAccountNumber, accountNumber, checkNumber } = record;

          const method = DISBURSEMENTS_METHOD_TYPES_HISTORY.find(
            (method) => method.value === value
          )?.label;

          const descriptionLine = getLine({
            methodType: value,
            bankAccountNumber,
            accountNumber,
            checkNumber,
          });

          return (
            <div className="d-flex flex-column">
              {method}
              <span className="table-description-line">{descriptionLine}</span>
            </div>
          );
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
    ],
    [getIcon, getLine]
  );

  return { columns };
};
