import {
  Button,
  Card,
  Dropdown,
  MenuProps,
  Radio,
  RadioChangeEvent,
  Row,
  Table,
} from "antd";
import { useFetchData } from "../../../../../../lib/hooks/useDefaultFetchData";
import { useFetchDataCount } from "../../../../../../lib/hooks/useDefaultFetchDataCount";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { TablePagination } from "../../../../../../components/TablePagination";
import { useCallback, useMemo } from "react";
import { DownOutlined } from "@ant-design/icons";
import { BATCH_DISBURSEMENTS_FILTER_OPTIONS } from "../../../../../../lib/constants";
import { useDefaultTableScrollProp } from "../../../../../Customers/hooks/defaultTableScrollProp";
import { useOnSorterChange } from "./hooks/useOnSorterChange";
import "./styles.scss";
import BatchDisbursementsService from "../../../../../../services/processing/disbursements";
import { TRequest } from "./types";
import { IBatchDisbursements } from "../../../../../../types/PayeeDisbursements";

type TDisbursementsTableProps = {
  batchId: string;
};

export const DisbursementTable = ({ batchId }: TDisbursementsTableProps) => {
  const {
    total,
    isLoading: isCountLoading,
    request,
    setRequest,
    onPageChange,
  } = useFetchDataCount<{ batchId: string; method: number | null; orderBy?: number }>(
    BatchDisbursementsService,
    { batchId, method: null, orderBy: 1 },
    ["method"]
  );

  const { data, isLoading } = useFetchData<TRequest, IBatchDisbursements>(
    BatchDisbursementsService,
    request
  );

  const { columns } = useGetTableColumns();

  const { scroll } = useDefaultTableScrollProp(data);

  const { onSorterChange } = useOnSorterChange(request, setRequest);

  const items: MenuProps["items"] = useMemo(() => {
    return BATCH_DISBURSEMENTS_FILTER_OPTIONS.map((option) => ({
      label: <div>{option.label}</div>,
      key: option.value.toString(),
    }));
  }, []);

  const onRadioChange = useCallback(
    (e: RadioChangeEvent) => {
      const method = e.target.value || null;

      const newRequest = { ...request, method };

      setRequest(newRequest);
    },
    [request, setRequest]
  );

  return (
    <Row className="w-100 py-6 px-8">
      <Card className="w-100">
        <Row className="w-100 d-flex justify-space-between">
          <Radio.Group
            options={BATCH_DISBURSEMENTS_FILTER_OPTIONS}
            optionType="button"
            onChange={onRadioChange}
            defaultValue={0}
          />
          <Dropdown menu={{ items }} trigger={["click"]}>
            <Button shape="round">
              Register <DownOutlined />
            </Button>
          </Dropdown>
        </Row>
        <Row className="w-100 d-block pt-6">
          <Table
            dataSource={data}
            columns={columns}
            loading={isLoading}
            pagination={false}
            rowKey="id"
            className="w-100 batch-disbursements-table"
            scroll={scroll}
            onChange={onSorterChange}
            showSorterTooltip={false}
          />
          {total > 0 && (
            <TablePagination
              total={total}
              isTotalLoading={isCountLoading}
              onPageChange={onPageChange}
              currentPage={request.pageNumber}
              pageSize={request.pageSize}
            />
          )}
        </Row>
      </Card>
    </Row>
  );
};
