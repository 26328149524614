import { Rule } from "antd/es/form";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";
import { DATE_FORMAT } from "../../../../../lib/constants";

export const useGetBeginningDateRules = () => {
  const rules = useMemo(() => {
    return [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: Dayjs) {
          if (!value) {
            return Promise.reject(new Error(`Please enter Beginning Date`));
          }
          const maxDate = dayjs().startOf("D").add(1, "y");
          const minDate = dayjs().year(1950).month(0).date(1).startOf("D");
          const current = value.startOf("D");

          if (maxDate.diff(current) < 0) {
            return Promise.reject(
              new Error(`Date must be less than ${maxDate.format(DATE_FORMAT)}`)
            );
          }

          if (minDate.diff(current) > 0) {
            return Promise.reject(
              new Error("Date must be greater than 1/1/1950")
            );
          }

          return Promise.resolve("");
        },
      }),
    ];
  }, []);

  return { rules };
};
