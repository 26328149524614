import { ThunkAction } from "redux-thunk";
import { RootState } from "../../../store";
import { AnyAction } from "redux";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../actionsCreators/messageActions";
import {
  DELETE_USER_SUCCESS,
  DISTRBUTION_CREATED,
} from "../../../lib/constants";
import { IImpDistributionPost } from "../../../types/Distribuions";
import DistributionService from "../../../services/accounts/impounds/distributions";

export const deleteDistribution =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await DistributionService.deleteOne(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const createDistribution =
  (
    entry: IImpDistributionPost
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await DistributionService.create(entry);

      dispatch(setSuccessMessage(DISTRBUTION_CREATED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
