import { Col, Form, FormInstance, Input, Row } from "antd";
import { useGetFullNameFormRules } from "../../../lib/hooks/getFullNameFormRules";
import { useValidateOnBlur } from "../../../lib/hooks/validateOnBlur";

type FullNameFormItemsProps = {
  form: FormInstance;
  isDisabled?: boolean;
  initialValues?: any;
};

export const FullNameFormItems = ({
  form,
  isDisabled,
  initialValues,
}: FullNameFormItemsProps) => {
  const { formOptions } = useGetFullNameFormRules();

  const [validateOnBlur] = useValidateOnBlur(form);

  return (
    <>
      {formOptions.map((item: any, i: number) => (
        <Row className="align-center mb-4" key={i}>
          <Col span={8}>
            <span className="text-md title">{item.label}</span>
          </Col>
          <Col span={16}>
            <Form.Item
              name={item.name}
              className="mb-0"
              rules={item.rules}
              initialValue={
                initialValues ? initialValues[item.name] : undefined
              }
            >
              <Input
                onBlur={(e) => validateOnBlur(e, item.name)}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
      ))}
    </>
  );
};
