import { FormInstance, Row } from "antd";
import { ReactNode, useMemo } from "react";
import { useCalculateInterestPerDiem } from "../../../hooks/calculateInterestPerDiem";
import { FloatNumberInput } from "../../../../../../../../../components/FloatNumberInput";

type BalanceProps = {
  form: FormInstance;
};

export const Balance = ({ form }: BalanceProps) => {
  const { calculate } = useCalculateInterestPerDiem(form);

  const formItems: ReactNode = useMemo(() => {
    const values: any = [
      {
        name: "balancePrincipal",
        label: "Principal",
      },
      {
        name: "balanceUnpaidInterest",
        label: "Unpaid Interest",
      },
      {
        name: "balanceUnpaidLateCharges",
        label: "Unpaid Late Charges",
      },
      {
        name: "balancePartialPayments",
        label: "Partial Payment",
      },
      {
        name: "balanceImpound",
        label: "Impound",
        disabled: true,
      },
    ];

    return values.map((item: any, i: number) => (
      <Row className="align-center mb-4 justify-space-between" key={i}>
        <span className="text-md title">{item.label}</span>
        <FloatNumberInput
          name={item.name}
          label={""}
          form={form}
          className={`mb-0 balance__form-item ${
            item.disabled ? "disabled-input" : ""
          }`}
          disabled={item.disabled}
          onChange={() => item.name === "balancePrincipal" && calculate()}
        />
      </Row>
    ));
  }, [calculate, form]);

  return (
    <>
      <h5 className="mt-4 mb-5 h h5 card-header">Balance</h5>
      {formItems}
    </>
  );
};
