import { useEffect, useState } from "react";
import CustomerPaymentsService from "../../../../../../services/customers/payments";

export const useFetchData = (customerId: string, request: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);
    CustomerPaymentsService.fetchAll({ ...request, customerId })
      .then((res: any) => {
        setIsLoading(false);
        setData(
          res.map((item: any, i: number) => ({
            ...item,
            id: i,
          }))
        );
      })
      .catch(() => setIsLoading(false));
  }, [customerId, request]);

  return { data, isLoading };
};
