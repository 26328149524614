import { useMemo } from "react";
import { SecurityCheck as SecurityCheckBase } from "../../../components/SecurityCheck";
import { useVerificationCode } from "../hooks/verificationCode";

type SecurityCheckProps = {
  email: string;
  verificationCodeId: string | null;
  setIsChecking: (value: boolean) => void;
  setVerificationCodeId: (value: string | null) => void;
  onConfirmSecurityCode: (value: 0 | 1 | 2 | 3) => void;
};

export const SecurityCheck = ({
  email,
  verificationCodeId,
  setIsChecking,
  setVerificationCodeId,
  onConfirmSecurityCode,
}: SecurityCheckProps) => {
  const onSendCodeSuccess = (value: string): void =>
    setVerificationCodeId(value);

  const { sendCode, confirm, isDisabled } = useVerificationCode(
    email,
    onSendCodeSuccess
  );

  const onSend = () => sendCode();

  const onCofirm = (values: any): void => {
    const request = {
      code: values.securityCode.replace(/-/g, ""),
      verificationCodeId,
      email,
    };

    confirm(request, (res) => onConfirmSecurityCode(res));
  };

  const subTitle = useMemo(() => {
    return (
      <div className="sub-title__container mb-10">
        <p className="justify-center my-0">
          Let us know this email belongs to you.
        </p>
        <p className="justify-center my-0">
          Enter the code we’ve just sent to
          <span className="email-text">&ensp;{email}</span>
        </p>
      </div>
    );
  }, [email]);

  return (
    <SecurityCheckBase
      header="Enter the code from your email"
      setStep={() => setIsChecking(false)}
      isRememberCode={false}
      isTimer={false}
      subTitle={subTitle}
      type="email"
      isConfirmDisabled={isDisabled}
      onResend={onSend}
      onConfirm={onCofirm}
    />
  );
};
