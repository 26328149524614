import { Row, Form, Col, Input, Button } from "antd";
import { useEffect, useMemo, useState } from "react";
import { PasswordInput } from "../../../../components/PasswordInput";
import { PhoneInput } from "../../../../components/PhoneInput";
import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  MAX_FIRSTNAME,
  MAX_LASTNAME,
  WRONG_FIRST_NAME,
  WRONG_LAST_NAME,
} from "../../../../lib/constants";
import { useValidateOnBlur } from "../../../../lib/hooks/validateOnBlur";
import { getFormatted } from "../../../../lib/PhoneFormatter";
import { NAME_RULE } from "../../../../lib/validationRules";
import { LoadingOutlined } from "@ant-design/icons";
import { useRegisterUser } from "../../hooks/registerUser";
import { TermsAndConditionsModal } from "../../../../components/TermsAndConditionsModal";

type SecondStepProps = {
  email: string;
  userInitialData: any;
  isFormDisabled: boolean;
  verificationCodeId: string | null;
  setIsFormDisabled: (value: boolean) => void;
};

export const SecondStep = ({
  email,
  userInitialData,
  isFormDisabled,
  verificationCodeId,
  setIsFormDisabled,
}: SecondStepProps) => {
  const [form] = Form.useForm();

  const [validateOnBlur] = useValidateOnBlur(form);

  const [phone, setPhone] = useState<string | null>(null);

  const [termsTabId, setTermsTabId] = useState<undefined | "1" | "2">(
    undefined
  );

  const onCloseTerms = () => setTermsTabId(undefined);

  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const { onRegister } = useRegisterUser(email, setIsFormDisabled);

  const nameFormProps = useMemo(
    () => [
      {
        name: "firstName",
        label: "First Name",
        className: "mr-3 mb-5",
        rules: {
          requiredMessage: FIRST_NAME_REQUIRED,
          patternMessage: WRONG_FIRST_NAME,
          maxMessage: MAX_FIRSTNAME,
        },
      },
      {
        name: "lastName",
        label: "Last Name",
        className: "ml-3 mb-5",
        rules: {
          requiredMessage: LAST_NAME_REQUIRED,
          patternMessage: WRONG_LAST_NAME,
          maxMessage: MAX_LASTNAME,
        },
      },
    ],
    []
  );

  useEffect(() => {
    const { phone } = userInitialData;
    setPhone(phone ? getFormatted(phone).slice(2) : "");
  }, [userInitialData]);

  const onConfirm = (values: any) => {
    isPasswordValid &&
      onRegister(values, userInitialData.id, verificationCodeId);
  };

  return (
    <Row className="d-block">
      <p className="mb-6 mt-0">This info needs to be accurate.</p>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        disabled={isFormDisabled}
        onFinish={onConfirm}
      >
        <Row>
          {nameFormProps.map((item: any, i: number) => {
            return (
              <Col span={12} key={i}>
                <Form.Item
                  className={item.className}
                  name={item.name}
                  label={item.label}
                  initialValue={userInitialData[item.name]}
                  rules={[
                    {
                      required: true,
                      message: item.rules.requiredMessage,
                      validateTrigger: "onSubmit",
                    },
                    {
                      pattern: NAME_RULE,
                      message: item.rules.patternMessage,
                      validateTrigger: "onSubmit",
                    },
                    {
                      max: 50,
                      message: item.rules.maxMessage,
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    onBlur={(e) => validateOnBlur(e, item.name)}
                  />
                </Form.Item>
              </Col>
            );
          })}
        </Row>

        {phone !== null && <PhoneInput form={form} initialValue={phone} />}

        <PasswordInput
          setIsPasswordValid={setIsPasswordValid}
          form={form}
          label="Password"
        />

        <Row className="mt-2">
          <p className="my-0 text-sm">
            By selecting <strong>Agree and Sign Up</strong> below, you agree to
            Sunwest’s &nbsp;
            <strong
              className="ant-btn-link logo-cursor"
              onClick={() => setTermsTabId("1")}
            >
              Terms of Use
            </strong>
            &nbsp;and&nbsp;
            <strong
              className="ant-btn-link logo-cursor"
              onClick={() => setTermsTabId("2")}
            >
              Privacy Policy
            </strong>
            .
          </p>
        </Row>

        <Form.Item className="mt-3 mb-0">
          <Button
            type="primary"
            shape="round"
            size="large"
            htmlType="submit"
            disabled={!isPasswordValid || isFormDisabled}
            block
          >
            Agree and Sign Up
            {isFormDisabled && <LoadingOutlined />}
          </Button>
        </Form.Item>

        <TermsAndConditionsModal
          termsTabId={termsTabId}
          setTermsTabId={setTermsTabId}
          onClose={onCloseTerms}
        />
      </Form>
    </Row>
  );
};
