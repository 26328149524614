import { Button, Row } from "antd";
import { ITicklerExtended } from "../../../../types/Tickler";
import { Tickler } from "./Tickler";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { PaymentRestrictionsRow } from "./PaymentRestrictionsRow";
import { Modal } from "../Modal";
import "./styles.scss";

type BeforeReadModalProps = {
  ticklers: ITicklerExtended[];
  closed: boolean;
  setIsFormBlured: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
};

export const BeforeReadModal = ({
  ticklers,
  closed,
  setIsFormBlured,
  data,
}: BeforeReadModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const title: ReactNode = useMemo(() => {
    return (
      <Row className="align-center">
        <ExclamationCircleFilled />
        <h5 className="h h5 ml-4">Read Before Posting</h5>
      </Row>
    );
  }, []);

  const onProceed = useCallback((): void => {
    setIsOpen(false);
    setIsFormBlured(false);
  }, [setIsFormBlured]);

  const footer: ReactNode = useMemo(
    () => (
      <Button shape="round" type="primary" onClick={onProceed}>
        Proceed to Payment
      </Button>
    ),
    [onProceed]
  );

  useEffect(() => {
    ticklers.length > 0 && closed && setIsOpen(true);
  }, [closed, ticklers]);

  const paymentRestrictions: number | undefined = useMemo(() => {
    return data ? data.payment.contract.paymentRestrictions : undefined;
  }, [data]);

  return (
    <Modal title={title} footer={footer} isOpen={isOpen}>
      <Row className="d-block py-6 px-8">
        <PaymentRestrictionsRow paymentRestrictions={paymentRestrictions} />
        <Row className="mb-4">
          <p className="my-0 text-md title">TICKLERS</p>
        </Row>
        <Row className="d-block ticklers-list">
          {ticklers.map((item: ITicklerExtended, i: number) => (
            <Tickler tickler={item} key={i} />
          ))}
        </Row>
      </Row>
    </Modal>
  );
};
