import { Form, DatePicker, Radio, Checkbox, Button, FormInstance } from "antd";
import dayjs from "dayjs";
import { BATCH_TYPES, DATE_FORMAT } from "../../../lib/constants";
import { useMemo } from "react";
import { useButtonHandlers } from "./hooks/useButtonHandlers";

const { RangePicker } = DatePicker;

type TPrintFormProps = {
  isBatchReport: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContentType: React.Dispatch<React.SetStateAction<number>>;
};

export const PrintForm = ({
  isBatchReport,
  setIsOpen,
  setContentType,
}: TPrintFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const typesLabel = useMemo(
    () => (isBatchReport ? "Batch Types" : "Batch Type"),
    [isBatchReport]
  );

  const sortOptions = useMemo(
    () => [
      { value: 1, label: "Date" },
      { value: 2, label: "Payment#" },
      { value: 3, label: "Account#" },
      { value: 4, label: "Amount" },
      { value: 5, label: "Method" },
    ],
    []
  );

  const initialValues = useMemo(
    () => ({
      dates: [dayjs(), dayjs()],
      type: 0,
      sorting: 1,
    }),
    []
  );

  const { onCancelClick } = useButtonHandlers({ setIsOpen, setContentType, form });

  return (
    <>
      <Form
        layout="vertical"
        requiredMark={false}
        className="pt-6 processing-print-form"
        initialValues={initialValues}
        form={form}
      >
        <Form.Item label="Batch Dates" name="dates" className="mb-8">
          <RangePicker format={DATE_FORMAT} />
        </Form.Item>
        <div className="d-flex">
          <Form.Item label={typesLabel} name="type" className="pr-8">
            {isBatchReport ? (
              <Checkbox.Group
                options={BATCH_TYPES}
                className="d-flex flex-column"
              />
            ) : (
              <Radio.Group
                options={[{ label: "All Types", value: 0 }].concat(BATCH_TYPES)}
                className="d-flex flex-column"
              />
            )}
          </Form.Item>
          {isBatchReport && (
            <Form.Item label="Sort Payments by" name="sorting" className="pl-8">
              <Radio.Group
                options={sortOptions}
                className="d-flex flex-column"
              />
            </Form.Item>
          )}
        </div>
      </Form>
      <div className="w-100 d-flex justify-end pt-6 pb-1">
        <Button type="primary" shape="round" onClick={onCancelClick}>
          Print
        </Button>
        <Button shape="round" className="ml-2" onClick={onCancelClick}>
          Cancel
        </Button>
      </div>
    </>
  );
};
