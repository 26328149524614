import { Col, Row } from "antd";
import { ReactNode } from "react";
import { getFormattedCurrency } from "../../../../../../lib/currencyFormatter";
import { getFormattedPercentage } from "../../../../../../lib/percentageFormatter";

type GeneralInfoProps = {
  data: any;
};

export const GeneralInfo = ({ data }: GeneralInfoProps) => {
  const getGeneralInfoCell = (
    title: string,
    value: string | number,
    isAmount: boolean,
    isLastInRow: boolean
  ): ReactNode => {
    let displayView: string;

    if (value || isAmount) {
      displayView = isAmount ? getFormattedCurrency(+value) : value.toString();
    } else {
      displayView = title !== "Property Address" ? "0" : "-";
    }

    return (
      <Col span={8} className={`mb-4 ${isLastInRow ? "" : "pr-2"}`}>
        <p className="text-md title mt-0 mb-1">{title}</p>
        <p className="text-md my-0 elipsis" title={displayView}>
          {displayView}
        </p>
      </Col>
    );
  };

  return (
    <>
      <h5 className="h h5 header-text">General Information</h5>
      <Row className="mt-6 info-row">
        {getGeneralInfoCell(
          "Property Address",
          data.propertyAddress,
          false,
          false
        )}
        {getGeneralInfoCell("Seller", data.seller, false, false)}
        {getGeneralInfoCell("Buyer", data.buyer, false, true)}
      </Row>
      <Row className="info-row">
        {getGeneralInfoCell(
          "Interest Rate",
          `${getFormattedPercentage(data.interestRate)}`,
          false,
          false
        )}
        {getGeneralInfoCell(
          "Principal Balance",
          data.principalBalance,
          true,
          false
        )}
      </Row>
      <Row className="info-row">
        {getGeneralInfoCell(
          "Impound Balance",
          data.impoundBalance,
          true,
          false
        )}
        {getGeneralInfoCell(
          "UnPaid Late Charges",
          data.unpaidLateCharges,
          true,
          false
        )}
      </Row>
    </>
  );
};
