import { Card, Col, Row, Form, FormInstance } from "antd";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { Loader } from "../../../../../../components/Loader";
import { GeneralInfo } from "./components/GeneralInfo";
import { OtherInfo } from "./components/OtherInfo";
import { Impounds } from "./components/Impounds";
import { LateCharges } from "../../../../../Administration/LateChargesTab";
import { PayeeDisbursements } from "./components/PayeeDisbursements";
import { Fees } from "../../../../../Administration/FeeTab";
import { Payers } from "./components/Payers";
import "./styles.scss";

type DetailsProps = {
  id: string;
  form: FormInstance;
  data: any;
  isLoading: boolean;
  isDisabled: boolean;
  hasPrimaryBuyerId: boolean;
  hasPrimarySellerId: boolean;
  lcSectionKey: boolean;
  setHasChanges: (value: boolean) => void;
  onCustomerFound: (number: number | null, name: string[]) => void;
  reloadAccount: () => void;
};

export const Details = ({
  id,
  form,
  data,
  isLoading,
  isDisabled,
  setHasChanges,
  hasPrimaryBuyerId,
  hasPrimarySellerId,
  onCustomerFound,
  reloadAccount,
  lcSectionKey,
}: DetailsProps) => {
  const [shouldPayeeDisbUpdate, setShouldPayeeDisbUpdate] =
    useState<boolean>(false);

  const [lCDays1, setLCDays1] = useState<number>(0);

  const loader: ReactNode = useMemo(
    () => (
      <Row className="h-100 d-flex justify-center align-center">
        <Loader fontSize="64" />
      </Row>
    ),
    []
  );

  const onChange = useCallback((): void => {
    setHasChanges(true);
  }, [setHasChanges]);

  return (
    <Row className="my-6 mx-8 fixed-container justify-center d-flex">
      <Row className="account-details-wrapper">
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          initialValues={data}
          onFieldsChange={onChange}
          disabled={isDisabled}
          className="w-100"
        >
          <Row className="w-100">
            <div className="w-100 d-flex general-account-info fixed-container">
              <Col span={16} className="pr-4">
                <Card className="h-100">
                  {isLoading ? (
                    loader
                  ) : (
                    <GeneralInfo
                      form={form}
                      isDisabled={isDisabled}
                      onCustomerFound={onCustomerFound}
                      hasPrimaryBuyerId={hasPrimaryBuyerId}
                      hasPrimarySellerId={hasPrimarySellerId}
                    />
                  )}
                </Card>
              </Col>
              <Col span={8}>
                <Card className="h-100">
                  {isLoading ? (
                    loader
                  ) : (
                    <OtherInfo
                      data={data}
                      form={form}
                      isDisabled={isDisabled}
                      lCDays1={lCDays1}
                    />
                  )}
                </Card>
              </Col>
            </div>
          </Row>
        </Form>
        <Row className="mt-4 w-100">
          <Fees accountId={id} onSuccess={reloadAccount} />
          <LateCharges
            key={`lc${lcSectionKey}`}
            accountId={id}
            setLCDays1={setLCDays1}
          />
          <Impounds
            accountId={id}
            primaryBuyerNumber={data !== null && data.primaryBuyerNumber}
            primarySellerNumber={data !== null && data.primarySellerNumber}
            setShouldPayeeDisbUpdate={setShouldPayeeDisbUpdate}
            reloadAccount={reloadAccount}
          />
          <Payers
            contractId={id}
            primaryBuyerNumber={data !== null && data.primaryBuyerNumber}
          />
          <PayeeDisbursements
            accountId={id}
            primaryBuyerNumber={data !== null && data.primaryBuyerNumber}
            primarySellerNumber={data !== null && data.primarySellerNumber}
            shouldPayeeDisbUpdate={shouldPayeeDisbUpdate}
            setShouldPayeeDisbUpdate={setShouldPayeeDisbUpdate}
            onSuccess={reloadAccount}
          />
        </Row>
      </Row>
    </Row>
  );
};
