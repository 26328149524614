import { Button, Row } from "antd";

type TFooter = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
};

export const Footer = ({ setIsOpen, setId}: TFooter) => {
  return (
    <Row className="pa-6 w-100 divider position-absolute footer">
      <Button
        shape="round"
        onClick={() => {
          setId(null);
          setIsOpen(false);
        }}
      >
        Cancel
      </Button>
    </Row>
  );
};