import { useState, useCallback } from "react";
import { useAppDispatch } from "../../../store";
import { saveCustomer, updateCustomer } from "../../../acions/customers";
import { App, FormInstance } from "antd";
import { getFormatted } from "./getFormattedRequest";
import { useHasChangesModalOptions } from "./hasChangesModalOptions";
import dayjs from "dayjs";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ROUTES from "../../../configs/routesConfig";

export const useCustomerFormHandlers = (
  selectedItemId: string,
  form: FormInstance,
  initialData: any,
  hasChanges: boolean,
  setInitialData: (value: any) => void,
  reduceBackStep: () => void
) => {
  const navigate: NavigateFunction = useNavigate();

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { modal } = App.useApp();

  const { options } = useHasChangesModalOptions(isFormDisabled);

  const save = useCallback(
    async (isNew: boolean, res: any) => {
      try {
        const response: any = await Promise.resolve(
          dispatch(saveCustomer(res))
        );

        setIsFormDisabled(false);

        !isNew && reduceBackStep();

        isNew
          ? form.resetFields()
          : navigate(`${ROUTES.CUSTOMERS}/${response.id}`);

        return response;
      } catch {
        return setIsFormDisabled(false);
      }
    },
    [dispatch, form, navigate, reduceBackStep]
  );

  const update = useCallback(
    async (isNew: boolean, res: any, id: string, onSaved?: () => void) => {
      try {
        await Promise.resolve(dispatch(updateCustomer(id, res)));
        setIsFormDisabled(false);

        if (!onSaved) {
          !isNew && navigate(ROUTES.CUSTOMERS);
        } else {
          setInitialData({
            ...form.getFieldsValue(),
            lastModifiedAt: dayjs().format(),
            number: initialData.number,
          });
          onSaved();
        }

        return true;
      } catch {
        setIsFormDisabled(false);
        return false;
      }
    },
    [dispatch, form, initialData, navigate, setInitialData]
  );

  const onCancelClicked = (
    isNew: boolean,
    onClose: () => void,
    onSaved?: () => void,
    onValidationFailed?: () => void
  ): void => {
    const formattedRequest = getFormatted(form.getFieldsValue());

    !hasChanges
      ? onClose()
      : form
          .validateFields()
          .then(() => onHasChanges(isNew, formattedRequest, onClose, onSaved))
          .catch(() => {
            onValidationFailed ? onValidationFailed() : onClose();
          });
  };

  const onHasChanges = useCallback(
    (
      isNew: boolean,
      res: any,
      onClose: () => void,
      onSaved?: () => void
    ): void => {
      modal.confirm({
        ...options,
        onCancel: () =>
          selectedItemId !== "create"
            ? update(isNew, res, selectedItemId, onSaved)
            : save(isNew, res),
        onOk: () => onClose(),
      });
    },
    [modal, options, selectedItemId, update, save]
  );

  const onSaveClicked = useCallback(
    async (isNew: boolean, onSaved?: () => void) => {
      try {
        const res = await form.validateFields();
        const formattedRequest = getFormatted(res);

        setIsFormDisabled(true);
        return await (selectedItemId !== "create"
          ? update(isNew, formattedRequest, selectedItemId, onSaved)
          : save(isNew, formattedRequest));
      } catch {
        return false;
      }
    },
    [form, selectedItemId, update, save]
  );

  return {
    isFormDisabled,
    onSaveClicked,
    onCancelClicked,
  };
};
