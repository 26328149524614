import { useCallback } from "react";

export const useCalcLCBalance = () => {
  const calcLCBalance = useCallback((history: any) => {
    const { breakdown } = history;

    const {
      lateCharge,
      lateChargeAmount,
      principalToBalance,
      lateChargeBefore,
    } = breakdown;

    const { assessed, addedToPrincipal } = lateCharge;

    const lateChargeAfter =
      +lateChargeBefore + +assessed - +lateChargeAmount - +addedToPrincipal;

    history.breakdown.lateChargeAfter = lateChargeAfter;

    if (+addedToPrincipal > 0 && +principalToBalance < +addedToPrincipal) {
      history.breakdown.principalToBalance =
        Number(addedToPrincipal).toFixed(2);
    }
  }, []);

  return { calcLCBalance };
};
