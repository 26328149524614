import { useCallback, useState } from "react";
import { FormInstance } from "antd";
import AutoChargesService from "../../../../../../../services/processing/autoCharges";
import dayjs from "dayjs";
import { useOpenProgressModal } from "../../../../../../../lib/hooks/useOpenProgressModal";

export const useRunAutoCharge = (
  batchId: string,
  form: FormInstance,
  close: () => void,
  onSuccess: () => void
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { openModal } = useOpenProgressModal(
    "Creating AutoCharges",
    "Creating Autocharges failed",
    onSuccess
  );

  const onApply = useCallback(() => {
    setIsLoading(true);

    const dateRange = form.getFieldValue("date");

    const request = {
      beginDate: dayjs(dateRange[0]).startOf("D").format(),
      endDate: dayjs(dateRange[1]).startOf("D").format(),
    };

    AutoChargesService.create(batchId, request)
      .then((res) => {
        openModal(res);
        setIsLoading(false);
        close();
      })
      .catch(() => {
        setIsLoading(false);
        close();
      });
  }, [batchId, close, form, openModal]);

  return { onApply, isLoading };
};
