import { Form } from "antd";
import { ReactNode, useMemo, useState } from "react";
import { SideBarContainer } from "../../../../../../../../components/SideBarContainer";
import { BankAccountForm } from "../../BankAccountForm";
import { useOnCloseModal } from "./onCloseModal";

export const useOpenSideBar = (
  selectedItem: any | null,
  showAddAndNewButton: boolean,
  isPrimaryDisabled: boolean,
  customerId: string,
  isPrimary: boolean,
  fetchData: () => void,
  setSelectedItem: (value: any | null) => void,
  setIsPrimary: (value: boolean) => void
) => {
  const [form] = Form.useForm();

  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  const { onCloseSideBar } = useOnCloseModal(form, setShowSideBar);

  const sideBar: ReactNode = useMemo(() => {
    const title: string = selectedItem
      ? "Bank Account Details"
      : "Add Bank Account";

    return (
      <SideBarContainer title={title} setShowSideBar={onCloseSideBar}>
        <BankAccountForm
          selectedItem={selectedItem}
          showAddAndNewButton={showAddAndNewButton}
          isPrimaryDisabled={isPrimaryDisabled}
          onClose={onCloseSideBar}
          reload={fetchData}
          setSelectedItem={setSelectedItem}
          setShowSideBar={setShowSideBar}
          customerId={customerId}
          form={form}
          setIsPrimary={setIsPrimary}
          isPrimary={isPrimary}
        />
      </SideBarContainer>
    );
  }, [
    selectedItem,
    onCloseSideBar,
    showAddAndNewButton,
    isPrimaryDisabled,
    fetchData,
    setSelectedItem,
    customerId,
    form,
    setIsPrimary,
    isPrimary,
  ]);

  return { sideBar, showSideBar, setShowSideBar };
};
