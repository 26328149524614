import { useCallback, useEffect } from "react";
import ImpoundActivitiesService from "../../../../../../../../../../../services/accounts/impounds/activities";
import { FormInstance } from "antd";
import dayjs from "dayjs";

export const useFetch = (
  id: string | null,
  form: FormInstance,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const fetchData = useCallback(async () => {
    if (id) {
      try {
        const response = await ImpoundActivitiesService.fetch(id);

        const { date } = response;

        response.date = dayjs(date);

        form.setFieldsValue(response);

        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    }
  }, [id, form, setIsLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
};
