import { Row, Table } from "antd";
import { useTableColumns } from "../hooks/useTableColumns";
import { useMemo } from "react";
import { IPayeeDisbursementsBase } from "../../../../../../types/PayeeDisbursements";
import { useDefualtOnRow } from "../../../../../../lib/hooks/useDefaultOnRow";

type DisbursementsTableProps = {
  disbursements: IPayeeDisbursementsBase[];
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  openDeleteModal: (id: string) => void;
  onOpenSideBar: (value: any) => void;
};

export const DisbursementsTable = ({
  disbursements,
  isFormDisabled,
  isPaymentPosted,
  openDeleteModal,
  onOpenSideBar,
}: DisbursementsTableProps) => {
  const { columns } = useTableColumns(
    isFormDisabled,
    isPaymentPosted,
    openDeleteModal,
    onOpenSideBar
  );

  const { onRow } = useDefualtOnRow(onOpenSideBar);

  const paginationProps: false | any = useMemo(() => {
    return disbursements.length <= 10
      ? false
      : {
          size: "small",
        };
  }, [disbursements]);

  return (
    <Row className="w-100">
      <Table
        dataSource={disbursements}
        columns={columns}
        pagination={paginationProps}
        rowKey="index"
        showHeader={false}
        expandable={{ showExpandColumn: false }}
        className="w-100 payment-disb-table mb-4"
        onRow={onRow}
      />
    </Row>
  );
};
