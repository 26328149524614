import { useCallback, useState } from "react";

export const useHistoryBackStep = (route: string) => {
  const [backLocation, setBackLocation] = useState<any>(
    window.history.length === 1 ? route : -1
  );

  const reduceBackStep = useCallback((): void => {
    if (typeof backLocation === "number") {
      setBackLocation(backLocation - 1);
    }
  }, [backLocation]);

  return { backLocation, reduceBackStep };
};
