import axios from "axios";
import { API_URL } from "../configs/apiUrl";

const sendVerificationCode = async (email: string) => {
  const response = await axios.post(API_URL + "Register/SendVerificationCode", {
    email,
  });

  return response.data;
};

const confirmVerificationCode = async (request: any) => {
  const response = await axios.post(
    API_URL + "Register/ConfirmVerificationCode",
    request
  );

  return response.data;
};

const register = async (request: any) => {
  const response = await axios.post(API_URL + "Register/Register", request);

  return response.data;
};

const SignUpService = {
  sendVerificationCode,
  confirmVerificationCode,
  register,
};

export default SignUpService;
