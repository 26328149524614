import { Input as AntdInput, InputRef } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  BaseSyntheticEvent,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import _ from "lodash";
import { ENTER_CUSTOMER_NUMBER } from "../../lib/constants";

type InputProps = {
  isLoading: boolean;
  initialValue?: string;
  toggleIsSearch: () => void;
  setIsSearch: (value: boolean) => void;
  search: (number: number, onFail: () => void) => void;
};

export const Input = ({
  isLoading,
  initialValue,
  toggleIsSearch,
  setIsSearch,
  search,
}: InputProps) => {
  const [input, setInput] = useState<string>("");

  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    const { current } = inputRef;

    if (current) {
      current.focus();
      current.select();
    }
  }, []);

  const [hasValidationError, setHasValidationError] = useState<boolean>(false);

  const status: "error" | undefined = useMemo(() => {
    return hasValidationError ? "error" : undefined;
  }, [hasValidationError]);

  const onChange = (e: BaseSyntheticEvent) => {
    setInput(e.target.value);
    setHasValidationError(false);
  };

  const onPressEnter = () => {
    const value: number = +input;
    if (!_.isNaN(value) && input.length > 0) {
      search(value, () => setIsSearch(false));
    } else {
      setHasValidationError(true);
    }
  };

  return (
    <AntdInput
      className="customer-number-input"
      placeholder={ENTER_CUSTOMER_NUMBER}
      suffix={<SearchOutlined />}
      disabled={isLoading}
      status={status}
      ref={inputRef}
      defaultValue={initialValue}
      onBlur={toggleIsSearch}
      onPressEnter={onPressEnter}
      onChange={onChange}
    />
  );
};
