import { useParams } from "react-router-dom";
import { Main } from "./components/Main";
import { useGetTypes } from "../Customers/hooks/fetchTypes";
import { useHistoryBackStep } from "../../lib/hooks/useHistoryBackStep";
import ROUTES from "../../configs/routesConfig";
import "./styles.scss";

export const Customer = () => {
  const { types } = useGetTypes();

  const { customerId } = useParams();

  const { backLocation, reduceBackStep } = useHistoryBackStep(ROUTES.CUSTOMERS);

  return (
    <Main
      key={`${customerId}Customer`}
      selectedItemId={customerId || ""}
      types={types}
      backLocation={backLocation}
      reduceBackStep={reduceBackStep}
    />
  );
};
