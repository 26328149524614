import { ReactNode, useCallback, useMemo, useState } from "react";
import { SideBarContainer } from "../../../components/SideBarContainer";
import { CreateAdmin } from "../SideBar/CreateAdmin";

export const useOpenCreateAdmin = (roles: any, reloadData: () => void) => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  const openCreate = useCallback(() => setShowSideBar(true), []);

  const sideBar = useMemo(
    (): ReactNode => (
      <SideBarContainer
        title={<h4 className="my-0 title">Create Admin</h4>}
        setShowSideBar={setShowSideBar}
      >
        <CreateAdmin
          setShowSideBar={setShowSideBar}
          roles={roles}
          reloadData={reloadData}
        />
      </SideBarContainer>
    ),
    [roles, reloadData]
  );

  return { showSideBar, openCreate, sideBar };
};
