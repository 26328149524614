import { FormInstance } from "antd";
import { useCallback, useEffect, useState } from "react";
import BatchesService from "../../../../services/processing/batches";
import { IBatchGet } from "../../../../types/Batches";
import dayjs, { Dayjs } from "dayjs";
import { BATCH_TYPES } from "../../../../lib/constants";

export const useGetInitialValues = (
  id: string | null,
  form: FormInstance,
  setIsWarehouse: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!id);

  const getInitialValues = useCallback(() => {
    if (id !== null) {
      BatchesService.fetch(id)
        .then((res: IBatchGet) => {
          const initialValues = {
            ...res,
            date: dayjs(res.date),
            paymentsDate:
              res.paymentsDate !== null ? dayjs(res.paymentsDate) : dayjs(),
          };
          setIsLoading(false);

          form.setFieldsValue(initialValues);
          setIsWarehouse(res.type === 3);
        })
        .catch(() => setIsLoading(false));
    } else {
      const now: Dayjs = dayjs();

      const initialValues = {
        type: BATCH_TYPES[0].value,
        date: now,
        paymentsDate: now,
        isCreatorPaymentOnly: true,
      };

      form.setFieldsValue(initialValues);
    }
  }, [form, id, setIsWarehouse]);

  useEffect(() => getInitialValues(), [getInitialValues, id]);

  return { isLoading };
};
