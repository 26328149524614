import { App } from "antd";
import { useState } from "react";
import { useHasChangesModalOptions } from "../../Customer/hooks/hasChangesModalOptions";

export const useLeaveDetailsView = (hasChanges: boolean) => {
  const { modal } = App.useApp();

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const { options } = useHasChangesModalOptions(isFormDisabled);

  const onLeaveView = (action: () => void, onCancel: () => void): void => {
    if (hasChanges) {
      modal.confirm({
        ...options,
        onCancel: () => action(),
        onOk: () => onCancel(),
      });
    } else {
      onCancel();
    }
  };

  return {
    hasChanges,
    onLeaveView,
    isFormDisabled,
    setIsFormDisabled,
  };
};
