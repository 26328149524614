import { FormInstance, Row, DatePicker, Form } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  DATE_FORMAT,
  DUE_DATE_REQUIRED,
} from "../../../../../../../../lib/constants";
import { useCallback, useMemo } from "react";
import ROUTES from "../../../../../../../../configs/routesConfig";
import { CustomerInputSearch } from "../../../../../../../Accounts/components/Filters/CustomerInputSearch";
import { Rule } from "antd/es/form";

type TSidePanelProps = {
  backToAccountDetails: () => void;
  accountNumber: number;
  instance: any;
  isPaymentEdited: boolean;
  form: FormInstance;
  hasPayeeId: any;
  onCustomerFound: (number: number | null, name: string[]) => void;
  isDisabled: boolean;
  paymentId: string | null;
};

export const SidePanel = ({
  backToAccountDetails,
  accountNumber,
  instance,
  isPaymentEdited,
  form,
  hasPayeeId,
  onCustomerFound,
  isDisabled,
  paymentId,
}: TSidePanelProps) => {
  const additionalLabelClass = useCallback((isClassNeeded: boolean) => {
    return isClassNeeded ? "pt-2" : "";
  }, []);

  const dateRules: Rule[] = useMemo(
    () => [
      {
        type: "date",
        required: true,
        validateTrigger: "onSubmit",
        message: DUE_DATE_REQUIRED,
      },
    ],
    []
  );

  const getRow = useCallback(
    (label: string, value: any, shouldInputAppear = false) => {
      return (
        <Row className="d-flex mb-4">
          <p
            className={`side-panel-label ma-0 ${additionalLabelClass(
              isPaymentEdited && shouldInputAppear
            )}`}
          >
            {label}
          </p>
          {isPaymentEdited && shouldInputAppear ? (
            <Form.Item name="dueDate" rules={dateRules}>
              <DatePicker format={DATE_FORMAT} className="w-100" />
            </Form.Item>
          ) : (
            <p className="side-panel-value pl-2 ma-0 text-md w-500 elipsis">
              {value}
            </p>
          )}
        </Row>
      );
    },
    [additionalLabelClass, dateRules, isPaymentEdited]
  );

  return (
    <Form
      form={form}
      disabled={isDisabled}
      className="payment-history-record-side-panel d-flex flex-column pt-8 pr-4"
    >
      <p className="ma-0 text-sm w-500 title p ls-5">ACCOUNT</p>
      <p className="my-4 h h4 link" onClick={backToAccountDetails}>
        {accountNumber}
      </p>
      <Row className="d-flex mb-4">
        <p
          className={`side-panel-label ma-0 ${additionalLabelClass(
            isPaymentEdited
          )}`}
        >
          Payer
        </p>
        {!isPaymentEdited ? (
          <Link
            to={`${ROUTES.CUSTOMERS}/${instance?.payerId}`}
            className="link elipsis side-panel-value pl-2 ma-0"
            target="_blank"
          >
            {instance?.payerName}
          </Link>
        ) : (
          <CustomerInputSearch
            form={form}
            label=""
            name={["payeeId"]}
            isFormDisabled={isDisabled}
            onCustomerFound={onCustomerFound}
            hasId={hasPayeeId}
            additionalClass="side-panel-value payee-input"
          />
        )}
      </Row>
      {getRow(
        "Due Date",
        instance
          ? instance.dueDate
            ? dayjs(instance.dueDate).format(DATE_FORMAT)
            : "-"
          : "",
        true
      )}
      {paymentId !== null && (
        <>
          <Row className="divider mt-4 mb-8"></Row>
          {isPaymentEdited && (
            <>
              {getRow(
                "Last Updated Date",
                instance ? dayjs(instance.lastUpdated).format(DATE_FORMAT) : ""
              )}
              {getRow("Last Updated By", instance?.lastUpdatedBy)}
            </>
          )}
          {getRow(
            "Created Date",
            instance ? dayjs(instance.added).format(DATE_FORMAT) : ""
          )}
          {getRow("Created By", instance?.addedBy)}
        </>
      )}
    </Form>
  );
};
