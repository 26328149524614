import { ColumnsType } from "antd/es/table";
import { IPayersGet } from "../../../../../../../../../types/Payer";
import dayjs from "dayjs";
import {
  DATE_FORMAT,
  PAYER_STATUS,
} from "../../../../../../../../../lib/constants";
import { Link } from "react-router-dom";
import { Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useButtonHandlers } from "./useButtonHandlers";
import { useCallback, useMemo } from "react";
import { getFormattedPercentage } from "../../../../../../../../../lib/percentageFormatter";
import ROUTES from "../../../../../../../../../configs/routesConfig";

type TTableColumns = {
  reloadData: () => void;
  setId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
};

export const useGetTableColumns = ({ reloadData, setId }: TTableColumns) => {
  const { onDeleteBtnClick } = useButtonHandlers({
    reloadData,
    setId,
  });

  const handleDeleteBtnClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      onDeleteBtnClick(e.currentTarget.id);
    },
    [onDeleteBtnClick]
  );

  const handleEditBtnClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      setId(e.currentTarget.id);
    },
    [setId]
  );

  const columns: ColumnsType<IPayersGet> = useMemo(
    () => [
      {
        title: "Payer",
        key: "payer",
        dataIndex: "payerId",
        width: "24%",
        ellipsis: true,
        render: (value: string, record: IPayersGet) => {
          const { payerName } = record;

          return (
            <Link
              className="link-text-color"
              to={`${ROUTES.CUSTOMERS}/${value}`}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              {payerName}
            </Link>
          );
        },
      },
      {
        title: "Percentage",
        key: "percentage",
        dataIndex: "percentage",
        width: "8%",
        align: "right",
        render: (value: number) => getFormattedPercentage(value),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        width: "39%",
        render: (value: number) => {
          const color: string = value === 0 ? "success" : "";

          const text = PAYER_STATUS.find(
            (status) => status.value === value
          )?.label;

          return <Tag color={color}>{text}</Tag>;
        },
      },
      {
        title: "Assumed Date",
        key: "assumedDate",
        dataIndex: "assumedDate",
        width: "10%",
        render: (value: string) =>
          value ? dayjs(value).format(DATE_FORMAT) : "",
      },
      {
        title: "Sold Date",
        key: "soldDate",
        dataIndex: "soldDate",
        width: "10%",
        render: (value: string) =>
          value ? dayjs(value).format(DATE_FORMAT) : "",
      },
      {
        key: "actions",
        width: "9%",
        render: (_: any, record: IPayersGet) => {
          const { id } = record;

          return (
            <div className="ant-table-actions d-flex justify-end">
              <EditOutlined
                className="ant-table-btn"
                id={id}
                onClick={handleEditBtnClick}
              />
              <DeleteOutlined
                id={id}
                className="ant-table-btn"
                onClick={handleDeleteBtnClick}
              />
            </div>
          );
        },
      },
    ],
    [handleDeleteBtnClick, handleEditBtnClick]
  );

  return { columns };
};
