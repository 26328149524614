import { Tabs, TabsProps } from "antd";
import { useMemo, useState } from "react";
import { ImpoundForm } from "./ImpoundForm";
import { Distributions } from "./Distributions";
import { ActivityDetails } from "./ActivityDetails";
import { TFormProps } from "../../../../../../../../../lib/hooks/defaultFormSidebar/types";

type TImpoundsTabs = TFormProps & {
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  };
  activeKey: string;
  isDistributionModalOpen: boolean;
  setIsDistributionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  impoundCurrentBalance: number;
};

export const ImpoundsTabs = ({
  id,
  reloadData,
  setIsOpen,
  setId,
  parentId,
  payeesNumber,
  activeKey,
  isDistributionModalOpen,
  setIsDistributionModalOpen,
  impoundCurrentBalance,
}: TImpoundsTabs) => {
  const [balanceBeginningYear, setBalanceBeginningYear] = useState<
    number | null
  >(null);

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "details",
        label: "Details",
        children: (
          <ImpoundForm
            id={id}
            reloadData={reloadData}
            setIsOpen={setIsOpen}
            setId={setId}
            parentId={parentId}
            props={{ setBalanceBeginningYear }}
          />
        ),
      },
      {
        key: "paymentsFromImpound",
        label: "Payments From Impound",
        children: (
          <Distributions
            impoundId={id}
            payeesNumber={payeesNumber}
            setIsOpen={setIsOpen}
            setParentId={setId}
            isDistributionModalOpen={isDistributionModalOpen}
            setIsDistributionModalOpen={setIsDistributionModalOpen}
            impoundCurrentBalance={impoundCurrentBalance}
          />
        ),
      },
      {
        key: "activitydetails",
        label: "Activity Details",
        children: (
          <ActivityDetails
            impoundId={id}
            setIsOpen={setIsOpen}
            setParentId={setId}
            balanceBeginningYear={balanceBeginningYear}
          />
        ),
      },
    ],
    [
      id,
      impoundCurrentBalance,
      isDistributionModalOpen,
      parentId,
      payeesNumber,
      reloadData,
      setId,
      setIsDistributionModalOpen,
      setIsOpen,
      balanceBeginningYear,
    ]
  );

  return (
    <>
      {id !== null ? (
        <Tabs items={items} defaultActiveKey={activeKey} />
      ) : (
        <ImpoundForm
          id={id}
          reloadData={reloadData}
          setIsOpen={setIsOpen}
          setId={setId}
          parentId={parentId}
        />
      )}
    </>
  );
};
