import { Form, FormInstance } from "antd";
import { FeeOverridePart } from "../../../../../Account/components/View/Admin/Details/components/PayeeDisbursements/components/FeeOverridePart";
import { useGetInpurSearchIds } from "../../../../../Accounts/components/Filters/hooks/getInputSearchIds";
import { DisbursementsMethodPart } from "../../../../../Account/components/View/Admin/Details/components/PayeeDisbursements/components/DisbursementsMethodPart";
import { MainPart } from "../../../../../Account/components/View/Admin/Details/components/PayeeDisbursements/components/MainPart";
import { useSetInitialValues } from "../hooks/useSetInitialValues";
import { FormButtons } from "./FormButtons";
import { Loader } from "../../../../../../components/Loader";
import { useCallback } from "react";

type CreateEditFormProps = {
  record: any;
  contractId: string;
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  };
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  onCloseSideBar: (value: any, isOpen?: boolean) => void;
  saveRecord: (
    value: FormInstance,
    customerIds: any,
    cancel: () => void,
    isNew: boolean,
    index: string | null,
    customerName?: string
  ) => void;
  openDeleteModal: (id: string) => void;
  isHistoryOpened: boolean;
};

export const CreateEditForm = ({
  record,
  contractId,
  payeesNumber,
  isFormDisabled,
  isPaymentPosted,
  onCloseSideBar,
  saveRecord,
  openDeleteModal,
  isHistoryOpened,
}: CreateEditFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const {
    onCustomerFound,
    hasPayeeId,
    customerIds,
    hasAlternativePayeeId,
    customerName,
  } = useGetInpurSearchIds(true);

  const { disbursementMethodState, isLoading } = useSetInitialValues(
    record,
    form
  );

  const cancel = useCallback(
    () => onCloseSideBar(null, false),
    [onCloseSideBar]
  );

  const onSaveClicked = (isNew: boolean) =>
    saveRecord(
      form,
      customerIds,
      cancel,
      isNew,
      record.index || null,
      customerName
    );

  return (
    <>
      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        className="w-100 pt-8 px-6 mb-4 payee-disbursements-form payment-disbursements"
        disabled={isFormDisabled || isPaymentPosted}
      >
        {!isLoading ? (
          <>
            <MainPart
              form={form}
              payeesNumber={payeesNumber}
              onCustomerFound={onCustomerFound}
              hasPayeeId={hasPayeeId}
              hasAlternativePayeeId={hasAlternativePayeeId}
              isFormDisabled={isFormDisabled || isPaymentPosted}
              isPayment
              isHistoryOpened
            />
            <DisbursementsMethodPart
              form={form}
              customerIds={customerIds}
              contractId={contractId}
              disbursementMethodState={disbursementMethodState}
              isFormDisabled={isFormDisabled || isPaymentPosted}
            />
            {!isHistoryOpened && (
              <FeeOverridePart
                form={form}
                isFormDisabled={isFormDisabled || isPaymentPosted}
              />
            )}
          </>
        ) : (
          <Loader fontSize="64px" />
        )}
      </Form>
      <FormButtons
        record={record}
        isFormDisabled={isFormDisabled}
        isPaymentPosted={isPaymentPosted}
        cancel={cancel}
        onSaveClick={onSaveClicked}
        openDeleteModal={openDeleteModal}
      />
    </>
  );
};
