import { Form, FormInstance, Input, InputRef } from "antd";
import { useEffect, useRef } from "react";
import { FormButtons } from "./components/FormButtons";
import { useGetInitialValues } from "./hooks/useGetInitialValues";
import { IMPOUND_TYPE_REQUIRED, IMPOUND_TYPE_MAX } from "../../../lib/constants";
import { IImpoundsTypeExtended } from "../../../types/Impounds";

type TImpoundsForm = {
  onSaveClick: (id: string | null) => Promise<void>;
  onCloseClick: () => void;
  id: string | null;
  form: FormInstance;
  isFormDisabled: boolean;
  isEditForm?: boolean;
  data?: IImpoundsTypeExtended[];
}

export const ImpoundsForm = ({
  onSaveClick,
  onCloseClick,
  id,
  form,
  isFormDisabled,
  isEditForm = false,
  data
}: TImpoundsForm) => {
  const ref = useRef<InputRef>(null);

  const initialValues = useGetInitialValues({ id, data });

  useEffect(() => {
    ref.current && ref.current.focus();
  }, []);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      form={form}
      className="w-100 h-100 d-flex impounds-form"
      disabled={isFormDisabled}
      initialValues={initialValues}
    >
      <Form.Item
        className="w-100 h-100"
        name="name"
        rules={[
          { required: true, message: IMPOUND_TYPE_REQUIRED, validateTrigger: "onSubmit" },
          { max: 20, message: IMPOUND_TYPE_MAX, validateTrigger: "onSubmit" }
        ]}
      >
        <Input ref={ref} className="h-100" />
      </Form.Item>
      {!isEditForm && <FormButtons onSaveClick={onSaveClick} onCloseClick={onCloseClick} id={id}/>}
    </Form>
  );
}