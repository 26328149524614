import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  CHANGES_WERE_SAVED,
  DELETE_USER_SUCCESS,
  NEW_CUSTOMER_CREATED,
  NO_CUSTOMERS_FOUND,
} from "../../lib/constants";
import CustomersService from "../../services/customers";
import { RootState } from "../../store";
import { setSuccessMessage, setErrorMessage } from "../actionsCreators/messageActions";

export const deleteCustomer =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await CustomersService.deleteCustomer(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const saveCustomer =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await CustomersService.save(request);

      dispatch(setSuccessMessage(NEW_CUSTOMER_CREATED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const updateCustomer =
  (
    id: string,
    request: any
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await CustomersService.update(id, request);

      dispatch(setSuccessMessage(CHANGES_WERE_SAVED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const searchCustomerByNumber =
  (number: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await CustomersService.searchByNumber(number);

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(NO_CUSTOMERS_FOUND, true));
      return await Promise.reject(error);
    }
  };
