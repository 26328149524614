import { Row, Button, Table, MenuProps, Dropdown } from "antd";
import { useFetchData } from "./hooks/useFetchData";
import { useGetBatchTableColumns } from "./hooks/useGetBatchTableColumns";
import { TablePagination } from "../../components/TablePagination";
import { useFetchCount } from "./hooks/useFetchCount";
import { useCallback, useMemo, useState } from "react";
import { BatchModal } from "./BatchModal";
import { BatchDatePicker } from "./BatchModal/BatchDate";
import "./styles.scss";
import { ExpandableConfig } from "antd/es/table/interface";
import { useInitializeDay } from "./hooks/useInitializeDay";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ROUTES from "../../configs/routesConfig";
import { useDefualtOnRow } from "../../lib/hooks/useDefaultOnRow";
import { DownOutlined } from "@ant-design/icons";
import { PrintModal } from "./PrintModal";

export const Processing = () => {
  const navigate: NavigateFunction = useNavigate();

  const navigateToBatch = useCallback(
    (id: string) => {
      navigate(`${ROUTES.PROCESSING}/${id}`);
    },
    [navigate]
  );

  const { onRow } = useDefualtOnRow(navigateToBatch);

  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const [id, setId] = useState<string | null>(null);

  const [isOpenPrintModal, setIsOpenPrintModal] = useState<boolean>(false);

  const [printType, setPrintType] = useState<number>(0);

  const {
    total,
    request,
    isLoading: isCountLoading,
    onPageChange,
    fetchCount,
  } = useFetchCount();

  const { isLoading, fetchData, data } = useFetchData(request);

  const reloadData = useCallback((): void => {
    fetchCount();
    fetchData();
  }, [fetchCount, fetchData]);

  const { columns } = useGetBatchTableColumns({
    setIsOpenEditModal,
    setId,
    reloadData,
    navigateToBatch,
  });

  const { isLoading: isInitializeDayLoading, onInitializeDayClick } =
    useInitializeDay(reloadData);

  const onCreateClick = useCallback(() => setIsOpenEditModal(true), []);

  const tableExpandabelProp: ExpandableConfig<any> = useMemo(
    () => ({
      defaultExpandAllRows: true,
      indentSize: 0,
      expandIcon: () => <span className="d-none" />,
    }),
    []
  );

  const handleDropdownClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setIsOpenPrintModal(true);
      setPrintType(+e.currentTarget.id);
    },
    []
  );

  const items: MenuProps["items"] = useMemo(
    () => [
      { key: 1, label: <div id="1">Totals</div> },
      {
        key: 2,
        label: (
          <div id="2" onClick={handleDropdownClick}>
            Batch
          </div>
        ),
      },
      {
        key: 3,
        label: (
          <div id="3" onClick={handleDropdownClick}>
            Payments
          </div>
        ),
      },
      {
        key: 4,
        label: (
          <div id="4" onClick={handleDropdownClick}>
            Disbursements
          </div>
        ),
      },
    ],
    [handleDropdownClick]
  );

  return (
    <>
      <Row className="table-page-content w-100 px-8">
        <Row className="my-6">
          <h3 className="h h3">Processing</h3>
        </Row>
        <Row className="w-100">
          <Row className="w-100 d-flex justify-space-between">
            <BatchDatePicker request={request} />
            <div>
              <Dropdown menu={{ items }} trigger={["click"]}>
                <Button shape="round" className="d-inline-flex align-center">
                  Print Report <DownOutlined />
                </Button>
              </Dropdown>
              <Button
                shape="round"
                onClick={onInitializeDayClick}
                disabled={isInitializeDayLoading}
                className="ml-4"
              >
                Initialize Day
              </Button>
              <Button
                shape="round"
                type="primary"
                className="ml-4"
                onClick={onCreateClick}
              >
                Create Batch
              </Button>
            </div>
          </Row>
        </Row>
        <Row className="d-block table__container w-100 pt-2 processing-table">
          <Table
            key={data.length}
            dataSource={data}
            loading={isLoading}
            columns={columns}
            pagination={false}
            className="overflow-y-auto"
            rowKey="id"
            expandable={tableExpandabelProp}
            onRow={onRow}
          />
          <TablePagination
            total={total}
            isTotalLoading={isCountLoading}
            currentPage={request?.pageNumber || 1}
            pageSize={request?.pageSize || 20}
            onPageChange={onPageChange}
          />
        </Row>
      </Row>
      <BatchModal
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        id={id}
        onEditSuccess={reloadData}
        setId={setId}
      />
      <PrintModal
        isOpen={isOpenPrintModal}
        setIsOpen={setIsOpenPrintModal}
        contentType={printType}
        setContentType={setPrintType}
      />
    </>
  );
};
