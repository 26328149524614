import { useCallback, useMemo, useState } from "react";

export const useGetInpurSearchIds = (shouldSaveName = false) => {
  const [customerIds, setCustomerIds] = useState<any>({});

  const [customerName, setCustomerName] = useState<string | undefined>(undefined);

  const hasPrimaryBuyerId = useMemo(() => {
    return customerIds.primaryBuyerNumber;
  }, [customerIds]);

  const hasPrimarySellerId = useMemo(() => {
    return customerIds.primarySellerNumber;
  }, [customerIds]);

  const hasPayeeId = useMemo(() => {
    return customerIds.payeeId;
  }, [customerIds]);

  const hasAlternativePayeeId = useMemo(() => {
    return customerIds.alternativePayeeId;
  }, [customerIds]);

  const onCustomerFound = useCallback(
    (number: number | null, name: string[], customerName?: string): void => {
      setCustomerIds((prevCustomerIds: any) => {
        const value = { ...prevCustomerIds };

        value[name[0]] = number;

        return value;
      });

      shouldSaveName && setCustomerName(customerName);
    },
    [shouldSaveName]
  );

  return {
    customerIds,
    onCustomerFound,
    hasPrimaryBuyerId,
    hasPrimarySellerId,
    hasPayeeId,
    hasAlternativePayeeId,
    customerName,
  };
};
