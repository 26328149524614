import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import PaymentHistoryService from "../../services/accounts/paymentHistory";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../actionsCreators/messageActions";
import {
  DELETE_USER_SUCCESS,
  UPDATE_RECORD_SUCCESS,
  YTD_AMOUNT_RECALCULATED,
  PAYMENT_HISTORY_CREATED,
} from "../../lib/constants";

export const deletePaymentHistoryRecord =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await PaymentHistoryService.remove(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const editPaymentHistoryRecord =
  (id: string, req: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await PaymentHistoryService.edit(id, req);

      dispatch(setSuccessMessage(UPDATE_RECORD_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const recalculateYTDAmounts =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await PaymentHistoryService.recalculate(id);

      dispatch(setSuccessMessage(YTD_AMOUNT_RECALCULATED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const createPaymentHistoryRecord =
  (req: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await PaymentHistoryService.create(req);

      dispatch(setSuccessMessage(PAYMENT_HISTORY_CREATED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };