import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "./previous";
import _ from "lodash";

export const useFetchDataCount = <TReq>(
  service: any,
  req: TReq,
  doubleCheckFields = [""],
  pageSize = 20
) => {
  const [total, setTotal] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [request, setRequest] = useState({
    pageNumber: 1,
    pageSize,
    ...req,
  });

  const prevReq = usePrevious(req);

  const prevRequest = usePrevious(request);

  const onPageChange = useCallback(
    (pageNumber: number, pageSize = 20) => {
      const newRequest = { ...request, pageNumber, pageSize };

      setRequest(newRequest);
    },
    [request]
  );

  const fetchCount = useCallback(() => {
    setIsLoading(true);

    service
      .fetchCount(request)
      .then((res: number) => {
        if (
          res !== 0 &&
          request.pageNumber > Math.ceil(res / request.pageSize)
        ) {
          onPageChange(request.pageNumber - 1);
        }

        setTotal(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [onPageChange, request, service]);

  useEffect(() => {
    if (!_.isEqual(prevReq, req) && prevReq) {
      setRequest({ ...request, ...req });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [req, request]);

  useEffect(() => {
    if (
      !prevRequest ||
      !_.isEqual(
        _.pick(request, doubleCheckFields),
        _.pick(prevRequest, doubleCheckFields)
      )
    )
      fetchCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);

  return { total, isLoading, request, setRequest, onPageChange, fetchCount };
};
