import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../../store";
import { useDefaultDeleteModal } from "../../../../lib/hooks/defaultDeleteModal";
import { createTicklerType, deleteTicklerType, editTicklerType } from "../../../../acions/administration/ticklers";
import { ITicklerType } from "../../../../types/Tickler";
import { FormInstance } from "antd";

type TButtonHandlersProps = {
  reloadData: () => void;
  form?: FormInstance;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const useButtonHandlers = ({ reloadData, form, setIsOpen, setId }: TButtonHandlersProps) => {
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { openDeleteModal } = useDefaultDeleteModal(deleteTicklerType, reloadData, setIsOpen);

  const onDeleteBtnClick = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Tickler Type");
    }, [openDeleteModal]);

  const create = useCallback(
    async (entry: ITicklerType, isNew: boolean) => {
      try {
        await Promise.resolve(dispatch(createTicklerType(entry)));

        reloadData();
        setIsFormDisabled(false);
        setId(null);
        setIsOpen(isNew);
        form?.resetFields();
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, form, reloadData, setId, setIsOpen]
  );

  const edit = useCallback(
    async (entry: ITicklerType, id: string) => {
      try {
        await Promise.resolve(dispatch(editTicklerType(entry, id)));

        reloadData();
        setIsFormDisabled(false);
        setIsOpen(false);
        setId(null);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, reloadData, setId, setIsOpen]
  );

  const onSaveClick = useCallback(
    async (isNew: boolean, id: string | null) => {
      try {
        const formData = await form?.validateFields();

        setIsFormDisabled(true);

        id !== null ? edit(formData, id) : create(formData, isNew);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [create, edit, form]
  );

  const onCancelClick = useCallback(
    () => {
      setIsOpen(false);
      setId(null);
    },
    [setIsOpen, setId]
  );

  return {
    isFormDisabled,
    onDeleteBtnClick,
    onSaveClick,
    onCancelClick
  };
}