import { ColumnsType } from "antd/es/table";
import { useCallback, useMemo, MouseEvent } from "react";
import { Dropdown, MenuProps, Row } from "antd";
import {
  CHART_VALUE_COLOR,
  DISBURSEMENTS_METHOD_TYPES,
  PAYEE_TYPES,
} from "../../../../../../lib/constants";
import { useGetPayeeIcon } from "../../../../../Account/components/View/Admin/Details/components/PayeeDisbursements/hooks/useGetPayeeIcon";
import { getFormattedCurrency } from "../../../../../../lib/currencyFormatter";
import { EllipsisOutlined } from "@ant-design/icons";
import { IPayeeDisbursementsBase } from "../../../../../../types/PayeeDisbursements";
import { ReactComponent as IconPayeeRelation } from "../../../../../../images/icons/PayeeRelation.svg";

export const useTableColumns = (
  isFormDisabled: boolean,
  isPaymentPosted: boolean,
  openDeleteModal: (id: string) => void,
  onOpenSideBar: (value: any) => void
) => {
  const { getIcon } = useGetPayeeIcon(CHART_VALUE_COLOR);

  const onDelete = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      openDeleteModal(e.currentTarget.id);
    },
    [openDeleteModal]
  );

  const columns: ColumnsType<IPayeeDisbursementsBase> = useMemo(
    () => [
      {
        key: "left",
        dataIndex: "left",
        width: "50%",
        render: (_: any, record: IPayeeDisbursementsBase) => {
          const {
            type,
            payeeName,
            description,
            alternativePayeeName,
            alternativePayeeId,
          } = record;

          const payeeType: any = PAYEE_TYPES.find(
            (item) => item.value === type
          );

          const icon = getIcon(payeeType);

          const isAlternate = !!alternativePayeeId;

          return (
            <Row className="w-100">
              <Row
                className="w-100 align-center logo-cursor payee-name"
                onClick={() => onOpenSideBar(record)}
              >
                {icon}
                <p
                  className={`ml-2 my-0 text-md elipsis payee-name__container ${
                    isAlternate ? "alter" : "w-500"
                  }`}
                  title={payeeName}
                >
                  {payeeName}
                </p>
              </Row>
              {isAlternate && (
                <Row className="align-center payee-name mt-1">
                  <IconPayeeRelation fill={CHART_VALUE_COLOR} />
                  <p
                    className="ml-2 my-0 text-md w-500 elipsis payee-name__container"
                    title={alternativePayeeName || ""}
                  >
                    {alternativePayeeName || ""}
                  </p>
                </Row>
              )}

              <Row className="w-100 mt-1">
                <p
                  className="my-0 text-sm title elipsis payee-description"
                  title={payeeType.label}
                >
                  {payeeType.label}
                </p>
              </Row>

              <Row className="w-100 mt-1">
                <p
                  className="my-0 text-md elipsis payee-description"
                  title={description}
                >
                  {description}
                </p>
              </Row>
            </Row>
          );
        },
      },
      {
        align: "right",
        key: "right",
        dataIndex: "right",
        width: "50%",
        render: (_: any, record: IPayeeDisbursementsBase) => {
          const { amount, method, index, alternativePayeeId } = record;

          const isAlternate = !!alternativePayeeId;

          const methodLabel = DISBURSEMENTS_METHOD_TYPES.find(
            (item) => item.value === method
          )?.label;

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <div onClick={() => onOpenSideBar(record)}>Edit Payee</div>
              ),
            },
            {
              key: "2",
              label: (
                <div id={index || ""} onClick={onDelete}>
                  Remove Payee
                </div>
              ),
            },
          ];

          const amountValue = +(amount || 0);

          return (
            <Row className="block">
              <Row className="w-100 justify-end">
                <p
                  className={`my-0 text-md w-500 elipsis payee-name ${
                    amountValue < 0 && "text-color-red"
                  }`}
                  title={getFormattedCurrency(amountValue)}
                >
                  {getFormattedCurrency(amountValue)}
                </p>
              </Row>
              {isAlternate && <Row className="empty-row mt-1" />}
              <Row className="w-100 justify-end mt-1">
                <p className="my-0 text-sm title elipsis" title={methodLabel}>
                  {methodLabel}
                </p>
              </Row>
              <Row className="w-100 justify-end mt-1" style={{ height: 20 }}>
                {!isPaymentPosted && (
                  <Dropdown
                    trigger={["click"]}
                    menu={{ items }}
                    disabled={isFormDisabled}
                  >
                    <EllipsisOutlined
                      className="ant-table-btn"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Dropdown>
                )}
              </Row>
            </Row>
          );
        },
      },
    ],
    [getIcon, onOpenSideBar, onDelete, isPaymentPosted, isFormDisabled]
  );

  return { columns };
};
