import { Col, Row } from "antd";
import { ReactNode, useEffect, useMemo } from "react";
import { Loader } from "../../../../../components/Loader";
import { UsersService } from "../../../../../services/users";
import { getFormatted } from "../../../../../lib/PhoneFormatter";
import { EditProfile } from "./EditProfile";
import { useGetFullName } from "../../../../../lib/hooks/getFullName";
import { PortalAccess } from "./PortalAccess";

type ProfileTabProps = {
  userId: string | null;
  user: any;
  setUser: (value: any) => void;
  isEdit: boolean;
  roles: any[];
  isSaveClicked: boolean;
  setIsSaveClicked: (value: boolean) => void;
  reloadData: () => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  setHeaderTitleOnLoad: (value: any) => void;
};

export const ProfileTab = ({
  userId,
  user,
  setUser,
  isEdit,
  roles,
  setIsSaveClicked,
  isSaveClicked,
  reloadData,
  isLoading,
  setIsLoading,
  setHeaderTitleOnLoad,
}: ProfileTabProps) => {
  const { getFullName } = useGetFullName();

  const fullName = useMemo(() => getFullName(user), [getFullName, user]);

  const formattedPhone = useMemo(
    () => getFormatted(user?.phone).slice(2),
    [user]
  );

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      UsersService.fetchUser(userId)
        .then((res) => {
          setHeaderTitleOnLoad(res);
          setUser(res);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, setUser, setIsLoading]);

  const getRow = (
    label: string,
    value: string,
    isTitle: boolean
  ): ReactNode => {
    return (
      <Row className="align-center mb-4">
        <Col span={8}>
          <span className="text-md title">{label}</span>
        </Col>
        <Col span={16}>
          <div className="elipsis" title={isTitle ? value : ""}>
            {value}
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <>
          {!isEdit ? (
            <div className="py-8 px-6">
              {getRow("Name", fullName, true)}
              {getRow("Username", user?.userName || "-", true)}
              {getRow("Email", user?.email, true)}
              {getRow("Phone", formattedPhone, false)}
              {getRow(
                "Email notice",
                user?.isPaymentNoticeEmailed ? "Subscribed" : "Not Subscribed",
                false
              )}
              {getRow("Role", user?.role, false)}
              <PortalAccess parentId={userId} parentStatus={user?.status} />
            </div>
          ) : (
            <EditProfile
              user={user}
              roles={roles}
              isSaveClicked={isSaveClicked}
              setIsSaveClicked={setIsSaveClicked}
              reloadData={reloadData}
            />
          )}
        </>
      )}
    </>
  );
};
