import { Button, Card, Row, Table } from "antd";
import { useFetchData } from "../../../../../../../../lib/hooks/useDefaultFetchData";
import { useFetchDataCount } from "../../../../../../../../lib/hooks/useDefaultFetchDataCount";
import PayersService from "../../../../../../../../services/accounts/payers";
import { IPayersGet } from "../../../../../../../../types/Payer";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { Loader } from "../../../../../../../../components/Loader";
import { SimpleTablePagination } from "../../../../../../../../components/SimpleTablePagination";
import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { useOpenSidebar } from "./hooks/useOpenSidebar";
import "./styles.scss";
import { useDefualtOnRow } from "../../../../../../../../lib/hooks/useDefaultOnRow";

type TPayerProps = {
  contractId: string;
  primaryBuyerNumber: number;
};

type TRequest = {
  pageNumber: number;
  pageSize: number;
  contractId: string;
};

export const Payers = ({ contractId, primaryBuyerNumber }: TPayerProps) => {
  const [id, setId] = useState<string | null | undefined>(undefined);

  const {
    total,
    isLoading: isCountLoading,
    onPageChange,
    request,
    fetchCount,
  } = useFetchDataCount<{ contractId: string }>(
    PayersService,
    { contractId },
    ["contractId"],
    10
  );

  const { data, isLoading, fetchData } = useFetchData<TRequest, IPayersGet>(
    PayersService,
    request
  );

  const reloadData = useCallback(() => {
    fetchCount();
    fetchData();
  }, [fetchCount, fetchData]);

  const { formWrapper } = useOpenSidebar({
    id,
    setId,
    reloadData,
    primaryBuyerNumber,
    parentId: contractId,
  });

  const { columns } = useGetTableColumns({ reloadData, setId });

  const rowClassName = useCallback(
    (record: IPayersGet): string => (record.status === 1 ? "gray-row" : ""),
    []
  );

  const onAddClick = useCallback(() => setId(null), [setId]);

  const { onRow } = useDefualtOnRow(setId);

  return (
    <Row className="w-100 mb-4">
      <Card className="w-100">
        <Row className="w-100">
          <Row className="w-100 d-flex justify-space-between align-center">
            <h5 className="h h5 ant-table-title">Payers</h5>
            <Button shape="round" onClick={onAddClick}>
              Add new
            </Button>
          </Row>
          <p className="ma-0 text-md title">
            The information is used for tax 1098 allocation.
          </p>
        </Row>
        {isLoading ? (
          <Loader fontSize="64px" />
        ) : (
          data.length !== 0 && (
            <Row className="mt-6 d-block">
              <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                rowKey="id"
                className="w-100 payer-table account-details-table"
                rowClassName={rowClassName}
                onRow={onRow}
              />
              {total > 10 && (
                <SimpleTablePagination
                  total={total}
                  isLoading={isCountLoading}
                  pageNumber={request.pageNumber}
                  pageSize={request.pageSize}
                  onPageChange={onPageChange}
                />
              )}
            </Row>
          )
        )}
      </Card>
      {id !== undefined && createPortal(formWrapper, document.body)}
    </Row>
  );
};
