export const NAME_RULE = /^([^!@#$%^_&()~:=+"<>?/.,*]*)$/;

export const TAX_ID_RULE = /\b\d{4}\b/g;

export const ACCOUNT_NUMBER_RULE = /^\d+$/;

export const ACCOUNT_NUMBER_6_DIGITS_RULE = /\b\d{6}\b/g;

export const EMAIL_RULE = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const PASSWORD_RULE =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{10,30}$/;

export const PHONE_RULE = /^[()]\d{3}[)] \d{3}-\d{4}$/;

export const PHONE_MASK_RULE = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const SECURITY_CODE_RULE = /\d-\d-\d-\d-\d-\d$/;

export const SECURITY_CODE_MASK_RULE = [
  /\d/,
  "-",
  /\d/,
  "-",
  /\d/,
  "-",
  /\d/,
  "-",
  /\d/,
  "-",
  /\d/,
];

export const SSN_INPUT_MASK_RULE = [
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const SSN_INPUT_RULE = /\d{3}-\d{2}-\d{4}$/;

export const EIN_INPUT_MASK_RULE = [
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const EIN_INPUT_RULE = /\d{2}-\d{7}$/;

export const ZIP_INPUT_RULE = /^\d{5}(?:[-\s]\d{4})?$/;

export const FLOAT_NUMBER_INPUT_RULE = /^[+]?([.]\d+|\d+([.]\d+)?)$/;

export const DAY_OF_MONTH_RULE = /\b([1-9]|[1-2][0-9]|3[0-1])\b/;
