import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../actionsCreators/messageActions";
import {
  DELETE_USER_SUCCESS,
  PAYMENT_CREATED,
  PAYMENT_POSTED,
  PAYMENT_UNPOSTED,
  PAYMENT_UPDATED,
} from "../../lib/constants";
import BatchPaymentsService from "../../services/processing/payments";

export const deletePayment =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await BatchPaymentsService.remove(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const savePaymentAsDraft =
  (
    request: any,
    showMessage: boolean
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await BatchPaymentsService.saveAsDraft(request);

      showMessage && dispatch(setSuccessMessage(PAYMENT_CREATED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const updatePayment =
  (
    id: string,
    request: any,
    showMessage: boolean
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await BatchPaymentsService.update(id, request);

      showMessage && dispatch(setSuccessMessage(PAYMENT_UPDATED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const togglePayment =
  (
    id: string,
    isPost: boolean
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await BatchPaymentsService.toggle(id, isPost);

      dispatch(setSuccessMessage(isPost ? PAYMENT_POSTED : PAYMENT_UNPOSTED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
