import { useCallback, useState } from "react";
import { RootState, useAppDispatch } from "../../store";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

export const useSearchByNumber = (
  onSuccess: (res: string) => void,
  searchAction: (
    number: number
  ) => ThunkAction<void, RootState, unknown, Action>
) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const search = useCallback(
    (number: number, onFail: () => void) => {
      setIsLoading(true);

      Promise.resolve(dispatch(searchAction(number)))
        .then((res: any) => {
          onSuccess(res);
          setIsLoading(false);
        })
        .catch(() => {
          onFail();
          setIsLoading(false);
        });
    },
    [dispatch, onSuccess, searchAction]
  );

  return { search, isLoading };
};
