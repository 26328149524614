import { Button, Row, Card, Table } from "antd";
import { useCallback, useState } from "react";
import { ITicklerTypeExtended } from "../../../types/Tickler";
import TicklersTypeService from "../../../services/administration/ticklers";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { TicklersForm } from "./TicklersForm";
import { createPortal } from "react-dom";
import { useFetchDataCount } from "../../../lib/hooks/useDefaultFetchDataCount";
import { useDefaultFormSidebar } from "../../../lib/hooks/defaultFormSidebar";
import { SimpleTablePagination } from "../../../components/SimpleTablePagination";
import { useFetchData } from "../../../lib/hooks/useDefaultFetchData";
import { TRequest } from "./types";
import { useDefualtOnRow } from "../../../lib/hooks/useDefaultOnRow";

export const Ticklers = () => {
  const [id, setId] = useState<string | null>(null);

  const {
    request,
    isLoading: isCountLoading,
    total,
    onPageChange,
    fetchCount,
  } = useFetchDataCount<{}>(TicklersTypeService, {});

  const { isLoading, data, fetchData } = useFetchData<
    TRequest,
    ITicklerTypeExtended
  >(TicklersTypeService, request);

  const reloadData = useCallback(() => {
    fetchData();
    fetchCount();
  }, [fetchData, fetchCount]);

  const { formWrapper, isOpen, setIsOpen } = useDefaultFormSidebar({
    id,
    setId,
    reloadData,
    title: "Tickler Type",
    FormElem: TicklersForm,
  });

  const openSideBar = useCallback(
    (id: string): void => {
      setIsOpen(true);
      setId(id);
    },
    [setId, setIsOpen]
  );

  const { columns } = useGetTableColumns({
    setIsOpen,
    setId,
    reloadData,
    openSideBar,
  });

  const { onRow } = useDefualtOnRow(openSideBar);

  return (
    <Row className="w-100">
      <Row className="d-block w-100 pr-8 pl-2 py-6 ticklers-type-wrapper">
        <Card className="w-100">
          <Row className="w-100 d-flex justify-space-between">
            <h5 className="h h5 ant-table-title">Tickler Types</h5>
            <Button shape="round" onClick={() => setIsOpen(true)}>
              Add New
            </Button>
          </Row>
          <Row className="w-100 mt-6">
            <Table
              className="w-100"
              dataSource={data}
              columns={columns}
              pagination={false}
              rowKey="id"
              loading={isLoading}
              onRow={onRow}
            />
            {total > 20 && (
              <SimpleTablePagination
                isLoading={isCountLoading}
                total={total}
                pageNumber={request.pageNumber}
                onPageChange={onPageChange}
                pageSize={request.pageSize}
              />
            )}
          </Row>
        </Card>
      </Row>
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
