import { ColumnsType } from "antd/es/table";
import { useCallback, useMemo } from "react";
import { ITicklerExtended } from "../../../../../../../types/Tickler";
import { DATE_FORMAT, ORANGE_1 } from "../../../../../../../lib/constants";
import { EditOutlined, DeleteOutlined, BellFilled } from "@ant-design/icons";
import { useButtonHandlers } from "./useButtonHandlers";
import dayjs from "dayjs";

type TGetColumnsProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  reloadData: () => void;
  openTickler: (id: string) => void;
};

export const useGetTableColumns = ({
  setId,
  setIsOpen,
  reloadData,
  openTickler,
}: TGetColumnsProps) => {
  const { onDeleteClick } = useButtonHandlers({ reloadData, setIsOpen, setId });

  const onEdit = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      openTickler(e.currentTarget.id);
    },
    [openTickler]
  );

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      onDeleteClick(e.currentTarget.id);
    },
    [onDeleteClick]
  );

  const columns: ColumnsType<ITicklerExtended> = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: "8%",
        render: (value: string) => dayjs(value).format(DATE_FORMAT),
      },
      {
        title: "Type",
        dataIndex: "ticklerTypeName",
        key: "ticklerTypeName",
        width: "17%",
        ellipsis: true,
        render: (value: string, record: ITicklerExtended) => {
          return (
            <div className="w-100 d-flex justify-space-between">
              <div className="elipsis" title={value}>
                {value}
              </div>
              {record.paymentAlert && (
                <BellFilled style={{ color: ORANGE_1 }} />
              )}
            </div>
          );
        },
      },
      {
        title: "Text",
        dataIndex: "text",
        key: "text",
        width: "48%",
      },
      {
        title: "Last Updated",
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        width: "19%",
        render: (value: string, record: ITicklerExtended) => {
          const { lastUpdatedBy } = record;

          const displayValue = `${dayjs(value).format(
            DATE_FORMAT
          )} by ${lastUpdatedBy}`;

          return (
            <div className="elipsis" title={displayValue}>
              {displayValue}
            </div>
          );
        },
      },
      {
        key: "actions",
        width: "8%",
        render: (_: any, record: ITicklerExtended) => {
          const { id } = record;

          return (
            <div className="w-100 h-100 d-flex justify-end ant-table-actions">
              <EditOutlined
                id={id}
                className="ant-table-btn logo-cursor"
                onClick={onEdit}
              />
              <DeleteOutlined
                id={id}
                className="ant-table-btn mr-1 logo-cursor"
                onClick={onDelete}
              />
            </div>
          );
        },
      },
    ],
    [onDelete, onEdit]
  );

  return { columns };
};
