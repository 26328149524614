import { Row, Pagination, Skeleton } from "antd";

type SimpleTablePaginationProps = {
  isLoading: boolean;
  total: number;
  pageNumber: number;
  onPageChange?: (value: number) => void;
  pageSize?: number
};

export const SimpleTablePagination = ({
  isLoading,
  total,
  pageNumber,
  onPageChange,
  pageSize = 10
}: SimpleTablePaginationProps) => {
  return (
    <Row className="mt-4 justify-end w-100">
      <>
        {!isLoading ? (
          <Pagination
            current={pageNumber}
            total={total}
            size="small"
            onChange={onPageChange}
            defaultPageSize={pageSize}
          />
        ) : (
          <Skeleton
            active
            round
            paragraph={{ rows: 1, width: "325px" }}
            title={false}
          />
        )}
      </>
    </Row>
  );
};
