import { useCallback } from "react";
import { useDefaultDeleteModal } from "../../../../../../../lib/hooks/defaultDeleteModal";
import { deletePayment } from "../../../../../../../acions/processing/payments";

export const useButtonHandlers = (reloadData: () => void) => {
  const { openDeleteModal } = useDefaultDeleteModal(deletePayment, reloadData);

  const onDeleteBtnClick = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Payment");
    },
    [openDeleteModal]
  );

  return {
    onDeleteBtnClick,
  };
};
