import { Button, Row } from "antd";

type EditPanelProps = {
  isLoading: boolean;
  onCancel: () => void;
  onUpdate: () => void;
};

export const EditPanel = ({
  isLoading,
  onCancel,
  onUpdate,
}: EditPanelProps) => {
  return (
    <Row className="position-absolute edit-panel-container py-2 px-8 justify-end">
      <Button
        className="mr-4"
        shape="round"
        type="primary"
        disabled={isLoading}
        onClick={onUpdate}
      >
        Save Changes
      </Button>
      <Button shape="round" disabled={isLoading} onClick={onCancel}>
        Cancel
      </Button>
    </Row>
  );
};
