import { LOGIN_FAIL, LOGIN_SUCCESS } from "../types";

export const loginFail = () => ({
  type: LOGIN_FAIL,
});

export const loginSuccess = (payload: any) => {
  return {
    type: LOGIN_SUCCESS,
    payload
  }
};