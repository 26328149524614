import { Button, Checkbox, DatePicker, Form, FormInstance, Select } from "antd";
import { BATCH_TYPES, DATE_FORMAT } from "../../../lib/constants";
import { useState } from "react";
import { useGetInitialValues } from "./hooks/useGetInitialVales";
import { Loader } from "../../../components/Loader";
import { useButtonHandlers } from "../hooks/useButtonHandlers";

type TBatchForm = {
  id: string | null;
  onEditSuccess: () => void;
  setIsOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  setId?: React.Dispatch<React.SetStateAction<string | null>>;
  onClose: () => void;
};

export const BatchForm = ({
  id,
  onEditSuccess,
  setIsOpenEditModal,
  onClose,
}: TBatchForm) => {
  const [form]: FormInstance[] = Form.useForm();

  const [isWarehouse, setIsWarehouse] = useState<boolean>(false);

  const onChange = (value: number) => setIsWarehouse(value === 3);

  const { isLoading } = useGetInitialValues(id, form, setIsWarehouse);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCancelBtnClick: _,
    onSaveBtnClick,
    isDisabled,
  } = useButtonHandlers({
    setIsOpenEditModal,
    form,
    onEditSuccess,
  });

  return (
    <>
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          disabled={isDisabled}
        >
          <Form.Item
            name="type"
            label="Batch Type"
            className="mt-6 mb-4 batch-form"
          >
            <Select options={BATCH_TYPES} onChange={onChange} />
          </Form.Item>
          <Form.Item name="date" label="Batch Date" className="mb-4">
            <DatePicker
              format={DATE_FORMAT}
              disabled
              className="batch-form-date-input"
            />
          </Form.Item>
          {!isWarehouse && (
            <>
              <Form.Item name="paymentsDate" label="Payments Date">
                <DatePicker format={DATE_FORMAT} />
              </Form.Item>
              <Form.Item
                name="isCreatorPaymentOnly"
                valuePropName="checked"
                className="mb-4"
              >
                <Checkbox>Batch creator only can make payments</Checkbox>
              </Form.Item>
            </>
          )}
          <div className="pt-6 pb-1 w-100 d-flex justify-end">
            <Button
              shape="round"
              type="primary"
              onClick={() => onSaveBtnClick(id)}
            >
              {id !== null ? "Save" : "Create"}
            </Button>
            <Button shape="round" className="ml-2" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};
