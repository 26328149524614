import { FormInstance } from "rc-field-form";
import { useState } from "react";
import { createPortalAccess } from "../../../../../../../acions/customers/portalAccesses";
import { useAppDispatch } from "../../../../../../../store";

export const useCreatePortalAccess = (
  form: FormInstance,
  customerId: string | null | undefined,
  setIsLoadingEmail: (value: boolean) => void,
  setShowMessage: (value: boolean) => void,
  closeSideBar: () => void,
  resetData: () => void,
  reloadData: () => void
) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendRequest = (request: any, shouldResetForm: boolean): void => {
    setIsLoading(true);
    setIsLoadingEmail(true);
    Promise.resolve(dispatch(createPortalAccess(request)))
      .then(() => {
        setIsLoading(false);
        setIsLoadingEmail(false);
        reloadData();
        shouldResetForm ? resetData() : closeSideBar();
      })
      .catch(() => {
        setIsLoading(false);
        setIsLoadingEmail(false);
      });
  };

  const saveNew = (shouldResetForm: boolean, isPrimary: boolean): void => {
    form
      .validateFields()
      .then((res: any) => {
        const request = { ...res, customerId, isPrimary };

        const { newUser } = res;
        if (newUser) {
          const { phone } = newUser;
          request.newUser.phone = phone
            ? `1${phone.replace(/[\s-()]/g, "")}`
            : null;
        }

        sendRequest(request, shouldResetForm);
      })
      .catch(() => setShowMessage(false));
  };

  const selectExisting = (
    shouldResetForm: boolean,
    id: string,
    isPrimary: boolean
  ): void => {
    id
      ? sendRequest({ customerId, userId: id, isPrimary }, shouldResetForm)
      : form.validateFields();
  };

  return { saveNew, selectExisting, isLoading };
};
