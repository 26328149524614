import { App } from "antd";
import { useCallback, useState } from "react";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState, useAppDispatch } from "../../store";
import { useGetDeleteDialogProps } from "./getDeleteDialogProps";

export const useDefaultDeleteModal = (
  deleteFunc: (id: string) => ThunkAction<void, RootState, unknown, Action>,
  reloadData: () => void,
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { modal } = App.useApp();

  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [options] = useGetDeleteDialogProps(isDeleting);

  const openDeleteModal = useCallback(
    (id: string, title: string): void => {
      modal.confirm({
        ...options,
        title,
        content: (
          <div className="text-md mb-1">Are you sure you want to delete?</div>
        ),
        onOk: async () => {
          setIsDeleting(true);

          try {
            await Promise.resolve(dispatch(deleteFunc(id)));
            setIsDeleting(false);
            reloadData();
            if (setIsOpen) {
              setIsOpen(false);
            }
          } catch {
            return setIsDeleting(false);
          }
        },
      });
    },
    [modal, options, dispatch, deleteFunc, reloadData, setIsOpen]
  );

  return { openDeleteModal };
};
