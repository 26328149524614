import { ThunkAction } from "redux-thunk";
import {
  DELETE_USER_SUCCESS,
  CREATE_FEE_MSG,
  UPDATE_RECORD_SUCCESS,
} from "../../lib/constants";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import { IFeeType } from "../../types/Fees";
import FeesService from "../../services/administration/fees";
import {
  setSuccessMessage,
  setErrorMessage,
} from "../actionsCreators/messageActions";
import ContractFeesService from "../../services/accounts/fees";

export const deleteFeeType =
  (req: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const service = req.contractId ? ContractFeesService : FeesService;

      const res = await service.deleteFee(req);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const createFeeType =
  (entry: IFeeType): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const service = entry.contractId ? ContractFeesService : FeesService;
      const res = await service.create(entry);

      dispatch(setSuccessMessage(CREATE_FEE_MSG));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const editFeeType =
  (
    entry: IFeeType,
    id: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const service = entry.contractId ? ContractFeesService : FeesService;

      const res = await service.edit(entry, id);

      dispatch(setSuccessMessage(UPDATE_RECORD_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
