import { ColumnsType } from "antd/es/table";
import { ITicklerTypeExtended } from "../../../../types/Tickler";
import { useMemo } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useButtonHandlers } from "./useButtonHandlers";

type TTableColumnsProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  reloadData: () => void;
  openSideBar: (id: string) => void;
};

export const useGetTableColumns = ({
  setIsOpen,
  setId,
  reloadData,
  openSideBar,
}: TTableColumnsProps) => {
  const { onDeleteBtnClick } = useButtonHandlers({
    reloadData,
    setIsOpen,
    setId,
  });

  const columns: ColumnsType<ITicklerTypeExtended> = useMemo(
    () => [
      {
        title: "Tickler Type Name",
        key: "type",
        dataIndex: "typeName",
        width: "30%",
      },
      {
        title: "Default Text",
        key: "text",
        dataIndex: "defaultText",
        width: "61%",
        render: (value: string) => {
          return <span className="ticklers-type-table-text">{value}</span>;
        },
      },
      {
        key: "actions",
        width: "9%",
        render: (_: any, record: ITicklerTypeExtended) => {
          const { id } = record;

          return (
            <div
              className="w-100 h-100 d-flex justify-end ant-table-actions"
              onClick={(e) => e.stopPropagation()}
            >
              <EditOutlined
                className="ant-table-btn logo-cursor"
                onClick={() => openSideBar(id)}
              />
              <DeleteOutlined
                className="ant-table-btn logo-cursor mr-1"
                onClick={() => onDeleteBtnClick(id)}
              />
            </div>
          );
        },
      },
    ],
    [onDeleteBtnClick, openSideBar]
  );

  return { columns };
};
