import { Button, Dropdown, MenuProps, Row } from "antd";
import { useCallback, useMemo, useState } from "react";
import { UserOutlined, DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ROUTES from "../../../../configs/routesConfig";
import { TermsAndConditionsModal } from "../../../TermsAndConditionsModal";
import "./styles.scss";

type MobileMenuProps = {
  onLogoutClicked: () => void;
};

export const MobileMenu = ({ onLogoutClicked }: MobileMenuProps) => {
  const [termsTabId, setTermsTabId] = useState<undefined | "1" | "2">(
    undefined
  );

  const onCloseTerms = useCallback(() => setTermsTabId(undefined), []);

  const items: MenuProps["items"] = useMemo(() => {
    return [
      {
        key: "1",
        label: (
          <Row className="w-100 align-center">
            <Link to={ROUTES.PROFILE} className="w-100">
              <UserOutlined className="mr-1" />
              Profile
            </Link>
          </Row>
        ),
      },
      {
        type: "divider",
      },
      {
        key: "2",
        label: (
          <Row className="w-100 align-center" onClick={onLogoutClicked}>
            <LogoutOutlined className="mr-1" />
            Log out
          </Row>
        ),
      },
      {
        key: "3",
        label: (
          <Row className="align-center terms-items">
            <Button
              type="link"
              className="pl-2 pr-0 py-0"
              onClick={() => setTermsTabId("1")}
            >
              Terms of Use
            </Button>
            <div className="elipse ml-2" />
            <Button
              type="link"
              className="px-2 py-0"
              onClick={() => setTermsTabId("2")}
            >
              Privacy Policy
            </Button>
          </Row>
        ),
      },
    ];
  }, [onLogoutClicked]);
  return (
    <>
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        className="header-button px-4 mobile-btn align-center"
        overlayClassName="mobile-menu"
      >
        <Row>
          <UserOutlined className="mr-1" />
          <DownOutlined />
        </Row>
      </Dropdown>
      <TermsAndConditionsModal
        termsTabId={termsTabId}
        setTermsTabId={setTermsTabId}
        onClose={onCloseTerms}
      />
    </>
  );
};
