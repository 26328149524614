import { useCallback, useEffect, useState } from "react";
import { AddressForm } from "./AddressForm";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { FormInstance } from "antd";

type ToggleAlternateAddressProps = {
  hasAlternateAddress: boolean;
  statesList: any[];
  selectedItemId: string | null | undefined;
  form: FormInstance;
};

export const ToggleAlternateAddress = ({
  hasAlternateAddress,
  statesList,
  selectedItemId,
  form,
}: ToggleAlternateAddressProps) => {
  const [isAddAlternateAddress, setIsAddAternateAddress] =
    useState<boolean>(false);

  useEffect(() => {
    setIsAddAternateAddress(hasAlternateAddress);
  }, [hasAlternateAddress]);

  const toggleAlternate = useCallback((): void => {
    setIsAddAternateAddress(!isAddAlternateAddress);
  }, [isAddAlternateAddress]);

  return !isAddAlternateAddress ? (
    <p className="my-0 text-sm expand-btn" onClick={toggleAlternate}>
      + Alternate Address
    </p>
  ) : (
    <>
      <div
        className="d-flex align-center toggle-label mb-4"
        onClick={toggleAlternate}
      >
        <DownOutlined className="mr-1" />
        Alternate Address
        <CloseOutlined className="ml-2" />
      </div>
      <AddressForm
        propName="alternativeAddress"
        statesList={statesList}
        selectedItemId={selectedItemId}
        form={form}
      />
    </>
  );
};
