import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useMemo } from "react";

type TFormButtons = {
  onSaveClick: (id: string | null) => Promise<void>;
  onCloseClick: () => void;
  id: string | null;
  isEditForm?: boolean;
};

export const FormButtons = ({
  onSaveClick,
  onCloseClick,
  id,
  isEditForm = false,
}: TFormButtons) => {
  const className: string = useMemo(
    () =>
      `h-100 d-flex justify-end ant-table-actions ${
        !isEditForm ? "pr-2 py-2 pl-4" : ""
      }`,
    [isEditForm]
  );

  return (
    <div className={className} onClick={(e) => e.stopPropagation()}>
      <CheckOutlined
        className="ant-table-btn ant-table-btn-impounds logo-cursor"
        onClick={() => onSaveClick(id)}
      />
      <CloseOutlined
        onClick={() => onCloseClick()}
        className="ant-table-btn ant-table-btn-impounds logo-cursor mr-1"
      />
    </div>
  );
};
