import { Button, Dropdown, MenuProps } from "antd";
import { useMemo } from "react";
import { DownOutlined } from "@ant-design/icons";

type CreatePaymentDropdownProps = {
  openCreatePayment: (isCreatePaymentAutoOpen: boolean) => void;
};

export const CreatePaymentDropdown = ({
  openCreatePayment,
}: CreatePaymentDropdownProps) => {
  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: (
          <div onClick={() => openCreatePayment(true)}>
            Create Automatically
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div onClick={() => openCreatePayment(false)}>Create Manually</div>
        ),
      },
    ],
    [openCreatePayment]
  );

  return (
    <Dropdown className="ml-4" menu={{ items }} trigger={["click"]}>
      <Button shape="round" type="primary">
        Create Payment
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};
