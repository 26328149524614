import { useCallback } from "react";
import {
  IPayoffQuoteFormData,
  IPayoffQuotePost,
} from "../../../../../../../../../types/PayoffQute";
import dayjs from "dayjs";
import { TCustomersData } from "../../../types";
import { getFormatted } from "../../../../../../../../../lib/PhoneFormatter";

export const useFormatData = () => {
  const filterObject = useCallback((obj: any, word: string) => {
    let result: Record<string, number> = {};

    const filteredKeys = Object.keys(obj).filter((key) => key.includes(word));

    filteredKeys.forEach((key: keyof typeof result) => {
      result[key] = obj[key as keyof typeof obj];
    }, {});

    return result;
  }, []);

  const getFormattedIncomingData = useCallback(
    (data: IPayoffQuotePost) => {
      const formattedData = {
        ...data,
        date: dayjs(data.date),
        buyer: {
          ...filterObject(data, "buyer"),
          principal: data.principal,
          currentLateCharges: data.currentLateCharges,
          interest: data.interest,
          unpaidInterest: data.unpaidInterest,
          unpaidLateCharges: data.unpaidLateCharges,
          amount: data.item.amount,
          buyerFee: data.itemFee.buyerFees,
        },
        seller: {
          ...filterObject(data, "seller"),
          sellerFee: data.itemFee.sellerFees,
        },
        subtrahend: {
          impoundRefund: data.impoundRefund,
          discount: data.discount,
        },
        receiverType: data.receiver.companyName !== null ? 2 : 1,
        receiver: {
          ...data.receiver,
          phone: getFormatted(data.receiver.phone || "").slice(2),
        }
      };

      return formattedData;
    },
    [filterObject]
  );

  const getFormattedCustomersData = useCallback((data: TCustomersData) => {
    const formattedData = {
      receiver: {
        ...data.customerIndividualData,
        ...data.customerCompanyData,
        ...data.primaryAddress,
        email: data.primaryEmail,
        phone: getFormatted(data.phone || "").slice(2),
      },
    };

    return formattedData;
  }, []);

  const getFormattedOutcomingData = useCallback(
    (data: IPayoffQuoteFormData, contractId: string | undefined) => {
      const formattedData: IPayoffQuotePost = {
        ...data,
        date: dayjs(data.date),
        ...data.buyer,
        ...data.seller,
        ...data.subtrahend,
        item: {
          ...data.item,
          amount: data.buyer.amount,
        },
        itemFee: {
          ...data.itemFee,
          buyerFees: data.buyer.buyerFees,
          sellerFees: data.seller.sellerFees,
        },
        contractId,
        receiver: {
          ...data.receiver,
          phone: data.receiver.phone ? `1${data.receiver.phone.replace(/[\s-()]/g, "")}` : null
        }
      };

      return formattedData;
    },
    []
  );

  return {
    getFormattedIncomingData,
    getFormattedCustomersData,
    getFormattedOutcomingData,
  };
};
