import { ColumnsType } from "antd/es/table";
import { useCallback, useMemo, MouseEvent } from "react";
import { IBatchPayment } from "../../../../../../../types/BatchPayment";
import dayjs from "dayjs";
import {
  DATE_FORMAT,
  PAYMENT_METHODS,
} from "../../../../../../../lib/constants";
import { Dropdown, MenuProps } from "antd";
import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";
import { EllipsisOutlined } from "@ant-design/icons";
import { useButtonHandlers } from "./useButtonHandlers";
import { useGetStatusLabel } from "./useGetStatusLabel";
import { useTogglePost } from "./useTogglePost";
import { Link } from "react-router-dom";

type TTableColumns = {
  reloadData: () => void;
  reloadBatch: () => void;
  getPaymentRoute: (id: string) => string;
  navigateToPeyment: (id: string) => void;
};

export const useTableColumns = ({
  reloadData,
  reloadBatch,
  getPaymentRoute,
  navigateToPeyment,
}: TTableColumns) => {
  const { onDeleteBtnClick } = useButtonHandlers(reloadData);

  const { getStatusLabel } = useGetStatusLabel();

  const { onToggle } = useTogglePost(reloadBatch);

  const onEdit = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      navigateToPeyment(e.currentTarget.id);
    },
    [navigateToPeyment]
  );

  const onDelete = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      onDeleteBtnClick(e.currentTarget.id);
    },
    [onDeleteBtnClick]
  );

  const onToggleStatus = useCallback(
    (e: MouseEvent<HTMLDivElement>, isPosted: boolean) => {
      e.stopPropagation();
      onToggle(e.currentTarget.id, isPosted);
    },
    [onToggle]
  );

  const columns: ColumnsType<IBatchPayment> = useMemo(
    () => [
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
        width: "11%",
        sorter: true,
        render: (value: string) => dayjs(value).format(DATE_FORMAT),
      },
      {
        title: "Payment#",
        key: "paymentNumber",
        dataIndex: "paymentNumber",
        width: "14%",
        sorter: true,
        render: (value: number, record: IBatchPayment) => {
          const { id } = record;

          return (
            <Link className="cell-elipsis pa-0" to={getPaymentRoute(id)}>
              <span className="link-btn">{value}</span>
            </Link>
          );
        },
      },
      {
        title: "Account#",
        key: "contractNumber",
        dataIndex: "contractNumber",
        width: "14%",
        sorter: true,
      },
      {
        title: "Payer",
        key: "payerName",
        dataIndex: "payerName",
        width: "24%",
        sorter: true,
        ellipsis: true,
        render: (value: string) => (
          <div className="elipsis" title={value}>
            {value}
          </div>
        ),
      },
      {
        title: "Amount",
        key: "amount",
        dataIndex: "amount",
        width: "12%",
        align: "right",
        render: (value: number) => getFormattedCurrency(value),
      },
      {
        title: "Method",
        key: "method",
        dataIndex: "method",
        width: "11%",
        sorter: true,
        render: (value: number) =>
          PAYMENT_METHODS.find((method) => method.value === value)?.label,
      },
      {
        title: "Status",
        key: "isPosted",
        dataIndex: "isPosted",
        width: "7%",
        sorter: true,
        render: (value: boolean) => getStatusLabel(value),
      },
      {
        key: "actions",
        width: "7%",
        render: (_: any, record: IBatchPayment) => {
          const { id, isPosted } = record;

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <div id={id} onClick={onEdit}>
                  Edit
                </div>
              ),
            },
            {
              key: "2",
              label: (
                <div id={id} onClick={(e) => onToggleStatus(e, isPosted)}>
                  {isPosted ? "Unpost" : "Post"}
                </div>
              ),
            },
            {
              key: "3",
              label: (
                <div id={id} onClick={onDelete}>
                  Delete
                </div>
              ),
            },
          ];

          return (
            <div className="ant-table-actions payments-table-actions">
              <Dropdown menu={{ items }} trigger={["click"]}>
                <EllipsisOutlined
                  className="ant-table-btn"
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [getPaymentRoute, getStatusLabel, onEdit, onDelete, onToggleStatus]
  );

  return { columns };
};
