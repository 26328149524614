import "./styles.scss";
import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ReactNode } from "react";

type SideBarContainerProps = {
  title: string | ReactNode;
  setShowSideBar: (value: boolean) => void;
  children: ReactNode;
  setCurrentId?: React.Dispatch<React.SetStateAction<string | null>>;
  customClass?: string;
};

export const SideBarContainer = ({
  setShowSideBar,
  title,
  children,
  setCurrentId,
  customClass,
}: SideBarContainerProps) => {
  const onCloseClicked = () => {
    setShowSideBar(false);

    setCurrentId && setCurrentId(null);
  };

  return (
    <div className="sidebar-wrapper">
      <div className="mask logo-cursor" onClick={onCloseClicked}></div>
      <div className={`sidebar__container ${customClass || ""}`}>
        <div className="px-6 py-6 d-flex align-center sidebar-header">
          <Button
            className="mr-3 close-button"
            shape="circle"
            icon={<CloseOutlined />}
            type="text"
            onClick={onCloseClicked}
            disabled={false}
          />
          {title}
        </div>
        <div className="sidebar-content">{children}</div>
      </div>
    </div>
  );
};
