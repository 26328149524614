import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  ACCOUNT_CREATED,
  CHANGES_WERE_SAVED,
  DELETE_USER_SUCCESS,
  NO_ACCOUNTS_FOUND,
} from "../../lib/constants";
import { RootState } from "../../store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../actionsCreators/messageActions";
import ContractsService from "../../services/accounts/contracts";

export const createContract =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await ContractsService.save(request);

      dispatch(setSuccessMessage(ACCOUNT_CREATED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const searchByNumber =
  (number: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await ContractsService.searchByNumber(number);

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(NO_ACCOUNTS_FOUND, true));
      return await Promise.reject(error);
    }
  };

export const deleteContract =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await ContractsService.remove(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const updateContract =
  (
    id: string,
    request: any
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await ContractsService.update(id, request);

      dispatch(setSuccessMessage(CHANGES_WERE_SAVED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };
