import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import { IImpoundsType } from "../../../../types/Impounds";
import { useAppDispatch } from "../../../../store";
import { deleteImpoundsType, createImpoundType, editImpoundsType } from "../../../../acions/administration/impounds";
import { useDefaultDeleteModal } from "../../../../lib/hooks/defaultDeleteModal";
import { setErrorMessage } from "../../../../acions/actionsCreators/messageActions";

type TButtonHandlersProps = {
  reloadData: () => void;
  form?: FormInstance;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEntryId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const useButtonHandlers = ({ reloadData, setIsOpen, setEntryId, form }: TButtonHandlersProps) => {
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { openDeleteModal } = useDefaultDeleteModal(deleteImpoundsType, reloadData);

  const onDeleteClick = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Impound Type");
    }, [openDeleteModal]);

  const create = useCallback(
    async (entry: IImpoundsType) => {
      try {
        await Promise.resolve(dispatch(createImpoundType(entry)));

        reloadData();
        setIsFormDisabled(false);
        setIsOpen(false);
        form?.resetFields();
      } catch (error) {
        setIsFormDisabled(false);
      }
    }, [dispatch, form, reloadData, setIsOpen]);

  const edit = useCallback(
    async (entry: IImpoundsType, id: string) => {
      try {
        await Promise.resolve(dispatch(editImpoundsType(id, entry)));

        reloadData();
        setIsFormDisabled(false);
        setEntryId(null);
      } catch (error) {
        setIsFormDisabled(false);
      }
    }, [dispatch, reloadData, setEntryId]);

  const onSaveClick = useCallback(
    async (id: string | null) => {
      try {
        const formData = await form?.validateFields();
  
        setIsFormDisabled(true);
  
        id !== null ? edit(formData, id) : create(formData);
        
      } catch (error: any) {
        setIsFormDisabled(false);
        dispatch(setErrorMessage(error.errorFields[0].errors[0], true));
      }
    }, [create, dispatch, edit, form]);

  const onCloseClick = useCallback(() => {
    setIsOpen(false);
    setEntryId(null);
  }, [setEntryId, setIsOpen]);

  const onAddTypeClick = useCallback(() => {
    setIsOpen(true);
    setEntryId(null);
  }, [setEntryId, setIsOpen]);

  return {
    onDeleteClick,
    onSaveClick,
    onCloseClick,
    isFormDisabled,
    onAddTypeClick
  };
}