import { ColumnsType } from "antd/es/table";
import { IPayoffQuotesGet } from "../../../../../../../types/PayoffQute";
import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import {
  DATE_FORMAT,
  QUOTE_RECEIVER,
} from "../../../../../../../lib/constants";
import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";
import { EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { useButtonHandlers } from "./useButtonHandlers";

type TTableColumnsProps = {
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reloadData: () => void;
  openPayoff: (id: string) => void;
};

export const useGetTableColumns = ({
  setId,
  setIsOpen,
  reloadData,
  openPayoff,
}: TTableColumnsProps) => {
  const onEditClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      openPayoff(e.currentTarget.id);
    },
    [openPayoff]
  );

  const { onDeleteBtnClick } = useButtonHandlers({
    setIsOpen,
    setId,
    reloadData,
  });

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      onDeleteBtnClick(e.currentTarget.id);
    },
    [onDeleteBtnClick]
  );

  const columns: ColumnsType<IPayoffQuotesGet> = useMemo(
    () => [
      {
        title: "Payoff Date",
        dataIndex: "date",
        key: "date",
        width: "17%",
        render: (value: string) =>
          value ? dayjs(value).format(DATE_FORMAT) : "-",
      },
      {
        title: "Buyer Amount",
        dataIndex: "buyerAmount",
        key: "buyerAmount",
        width: "17%",
        align: "right",
        render: (value: number) => getFormattedCurrency(value),
      },
      {
        key: "emptiness",
        width: "17%",
      },
      {
        title: "Quote Receiver",
        dataIndex: "type",
        key: "type",
        width: "13%",
        render: (value: number) =>
          QUOTE_RECEIVER.find((item) => item.value === value)?.label,
      },
      {
        title: "Last Updated",
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        width: "26%",
        align: "right",
        render: (value: string) =>
          value ? dayjs(value).format(DATE_FORMAT) : "-",
      },
      {
        key: "actions",
        width: "10%",
        render: (_: any, record: IPayoffQuotesGet) => {
          const { id } = record;

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <div id={id} onClick={onDelete}>
                  Delete Quote
                </div>
              ),
            },
          ];

          return (
            <div className="ant-table-actions d-flex justify-end">
              <EditOutlined
                className="ant-table-btn"
                id={id}
                onClick={onEditClick}
              />
              <Dropdown menu={{ items }} trigger={["click"]} className="mr-3">
                <EllipsisOutlined
                  className="ant-table-btn"
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [onEditClick, onDelete]
  );

  return { columns };
};
