import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  CHANGES_WERE_SAVED,
  DELETE_USER_SUCCESS,
  UPDATE_RECORD_SUCCESS,
} from "../../lib/constants";
import CustomerPortalAccessesService from "../../services/customers/portalAccesses";
import { RootState } from "../../store";
import { setSuccessMessage, setErrorMessage } from "../actionsCreators/messageActions";

export const removePortalAccess =
  (id: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await CustomerPortalAccessesService.removeItem(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const updatePortalAccess =
  (
    id: number,
    request: any
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await CustomerPortalAccessesService.update(id, request);

      dispatch(setSuccessMessage(UPDATE_RECORD_SUCCESS));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const createPortalAccess =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await CustomerPortalAccessesService.create(request);

      dispatch(setSuccessMessage(CHANGES_WERE_SAVED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };
