import { Row } from "antd";
import { PAYMENT_RESTRICTIONS } from "../../../../lib/constants";
import { useMemo } from "react";

type PaymentRestrictionsRowProps = {
  paymentRestrictions: number | undefined;
};

export const PaymentRestrictionsRow = ({
  paymentRestrictions,
}: PaymentRestrictionsRowProps) => {
  const valueObj = useMemo(() => {
    return PAYMENT_RESTRICTIONS.find(
      (item: any) => item.value === paymentRestrictions
    );
  }, [paymentRestrictions]);

  return (
    <Row className="d-block">
      <Row className="mb-4">
        <p className="my-0 text-md title">PAYMENT RESTRICTIONS</p>
      </Row>
      {valueObj && (
        <Row className="align-center mb-8">
          <span
            className={`mark ${valueObj.value === 0 ? "success" : "alert"}`}
          ></span>
          <p className="my-0 pl-2 text-md elipsis">{valueObj.label}</p>
        </Row>
      )}
    </Row>
  );
};
