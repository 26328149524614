import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import { setErrorMessage, setSuccessMessage } from "../actionsCreators/messageActions";
import {
  DELETE_USER_SUCCESS,
  UPDATE_RECORD_SUCCESS,
  TICKLERS_TYPE_CREATED,
} from "../../lib/constants";
import TicklersTypeService from "../../services/administration/ticklers";
import { ITicklerType } from "../../types/Tickler";

export const deleteTicklerType =
(id: string): ThunkAction<void, RootState, unknown, AnyAction> => 
async (dispatch) => {
  try {
    const res = await TicklersTypeService.deleteTicklersType(id);

    dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

    return await Promise.resolve(res);
  } catch (error: any) {
    dispatch(setErrorMessage(error.response.data.title, true));

    return await Promise.reject(error);
  }
};

export const createTicklerType = 
(entry: ITicklerType): ThunkAction<void, RootState, unknown, AnyAction> =>
async (dispatch) => {
  try {
    const res = await TicklersTypeService.createTicklersType(entry);

    dispatch(setSuccessMessage(TICKLERS_TYPE_CREATED));

    return await Promise.resolve(res);
  } catch (error: any) {
    dispatch(setErrorMessage(error.response.data.title, true));

    return await Promise.reject(error);
  }
};

export const editTicklerType = 
(entry: ITicklerType, id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
async (dispatch) => {
  try {
    const res = await TicklersTypeService.editTicklersType(entry, id);

    dispatch(setSuccessMessage(UPDATE_RECORD_SUCCESS));

    return await Promise.resolve(res);
  } catch (error: any) {
    dispatch(setErrorMessage(error.response.data.title, true));

    return await Promise.reject(error);
  }
};
