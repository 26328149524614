import { useMemo } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";

export const useWarnPopupProps = (isLoading: boolean) => {
  const warnPopupProps: any = useMemo(() => {
    return {
      icon: <ExclamationCircleFilled />,
      okText: "Cancel",
      okType: "primary",
      okButtonProps: {
        shape: "round",
        disabled: isLoading,
      },
      cancelButtonProps: {
        shape: "round",
        disabled: isLoading,
      },
    };
  }, [isLoading]);

  return { warnPopupProps };
};
