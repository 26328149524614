import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useSearchByNumber } from "../../../../lib/hooks/searchByNumber";
import { SearchInput } from "../../../../components/SearchInput";
import { searchCustomerByNumber } from "../../../../acions/customers";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../store";
import { setLocation } from "../../../../acions/actionsCreators/nextLocation";
import ROUTES from "../../../../configs/routesConfig";

type SearchHeaderProps = {
  title: string;
  hasChanges: boolean;
  backLocation: any;
  reduceBackStep: () => void;
};

export const SearchHeader = ({
  title,
  hasChanges,
  backLocation,
  reduceBackStep,
}: SearchHeaderProps) => {
  const navigate: NavigateFunction = useNavigate();

  const dispatch = useAppDispatch();

  const [isSearch, setIsSearch] = useState<boolean>(false);

  const toggleIsSearch = useCallback(() => setIsSearch(!isSearch), [isSearch]);

  const onSearchSuccess = useCallback(
    (value: any) => {
      if (value && value.id) {
        navigate(`${ROUTES.CUSTOMERS}/${value.id}`);
        reduceBackStep();
        toggleIsSearch();
      }
    },
    [navigate, reduceBackStep, toggleIsSearch]
  );

  const { search, isLoading } = useSearchByNumber(
    onSearchSuccess,
    searchCustomerByNumber
  );

  const setNextLocation = useCallback((): void => {
    hasChanges && dispatch(setLocation(backLocation));

    navigate(backLocation);
  }, [dispatch, hasChanges, navigate, backLocation]);

  const initialNumber: string = useMemo(() => title.split(":")[0], [title]);

  return (
    <div className="d-flex align-center">
      <Button
        shape="circle"
        icon={<ArrowLeftOutlined />}
        size="large"
        className="back-btn mr-4"
        onClick={setNextLocation}
      />
      <SearchInput
        title={title}
        isLoading={isLoading}
        isSearch={isSearch}
        search={search}
        setIsSearch={setIsSearch}
        toggleIsSearch={toggleIsSearch}
        initialValue={initialNumber}
      />
    </div>
  );
};
