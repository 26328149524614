import { Col, Row, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

type FirstStepErrorProps = {
  onBackClicked: () => void;
};

export const FirstStepError = ({ onBackClicked }: FirstStepErrorProps) => {
  return (
    <Row className="mt-16 justify-center">
      <Col className="forgot-acc-form mx-4">
        <Row className="w-100">
          <Col className="w-100 err-header justify-space-between">
            <Button
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size="large"
              className="back-btn"
              onClick={onBackClicked}
            />

            <h1 className="header mb-2 mt-0 text-center">
              There is an issue with your account
            </h1>
            <div />
          </Col>
          <Row className="w-100 justify-center d-flex">
            <Col>
              <div className="sub-title__container mb-10">
                <p className="d-flex justify-center my-0">
                  Please contact Sunwest Escrow
                  <a
                    href="mailto:info@sunwestescrow.com"
                    className="useful-link item pl-1 underline"
                  >
                    support
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};
