import { FormInstance } from "antd";
import { useEffect, useState } from "react";
import _ from "lodash";

export const useDefineAmountState = (form: FormInstance, value: number) => {
  const [isBuyerDisabled, setIsBuyerDisabled] = useState<boolean>(false);

  const [isSellerDisabled, setIsSellerDisabled] = useState<boolean>(true);

  const calctTaxAndFee = (value: number): void => {
    const { sellerFee, sellerTax, buyerFee, buyerTax } = form.getFieldsValue();

    const feeSum: number = _.round(+sellerFee + +buyerFee, 2);

    const taxSum: number = _.round(+sellerTax + +buyerTax, 2);

    if (value === 0) {
      form.setFieldValue("buyerFee", feeSum.toFixed(2));
      form.setFieldValue("buyerTax", taxSum.toFixed(2));
      form.setFieldValue("sellerFee", "0.00");
      form.setFieldValue("sellerTax", "0.00");
    }

    if (value === 1) {
      form.setFieldValue("buyerFee", "0.00");
      form.setFieldValue("buyerTax", "0.00");
      form.setFieldValue("sellerFee", feeSum.toFixed(2));
      form.setFieldValue("sellerTax", taxSum.toFixed(2));
    }

    if (value === 2) {
      const halfFeeSum: string = _.ceil(feeSum / 2, 2).toFixed(2);

      const halfTaxSum: string = _.ceil(taxSum / 2, 2).toFixed(2);

      form.setFieldValue("buyerFee", halfFeeSum);
      form.setFieldValue("buyerTax", halfTaxSum);
      form.setFieldValue("sellerFee", halfFeeSum);
      form.setFieldValue("sellerTax", halfTaxSum);
    }
  };

  useEffect(() => {
    switch (value) {
      case 0:
        setIsSellerDisabled(true);
        setIsBuyerDisabled(false);
        break;
      case 1:
        setIsSellerDisabled(false);
        setIsBuyerDisabled(true);
        break;
      case 2:
        setIsSellerDisabled(false);
        setIsBuyerDisabled(false);
        break;
      default:
        break;
    }
  }, [form, value]);

  return { isSellerDisabled, isBuyerDisabled, calctTaxAndFee };
};
