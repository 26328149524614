export const useGetFullName = () => {
  const getFullName = (record: any): string => {
    if (record) {
      const { firstName, middleName, lastName } = record;

      return `${firstName} ${middleName || ""} ${lastName}`;
    } else {
      return "";
    }
  };

  return { getFullName };
};
