import { FormInstance, Row } from "antd";
import { useGetDisbursementsDate } from "./hooks/useGetDisbursementsDate";
import { useOpenSideBar } from "./hooks/useOpenSidebar";
import { createPortal } from "react-dom";
import { useActions } from "./hooks/useActions";
import { DisbursementsTable } from "./components/DisbursementsTable";
import { useGetFormattedValues } from "./hooks/useGetFormattedValues";
import { IPayeeDisbursementsBase } from "../../../../../types/PayeeDisbursements";

type DisbursementsProps = {
  instance: any;
  form: FormInstance;
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  reset: (value: IPayeeDisbursementsBase[]) => Promise<0 | 1>;
  isHistoryEdited: boolean;
  isHistoryOpened: boolean;
};

export const Disbursements = ({
  instance,
  form,
  isFormDisabled,
  isPaymentPosted,
  reset,
  isHistoryEdited,
  isHistoryOpened,
}: DisbursementsProps) => {
  const { payeesNumber, contractId, disbursements } =
    useGetFormattedValues(instance);

  const { openDeleteModal, saveRecord } = useActions(disbursements, reset);

  const { formWrapper, isOpen, toggleSideBarSideBar } = useOpenSideBar(
    contractId,
    payeesNumber,
    isFormDisabled,
    isPaymentPosted,
    saveRecord,
    openDeleteModal,
    isHistoryOpened,
  );

  const { dateValue } = useGetDisbursementsDate(form);

  return (
    <Row className="d-block">
      <Row className="align-center justify-space-between w-100">
        <p className="my-0 text-md card-header w-500">
          Payee Disbursements ({disbursements.length})
        </p>
        <p className="my-0 text-sm">{dateValue}</p>
      </Row>
      <Row>
        {disbursements.length > 0 ? (
          <DisbursementsTable
            disbursements={disbursements}
            openDeleteModal={openDeleteModal}
            onOpenSideBar={toggleSideBarSideBar}
            isFormDisabled={isFormDisabled}
            isPaymentPosted={isPaymentPosted}
          />
        ) : (
          <p className="my-4 text-sm title">No Payee Added</p>
        )}
      </Row>
      {isHistoryEdited && (
        <p
          className="ant-btn-link text-sm my-0 logo-cursor"
          onClick={() => toggleSideBarSideBar({})}
        >
          + New Payee
        </p>
      )}
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
