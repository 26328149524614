import qs from "qs";
import { API_URL } from "../../../configs/apiUrl";
import authAxios, { authHeader } from "../../data";
import { IImpoundActivity } from "../../../types/ImpoundActivity";

const fetchAll = async (request: any) => {
  const response = await authAxios.get(API_URL + "ImpoundActivities", {
    params: request,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (request: { impoundId: string }) => {
  const response = await authAxios.get(API_URL + "ImpoundActivities/Count", {
    params: request,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const fetch = async (id: string) => {
  const response = await authAxios.get(API_URL + `ImpoundActivities/${id}`, {
    headers: authHeader(),
  });

  return response.data;
};

const update = async (id: string, request: IImpoundActivity) => {
  const response = await authAxios.put(
    `${API_URL}ImpoundActivities/${id}`,
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const remove = async (id: string) => {
  const response = await authAxios.delete(API_URL + "ImpoundActivities/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const calculateBalances = async (id: string) => {
  const response = await authAxios.put(
    API_URL + "ImpoundActivities/" + id + "/calculateToLast",
    {},
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const updateImpoundWithBalance = async (id: string) => {
  const response = await authAxios.put(
    API_URL + "ImpoundActivities/" + id + "/updateImpoundBalance",
    {},
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const updateImpoundWithYTD = async (id: string) => {
  const response = await authAxios.put(
    API_URL + "ImpoundActivities/" + id + "/updateImpoundYTD",
    {},
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const ImpoundActivitiesService = {
  fetchAll,
  fetchCount,
  remove,
  fetch,
  update,
  calculateBalances,
  updateImpoundWithBalance,
  updateImpoundWithYTD,
};

export default ImpoundActivitiesService;
