import { Form, FormInstance, Row, DatePicker, Checkbox, Select } from "antd";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { Loader } from "../../../../../../components/Loader";
import { useGetInitialValues } from "./hooks/useGetInitialValues";
import { DATE_FORMAT } from "../../../../../../lib/constants";
import { useButtonHandlers } from "./hooks/useButtonHandlers";
import { FormButtons } from "../../../../../../components/DefaultFormButtons";

type TTicklerFormProps = {
  id: string | null;
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  parentId?: string;
};

export const TicklerForm = ({
  id,
  reloadData,
  setIsOpen,
  setId,
  parentId,
}: TTicklerFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(!!id);

  const { options, types, initialValues, isTickleTypesLoading } =
    useGetInitialValues({
      setIsLoading,
      id,
    });

  const { isDisabled, onCancelClick, onDeleteClick, onSaveClick } =
    useButtonHandlers({ reloadData, setIsOpen, setId, form, parentId });

  useEffect(() => {
    if (!id) {
      form.setFieldsValue(initialValues);
    }
  }, [id, form, initialValues]);

  const onChangeType = (e: any) => {
    const choosenType = types?.filter((type) => type.id === e);

    if (choosenType !== undefined && choosenType.length > 0) {
      form.setFieldValue("text", choosenType[0].defaultText);
    }
  };

  return (
    <Row className="ticklers-form h-100 d-flex position-relative flex-column">
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <>
          <Form
            form={form}
            className="pa-6"
            layout="vertical"
            requiredMark={false}
            initialValues={initialValues}
            disabled={isDisabled}
          >
            <Form.Item
              label="Date"
              name="date"
              className="mb-4 w-100"
              rules={[
                { required: true, validateTrigger: "onSubmit", type: "date" },
              ]}
            >
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>
            <Form.Item label="Type" name="ticklerTypeId" className="mb-4 w-100">
              <Select
                options={options}
                disabled={isTickleTypesLoading || isDisabled}
                onChange={onChangeType}
              />
            </Form.Item>
            <Form.Item label="Text" name="text" className="mb-4 w-100">
              <TextArea rows={2} />
            </Form.Item>
            <Form.Item
              name="paymentAlert"
              className="mb-4 w-100"
              valuePropName="checked"
            >
              <Checkbox>Payment Alert</Checkbox>
            </Form.Item>
          </Form>
          <FormButtons
            currentId={id}
            onCancelClick={onCancelClick}
            onDeleteClick={onDeleteClick}
            onSaveClick={onSaveClick}
            isFormDisabled={isDisabled}
          />
        </>
      )}
    </Row>
  );
};
