import { Row, Table } from "antd";
import { useCallback } from "react";
import { useAccountsTableColumns } from "./hooks/accountsTableColumns";
import { TablePagination } from "../../components/TablePagination";
import { useFetchData } from "./hooks/fetchData";
import { useFetchDataCount } from "./hooks/fetchDataCount";
import { Filters } from "./components/Filters";
import { useIsOther } from "./hooks/isOther";
import { NavigateFunction, useNavigate } from "react-router-dom";
import "./styles.scss";
import ROUTES from "../../configs/routesConfig";
import { useDefualtOnRow } from "../../lib/hooks/useDefaultOnRow";

export const Accounts = () => {
  const navigate: NavigateFunction = useNavigate();

  const { isOther } = useIsOther();

  const {
    total,
    isLoading: isTotalLoading,
    request,
    fetchDataCount,
    setSearchParams,
  } = useFetchDataCount(isOther);

  const { data, isLoading, fetchData } = useFetchData(request);

  const reloadData = useCallback((): void => {
    fetchData();
    fetchDataCount();
  }, [fetchData, fetchDataCount]);

  const navigateToAccount = useCallback(
    (id: string) => {
      navigate(`${ROUTES.ACCOUNTS}/${id}`);
    },
    [navigate]
  );

  const { onRow } = useDefualtOnRow(navigateToAccount);

  const { columns, onSortChange } = useAccountsTableColumns(
    reloadData,
    navigateToAccount
  );

  const onPageChange = (pageNumber: number, pageSize: number) => {
    const newRequest = { ...request, pageNumber, pageSize };

    setSearchParams(newRequest);
  };

  const onSorterChange = (_pagination: any, _filter: any, sorter: any) => {
    onSortChange(sorter, request);
  };

  return (
    <Row className="accounts table-page-content">
      <Filters request={request} isLoading={isLoading} isOther={isOther} />
      <Row className="d-block table__container width-100">
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          pagination={false}
          className="overflow-y-auto"
          rowKey={"id"}
          onChange={onSorterChange}
          showSorterTooltip={false}
          onRow={onRow}
        />
        <TablePagination
          isTotalLoading={isTotalLoading}
          total={total}
          currentPage={request.pageNumber}
          pageSize={request.pageSize}
          onPageChange={onPageChange}
        />
      </Row>
    </Row>
  );
};
