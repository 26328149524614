import { Form, FormInstance, Input, Row } from "antd";
import { FloatNumberInput } from "../../../../../../../../components/FloatNumberInput";
import { useMemo } from "react";
import { useGetItemTitleRules } from "./hooks/useGetItemTitleRules";

type TFeesProps = {
  form: FormInstance;
  isFormDisabled: boolean;
};

export const Fees = ({ form, isFormDisabled }: TFeesProps) => {
  const FEES_INPUTS_DATA = useMemo(
    () => [
      {
        label: "Payment Fee",
        name: [
          ["buyer", "buyerPaymentFee"],
          ["seller", "sellerPaymentFee"],
        ],
      },
      {
        label: "Closing Fee",
        name: [
          ["buyer", "buyerClosingFee"],
          ["seller", "sellerClosingFee"],
        ],
      },
      {
        label: "Reconveyance Fee",
        name: [
          ["buyer", "buyerReconveyanceFee"],
          ["seller", "sellerReconveyanceFee"],
        ],
      },
      {
        label: "Recording Fee",
        name: [
          ["buyer", "buyerRecordingFee"],
          ["seller", "sellerRecordingFee"],
        ],
      },
      {
        label: null,
        name: [
          ["buyer", "buyerFees"],
          ["seller", "sellerFees"],
        ],
      },
    ],
    []
  );

  const { rules } = useGetItemTitleRules();

  return (
    <Row className="w-100 d-flex flex-column">
      <Row className="w-100 d-flex justify-end mb-4">
        <p className="text-md w-500 ma-0 pr-12">Buyer Fees</p>
        <p className="text-md w-500 ma-0">Seller Fees</p>
      </Row>
      <Row>
        {FEES_INPUTS_DATA.map((item) => {
          return (
            <Row
              className="payoff-form-fee-layout mb-2"
              key={item.label || "customFee"}
            >
              {!!item.label ? (
                <span>{item.label}</span>
              ) : (
                <Form.Item
                  className="ma-0 w-100"
                  name={["itemFee", "title"]}
                  rules={rules}
                >
                  <Input placeholder="Fee Title" />
                </Form.Item>
              )}
              <FloatNumberInput
                name={item.name[0]}
                label=""
                form={form}
                className="ma-0 w-100"
                disabled={isFormDisabled}
              />
              <FloatNumberInput
                name={item.name[1]}
                label=""
                form={form}
                className="ma-0 w-100"
                disabled={isFormDisabled}
              />
            </Row>
          );
        })}
      </Row>
    </Row>
  );
};
