import { useEffect } from "react";
import {
  NavigateFunction,
  Outlet,
  useLocation,
  useNavigate,
  Location,
} from "react-router-dom";
import AuthService from "../../services/auth";
import ROUTES from "../../configs/routesConfig";

export const RedirectHome = () => {
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  useEffect(() => {
    if (location.pathname === ROUTES.HOME) {
      navigate(AuthService.isLoggedIn() ? ROUTES.DASHBOARD : ROUTES.LOGIN);
    }
  }, [location, navigate]);

  return <Outlet />;
};
