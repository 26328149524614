import { Button, Row, Space } from "antd";
import { useCustomerHeader } from "../hooks/useCustomerHeader";
import { ReactNode, useMemo } from "react";
import { LoadingOutlined } from "@ant-design/icons";

type HeaderProps = {
  initialData: any;
  isCreate: boolean;
  isFormDisabled: boolean;
  hasChanges: boolean;
  backLocation: any;
  onCloseView: () => void;
  onSaveClicked: (value: boolean) => void;
  reduceBackStep: () => void;
};

export const Header = ({
  initialData,
  isCreate,
  isFormDisabled,
  hasChanges,
  backLocation,
  onCloseView,
  onSaveClicked,
  reduceBackStep,
}: HeaderProps) => {
  const { header } = useCustomerHeader(
    initialData,
    isCreate,
    hasChanges,
    backLocation,
    reduceBackStep
  );

  const extraButtonsHeader = useMemo((): ReactNode | undefined => {
    return isCreate ? (
      <Space>
        <Button
          type="primary"
          shape="round"
          onClick={() => onSaveClicked(false)}
          disabled={isFormDisabled}
        >
          Save
          {isFormDisabled && <LoadingOutlined />}
        </Button>
        <Button
          shape="round"
          disabled={isFormDisabled}
          onClick={() => onSaveClicked(true)}
        >
          Save & New
          {isFormDisabled && <LoadingOutlined />}
        </Button>

        <Button shape="round" onClick={onCloseView} disabled={isFormDisabled}>
          Cancel
        </Button>
      </Space>
    ) : undefined;
  }, [isFormDisabled, onCloseView, onSaveClicked, isCreate]);

  return (
    <Row className="header-container aling-center justify-space-between px-8 py-4 w-100">
      {header}
      {extraButtonsHeader}
    </Row>
  );
};
