import { Col, Row } from "antd";
import { SingleSelect } from "../../../../../components/SingleSelect";
import { PAYMENT_TYPE_OPTIONS } from "../../../../../lib/constants";
import { useFetchYears } from "./hooks/useFetchYears";
import { useCallback } from "react";

type FiltersProps = {
  isDataLoading: boolean;
  request: any;
  currentYear: string;
  setRequest: (value: any) => void;
};

export const Filters = ({
  isDataLoading,
  request,
  currentYear,
  setRequest,
}: FiltersProps) => {
  const { years, isLoading } = useFetchYears();

  const onYearChanged = useCallback(
    (value: number) => {
      setRequest({ ...request, pageNumber: 1, year: value });
    },
    [request, setRequest]
  );

  const onTypeChange = useCallback(
    (value: number) => {
      if (value === 0) {
        const newRequest = { ...request };
        delete newRequest.type;

        setRequest({ ...newRequest, pageNumber: 1 });
      } else {
        setRequest({ ...request, pageNumber: 1, type: value });
      }
    },
    [request, setRequest]
  );

  return (
    <Row>
      <Col className="mr-4">
        <SingleSelect
          options={years}
          label="Year"
          loading={isDataLoading || isLoading}
          value={request.year || currentYear}
          onChange={onYearChanged}
        />
      </Col>
      <Col>
        <SingleSelect
          options={PAYMENT_TYPE_OPTIONS}
          label="Type"
          loading={isDataLoading}
          value={request.type || PAYMENT_TYPE_OPTIONS[0]}
          onChange={onTypeChange}
        />
      </Col>
    </Row>
  );
};
