import { FormInstance, Row } from "antd";
import { AccountInfo } from "./AccountInfo";
import { useInitPayment } from "../hooks/useInitPayment";
import { useFetchTicklersData } from "../hooks/useFetchTicklesData";
import { useWarningPopups } from "../hooks/useWarningPopups";
import { BeforeReadModal } from "./BeforeReadModal";
import { useCallback, useEffect, useMemo } from "react";
import { PaymentForm } from "./PaymentForm";
import { useCalculate } from "./PaymentForm/hooks/useCalculate";
import { useSetPayment } from "./PaymentForm/hooks/useSetPayment";
import { EditPanel } from "./EditPanel";

type BodyProps = {
  paymentId: string;
  batchId: string;
  isFormBlured: boolean;
  instance: any;
  form: FormInstance;
  isLoading: boolean;
  manualUpdated: {
    principalAmount: boolean;
    interestAmount: boolean;
    disbursements: boolean;
  };
  setIsFormBlured: React.Dispatch<React.SetStateAction<boolean>>;
  setInstance: React.Dispatch<React.SetStateAction<any>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setManualUpdated: React.Dispatch<
    React.SetStateAction<{
      principalAmount: boolean;
      interestAmount: boolean;
      disbursements: boolean;
    }>
  >;
  update: (id: string) => Promise<boolean>;
};

export const Body = ({
  paymentId,
  batchId,
  isFormBlured,
  instance,
  form,
  isLoading,
  manualUpdated,
  setIsFormBlured,
  setInstance,
  setIsLoading,
  setManualUpdated,
  update,
}: BodyProps) => {
  const {
    init,
    isLoading: isInitialLoading,
    initialData,
    accountInfo,
    setInitialData,
  } = useInitPayment(paymentId, batchId, setManualUpdated);

  const {
    data: ticklers,
    isLoading: isTicklersLoading,
    paymentAlertTicklers,
  } = useFetchTicklersData(accountInfo);

  const { closed } = useWarningPopups(initialData, accountInfo);

  useEffect(() => {
    closed && paymentAlertTicklers.length === 0 && setIsFormBlured(false);
  }, [closed, paymentAlertTicklers, setIsFormBlured]);

  const { setForm, resetInitial } = useSetPayment(
    form,
    setInstance,
    setInitialData
  );

  const { calculate, hasChanges, setHasChanges } = useCalculate(
    form,
    instance,
    isFormBlured,
    setForm,
    setIsLoading,
    manualUpdated,
    setManualUpdated
  );

  const setInitialForm = useCallback(() => {
    setForm(initialData?.payment, isFormBlured);
    setHasChanges(false);
  }, [initialData, isFormBlured, setForm, setHasChanges]);

  const onCancelChanges = useCallback(() => {
    setInitialForm();
    setManualUpdated(initialData.payment.manualUpdated);
  }, [initialData, setInitialForm, setManualUpdated]);

  useEffect(() => setInitialForm(), [setInitialForm]);

  const isCreate: boolean = useMemo(() => paymentId === "create", [paymentId]);

  const showEditPanel: boolean = useMemo(
    () => !isCreate && hasChanges,
    [hasChanges, isCreate]
  );

  const isPaymentPosted: boolean = useMemo(() => {
    return !!(instance && instance.isPosted);
  }, [instance]);

  const onUpdate = useCallback(async () => {
    if (paymentId) {
      const res = await update(paymentId);

      if (res) {
        setHasChanges(false);
        resetInitial(instance);
      }
    }
  }, [instance, paymentId, resetInitial, setHasChanges, update]);

  return (
    <Row className="body-container px-8 pb-4">
      {showEditPanel && (
        <EditPanel
          isLoading={isLoading}
          onCancel={onCancelChanges}
          onUpdate={onUpdate}
        />
      )}
      <Row>
        <AccountInfo
          isCreate={isCreate}
          data={initialData}
          init={init}
          isLoading={isInitialLoading}
          isFormBlured={isFormBlured}
          ticklers={ticklers}
          isTicklersLoading={isTicklersLoading}
        />
        <PaymentForm
          isLoading={isLoading}
          form={form}
          isFormBlured={isFormBlured}
          instance={instance}
          calculate={calculate}
          isPaymentPosted={isPaymentPosted}
          manualUpdated={manualUpdated}
          setManualUpdated={setManualUpdated}
          setHasChanges={setHasChanges}
        />
        {isCreate && (
          <BeforeReadModal
            closed={closed}
            ticklers={paymentAlertTicklers}
            setIsFormBlured={setIsFormBlured}
            data={initialData}
          />
        )}
      </Row>
    </Row>
  );
};
