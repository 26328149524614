import { Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { useExportExcel } from "../../hooks/exportExcel";
import { useState } from "react";
import { CreateAccountModal } from "./CreateAccountModal";

type AdminActionsProps = {
  request: any;
  isOther: boolean;
};

export const AdminActions = ({ request, isOther }: AdminActionsProps) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  const { onExportExcelButtonClicked, isLoading: isExportLoading } =
    useExportExcel(request);

  const openCreateModal = (): void => setIsCreateModalOpen(true);

  return (
    <div>
      <Button
        shape="round"
        onClick={onExportExcelButtonClicked}
        disabled={isExportLoading}
        className="mr-4"
      >
        <ExportOutlined />
        Export
      </Button>
      {!isOther && (
        <>
          <Button shape="round" type="primary" onClick={openCreateModal}>
            Create Account
          </Button>

          <CreateAccountModal
            isModalOpen={isCreateModalOpen}
            setIsModalOpen={setIsCreateModalOpen}
          />
        </>
      )}
    </div>
  );
};
