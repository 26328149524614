import { useCallback } from "react";
import { useGetRole } from "../../../../../lib/hooks/getRole";
import { useAppDispatch } from "../../../../../store";
import { setErrorMessage } from "../../../../../acions/actionsCreators/messageActions";
import { BATCH_CREATOR_ONLY_CAN_MAKE_PAYMENTS } from "../../../../../lib/constants";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ROUTES from "../../../../../configs/routesConfig";

export const useOnCreatePayment = (
  data: any,
  setIsCreatePaymentAutoOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch();

  const navigate: NavigateFunction = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_role, _, me] = useGetRole();

  const onCreatePayment = useCallback(
    (isCreatePaymentAutoOpen: boolean) => {
      const { id, batchNumber, type, isCreatorPaymentOnly, createdByUserId } =
        data;

      if (!isCreatorPaymentOnly || me.uid === createdByUserId) {
        isCreatePaymentAutoOpen
          ? setIsCreatePaymentAutoOpen(true)
          : navigate(
              `${ROUTES.PROCESSING}/${id}/payments/create?batchNumber=${batchNumber}&type=${type}`
            );
      } else {
        dispatch(setErrorMessage(BATCH_CREATOR_ONLY_CAN_MAKE_PAYMENTS, true));
      }
    },
    [data, me, setIsCreatePaymentAutoOpen, navigate, dispatch]
  );

  return { onCreatePayment };
};
