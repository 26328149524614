import { Form, FormInstance, Radio, DatePicker, Button, Row } from "antd";
import {
  DATE_FORMAT,
  PAYMENT_DUE_DATE_OPTIONS,
} from "../../../../../../lib/constants";
import { useCallback, useEffect } from "react";
import "./styles.scss";
import { useOnRadioChange } from "./hooks/useOnRadioChange";
import { useRunAutoCharge } from "./hooks/useRunAutoCharge";

const { RangePicker } = DatePicker;

type CreatePaymentFormProps = {
  batchId: string;
  close: () => void;
  onSuccess: () => void;
};

export const CreatePaymentForm = ({
  batchId,
  close,
  onSuccess,
}: CreatePaymentFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const { DATE_DEFAULT_VALUE, onRadioChange } = useOnRadioChange(form);

  const onDatePickerChange = useCallback((): void => {
    form.setFieldValue("dueDate", 4);
  }, [form]);

  const { onApply, isLoading } = useRunAutoCharge(
    batchId,
    form,
    close,
    onSuccess
  );

  useEffect(() => {
    const initialValues = {
      dueDate: DATE_DEFAULT_VALUE[0].value,
      date: DATE_DEFAULT_VALUE[0].defaultValue,
    };

    form.setFieldsValue(initialValues);
  }, [DATE_DEFAULT_VALUE, form]);

  return (
    <Row className="w-100 payment-form-wrapper">
      <Form
        form={form}
        layout="vertical"
        className="w-100 pt-6 pb-4"
        requiredMark={false}
      >
        <Form.Item name="dueDate">
          <Radio.Group
            options={PAYMENT_DUE_DATE_OPTIONS}
            className="d-flex flex-column"
            onChange={onRadioChange}
          />
        </Form.Item>
        <Form.Item
          label="Selected Date Range"
          name="date"
          rules={[
            { type: "date", required: true, validateTrigger: "onSubmit" },
          ]}
        >
          <RangePicker format={DATE_FORMAT} onChange={onDatePickerChange} />
        </Form.Item>
      </Form>
      <div className="w-100 d-flex justify-end pt-6 pb-1">
        <Button
          shape="round"
          type="primary"
          onClick={onApply}
          disabled={isLoading}
          loading={isLoading}
        >
          Apply
        </Button>
        <Button
          shape="round"
          className="ml-2"
          onClick={close}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </Row>
  );
};
