import { useCallback } from "react"
import { useAppDispatch } from "../../../store";
import { setSuccessMessage } from "../../../acions/actionsCreators/messageActions";

export const useCopy = () => {
  const dispatch = useAppDispatch();

  const copy = useCallback((text: string, message: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        dispatch(setSuccessMessage(message))
      })
  }, [dispatch]);

  return { copy };
}