import { ColumnsType } from "antd/es/table";
import { IImpoundsGet } from "../../../../../../../../../types/Impounds";
import { useCallback, useMemo, useState } from "react";
import {
  EditOutlined,
  DollarOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import { getFormattedCurrency } from "../../../../../../../../../lib/currencyFormatter";
import { useCreateImpoundDisbursement } from "../../PayeeDisbursements/hooks/useCreateImpoundDisbursement";

type TTableColumns = {
  setShouldPayeeDisbUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  contractId: string;
  onDeleteBtnClick: (id: string) => void;
  onOpenBtnClick: (
    id: string,
    activeKey: string,
    impoundBalance: number,
    value?: boolean
  ) => void;
};

const TAB_KEYS = {
  PAYMENTS_FROM_IMPOUND: "paymentsFromImpound",
  ACTIVITY_DETAILS: "activitydetails",
  DETAILS: "details",
};

export const useGetTableColumns = ({
  setShouldPayeeDisbUpdate,
  contractId,
  onDeleteBtnClick,
  onOpenBtnClick,
}: TTableColumns) => {
  const [isCreateBtnDisabled, setIsCreateBtnDisabled] =
    useState<boolean>(false);

  const { createImpoundDisbursement } = useCreateImpoundDisbursement({
    setShouldPayeeDisbUpdate,
    setIsDisabled: setIsCreateBtnDisabled,
    contractId,
  });

  const onCreateImpoundDisbBtnClick = useCallback(
    (entry: IImpoundsGet): void => {
      createImpoundDisbursement(entry);
    },
    [createImpoundDisbursement]
  );

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      onDeleteBtnClick(e.currentTarget.id);
    },
    [onDeleteBtnClick]
  );

  const columns: ColumnsType<IImpoundsGet> = useMemo(
    () => [
      {
        title: "Imp Account",
        dataIndex: "impoundAccount",
        key: "impoundAccount",
        width: "9%",
      },
      {
        title: "Type",
        dataIndex: "impoundTypeName",
        key: "impoundTypeName",
        width: "28%",
        ellipsis: true,
      },
      {
        title: "Payment Amount",
        dataIndex: "amount",
        key: "amount",
        width: "12%",
        align: "right",
        render: (value: number) => getFormattedCurrency(value),
      },
      {
        title: "Payee",
        dataIndex: "payeeName",
        key: "payeeName",
        width: "28%",
        ellipsis: true,
      },
      {
        title: "Current Balance",
        dataIndex: "balance",
        key: "balance",
        width: "12%",
        align: "right",
        render: (value: number) => getFormattedCurrency(value),
      },
      {
        key: "actions",
        width: "11%",
        align: "right",
        render: (_: any, record: IImpoundsGet) => {
          const { id, balance } = record;

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpenBtnClick(id, TAB_KEYS.PAYMENTS_FROM_IMPOUND, balance);
                  }}
                >
                  View Payments From Impound
                </div>
              ),
            },
            {
              key: "2",
              label: (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpenBtnClick(id, TAB_KEYS.ACTIVITY_DETAILS, balance);
                  }}
                >
                  View Activity Details
                </div>
              ),
            },
            {
              key: "4",
              className: "create-impound-disb-btn pa-0",
              label: (
                <Button
                  disabled={isCreateBtnDisabled}
                  type="text"
                  className="px-3 py-1 h-auto"
                  style={{ border: "none" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onCreateImpoundDisbBtnClick(record);
                  }}
                >
                  Create Disbursement
                </Button>
              ),
            },
            {
              key: "3",
              label: <div onClick={onDelete}>Delete</div>,
            },
          ];
          return (
            <div className="d-flex justify-end ant-table-actions mr-1">
              <Tooltip title="Pay From Impound" trigger={"hover"}>
                <DollarOutlined
                  className="ant-table-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpenBtnClick(
                      id,
                      TAB_KEYS.PAYMENTS_FROM_IMPOUND,
                      balance,
                      true
                    );
                  }}
                />
              </Tooltip>
              <EditOutlined
                className="ant-table-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenBtnClick(id, TAB_KEYS.DETAILS, balance);
                }}
              />
              <Dropdown trigger={["click"]} menu={{ items }}>
                <EllipsisOutlined
                  className="ant-table-btn"
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [isCreateBtnDisabled, onCreateImpoundDisbBtnClick, onDelete, onOpenBtnClick]
  );

  return { columns };
};
