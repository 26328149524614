import ROUTES from "../../../configs/routesConfig";
import { useLocation, Location } from "react-router-dom";
import { useMemo } from "react";

export const useIsOther = () => {
  const location: Location = useLocation();

  const isOther: boolean = useMemo(
    () => location.pathname === ROUTES.OTHER_ACCOUNTS,
    [location.pathname]
  );

  return { isOther };
};
