import { Rule } from "antd/es/form";
import { useMemo } from "react";
import { PAYOFF_FORM_TITLE_MAX } from "../../../../../../../../../lib/constants";

export const useGetItemTitleRules = () => {
  const rules: Rule[] = useMemo(
    () => [
      {
        max: 20,
        message: PAYOFF_FORM_TITLE_MAX,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  return { rules };
};
