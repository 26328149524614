import { FormInstance } from "antd";
import { useCallback } from "react"
import { useAppDispatch } from "../../../../../../../store";
import { updateLegal } from "../../../../../../../acions/accounts/legal";

type TUpdateParams = {
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance;
  contractId: string
}

export const useUpdateData = ({ setIsDisabled, form, contractId }: TUpdateParams) => {
  const dispatch = useAppDispatch();

  const update = useCallback(async (onSuccess?: () => void) => {
    try {
      setIsDisabled(true)

      const formData = await form.validateFields();

      await Promise.resolve(dispatch(updateLegal(contractId, formData)));

      onSuccess && onSuccess();
    } catch (error) {
      setIsDisabled(false);
    }
  }, [dispatch, form, contractId, setIsDisabled]);

  return { update };
}