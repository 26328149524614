export const getTagColorProps = (value: string | number) => {
  let color: string | undefined;

  if (value === "Active" || value === 0) {
    color = "green";
  } else if (value === "Default" || value === 1) {
    color = "volcano";
  } else if (value === "Inactive" || value === 2) {
    color = "orange";
  } else if (value === "Draft" || value === 8) {
    color = "blue";
  }

  const props: any = {};

  if (color) {
    props.color = color;
  }

  return props;
};
