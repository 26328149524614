import { FormInstance } from "antd";
import { useEffect, useState } from "react";

export const useSetAmountInput = (form: FormInstance, value: number) => {
  const [isDollarInput, setIsDollarInput] = useState<boolean>(false);

  useEffect(() => {
    if (value === 1 || value === 4) {
      setIsDollarInput(true);
      form.setFieldValue("percentage", "0.0000");
    } else {
      setIsDollarInput(false);
      form.setFieldValue("amount", "0.00");
    }
  }, [form, value]);

  return { isDollarInput };
};
