import { Row } from "antd";
import { AccountNumberInput } from "./AccountNumberInput";
import { AccountData } from "./AccountData";
import { ITicklerExtended } from "../../../../types/Tickler";
import { Loader } from "../../../../components/Loader";

type AccountInfoProps = {
  isCreate: boolean;
  data: any | null;
  isLoading: boolean;
  isFormBlured: boolean;
  ticklers: ITicklerExtended[] | null;
  init: (value: string | undefined, setError: (value: string) => void) => void;
  isTicklersLoading: boolean;
};

export const AccountInfo = ({
  isCreate,
  data,
  isLoading,
  isFormBlured,
  ticklers,
  init,
  isTicklersLoading,
}: AccountInfoProps) => {
  return (
    <Row className="d-block py-8 pr-4 acc-info">
      <span className="text text-sm title">ACCOUNT</span>
      {isFormBlured && isCreate ? (
        <AccountNumberInput isLoading={isLoading} init={init} />
      ) : (
        <>
          {!isTicklersLoading ? (
            <AccountData ticklers={ticklers} data={data} />
          ) : (
            <Row className="d-block mt-10">
              <Loader fontSize="32px" />
            </Row>
          )}
        </>
      )}
    </Row>
  );
};
