import { useCallback } from "react";
import { useUnpost } from "../../../../../../Payment/hooks/useUnpost";

export const useTogglePost = (reloadData: () => void) => {
  const { openPopup, togglePost } = useUnpost(reloadData);

  const onToggle = useCallback(
    (id: string, isPosted: boolean) => {
      isPosted ? openPopup(id) : togglePost(id, true);
    },
    [openPopup, togglePost]
  );

  return { onToggle };
};
