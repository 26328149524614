import { Button, Dropdown, MenuProps } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useCallback, useMemo } from "react";
import { useButtonHandlers } from "../../../../Processing/hooks/useButtonHandlers";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ROUTES from "../../../../../configs/routesConfig";

type ActionsDropDownProps = {
  id: string;
  setIsOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ActionsDropDown = ({
  id,
  setIsOpenEditModal,
}: ActionsDropDownProps) => {
  const navigate: NavigateFunction = useNavigate();

  const onDeleted = useCallback(
    (value: React.SetStateAction<boolean>): void => {
      setIsOpenEditModal(value);
    },
    [setIsOpenEditModal]
  );

  const { onDeleteBtnClick } = useButtonHandlers({
    setIsOpenEditModal: onDeleted,
    onEditSuccess: () => navigate(ROUTES.PROCESSING),
  });

  const onEditBtnClick = useCallback((): void => {
    setIsOpenEditModal(true);
  }, [setIsOpenEditModal]);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <div onClick={onEditBtnClick}>Edit Batch Details</div>,
      },
      {
        key: "2",
        label: <div onClick={() => onDeleteBtnClick(id)}>Delete Batch</div>,
      },
    ],
    [id, onDeleteBtnClick, onEditBtnClick]
  );

  return (
    <Dropdown className="ml-4" menu={{ items }} trigger={["click"]}>
      <Button shape="circle" icon={<MoreOutlined />}></Button>
    </Dropdown>
  );
};
