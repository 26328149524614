import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import { ILateCharge } from "../../types/LateCharges";
import authAxios, { authHeader } from "../data";

const fetch = async (id: string) => {
  const response = await authAxios.get(
    API_URL + "AdministrateLateCharges/" + id,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchAll = async (req: any) => {
  const response = await authAxios.get(API_URL + "AdministrateLateCharges", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (req: any) => {
  const response = await authAxios.get(
    API_URL + "AdministrateLateCharges/Count",
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const create = async (req: ILateCharge) => {
  const response = await authAxios.post(
    API_URL + "AdministrateLateCharges",
    req,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const edit = async (req: ILateCharge, id: string) => {
  const response = await authAxios.put(
    API_URL + "AdministrateLateCharges/" + id,
    req,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const remove = async (id: string) => {
  const response = await authAxios.delete(
    API_URL + "AdministrateLateCharges/" + id,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchLateChargesCalc = async () => {
  const response = await authAxios.get(API_URL + "Servicer/", {
    headers: authHeader(),
  });

  return response.data;
};

const editLateChargeCalc = async (req: any) => {
  const response = await authAxios.put(API_URL + "Servicer/lateCharge", req, {
    headers: authHeader(),
  });

  return response.data;
};

const LateChargesService = {
  fetch,
  fetchAll,
  fetchCount,
  create,
  edit,
  remove,
  fetchLateChargesCalc,
  editLateChargeCalc,
};

export default LateChargesService;
