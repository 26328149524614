import { useCallback, useEffect, useState } from "react";
import CustomerPortalAccessesService from "../../../../../../services/customers/portalAccesses";

export const useLoadData = (customerId: string | null | undefined) => {
  const [data, setData] = useState<any[]>([]);

  const [count, setCount] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadData = useCallback(() => {
    if (customerId) {
      setIsLoading(true);

      CustomerPortalAccessesService.fetchData(customerId)
        .then((res: any) => {
          setIsLoading(false);
          setCount(res.length);
          setData(res);
        })
        .catch(() => setIsLoading(false));
    }
  }, [customerId]);

  useEffect(() => loadData(), [loadData]);

  return { data, count, isLoading, loadData };
};
