import { Col, Row, Skeleton } from "antd";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../../../lib/constants";
import { useFetchTotal } from "../../../../../Administration/LateChargesTab/ContractTotals/hooks/useFetchTotal";
import { ReactNode, useCallback } from "react";

type TotalsProps = {
  contractId: string;
};

export const Totals = ({ contractId }: TotalsProps) => {
  const { data, isLoading } = useFetchTotal(contractId);

  const getDataRow = useCallback(
    (label: string, value: string): ReactNode => {
      return (
        <Col className="d-flex align-center">
          <p className="my-0 text-sm">{label}:</p>
          <p className="my-0 ml-2 text-sm w-500">
            {data[value] ? dayjs(data[value]).format(DATE_FORMAT) : "-"}
          </p>
        </Col>
      );
    },
    [data]
  );

  return (
    <Row className="mt-4 w-100">
      {isLoading ? (
        <Skeleton paragraph={false} active className="totals-loader" />
      ) : (
        <Row className="align-center justify-space-between w-100">
          {getDataRow("Date Late Charge Assessed", "totalAssessedOn")}
          {getDataRow(
            "Assessed Late Charge Calculated To",
            "lateChargeAssessedCalculatedTo"
          )}
        </Row>
      )}
    </Row>
  );
};
