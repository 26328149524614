import { useCallback, useEffect, useMemo, useState } from "react";
import { UsersService } from "../../../services/users";
import { useSearchParams } from "react-router-dom";
import { usePrevious } from "../../../lib/hooks/previous";

export const useFetchUsersCount = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [total, setTotal] = useState<number>(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const request = useMemo(() => {
    const val: any = {
      pageNumber: +(searchParams.get("pageNumber") || 1),
      pageSize: +(searchParams.get("pageSize") || 20),
    };

    const search = searchParams.get("search");
    const statuses = searchParams.getAll("statuses");
    const roles = searchParams.getAll("roles");
    const userOrder = searchParams.get("userOrder");

    if (search) {
      val.search = search;
    }

    if (statuses && statuses.length > 0) {
      val.statuses = statuses.map((i: string) => +i);
    }

    if (roles && roles.length > 0) {
      val.roles = roles;
    }

    if (userOrder) {
      val.userOrder = userOrder;
    }

    return val;
  }, [searchParams]);

  const prevRequest = usePrevious(request);

  const fetchDataCount = useCallback((): void => {
    setIsLoading(true);

    UsersService.fetchUsersCount(request).then((res) => {
      setTotal(res);
      setIsLoading(false);
    });
  }, [request]);

  useEffect(() => {
    if (
      !prevRequest ||
      prevRequest.statuses !== request.statuses ||
      prevRequest.search !== request.search ||
      prevRequest.roles !== request.roles
    ) {
      fetchDataCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataCount]);

  return { total, isLoading, request, fetchDataCount, setSearchParams };
};
