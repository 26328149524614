import { useCallback, useState } from "react";
import { saveAs } from "file-saver";
import DocumentsService from "../../../../../../../services/accounts/documents";

export const useDownloadFile = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const download = useCallback((id: string, fileName: string): void => {
    setIsLoading(true);

    DocumentsService.downloadFile(id)
      .then((res) => {
        const blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, fileName);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return { isLoading, download };
};
