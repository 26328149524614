import { useCallback } from "react";
import { Row, Button } from "antd";
import { MultiSelect } from "../../components/MultiSelect";
import { createPortal } from "react-dom";
import { SearchWithClear } from "../../components/SearchWithClear";
import { USER_STATUSES } from "../../lib/constants";
import { useSearchParams } from "react-router-dom";
import { useOpenCreateAdmin } from "./hooks/useOpenCreateAdmin";

type FiltersProps = {
  roles: any[];
  isLoading: boolean;
  isRolesLoading: boolean;
  request: any;
  reloadData: () => void;
};

export const Filters = ({
  roles,
  isLoading,
  isRolesLoading,
  request,
  reloadData,
}: FiltersProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, setSearchParams] = useSearchParams();

  const { showSideBar, openCreate, sideBar } = useOpenCreateAdmin(
    roles,
    reloadData
  );

  const setSearch = useCallback(
    (value: string) => {
      const params = { ...request, pageNumber: 1 };

      if (!value) {
        delete params.search;
      } else {
        params.search = value;
      }

      setSearchParams(params);
    },
    [request, setSearchParams]
  );

  const setStatuses = useCallback(
    (value: string[]) => {
      const params = { ...request, pageNumber: 1 };

      if (value[0] === "All") {
        delete params.statuses;
      } else {
        params.statuses = value;
      }

      setSearchParams(params);
    },
    [request, setSearchParams]
  );

  const setRoles = useCallback(
    (value: string[]) => {
      const params = { ...request, pageNumber: 1 };

      if (value[0] === "All") {
        delete params.roles;
      } else {
        params.roles = value;
      }

      setSearchParams(params);
    },
    [request, setSearchParams]
  );

  return (
    <Row className="user-management-filters justify-space-between">
      <div className="d-flex">
        <div className="mr-4">
          <SearchWithClear
            isLoading={isLoading}
            search={request.search || ""}
            setSearch={setSearch}
          />
        </div>
        <div className="mr-4 roles">
          <MultiSelect
            label="Role"
            options={roles}
            isLoading={isLoading || isRolesLoading}
            onChange={setRoles}
            defaultValue={request.roles || undefined}
            maxTagCount={2}
          />
        </div>
        <div className="mr-4">
          <MultiSelect
            label="Status"
            options={USER_STATUSES}
            isLoading={isLoading}
            onChange={setStatuses}
            defaultValue={request.statuses || undefined}
            maxTagCount={2}
          />
        </div>
      </div>
      <div>
        <Button
          type="primary"
          shape="round"
          size="middle"
          htmlType="submit"
          block
          onClick={openCreate}
        >
          Create Admin
        </Button>
      </div>

      {showSideBar && createPortal(sideBar, document.body)}
    </Row>
  );
};
