import { ProfileTab } from "./ProfileTab/index";
import { Button, Tabs, TabsProps, Skeleton, MenuProps, Dropdown } from "antd";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useGetRole } from "../../../../lib/hooks/getRole";
import { HistoryTab } from "./HistoryTab";
import { LoadingOutlined, MoreOutlined } from "@ant-design/icons";

type UserContentProps = {
  userId: string | null;
  onOpenBlockModal: (userId: string | null, isBlockValue: boolean) => void;
  setIsEditUser: (value: boolean) => void;
  isEditUser: boolean;
  roles: any[];
  reloadData: () => void;
  setHeaderTitleOnLoad: (value: any) => void;
  openDeleteModal: (id: string, title: string) => void;
};

export const UserContent = ({
  userId,
  onOpenBlockModal,
  setIsEditUser,
  isEditUser,
  roles,
  reloadData,
  setHeaderTitleOnLoad,
  openDeleteModal,
}: UserContentProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [role, _, me] = useGetRole();

  const [user, setUser] = useState<any>(null);

  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<string>("1");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Profile",
      children: (
        <ProfileTab
          userId={userId}
          user={user}
          setUser={setUser}
          isEdit={isEditUser}
          roles={roles}
          setIsSaveClicked={setIsSaveClicked}
          isSaveClicked={isSaveClicked}
          reloadData={reloadData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setHeaderTitleOnLoad={setHeaderTitleOnLoad}
        />
      ),
    },
    {
      key: "2",
      label: "History",
      children: <HistoryTab userId={userId} />,
      disabled: isEditUser,
    },
  ];

  useEffect(() => setUser(null), [userId]);

  const onToggleBlock = useCallback(
    (value: boolean) => {
      onOpenBlockModal(userId, value);
    },
    [onOpenBlockModal, userId]
  );

  const getFooterGroup: ReactNode = useMemo(() => {
    let items: MenuProps["items"] = [];

    const getBlockUnblockBtn = (
      key: string,
      label: string,
      isBlocked: boolean
    ) => {
      return {
        key,
        label: <div onClick={() => onToggleBlock(isBlocked)}>{label}</div>,
      };
    };

    if (user?.status !== 3) {
      if (role === "Admin") {
        if (user?.status === 2) {
          items.push(getBlockUnblockBtn("1", "Unblock User", false));
        } else {
          if (user?.role === "User") {
            items.push(getBlockUnblockBtn("2", "Block User", true));
          }
        }
      } else if (me.uid !== user?.id) {
        const isApproved = user?.status === 1;

        items.push(
          getBlockUnblockBtn(
            "3",
            `${isApproved ? "Block" : "Unblock"} User`,
            isApproved
          )
        );
      }
    }

    if (user?.role === "User") {
      items.push({
        key: "4",
        label: (
          <div onClick={() => openDeleteModal(user.id, "Delete user?")}>
            Delete User
          </div>
        ),
      });
    }

    if (!isEditUser) {
      const editBtnTemplate: ReactNode = (
        <Button
          shape="round"
          onClick={() => setIsEditUser(true)}
          className="mr-4"
        >
          Edit Profile
        </Button>
      );

      let editBtn: ReactNode = <div />;

      if (role === "Admin") {
        if (me.uid === user?.id || user?.role === "User") {
          editBtn = editBtnTemplate;
        }
      } else {
        editBtn = editBtnTemplate;
      }

      return (
        <div>
          {activeTab === "1" && editBtn}
          {items.length !== 0 && (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <Button
                shape="circle"
                icon={<MoreOutlined />}
                style={{ minWidth: "unset" }}
              ></Button>
            </Dropdown>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <Button
            shape="round"
            type="primary"
            onClick={() => setIsSaveClicked(true)}
            disabled={isSaveClicked}
          >
            Save Changes
            {isSaveClicked && <LoadingOutlined />}
          </Button>
        </div>
      );
    }
  }, [
    user,
    isEditUser,
    onToggleBlock,
    role,
    me.uid,
    openDeleteModal,
    activeTab,
    setIsEditUser,
    isSaveClicked,
  ]);

  const onChangeTab = (key: string) => {
    setActiveTab(key);
  };

  return (
    <>
      <div className="sidebar-body user-content">
        <Tabs items={tabItems} defaultActiveKey="1" onChange={onChangeTab} />
      </div>
      <div className="sidebar-footer px-6 py-6">
        {!isLoading ? (
          getFooterGroup
        ) : (
          <div className="mt-2 mb-2">
            <Skeleton
              active
              round
              paragraph={{ rows: 1, width: "325px" }}
              title={false}
            />
          </div>
        )}
      </div>
    </>
  );
};
