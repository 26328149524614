import { Button, Card, Form, FormInstance, Row, Table } from "antd";
import { useCallback, useState } from "react";
import ImpoundsTypeService from "../../../services/administration/impounds";
import { IImpoundsTypeExtended } from "../../../types/Impounds";
import { useGetColumns } from "./hooks/useGetColumns";
import { useDefaultTableScrollProp } from "../../Customers/hooks/defaultTableScrollProp";
import { ImpoundsForm } from "./ImpoundsForm";
import { useButtonHandlers } from "./hooks/useButtonHandlers";
import { useFetchData } from "../../../lib/hooks/useDefaultFetchData";
import { useDefualtOnRow } from "../../../lib/hooks/useDefaultOnRow";

export const Impounds = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [entryId, setEntryId] = useState<string | null>(null);

  const [form]: FormInstance[] = Form.useForm();

  const { isLoading, data, fetchData } = useFetchData<
    "",
    IImpoundsTypeExtended
  >(ImpoundsTypeService, "");

  const reloadData = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const {
    onDeleteClick,
    onCloseClick,
    onSaveClick,
    isFormDisabled,
    onAddTypeClick,
  } = useButtonHandlers({ reloadData, setIsOpen, setEntryId, form });

  const openToEdit = useCallback((id: string) => {
    setEntryId(id);
    setIsOpen(false);
  }, []);

  const { columns } = useGetColumns({
    onDeleteClick,
    onSaveClick,
    onCloseClick,
    entryId,
    isFormDisabled,
    form,
    data,
    openToEdit,
  });

  const { scroll } = useDefaultTableScrollProp(data, 323);

  const { onRow } = useDefualtOnRow(openToEdit);

  return (
    <Row className="table-page-content">
      <Row className="d-block w-100 py-6 pr-8 pl-2">
        <Card>
          <Row className="w-100">
            <h5 className="h h5 ant-table-title">Impound Types</h5>
          </Row>
          <Row className="d-block impounds-table-wrapper mt-6">
            <Table
              dataSource={data}
              columns={columns}
              loading={isLoading}
              pagination={false}
              rowKey="id"
              scroll={scroll}
              onRow={onRow}
            />
            <Row className="mt-4">
              {isOpen ? (
                <ImpoundsForm
                  onCloseClick={onCloseClick}
                  onSaveClick={onSaveClick}
                  id={entryId}
                  form={form}
                  isFormDisabled={isFormDisabled}
                />
              ) : (
                <Button
                  type="link"
                  className="impounds-add-btn"
                  size="small"
                  onClick={onAddTypeClick}
                >
                  + Add Impound Type
                </Button>
              )}
            </Row>
          </Row>
        </Card>
      </Row>
    </Row>
  );
};
