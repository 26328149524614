import { Checkbox, Form, Row, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useMemo } from "react";

export const ApplyGlobalCheckBox = () => {
  const tooltipStyle: any = useMemo(
    () => ({
      maxWidth: "390px",
    }),
    []
  );

  return (
    <Row className="justify-center align-center filters">
      <Form.Item
        className="pt-5"
        name="globalAdjustmentAllowed"
        valuePropName="checked"
      >
        <Checkbox>Apply global fee type changes</Checkbox>
      </Form.Item>
      <Tooltip
        placement="top"
        overlayClassName="text-sm"
        overlayStyle={tooltipStyle}
        title="Automatically change the allocation, amounts and when it should be assessed with the fee type changes in the Administration page."
      >
        <QuestionCircleOutlined className="mb-1 search-icon" />
      </Tooltip>
    </Row>
  );
};
