export const PRIMARY_1_COLOR = "#F0F7FF";

export const PRIMARY_6_COLOR = "#2B59C3";

export const FONT_FAMILY = "Roboto";

export const GREY_12_COLOR = "#141414";

export const MODAL_MASK_COLOR = "#000000b3";

export const RED_6_COLOR = "#F5222D";

export const WARNING_COLOR = "#DF6420";

export const ORANGE_1 = "#FAAD14";

export const ENTER_EMAIL_OR_USERNAME = "Please enter Email or Username";

export const ENTER_6_DIGITS = "Please enter a 6-digit code";

export const ENTER_PASSWORD = "Please enter Password";

export const INCORRECT_PASSWORD_MSG = "Not allowed character is used";

export const FAIL_LOGIN_MESSAGE =
  "Incorrect Email/Username or Password. Please try again.";

export const FAIL_CONFIRM_ACCOUNT = "Incorrect code. Please try again";

export const CODE_LIMIT_REACHED =
  "Resend Code limit reached. Please try again later.";

export const FIRST_NAME_REQUIRED = "Please enter First Name";

export const LAST_NAME_REQUIRED = "Please enter Last Name";

export const ACCOUNT_NUMBER_REQUIRED = "Please enter Account Number";

export const TAX_ID_REQUIRED = "Please enter Tax ID Number";

export const EMAIL_REQUIRED = "Please enter Email address";

export const WRONG_TAX_ID = "Tax ID Number should contain 4 numbers only.";

export const WRONG_ACCOUNT_NUMBER =
  "Account Number should contain number only.";

export const WRONG_ACCEPT_NUMBER = "Accept Number should contain number only.";

export const WROND_6_DIGIT_NUMBER = "Access Number must be exactly 6 digits";

export const WRONG_PHONE_FORMAT = "Wrong phone number format";

export const WRONG_FAX_FORMAT = "Wrong fax number format";

export const WRONG_FIRST_NAME =
  "First Name should contain letters, numbers and dashes only";

export const WRONG_LAST_NAME =
  "Last Name should contain letters, numbers and dashes only";

export const WRONG_MIDDLE_NAME =
  "Middle Name should contain letters, numbers and dashes only";

export const WRONG_EMAIL_FORMAT = "Wrong email format";

export const EMAIL_IS_USED = "Email address is already registered.";

export const CODE_SEND = "New code was sent";

export const RESET_PASSWORD_SEND = "Reset password link was sent";

export const RESET_PASSWORD_SUCCESS = "Password is successfully changed";

export const RESET_PHONE_SUCCESS = "Phone number is successfully changed";

export const RESET_EMAIL_SECCESS = "Email is successfully changed";

export const INVALID_ACCOUNT_INFO =
  "Invalid Account Information. The provided Account information is invalid. Please ensure you have entered the correct details associated with your account.";

export const PHONE_REQUIRED = "Please enter Phone Number";

export const ACCOUNT_STATUSES = [
  { label: "Draft", value: 8 },
  { label: "Active", value: 0 },
  { label: "Inactive", value: 2 },
  { label: "Default", value: 1 },
  { label: "Paid Off", value: 3 },
  { label: "Replaced", value: 4 },
  { label: "Foreclosed", value: 5 },
  { label: "Cancelled", value: 6 },
];

export const NEXT_PAYMENT_OPTIONS = [
  { label: "All", value: 0 },
  { label: "Overdue", value: 1 },
  { label: "Today", value: 2 },
];

export const LAST_PAYMENT_OPTIONS = [
  { label: "All", value: 0 },
  { label: "Last 6 months or none", value: 3 },
  { label: "Last 6 months", value: 1 },
  { label: "More than 6 months", value: 2 },
];

export const MAX_EMAIL_USERNAME =
  "Email/Username shouldn't contain more than 100 symbols";

export const MAX_PASSWORD = "Password shouldn't contain more than 30 symbols";

export const MAX_FIRSTNAME =
  "First name shouldn't contain more than 50 symbols";

export const MAX_CITY = "City shouldn't contain more than 50 symbols";

export const MAX_ADDRESS_LINE =
  "Address shouldn't contain more than 100 symbols";

export const MAX_MIDDLENAME =
  "Middle name shouldn't contain more than 50 symbols";

export const MAX_LASTNAME = "Last name shouldn't contain more than 50 symbols";

export const MAX_COMPANY_NAME =
  "Company name shouldn't contain more than 50 symbols";

export const MAX_NOTEES = "Notes shouldn't contain more than 2000 symbols";

export const MAX_ACCOUNT_NUMBER =
  "Account number shouldn't contain more than 50 numbers";

export const MAX_EMAIL = "Email shouldn't contain more than 100 symbols";

export const FAILED_PASSWORD_CHANGE = "Failed to change password.";

export const FAILED_FIND_ACCOUNT = "Failed to find account data.";

export const CREATE_ADMIN_SUCCESS = "Admin is successfully created.";

export const UPDATE_USER_SUCCESS = "User’s profile was updated";

export const REASON_REQUIRED = "Reason is required.";

export const ACCESS_NUMBER_IS_REQUIRED = "Access Number is required.";

export const ACCEPT_USER_SUCCESS = "User was accepted";

export const REJECT_USER_SUCCESS = "User was rejected";

export const DELETE_USER_SUCCESS = "Record was deleted";

export const UPDATE_RECORD_SUCCESS = "Record was updated";

export const USER_EVENT_TYPES = {
  BLOCKED: 100,
  UNBLOCKED: 101,
  UPDATE_PROFILE_BY_SELF: 300,
  UPDATE_PROFILE_BY_ADMIN: 301,
  UPDATE_PASSWORD_BY_SELF: 302,
  RESET_PASSWROD: 303,
  CHANGED_USERNAME: 304,
  CHANGED_EMAIL: 305,
  CHANGED_PHONE: 306,
  CHANGED_ROLE: 307,
  CHANGED_FULLNAME: 308,
  PORTAL_ACCESS_ADDED: 309,
  PORTAL_ACCESS_REMOVED: 310,
  CREATED: 400,
  ACCESS_REQUESTED: 401,
  ACCESS_GRANTED: 402,
  ACCESS_DENIED: 403,
  CREATED_BY_IMPORT: 404,
  ACTIVATED: 405,
  CREATED_BY_PREREGISTRATION: 406,
};

export const DEFAULT_TIME_FORMAT = "MM/DD/YY, hh:mm A";

export const DATE_FORMAT = "MM/DD/YY";

export const BLOCK_ERROR_MESSGAE = "Sorry, your account is blocked";

export const LOGOUT_REASON =
  "You were logged out due to session expiration.It helps to protect your data. To proceed please log in again.";

export const CHART_TITLE_COLOR = "#0000000f";

export const CHART_VALUE_COLOR = "#000000d9";

export const CHART_GREEN_5 = "#73D13D";

export const CHART_GOLD_5 = "#FFC53D";

export const CHART_VOLCANO_5 = "#FF7A45";

export const CHART_RED_7 = "#CF1322";

export const CHART_BLUE_7 = "#096DD9";

export const CHART_CYAN_5 = "#36CFC9";

export const CHART_BLUE_5 = "#40A9FF";

export const CHART_PURPLE_5 = "#9254DE";

export const NEW_ROLE =
  "The changes were saved. Please log in again to apply new role changes";

export const WRONG_SSN_FORMAT = "Wrong SSN format";

export const WRONG_EIN_FORMAT = "Wrong EIN format";

export const WRONG_ZIP_FORMAT = "Wrong zip code format";

export const NEW_CUSTOMER_CREATED = "New customer was created";

export const CHANGES_WERE_SAVED = "Changes were saved";

export const BANK_ACCOUNT_NUMBER_MIN_MAX =
  "Bank Account number should contain 6-17 digits";

export const WRONG_FORMAT_BANK_ACCOUNT_NUMBER =
  "Bank Account number should contain only digits";

export const BANK_ACCOUNT_NUMBER_REQUIRED = "Bank Account number is required";

export const WRONG_FORMAT_BANK_TRANSIT_ROUTING =
  "Bank Transit/Routing should contain only 9 digits";

export const BANK_TRANSIT_ROUTING_REQUIRED = "Bank Transit/Routing is required";

export const COLOR_WHITE = "#FFFFFF";

export const PAYMENT_TYPE_OPTIONS = [
  { label: "All", value: 0 },
  { label: "Payment", value: 1 },
  { label: "Disbursement", value: 2 },
];

export const SELECT_OR_CREATE_USER =
  "Please select a user or create a new one.";

export const NO_CUSTOMERS_FOUND = "No Customers found with this number";

export const ENTER_CUSTOMER_NUMBER = "Enter Customer Number";

export const NO_USER_WITH_SUCH_EMAIL = "There is no user with such email";

export const ISSUE_WITH_EMAIL = "There is an issue with signing up";

export const EMAIL_WAS_ACTIVATED = "This email has been already activated";

export const LOGIN_CREATED = "Your login has been created.";

export const USER_STATUSES = [
  { label: "Approved", value: 1 },
  { label: "Blocked", value: 2 },
  { label: "Non-Activated", value: 3 },
];

export const FEE_ASSESSED_TYPE = [
  { label: "Do not assess", value: 0 },
  { label: "Each payment", value: 1 },
  { label: "Next Payment", value: 2 },
  { label: "Next Payment after Statement Request", value: 3 },
  { label: "Next Payment after Payoff Quote", value: 4 },
  { label: "Next Payment after Amort Schedule", value: 5 },
  { label: "Next Payment after Late notice", value: 6 },
  { label: "Upon Late Charge", value: 7 },
  { label: "Upon Impound", value: 8 },
  { label: "Upon Impound Distribution", value: 9 },
  { label: "Upon Auto Charge", value: 10 },
  { label: "Next Payment after Demand letter", value: 11 },
  { label: "Upon Final Payment", value: 12 },
];

export const MAX_SALUTATION =
  "Salutation shouldn't contain more than 10 symbols";

export const MAX_USERNAME = "Username shouldn't contain more than 100 symbols";

export const DATA_UPDATED = "Your data was updated";

export const ISSUE_WITH_ACCOUNT = "There is an issue with your account";

export const MAX_FEE_TYPE = "Fee Type shouldn't contain more than 20 symbols";

export const FEE_TYPE_REQUIRED = "Please enter Fee Type";

export const FEE_ALLOCATION_OPTIONS = [
  { label: "Buyer Pays", value: 0 },
  { label: "Split", value: 2 },
  { label: "Seller Pays", value: 1 },
];

export const FEE_PAYER_OPTIONS = {
  BUYER: "buyer",
  SELLER: "seller",
};

export const CREATE_FEE_MSG = "Fee Type was successfully created";

export const INITIAL_FEE_AMOUNTS = {
  buyerFee: "0.00",
  buyerTax: "0.00",
  sellerFee: "0.00",
  sellerTax: "0.00",
};

export const LATE_CHARGES_TYPE = [
  { label: "Fixed Amount", value: 1 },
  { label: "Percentage of Principle & Interest", value: 2 },
  { label: "Percentage of Payment Amount", value: 3 },
  { label: "Per Diem", value: 4 },
];

export const LATE_CHARGES_ASSESS_METHOD = [
  { label: "Do Not Charge", value: 1 },
  { label: "Pay in Next Payment", value: 2 },
  { label: "Pay in Next Payment if it has Extra Amount", value: 3 },
  { label: "Add to Unpaid Late Charges", value: 4 },
  { label: "Add to Principal", value: 5 },
];

export const LATE_CHARGES_CALCULATIONS = [
  {
    label: "10 Days specified on Late Charge section means on 10th day",
    value: 1,
  },
  {
    label: "10 Days specified on Late Charge section means on 11th day",
    value: 2,
  },
];

export const LATE_CHARGES_CALCULATIONS_CHANGED =
  "Late Charges calculation was changed";

export const CREATE_LATE_CHARGE_MSG = "Late Charge was successfully created";

export const LATE_CHARGE_DATE_REQUIRED = "Please enter Assess After";

export const LATE_CHARGE_DATE_LIMIT = "Assess after can't be greater than 999";

export const LATE_CHARGE_AMOUNT_LIMIT = "Amount can't be greater than 99999";

export const ACCOUNT_CREATED = "Account has been created";

export const CREATE_IMPOUND_TYPE_MSG = "Impounds Type was successfully created";

export const IMPOUND_TYPE_REQUIRED = "Please enter Impounds Type";

export const IMPOUND_TYPE_MAX =
  "Impound Type shouldn't contain more than 20 symbols";

export const PAYMENT_RESTRICTIONS = [
  { label: "Allow Payments", value: 0 },
  { label: "Do not Allow Payments (Freeze)", value: 1 },
  { label: "NSF Item Outstanding (Freeze)", value: 2 },
  { label: "Allow if All Past Due Amounts Included", value: 3 },
  { label: "Accept Payoff Only", value: 4 },
  { label: "Assignment Pending", value: 5 },
  { label: "Certified Funds Only", value: 6 },
];

export const FLOAT_NUMBER_MSG = "Only numbers are allowed";

export const TICKLERS_TYPE_CREATED = "Ticklers Type was successfully created";

export const TICKLERS_TYPE_NAME_REQUIRED = "Please enter Tickler Type Name";

export const TICKLERS_TYPE_NAME_MAX =
  "Tickler Type Name shouldn't contain more than 20 symbols";

export const TICKLERS_DEFAULT_TEXT_MAX =
  "Default Text shouldn't contain more than 100 symbols";

export const NUMBER_INPUT_REQUIRED = "This field is required";

export const PERCENT_LIMIT = "Percent can't be greater than 100";

export const FIX_VALIDATION_ERRORS_MSG = "Please fix validation errors";

export const TICKLER_CREATED = "Tickler was successfully created";

export const ACCOUNT_PAYMENT_TYPES = [
  { label: "Principal and Interest", value: 0 },
  { label: "Fixed Principal and Interest", value: 1 },
  { label: "Interest Only", value: 2 },
  { label: "Principal Only", value: 3 },
  { label: "Lease/Passthru", value: 4 },
  { label: "Impound Only", value: 5 },
];

export const PAYMENT_SCHEDULE_OPTIONS = [
  { label: "Yearly", value: 1 },
  { label: "Semiannually", value: 2 },
  { label: "Quarterly", value: 4 },
  { label: "Every 2 months", value: 6 },
  { label: "Monthly", value: 12 },
  { label: "Semimonthly", value: 24 },
  { label: "Biweekly", value: 26 },
  { label: "Weekly", value: 52 },
];

export const WORKING_WEEK_DAYS = [
  { label: "Monday", value: 0 },
  { label: "Tuesday", value: 1 },
  { label: "Wednesday", value: 2 },
  { label: "Thursday", value: 3 },
  { label: "Friday", value: 4 },
];

export const INTEREST_CALCULATION_METHODS = [
  { label: "30/360", value: 0 },
  { label: "Actual/(365/366)", value: 1 },
  { label: "Actual/(365)", value: 2 },
];

export const ACCOUNT_PAYMENT_METHODS = [
  { label: "Check or Other", value: 1 },
  { label: "Cash", value: 4 },
  { label: "ACH Checking", value: 2 },
  { label: "ACH Saving", value: 3 },
  { label: "Credit Card", value: 5 },
];

export const DAY_IS_NOT_VALID = "Day is not valid";

export const INTEREST_RATE_MAX_MSG = "Interest Rate shouldn't be more than 99%";

export const DOCUMENT_CREATED = "Document was successfully created";

export const MAX_DOC_NAME = "Name shouldn't contain more than 100 symbols";

export const MAX_DOC_DESCRIPTION =
  "Description shouldn't contain more than 300 symbols";

export const NO_STATUS_LABEL = "No Status";

export const BEGINNING_PRINCIPAL_MAX_MSG =
  "Beginning Principal shouldn't be more than 1,000,000,000$";

export const NO_ACCOUNTS_FOUND = "No Accounts found with this number";

export const IMPOUND_CREATED = "Impound was successfully created";

export const DISTRBUTION_CREATED = "Distribution was successfully created";

export const DISTRIBUTION_DESC_MAX =
  "Description shouldn't contain more than 200 symbols";

export const DISTRIBUTION_AMOUNT_LIMIT =
  "Amount should be less than Impound Current Balance";

export const LATE_CHARGES_DATA_WAS_UPDATED = "Late charges data was updated";

export const PAYEE_TYPES = [
  { value: 0, label: "Primary Payee" },
  { value: 1, label: "Secondary Payee" },
  { value: 2, label: "Impound" },
  { value: 3, label: "Primary Payee Impound" },
  { value: 4, label: "Payee Impound" },
  { value: 101, label: "Impound Distribution" },
  { value: 5, label: "Mortgage" },
  { value: 6, label: "Passthru" },
  { value: 7, label: "Primary Passthru" },
  { value: 8, label: "Demand Letter" },
  { value: 9, label: "Escrow Agent" },
  { value: 10, label: "Servicer" },
  { value: 11, label: "Receipt" },
  { value: 12, label: "Report" },
];

export const DISBURSEMENTS_METHOD_TYPES = [
  { label: "Check", value: 1 },
  { label: "Wire Transfer", value: 2 },
  { label: "General Ledger", value: 3 },
  { label: "To Loan", value: 4 },
  { label: "ACH Checking", value: 5 },
  { label: "ACH Saving", value: 6 },
  { label: "ACH Loan", value: 7 },
  { label: "To Escrow Account", value: 8 },
];

export const DISBURSEMENTS_STATUS = [
  { value: 1, label: "Active", color: "green" },
  { value: 2, label: "Inactive", color: "" },
  { value: 3, label: "Frozen", color: "red" },
];

export const DISBURSEMENT_CREATED = "Payee Disbursement created";

export const ACCOUNT_NUMBER_MAX =
  "Account Number shouldn't contain more than 20 symbols";

export const PAYEE_DISB_DESCRIPTION_MAX =
  "Description  shouldn't contain more than 60 symbols";

export const FILE_SIZE_EXCEED = "The file size shouldn't exceed 10mb";

export const MAX_FILE_SIZE = 10000000;

export const ACCEPT_FILE_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  ".csv",
  ".jpg",
  ".png",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "application/msword",
  "image/png",
  "image/jpeg",
];

export const ACCEPT_FILE_ERROR_MSG =
  "Only .doc, .pdf, .png, .jpg, .xls, .csv, .docx, .xlsx types are allowed.";

export const FEE_TYPE_CANT_BE_DELETED =
  "Fee Type can not be deleted. The Fee Type is used in other Accounts";

export const BATCH_TYPES = [
  { label: "AutoCharges", value: 2 },
  { label: "Warehouse", value: 3 },
  { label: "LockBox1", value: 4 },
  { label: "LockBox2", value: 5 },
  { label: "Credit Card", value: 6 },
  { label: "Misc Transactions", value: 7 },
  { label: "Payments", value: 1 },
];

export const BATCH_CREATED = "Batch successfully created";

export const DAY_INITIALIZED = "Day successfully initialized";

export const BATCH_WAS_CALCULATED = "Batch was recalculated and validated";

export const ACCOUNT_NOT_FOUND = "The account was not found";

export const PAYMENT_CANT_BE_CREATED =
  "Payments for draft Accounts can’t be created";

export const PAYMENT_TYPES = [
  { label: "Regular", value: 1 },
  { label: "One-Time", value: 2 },
  { label: "Payoff", value: 3 },
];

export const PAYMENT_METHODS = [
  { label: "Check", value: 1 },
  { label: "ACH", value: 4 },
  { label: "ECheck", value: 5 },
  { label: "Credit Card", value: 6 },
  { label: "From Escrow Account", value: 8 },
  { label: "Extrernal Payment", value: 9 },
  { label: "From Impound", value: 10 },
];

export const SEVERAL_USERS_WITH_SAME_EMAIL =
  "There are several users with the same email";

export const BATCH_CREATOR_ONLY_CAN_MAKE_PAYMENTS =
  "Batch creator only can make payments in this batch";

export const PAYMENT_CREATED = "Payment was created";

export const PAYMENT_POSTED = "Payment was posted";

export const PAYMENT_UNPOSTED = "Payment was unposted";

export const PAYMENT_UPDATED = "Payment was updated";

export const PAYMENT_DUE_DATE_OPTIONS = [
  { label: "Today", value: 1 },
  { label: "Last 2 days", value: 2 },
  { label: "Last 3 days", value: 3 },
  { label: "Custom range", value: 4 },
];

export const BATCH_DISBURSEMENTS_FILTER_OPTIONS = [
  { label: "All", value: 0 },
  { label: "Checks", value: 1 },
  { label: "ACH", value: 5 },
  { label: "To Loans", value: 4 },
  { label: "To Escrow Accounts", value: 8 },
  { label: "Wire Transfers", value: 2 },
  { label: "General Ledgers", value: 3 },
];

export const ACTIVITY_TYPES = [
  { label: "Into Impound", value: 1 },
  { label: "From Impound", value: 2 },
];

export const QUOTE_RECEIVER = [
  { label: "Primary Buyer", value: 1 },
  { label: "Primary Seller", value: 2 },
  { label: "Another", value: 3 },
];

export const RECEIVER_TYPE = [
  { label: "Individual", value: 1 },
  { label: "Company", value: 2 },
];

export const PAYOFF_QUOTE_CREATED = "Payoff Quote was successfully created";

export const PAYER_STATUS = [
  { value: 0, label: "Active" },
  { value: 1, label: "Inactive" },
];

export const PAYER_CREATED = "Payer was successfully created";

export const DISBURSEMENTS_METHOD_TYPES_HISTORY = [
  ...DISBURSEMENTS_METHOD_TYPES,
  { label: "Copy", value: 9 },
  { label: "+Check", value: 101 },
  { label: "+ACHChecking", value: 105 },
  { label: "+ACHSaving", value: 106 },
  { label: "+ACHLoan", value: 107 },
];

export const TASK_WAS_COMPLETED = "Task was completed";

export const POSTED_PAYMENT_PAYEE_TYPE = [
  { label: "Primary Payee", value: 0 },
  { label: "Secondary Payee", value: 1 },
  { label: "Impound", value: 2 },
  { label: "Payee Impound", value: 3 },
  { label: "Mortgage", value: 4 },
  { label: "Passthru", value: 5 },
  { label: "Demand Letter", value: 6 },
  { label: "Servicer", value: 7 },
  { label: "Impound Distrinbution", value: 8 },
  { label: "Alternate Payee", value: 9 },
  { label: "Payee Impound Duplicated", value: 101 },
];

export const YTD_AMOUNT_RECALCULATED = "YTD Amounts were recalculated";

export const DUE_DATE_REQUIRED = "Please enter Due Date";

export const NEXT_DUE_DATE_REQUIRED = "Please enter Next Due Date";

export const CALC_BALANCES_TO_LAST_TRANS =
  "Balances To Last Trans were calculated";

export const UPDATE_IMPOUND = "Impound was updated";

export const PAYMENT_HISTORY_CREATED =
  "Payment History was successfully created";

export const PAYOFF_FORM_TITLE_MAX =
  "Title shouldn't contain more than 20 symbols";

export const COPIED_ACC_NUMBER = "Account number was copied";

export const COPIED_CUSTOMER_NUMBER = "Customer number was copied";

export {};
