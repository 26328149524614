import { Form, Input, Button, Row } from "antd";
import { useValidateOnBlur } from "../../../../lib/hooks/validateOnBlur";
import { Link } from "react-router-dom";
import ROUTES from "../../../../configs/routesConfig";
import { useGetEmailFormRules } from "../../../../lib/hooks/getEmailFormRules";

type FirstStepProps = {
  email: string;
  onNextClicked: (value: any) => void;
};

export const FirstStep = ({ email, onNextClicked }: FirstStepProps) => {
  const [form] = Form.useForm();

  const [validateOnBlur] = useValidateOnBlur(form);

  const { rules } = useGetEmailFormRules();

  return (
    <Row className="d-block">
      <p className="mb-6 mt-0">
        Enter the email you’ve provided for your escrow account creation. It
        will be used for login.
      </p>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        initialValues={{ email }}
        onFinish={onNextClicked}
      >
        <Form.Item name="email" label="Email" className="mb-5" rules={rules}>
          <Input size="large" onBlur={(e) => validateOnBlur(e, "email")} />
        </Form.Item>
        <Form.Item className="mt-7 mb-12">
          <Button
            type="primary"
            shape="round"
            size="large"
            htmlType="submit"
            block
          >
            Next
          </Button>
        </Form.Item>

        <div className="d-flex justify-center align-center">
          <span>Already registered?</span>
          <Link
            className="height-0 pt-0 pl-2 ant-btn-link"
            style={{ paddingBottom: "1px" }}
            to={ROUTES.LOGIN}
          >
            Log In
          </Link>
        </div>
      </Form>
    </Row>
  );
};
