import authAxios, { authHeader } from "../data";
import qs from "qs";
import { API_URL } from "../../configs/apiUrl";

const fetchAll = async (request: any) => {
  const response = await authAxios.get(API_URL + "Customers", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetchTypes = async () => {
  const response = await authAxios.get(API_URL + "Customers/CustomersTypes", {
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (request: any) => {
  const response = await authAxios.get(API_URL + "Customers/Count", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const save = async (request: any) => {
  const response = await authAxios.post(API_URL + "Customers", request, {
    headers: authHeader(),
  });

  return response.data;
};

const deleteCustomer = async (id: string) => {
  const response = await authAxios.delete(API_URL + "Customers/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const get = async (id: string) => {
  const response = await authAxios.get(API_URL + "Customers/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const update = async (id: string, request: any) => {
  const response = await authAxios.put(`${API_URL}Customers/${id}`, request, {
    headers: authHeader(),
  });

  return response.data;
};

const searchByNumber = async (number: number) => {
  const response = await authAxios.get(
    API_URL + "Customers/ByNumber?Number=" + number,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchCustomerAccounts = async (request: any) => {
  const response = await authAxios.get(API_URL + "CustomersContracts", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const CustomersService = {
  fetchAll,
  fetchCount,
  deleteCustomer,
  save,
  get,
  update,
  fetchCustomerAccounts,
  fetchTypes,
  searchByNumber,
};

export default CustomersService;
