import { ColumnsType } from "antd/es/table";
import { useMemo } from "react";
import { ILateChargeExtended } from "../../../../types/LateCharges";
import {
  LATE_CHARGES_ASSESS_METHOD,
  LATE_CHARGES_TYPE,
} from "../../../../lib/constants";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useButtonHandlers } from "./useButtonHandlers";
import { getFormattedCurrency } from "../../../../lib/currencyFormatter";
import { getFormattedPercentage } from "../../../../lib/percentageFormatter";

type TTableColumnsProps = {
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  accountId: string | undefined;
  openSideBar: (id: string) => void;
};

export const useGetTableColumns = ({
  reloadData,
  setIsOpen,
  setId,
  accountId,
  openSideBar,
}: TTableColumnsProps) => {
  const { onDeleteBtnClick } = useButtonHandlers({
    setId,
    reloadData,
    setIsOpen,
    parentId: accountId,
  });

  const columns: ColumnsType<ILateChargeExtended> = useMemo(
    () => [
      {
        title: "Assess after",
        dataIndex: "assessAfterDays",
        width: "16%",
        key: "assessAfterDays",
        render: (value: number) => {
          const measure = value === 1 ? "day" : "days";

          return (
            <div>
              {value} {measure}
            </div>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        width: "26%",
        key: "type",
        render: (value: number) => {
          const finalValue = LATE_CHARGES_TYPE.find(
            (type) => type.value === value
          )?.label;
          return (
            <div className="cell-elipsis" title={finalValue}>
              {finalValue}
            </div>
          );
        },
      },
      {
        title: "Amount",
        dataIndex: "amount",
        width: "16%",
        key: "amount",
        align: "right",
        render: (value: number, record: ILateChargeExtended) => {
          const { type, percentage } = record;
          let finalValue;

          if (type === 2 || type === 3) {
            finalValue = getFormattedPercentage(percentage);
          } else {
            finalValue = getFormattedCurrency(value);
          }

          return (
            <div className="cell-elipsis" title={finalValue}>
              <span>{finalValue}</span>
            </div>
          );
        },
      },
      {
        title: "Assess Method",
        dataIndex: "assessMethod",
        width: "35%",
        key: "assessMethod",
        render: (value: number) => {
          const finalValue = LATE_CHARGES_ASSESS_METHOD.find(
            (method) => method.value === value
          )?.label;

          return (
            <div className="cell-elipsis" title={finalValue}>
              {finalValue}
            </div>
          );
        },
      },
      {
        title: "",
        width: "7%",
        key: "actions",
        render: (_: any, record: ILateChargeExtended) => {
          const { id } = record;

          return (
            <div
              className="w-100 h-100 d-flex justify-end ant-table-actions"
              onClick={(e) => e.stopPropagation()}
            >
              <EditOutlined
                className="ant-table-btn logo-cursor"
                onClick={() => openSideBar(id)}
              />
              <DeleteOutlined
                className="ant-table-btn mr-1 logo-cursor"
                onClick={() => onDeleteBtnClick(id)}
              />
            </div>
          );
        },
      },
    ],
    [onDeleteBtnClick, openSideBar]
  );

  return { columns };
};
