import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "../../../lib/hooks/previous";

export const useFetchDataCount = (
  func: (req: any) => Promise<unknown>,
  pageSize = 10,
  contractId?: string
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(0);

  const [request, setRequest] = useState<any>({
    pageSize,
    pageNumber: 1,
  });

  const prevContractId = usePrevious(contractId);

  useEffect(() => {
    if (prevContractId !== contractId) {
      setRequest({
        pageSize,
        pageNumber: 1,
        contractId: contractId,
      });
    }
  }, [contractId, pageSize, prevContractId]);

  const onPageChange = useCallback(
    (pageNumber = 1) => {
      const newReq = { ...request, pageNumber, pageSize };

      setRequest(newReq);
    },
    [pageSize, request]
  );

  const fetchDataCount = useCallback(() => {
    setIsLoading(true);

    const req: any = contractId ? { contractId } : {};

    func(req)
      .then((res: any) => {
        if (
          res !== 0 &&
          request.pageNumber > Math.ceil(res / request.pageSize)
        ) {
          const pageNumber = request.pageNumber - 1;
          onPageChange(pageNumber);
        }

        setTotal(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [func, onPageChange, contractId]);

  useEffect(() => {
    fetchDataCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  return { request, isLoading, total, onPageChange, fetchDataCount };
};
