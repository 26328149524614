import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomersService from "../../../services/customers";
import { usePrevious } from "../../../lib/hooks/previous";

export const useFetchCount = () => {
  const [total, setTotal] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const request = useMemo(() => {
    const val: any = {
      pageNumber: +(searchParams.get("pageNumber") || 1),
      pageSize: +(searchParams.get("pageSize") || 20),
    };

    const typeId = searchParams.get("typeId");
    const search = searchParams.get("search");
    const order = searchParams.get("order");

    if (typeId) {
      val.typeId = +typeId;
    }

    if (search) {
      val.search = search;
    }

    if (order) {
      val.order = order;
    }

    return val;
  }, [searchParams]);

  const prevReq = usePrevious(request);

  const onPageChange = useCallback(
    (pageNumber: number) => {
      const newRequest = { ...request, pageNumber };

      setSearchParams(newRequest);
    },
    [request, setSearchParams]
  );

  const fetchCount = useCallback(() => {
    setIsLoading(true);

    CustomersService.fetchCount(request)
      .then((res: number) => {
        if (
          res !== 0 &&
          request.pageNumber > Math.ceil(res / request.pageSize)
        ) {
          onPageChange(request.pageNumber - 1);
        }

        setTotal(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [onPageChange, request]);

  useEffect(() => {
    if (
      !prevReq ||
      request.search !== prevReq.search ||
      request.typeId !== prevReq.typeId
    ) {
      fetchCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);

  return { total, isLoading, request, setSearchParams, fetchCount };
};
