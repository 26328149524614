import { Table } from "antd";
import { useMemo, useCallback } from "react";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../../../lib/constants";
import { getFormattedCurrency } from "../../../../../../lib/currencyFormatter";

type PastPaymentsProps = {
  data: any;
};

type Payment = {
  key: number;
  kindOfPayment: string;
  sum: number;
  yearToDate: number | null;
};

export const PastPayments = ({ data }: PastPaymentsProps) => {
  const columns: ColumnsType<Payment> = useMemo(() => {
    const totalLastPayment = data.lastPayment?.paymentDate
      ? dayjs(data.lastPayment?.paymentDate).format(DATE_FORMAT)
      : "";

    return [
      {
        title: "",
        dataIndex: "kindOfPayment",
        key: "kindOfPayment",
      },
      {
        title: (
          <div>
            <span className="desktop-label">Last payment</span>
            {totalLastPayment}
          </div>
        ),
        dataIndex: "sum",
        key: "sum",
        align: "right",
        render: (value) => getFormattedCurrency(value),
      },
      {
        title: "Year-to-date",
        dataIndex: "yearToDate",
        key: "yearToDate",
        align: "right",
        render: (value) => getFormattedCurrency(value),
      },
    ];
  }, [data]);

  const items: Payment[] = useMemo(
    () => [
      {
        key: 1,
        kindOfPayment: "Principal",
        sum: data.lastPayment?.principal || 0,
        yearToDate: data.yearToDatePayment?.principal || 0,
      },
      {
        key: 2,
        kindOfPayment: "Interest",
        sum: data.lastPayment?.interest || 0,
        yearToDate: data.yearToDatePayment?.interest || 0,
      },
      {
        key: 3,
        kindOfPayment: "Impound",
        sum: data.lastPayment?.impound || 0,
        yearToDate: data.yearToDatePayment?.impound || 0,
      },
      {
        key: 4,
        kindOfPayment: "Fees",
        sum: data.lastPayment?.fees || 0,
        yearToDate: data.yearToDatePayment?.fees || 0,
      },
    ],
    [data]
  );

  const summaryRow = useCallback(
    (_items: any) => {
      return (
        <>
          <Table.Summary.Row className="table-footer">
            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {getFormattedCurrency(data.lastPayment?.total || 0)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="right">
              {getFormattedCurrency(data.yearToDatePayment?.total || 0)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      );
    },
    [data]
  );

  return (
    <>
      <h5 className="h h5 header-text">Past Payments</h5>
      <div className="past-payments-table mt-4">
        <Table
          dataSource={items}
          columns={columns}
          pagination={false}
          summary={summaryRow}
        />
      </div>
    </>
  );
};
