import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMemo } from "react";

type HeaderProps = {
  header: string;
  onBackClicked: () => void;
  removeBackButton?: boolean;
};

export const Header = ({
  header,
  onBackClicked,
  removeBackButton,
}: HeaderProps) => {
  const className: string = useMemo(
    () =>
      `greeting__container justify-space-${
        removeBackButton ? "around" : "between"
      } mb-2`,
    [removeBackButton]
  );

  return (
    <>
      <div className={className}>
        {!removeBackButton && (
          <Button
            shape="circle"
            icon={<ArrowLeftOutlined />}
            size="large"
            className="back-btn"
            onClick={onBackClicked}
          />
        )}
        <h1 className="header">{header}</h1>
        {!removeBackButton && <div />}
      </div>
    </>
  );
};
