import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";
import { IFeeType } from "../../types/Fees";

const fetch = async (req: any) => {
  const response = await authAxios.get(
    API_URL + `contracts/${req.contractId}/FeeContracts/${req.id}`,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchAll = async (req: any) => {
  const response = await authAxios.get(
    API_URL + `contracts/${req.contractId}/FeeContracts`,
    {
      params: req,
      paramsSerializer: (params) => qs.stringify(params),
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchCount = async (req: any) => {
  const response = await authAxios.get(
    API_URL + `contracts/${req.contractId}/FeeContracts/Count`,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const create = async (entry: IFeeType) => {
  const response = await authAxios.post(
    API_URL + `contracts/${entry.contractId}/FeeContracts`,
    entry,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const edit = async (entry: IFeeType, id: string) => {
  const response = await authAxios.put(
    API_URL + `contracts/${entry.contractId}/FeeContracts/${id}`,
    entry,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const deleteFee = async (req: any) => {
  const response = await authAxios.delete(
    API_URL + `contracts/${req.contractId}/FeeContracts/${req.id}`,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const ContractFeesService = {
  fetch,
  fetchAll,
  fetchCount,
  create,
  edit,
  deleteFee,
};

export default ContractFeesService;
