import { Form, Input, Row } from "antd";
import { useGetFullNameFormRules } from "../../../../../../lib/hooks/getFullNameFormRules";

export const FullNameFormItems = () => {
  const { formOptions } = useGetFullNameFormRules();

  return (
    <>
      {formOptions.map((item: any, i: number) => (
        <Row key={i}>
          <Form.Item
            className="mb-4 w-100"
            name={["newUser", item.name]}
            label={item.label}
            rules={item.rules}
          >
            <Input />
          </Form.Item>
        </Row>
      ))}
    </>
  );
};
