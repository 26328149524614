import { FormInstance } from "antd";
import { useCallback } from "react";

export const useOnCloseModal = (
  form: FormInstance,
  setShowSideBar: (value: boolean) => void
) => {
  const onCloseSideBar = useCallback(
    (value: boolean) => {
      form.resetFields();
      setShowSideBar(value);
    },
    [form, setShowSideBar]
  );

  return { onCloseSideBar };
};
