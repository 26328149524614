import { Form, FormInstance, Input, Row } from "antd";
import { TFormProps } from "../../../lib/hooks/defaultFormSidebar/types";
import TextArea from "antd/es/input/TextArea";
import { useButtonHandlers } from "./hooks/useButtonHandlers";
import { FormButtons } from "../components/FormButtons";
import { useGetInitialValues } from "./hooks/useGetInitialValues";
import { useState } from "react";
import { Loader } from "../../../components/Loader";
import {
  TICKLERS_TYPE_NAME_REQUIRED,
  TICKLERS_TYPE_NAME_MAX,
  TICKLERS_DEFAULT_TEXT_MAX,
} from "../../../lib/constants";

export const TicklersForm = ({
  id,
  reloadData,
  setIsOpen,
  setId,
}: TFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(!!id);

  const { isFormDisabled, onCancelClick, onSaveClick, onDeleteBtnClick } =
    useButtonHandlers({ reloadData, form, setIsOpen, setId });

  const initialValues = useGetInitialValues(id, setIsLoading);

  return (
    <Row className="ticklers-type-form h-100 d-flex position-relative flex-column">
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <Form
          form={form}
          className="pa-6 w-100"
          layout="vertical"
          requiredMark={false}
          initialValues={initialValues}
          disabled={isFormDisabled}
        >
          <Form.Item
            className="w-100"
            label="Tickler Type Name"
            name="typeName"
            rules={[
              {
                required: true,
                message: TICKLERS_TYPE_NAME_REQUIRED,
                validateTrigger: "onSubmit",
              },
              {
                max: 20,
                message: TICKLERS_TYPE_NAME_MAX,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="w-100"
            label="Default Text"
            name="defaultText"
            rules={[
              {
                max: 100,
                message: TICKLERS_DEFAULT_TEXT_MAX,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <TextArea rows={2} className="ticklers-type-form-textarea" />
          </Form.Item>
        </Form>
      )}
      <FormButtons
        onCancelClick={onCancelClick}
        onSaveClick={onSaveClick}
        onDeleteClick={onDeleteBtnClick}
        isFormDisabled={isFormDisabled}
        currentId={id}
      />
    </Row>
  );
};
