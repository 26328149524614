export enum KPI_CODES {
  ACCOUNTS_HEALTHY = "Accounts.Healthy",
  ACCOUNTS_DEFAULT = "Accounts.Default",
  ACCOUNTS_SEMIACTIVE = "Accounts.Semiactive",
  ACCOUNTS_INACTIVE = "Accounts.Inactive",
  ACCOUNTS_PAYDOFF = "Accounts.PaidOff",
  ACCOUNTS_REPLACED = "Accounts.Replaced",
  ACCOUNTS_CANCELED = "Accounts.Cancelled",
  ACCOUNTS_FORCLOSED = "Accounts.Forclosed",
  ACCOUNTS_OTHER = "Accounts.Other",
  ACCOUNTS_DRAFT = "Accounts.Draft",
  IMPOUNDS_TOTAL_BALANCE = "Impounds.TotalBalanceInThousands",
  PAYMENTS_THIS_MONTH = "Payments.CountThisMonth",
  PAYMENTS_LAST_DAY = "Payments.CountLastDay",
}
