import { Row, Col, Card, Divider } from "antd";
import { Title } from "../../components/LoggedIn/Title";
import { LoadingOutlined } from "@ant-design/icons";
import { useGetRole } from "../../lib/hooks/getRole";
import ROUTES from "../../configs/routesConfig";
import { CardData } from "./components/CardData";
import { useFetchData } from "./hooks/useFetchData";
import { TimeZone } from "./TimeZone";
import "./styles.scss";

export const Profile = () => {
  const [role, isAdmin] = useGetRole();

  const { data, isLoading } = useFetchData();

  return (
    <Row className="d-block page-content">
      <Title title="Profile" path={ROUTES.ACCOUNTS} />
      <Row className="justify-center mt-6">
        <Col className="profile__container">
          <Row>
            <Card className="card card-data left">
              {isLoading ? (
                <div className="d-flex justify-center align-center loader__container">
                  <LoadingOutlined className="loader" />
                </div>
              ) : (
                <Col>
                  <h3
                    className="header h h2"
                    title={data.firstName}
                  >{`${data.firstName}`}</h3>
                  <h3
                    className="header h h2"
                    title={data.lastName}
                  >{`${data.lastName}`}</h3>
                  <Row className="mt-6">
                    <Col className="item">
                      {data.userName && (
                        <CardData
                          email={data.email}
                          label="Username"
                          data={data.userName || "-"}
                          isBorder
                          isNotChange={true}
                        />
                      )}

                      <CardData
                        email={data.email}
                        label="Email"
                        data={data.email}
                        isBorder
                        path={ROUTES.PROFILE_CHANGE_EMAIL}
                      />

                      <CardData
                        email={data.email}
                        label="Phone"
                        data={data.phone || undefined}
                        isBorder
                        path={ROUTES.PROFILE_CHANGE_PHONE}
                      />

                      <CardData
                        email={data.email}
                        label="Password"
                        data="**********"
                        path={ROUTES.PROFILE_CHANGE_PASSWORD}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Card>
            <Card className={`${isAdmin ? "admin-right" : ""} card right`}>
              {isAdmin && (
                <>
                  <div className="info">
                    <h5 className="h h5 mb-1">Role</h5>
                    <p className="text ls-5 my-0">{role}</p>
                  </div>
                  <Divider className="my-4" />
                </>
              )}
              <TimeZone />
            </Card>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};
