import { GuestLink } from "../../types/GuestLink";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../configs/routesConfig";

export const UsefulLinks = () => {
  const links: GuestLink[] = useMemo(
    () => [
      {
        title: "Forgot password",
        to: ROUTES.FORGOT_PASSWORD,
      },
      {
        title: "Forgot email/username",
        to: ROUTES.FORGOT_ACCOUNT,
      },
    ],
    []
  );

  return (
    <>
      <div className="useful-links__container mt-12 d-flex justify-space-between">
        <div className="d-flex flex-column align-start">
          {links.map((link) => {
            return (
              <Link
                type="link"
                className="pa-0 ant-btn-link mb-2"
                to={link.to || "#"}
                key={link.title}
              >
                {link.title}
              </Link>
            );
          })}
        </div>
        <div className="no-account__container d-flex justify-center">
          <span>New to the service?</span>
          <Link
            type="link"
            className="height-0 pl-2 ant-btn-link"
            to={ROUTES.SIGN_UP}
          >
            Sign Up
          </Link>
        </div>
      </div>
    </>
  );
};
