import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import { ITickler } from "../../types/Tickler";
import authAxios, { authHeader } from "../data";

const fetchAll = async (req: any) => {
  const response = await authAxios.get(API_URL + "Ticklers", {
    params: req,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (req: any) => {
  const response = await authAxios.get(API_URL + "Ticklers/Count", {
    params: req,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetch = async (id: string) => {
  const response = await authAxios.get(API_URL + "Ticklers/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const deleteEntry = async (id: string) => {
  const response = await authAxios.delete(`${API_URL}Ticklers/${id}`, {
    headers: authHeader(),
  });

  return response.data;
};

const createEntry = async (entry: ITickler) => {
  const response = await authAxios.post(API_URL + "Ticklers", entry, {
    headers: authHeader(),
  });

  return response.data;
};

const editEntry = async (id: string, entry: ITickler) => {
  const response = await authAxios.put(`${API_URL}Ticklers/${id}`, entry, {
    headers: authHeader(),
  });

  return response.data;
};

const TicklersService = {
  fetchAll,
  fetchCount,
  fetch,
  deleteEntry,
  createEntry,
  editEntry,
};

export default TicklersService;
