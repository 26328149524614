import { useEffect, useState } from "react";

export const useHeaderInfo = (data: any) => {
  const [headerInfo, setHeaderInfo] = useState<any>(null);

  useEffect(() => {
    if (data) {
      setHeaderInfo({
        accountNumber: data.accountNumber,
        xRef: data.xRef,
        status: data.status,
      });
    }
  }, [data]);

  return { headerInfo };
};
