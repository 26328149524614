import { useCallback, useState } from "react";
import { togglePayment } from "../../../acions/processing/payments";
import { useAppDispatch } from "../../../store";
import { App, Row } from "antd";
import { useWarnPopupProps } from "./useWarnPopupProps";

export const useUnpost = (
  onSuccess: () => void,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch();

  const { modal } = App.useApp();

  const [isUnposting, setIsUnposting] = useState<boolean>(false);

  const { warnPopupProps } = useWarnPopupProps(isUnposting);

  const togglePost = useCallback(
    async (id: string | null, isPost?: boolean) => {
      if (id) {
        try {
          setIsUnposting(true);
          setIsLoading && setIsLoading(true);

          await Promise.resolve(dispatch(togglePayment(id, !!isPost)));

          onSuccess();

          setIsUnposting(false);
        } catch {
          setIsUnposting(false);
          setIsLoading && setIsLoading(false);
        }
      }
    },
    [dispatch, onSuccess, setIsLoading]
  );

  const openPopup = useCallback(
    (id: string | null) => {
      modal.confirm({
        title: "Unpost Payment",
        content: (
          <Row className="text-md">Are you sure you want to unpost?</Row>
        ),
        ...warnPopupProps,
        cancelText: "Unpost",
        onCancel: () => togglePost(id),
      });
    },
    [modal, togglePost, warnPopupProps]
  );

  return { openPopup, togglePost };
};
