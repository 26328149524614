import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";
import { Dayjs } from "dayjs";

const fetchAll = async (req: {
  pageSize: number;
  pageNumber: number;
  contractId: string;
  year: number;
}) => {
  const result = await authAxios.get(API_URL + "PaymentHistories", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetchCount = async (req: {
  pageSize: number;
  pageNumber: number;
  contractId: string;
  year: number;
}) => {
  const result = await authAxios.get(API_URL + "PaymentHistories/Count", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetch = async (id: string | null) => {
  const result = await authAxios.get(API_URL + "PaymentHistories/" + id, {
    headers: authHeader(),
  });

  return result.data;
};

const fetchYears = async (req: { contractId: string }) => {
  const result = await authAxios.get(API_URL + "info/years", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const remove = async (id: string) => {
  const result = await authAxios.delete(API_URL + "PaymentHistories/" + id, {
    headers: authHeader(),
  });

  return result.data;
};

const edit = async (id: string, req: any) => {
  const result = await authAxios.put(API_URL + "PaymentHistories/" + id, req, {
    headers: authHeader(),
  });

  return result.data;
};

const calculateInterest = async (req: {
  contractId: string;
  interestDateBefore: Dayjs | string;
  interestDayAfter: Dayjs | string;
  principalBefore: string | number;
}) => {
  const result = await authAxios.get(
    API_URL + "PaymentHistories/calculateInterest",
    {
      params: req,
      paramsSerializer: (params) => qs.stringify(params),
      headers: authHeader(),
    }
  );

  return result.data;
};

const recalculate = async (id: string) => {
  const result = await authAxios.put(
    API_URL + "PaymentHistories/" + id + "/Recalculate",
    {},
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const initialize = async (contractId: string) => {
  const result = await authAxios.get(
    API_URL + "PaymentHistories/Initialize",
    {
      params: { contractId },
      paramsSerializer: (params) => qs.stringify(params),
      headers: authHeader(),
    }
  );

  return result.data;
};

const create = async (req: any) => {
  const result = await authAxios.post(API_URL + "PaymentHistories", req, {
    headers: authHeader(),
  });

  return result.data;
};

const PaymentHistoryService = {
  fetchAll,
  fetchCount,
  fetch,
  fetchYears,
  remove,
  edit,
  calculateInterest,
  recalculate,
  initialize,
  create,
};

export default PaymentHistoryService;
