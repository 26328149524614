import { Link } from "react-router-dom";
import { MouseEvent, useMemo } from "react";
import { NavItem } from "../../../types/NavItem";
import { Location } from "react-router-dom";

type ItemProps = {
  item: NavItem;
  location: Location;
  setNextLocation: (value: MouseEvent<HTMLAnchorElement>) => void;
};

export const Item = ({ item, location, setNextLocation }: ItemProps) => {
  const className = useMemo(() => {
    const routes = location.pathname.split("/");

    const isNested = routes.includes(item.path.substring(1));

    return `d-flex align-center header-button py-0 px-4 ${
      isNested ? "active" : ""
    }`;
  }, [location, item]);

  return (
    <Link
      className={className}
      to={item.path}
      id={item.path}
      onClick={setNextLocation}
    >
      {item.name}
    </Link>
  );
};
