import { useMemo } from "react";
import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  MAX_FIRSTNAME,
  MAX_LASTNAME,
  MAX_MIDDLENAME,
  WRONG_FIRST_NAME,
  WRONG_LAST_NAME,
  WRONG_MIDDLE_NAME,
} from "../constants";
import { NAME_RULE } from "../validationRules";

export const useGetFullNameFormRules = (namePath?: string) => {
  const formOptions = useMemo(() => {
    return [
      {
        label: "First Name",
        name: namePath ? [namePath, "firstName"] : "firstName",
        rules: [
          {
            max: 50,
            message: MAX_FIRSTNAME,
            validateTrigger: "onSubmit",
          },
          {
            pattern: NAME_RULE,
            message: WRONG_FIRST_NAME,
            validateTrigger: "onSubmit",
          },
          {
            required: true,
            message: FIRST_NAME_REQUIRED,
            validateTrigger: "onSubmit",
          },
        ],
      },
      {
        label: "Middle Name",
        name: namePath ? [namePath, "middleName"] : "middleName",
        rules: [
          {
            max: 50,
            message: MAX_MIDDLENAME,
            validateTrigger: "onSubmit",
          },
          {
            pattern: NAME_RULE,
            message: WRONG_MIDDLE_NAME,
            validateTrigger: "onSubmit",
          },
        ],
      },
      {
        label: "Last Name",
        name: namePath ? [namePath, "lastName"] : "lastName",
        rules: [
          {
            max: 50,
            message: MAX_LASTNAME,
            validateTrigger: "onSubmit",
          },
          {
            pattern: NAME_RULE,
            message: WRONG_LAST_NAME,
            validateTrigger: "onSubmit",
          },
          {
            required: true,
            message: LAST_NAME_REQUIRED,
            validateTrigger: "onSubmit",
          },
        ],
      },
    ];
  }, [namePath]);

  return { formOptions };
};
