import { Input } from "./Input";
import { SearchOutlined } from "@ant-design/icons";
import "./styles.scss";

type SearchInputProps = {
  title: string;
  isLoading: boolean;
  isSearch: boolean;
  initialValue?: string;
  search: (number: number, onFail: () => void) => void;
  setIsSearch: (value: boolean) => void;
  toggleIsSearch: () => void;
};

export const SearchInput = ({
  title,
  isLoading,
  isSearch,
  initialValue,
  search,
  setIsSearch,
  toggleIsSearch,
}: SearchInputProps) => {
  return (
    <div className="elipsis d-flex align-center">
      {!isSearch ? (
        <div
          className="customer-header-title px-2 d-flex align-center h h3"
          onClick={toggleIsSearch}
        >
          {title}
          <SearchOutlined className="ml-4" />
        </div>
      ) : (
        <Input
          isLoading={isLoading}
          toggleIsSearch={toggleIsSearch}
          setIsSearch={setIsSearch}
          search={search}
          initialValue={initialValue}
        />
      )}
    </div>
  );
};
