import { ReactNode, useMemo, useState } from "react";
import { SideBarContainer } from "../../../../../../../../../components/SideBarContainer";
import { PayeeForm } from "../PayeeForm";
import { TRecord } from "../types";

type TOpenSidebar = {
  reloadData: () => void;
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  };
  customSidebarClass: string;
  contractId: string;
  setRecord: React.Dispatch<React.SetStateAction<TRecord>>;
  record: TRecord;
};

export const useOpenSideBar = ({
  reloadData,
  payeesNumber,
  customSidebarClass,
  contractId,
  setRecord,
  record
}: TOpenSidebar
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const formWrapper: ReactNode = useMemo(() => {

    const formTitle = record.id !== null ? "Payee Details" : "Add Payee";

    return (
      <SideBarContainer
        title={formTitle}
        setShowSideBar={setIsOpen}
        customClass={customSidebarClass}
      >
        <PayeeForm
          reloadData={reloadData}
          payeesNumber={payeesNumber}
          setIsOpen={setIsOpen}
          contractId={contractId}
          record={record}
          setRecord={setRecord}
        />
      </SideBarContainer>
    )
  },
  [record, customSidebarClass, reloadData, payeesNumber, contractId, setRecord]);

  return { formWrapper, isOpen, setIsOpen };
};
