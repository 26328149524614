import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import ImpoundsTypeService from "../../services/administration/impounds";
import { DELETE_USER_SUCCESS, CREATE_IMPOUND_TYPE_MSG, UPDATE_RECORD_SUCCESS } from "../../lib/constants";
import { IImpoundsType } from "../../types/Impounds";
import { setSuccessMessage, setErrorMessage } from "../actionsCreators/messageActions";

export const deleteImpoundsType =
(id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
async (dispatch) => {
  try {
    const res = await ImpoundsTypeService.remove(id);

    dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

    return await Promise.resolve(res);
  } catch (error: any) {
    dispatch(setErrorMessage(error.response.data.title, true));

    return await Promise.reject(error);
  }
};

export const createImpoundType =
(entry: IImpoundsType): ThunkAction<void, RootState, unknown, AnyAction> =>
async (dispatch) => {
  try {
    const res = await ImpoundsTypeService.create(entry);

    dispatch(setSuccessMessage(CREATE_IMPOUND_TYPE_MSG));

    return await Promise.resolve(res);
  } catch (error: any) {
    dispatch(setErrorMessage(error.response.data.title, true));

    return await Promise.reject(error);
  }
};

export const editImpoundsType =
(id: string, entry: IImpoundsType): ThunkAction<void, RootState, unknown, AnyAction> =>
async (dispatch) => {
  try {
    const res = await ImpoundsTypeService.edit(entry, id);

    dispatch(setSuccessMessage(UPDATE_RECORD_SUCCESS));

    return await Promise.resolve(res);
  } catch (error: any) {
    dispatch(setErrorMessage(error.response.data.title, true));

    return await Promise.reject(error);
  }
};