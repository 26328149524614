import { useCallback, useState } from "react";
import BatchesService from "../../../../../../../services/processing/batches";
import { useOpenProgressModal } from "../../../../../../../lib/hooks/useOpenProgressModal";

export const usePostAll = (id: string, onSuccess: () => void) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { openModal } = useOpenProgressModal(
    "Posting Draft Payments",
    "Posting some draft payments failed",
    onSuccess
  );

  const onPostAll = useCallback(() => {
    setIsLoading(true);

    BatchesService.postAll(id)
      .then((res) => {
        openModal(res);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [id, openModal]);

  return { isLoading, onPostAll };
};
