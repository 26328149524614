import { useCallback, useMemo } from "react";
import { CustomModal } from "../../../../../../../../../../../lib/CustomModal";
import { ActivityForm } from "./ActivityForm";

type EditModalProps = {
  activityId: string | null;
  setActivityId: React.Dispatch<React.SetStateAction<string | null>>;
  reloadData: () => void;
};

export const EditModal = ({
  activityId,
  setActivityId,
  reloadData,
}: EditModalProps) => {
  const isOpen: boolean = useMemo(() => !!activityId, [activityId]);

  const setIsOpen = useCallback(
    (value: boolean): void => {
      if (!value) {
        setActivityId(null);
      }
    },
    [setActivityId]
  );

  const closeModal = useCallback((): void => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <CustomModal
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      titleText="Activity Details"
      width={318}
      headerClass="text w-500"
      modalClass="create-modal"
      destroyOnClose
    >
      <ActivityForm
        id={activityId}
        closeModal={closeModal}
        reloadData={reloadData}
      />
    </CustomModal>
  );
};
