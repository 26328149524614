import { ColumnsType } from "antd/es/table";
import { IUserCustomer } from "../../../../../../../types/User";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../../../../../../configs/routesConfig";
import { CloseOutlined } from "@ant-design/icons";
import { useButtonHandlers } from "./useButtonHandlers";

export const useGetTableColumns = (reloadData: () => void) => {
  const { onDeleteBtnClick } = useButtonHandlers(reloadData);

  const columns: ColumnsType<IUserCustomer> = useMemo(
    () => [
      {
        key: "number",
        dataIndex: "number",
        width: "90%",
        ellipsis: true,
        render: (value: number, record: IUserCustomer) => {
          const { customerId, fullName } = record;

          return (
            <Link
              target="_blank"
              title={fullName}
              to={`${ROUTES.CUSTOMERS}/${customerId}`}
              className="portal-access-table-link elipsis"
            >
              {value}: {fullName}
            </Link>
          );
        },
      },
      {
        key: "actions",
        width: "10%",
        render: (_: unknown, record: IUserCustomer) => {
          const { id } = record;

          return (
            <div className="ant-table-action">
              <CloseOutlined
                className="ant-table-btn"
                onClick={() => onDeleteBtnClick(id)}
              />
            </div>
          );
        },
      },
    ],
    [onDeleteBtnClick]
  );

  return { columns };
};
