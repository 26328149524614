import { API_URL } from "../../configs/apiUrl";
import { IImpoundsType } from "../../types/Impounds";
import authAxios, { authHeader } from "../data";

const fetchAll = async () => {
  const response = await authAxios.get(API_URL + "ImpoundTypes", {
    headers: authHeader(),
  });

  return response.data;
};

const remove = async (id: string) => {
  const response = await authAxios.delete(API_URL + "ImpoundTypes/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const create = async (req: IImpoundsType) => {
  const response = await authAxios.post(API_URL + "ImpoundTypes", req, {
    headers: authHeader(),
  });

  return response.data;
};

const edit = async (req: IImpoundsType, id: string) => {
  const response = await authAxios.put(API_URL + "ImpoundTypes/" + id, req, {
    headers: authHeader(),
  });

  return response.data;
};

const ImpoundsTypeService = {
  fetchAll,
  remove,
  create,
  edit
};

export default ImpoundsTypeService;