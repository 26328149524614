import { Button, Input, InputRef, Row, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";

type AccountNumberInputProps = {
  isLoading: boolean;
  init: (value: string | undefined, setError: (value: string) => void) => void;
};

export const AccountNumberInput = ({
  isLoading,
  init,
}: AccountNumberInputProps) => {
  const accountNumberRef = useRef<InputRef>(null);

  const [error, setError] = useState<string | null>(null);

  const onChange = () => setError(null);

  const onSearch = (): void => {
    const value = accountNumberRef.current?.input?.value;

    init(value, setError);
  };

  useEffect(() => accountNumberRef.current?.focus(), []);

  const status: "error" | undefined = useMemo(() => {
    return error ? "error" : undefined;
  }, [error]);

  return (
    <Row className="mt-4">
      <Space.Compact className="w-100">
        <Input
          placeholder="Input Escrow Account Number"
          ref={accountNumberRef}
          status={status}
          onChange={onChange}
          disabled={isLoading}
          onPressEnter={onSearch}
        />
        <Button className="px-2" onClick={onSearch} disabled={isLoading}>
          <CheckOutlined />
        </Button>
      </Space.Compact>
      {error && <span className="text text-sm text-color-red">{error}</span>}
    </Row>
  );
};
