import { Row } from "antd";
import { Tickler } from "../../BeforeReadModal/Tickler";
import { ITicklerExtended } from "../../../../../types/Tickler";
import { useMemo } from "react";

type ListProps = {
  ticklers: ITicklerExtended[] | null;
  showAll: boolean;
};

export const List = ({ ticklers, showAll }: ListProps) => {
  const data: ITicklerExtended[] = useMemo(() => {
    if (ticklers && ticklers.length > 0) {
      return showAll ? ticklers : ticklers.slice(0, 5);
    } else {
      return [];
    }
  }, [ticklers, showAll]);

  return (
    <Row className={`d-block ticklers-list ${showAll && "py-6 px-8"}`}>
      {data.length > 0 ? (
        data.map((item: ITicklerExtended, i: number) => (
          <Tickler tickler={item} key={i} />
        ))
      ) : (
        <Row className="mt-4">
          <p className="my-0 text-md title">No ticklers</p>
        </Row>
      )}
    </Row>
  );
};
