import { Form, FormInstance } from "antd";
import { Modal } from "../../../Modal";
import { FloatNumberInput } from "../../../../../../components/FloatNumberInput";
import { ModalFooter } from "../common/ModalFooter";
import { useButtonHandlers } from "./hooks/useButtonHandlers";
import "./styles.scss";

type TInterestAfterModalProps = {
  isOpen: boolean;
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  instance: any;
  setHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const InterestAfterModal = ({
  isOpen,
  isFormDisabled,
  isPaymentPosted,
  setIsOpen,
  instance,
  setHasChanges,
}: TInterestAfterModalProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const { close, onSaveClick } = useButtonHandlers(
    setIsOpen,
    form,
    instance,
    setHasChanges
  );

  return (
    <Modal
      title="Change Interest Balance"
      isOpen={isOpen}
      footer={
        <ModalFooter
          isFormDisabled={isFormDisabled}
          isPaymentPosted={isPaymentPosted}
          closeModal={close}
          onSaveClicked={onSaveClick}
        />
      }
      width={260}
      className="terms-modal interest-after-modal"
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className="px-6 pt-6 pb-3"
      >
        <FloatNumberInput
          form={form}
          name="interestAfter"
          label="Unpaid Interest Balance After"
        />
      </Form>
    </Modal>
  );
};
