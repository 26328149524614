import { Button, Checkbox, Form, FormInstance, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { TRecordHeaderData } from "../../types";
import { useCallback, useMemo } from "react";
import { BATCH_TYPES } from "../../../../../../../../lib/constants";
import { useButtonHandlers } from "../../hooks/useButtonHandlers";

type THeaderProps = {
  back: () => void;
  data: TRecordHeaderData | null;
  isLoading: boolean;
  reloadData: () => void;
  paymentId: string | null;
  setIsPaymentEdited: React.Dispatch<React.SetStateAction<boolean>>;
  isPaymentEdited: boolean;
  form: FormInstance;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled: boolean;
  instance: any;
  customerIds: any;
  setInitialForm: () => void;
  setOpenEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  contractId: string;
};

export const Header = ({
  back,
  data,
  isLoading,
  reloadData,
  paymentId,
  setIsPaymentEdited,
  isPaymentEdited,
  form,
  setIsDisabled,
  isDisabled,
  instance,
  customerIds,
  setInitialForm,
  setOpenEditMode,
  contractId,
}: THeaderProps) => {
  const { onDeleteBtnClick, onCancelClick, onSaveClick } = useButtonHandlers({
    reloadData,
    form,
    setIsPaymentEdited,
    setIsDisabled,
    instance,
    customerIds,
    setInitialForm,
    setOpenEditMode,
  });

  const batchType = useMemo(
    () => BATCH_TYPES.find((type) => type.value === data?.type)?.label,
    [data]
  );

  const onDeleteHistoryRecord = useCallback(
    () => onDeleteBtnClick(paymentId || ""),
    [onDeleteBtnClick, paymentId]
  );

  const onEditHistoryRecord = useCallback(() => {
    setIsPaymentEdited(true);
  }, [setIsPaymentEdited]);

  const onSaveHistoryRecord = useCallback(() => {
    onSaveClick(paymentId, contractId);
  }, [onSaveClick, paymentId, contractId]);

  const onCancelHistoryRecord = useCallback(() => {
    onCancelClick();
    paymentId === null && back();
  }, [back, onCancelClick, paymentId]);

  const title = useMemo(
    () =>
      paymentId === null
        ? "Create Payment History"
        : `${isPaymentEdited ? "Edit" : ""} Payment History: ${
            data?.paymentNumber
          }`,
    [data, isPaymentEdited, paymentId]
  );

  const headerClassname = useMemo(
    () =>
      `w-100 py-5 px-8 d-flex ${
        isLoading ? "justify-end" : "justify-space-between"
      } align-center payment-history-record-header`,
    [isLoading]
  );

  return (
    <Row className={headerClassname}>
      {isLoading ? (
        ""
      ) : (
        <div className="d-flex align-center">
          {!isPaymentEdited && (
            <Button
              shape="circle"
              icon={<ArrowLeftOutlined />}
              size="large"
              className="back-btn mr-6"
              onClick={back}
            />
          )}
          <>
            <p className="h h3 pr-8">{title}</p>
            {!(paymentId === null) && batchType && (
              <p className="h h5 pl-8 header-info">
                {batchType}: {data?.batchNumber}
              </p>
            )}
          </>
        </div>
      )}
      <div className="header-actions d-flex align-center">
        {!isPaymentEdited ? (
          <>
            <Button
              shape="round"
              className="mr-4"
              disabled={isLoading}
              onClick={onEditHistoryRecord}
            >
              Edit
            </Button>
            <Button
              shape="round"
              disabled={isLoading}
              onClick={onDeleteHistoryRecord}
            >
              Delete
            </Button>
          </>
        ) : (
          <>
            <Form form={form} disabled={isDisabled}>
              <Form.Item
                name="recalculateLaterPayments"
                valuePropName="checked"
                className="ma-0"
              >
                <Checkbox>Recalculate later payments</Checkbox>
              </Form.Item>
            </Form>
            <Button
              shape="round"
              className="mx-4"
              type="primary"
              disabled={isDisabled || isLoading}
              onClick={onSaveHistoryRecord}
            >
              {paymentId === null ? "Save" : "Save Changes"}
            </Button>
            <Button
              shape="round"
              onClick={onCancelHistoryRecord}
              disabled={isDisabled || isLoading}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </Row>
  );
};
