import { useParams } from "react-router-dom";
import { useGetRole } from "../../lib/hooks/getRole";
import { AdminView } from "./components/View/Admin";
import { UserView } from "./components/View/User";
import "./styles.scss";

export const Account = () => {
  const { accountId } = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  return isAdmin ? (
    <AdminView contractId={accountId || ""} />
  ) : (
    <UserView contractId={accountId || ""} />
  );
};
