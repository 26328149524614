import { FormInstance } from "antd";
import { useCallback } from "react";
import { useAppDispatch } from "../../../../../../../store";
import { useDefaultDeleteModal } from "../../../../../../../lib/hooks/defaultDeleteModal";
import {
  deletePayoffQuote,
  createPayoffQuote,
  editPayoffQuote,
} from "../../../../../../../acions/accounts/payoffQuote";
import { IPayoffQuotePost } from "../../../../../../../types/PayoffQute";
import { useFormatData } from "../components/PayoffForm/hooks/useFormatData";

type TButtonHandlersProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  reloadData: () => void;
  parentId?: string;
  form?: FormInstance;
  setIsDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useButtonHandlers = ({
  setIsOpen,
  setId,
  reloadData,
  parentId,
  form,
  setIsDisabled,
}: TButtonHandlersProps) => {
  const dispatch = useAppDispatch();

  const { openDeleteModal } = useDefaultDeleteModal(
    deletePayoffQuote,
    reloadData,
    setIsOpen
  );

  const { getFormattedOutcomingData } = useFormatData();

  const onDeleteBtnClick = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Payoff Quote");
    },
    [openDeleteModal]
  );

  const onCancelBtnClick = useCallback(() => {
    setId(null);
    setIsOpen(false);
  }, [setId, setIsOpen]);

  const onSuccess = useCallback(
    (isNew = false) => {
      setIsDisabled && setIsDisabled(false);
      setIsOpen(isNew);
      setId(null);
      reloadData();
    },
    [reloadData, setId, setIsDisabled, setIsOpen]
  );

  const create = useCallback(
    async (req: IPayoffQuotePost, isNew: boolean) => {
      try {
        await Promise.resolve(dispatch(createPayoffQuote(req)));

        form?.resetFields();
        onSuccess(isNew);
      } catch (error) {
        setIsDisabled && setIsDisabled(false);
      }
    },
    [dispatch, form, onSuccess, setIsDisabled]
  );

  const edit = useCallback(
    async (id: string, req: IPayoffQuotePost) => {
      try {
        await Promise.resolve(dispatch(editPayoffQuote(req, id)));

        onSuccess();
      } catch (error) {
        setIsDisabled && setIsDisabled(false);
      }
    },
    [dispatch, onSuccess, setIsDisabled]
  );

  const onSaveBtnClick = useCallback(
    async (isNew: boolean, id: string | null) => {
      try {
        setIsDisabled && setIsDisabled(true);

        const formData = await form?.validateFields();

        const req = getFormattedOutcomingData(formData, parentId)

        id ? edit(id, req) : create(req, isNew);
      } catch (error) {
        setIsDisabled && setIsDisabled(false);
      }
    },
    [create, edit, form, getFormattedOutcomingData, parentId, setIsDisabled]
  );

  return {
    onDeleteBtnClick,
    onCancelBtnClick,
    onSaveBtnClick,
  };
};
