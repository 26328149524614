import { FormInstance } from "antd";
import { useCallback } from "react";

export const useButtonHandlers = (
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  form: FormInstance,
  instance: any,
  setHasChanges?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const close = useCallback(() => {
    setIsOpen(false);
    form.resetFields();
  }, [form, setIsOpen]);

  const onSaveClick = useCallback(async () => {
    try {
      const { interestAfter } = await form.validateFields();

      instance.breakdown.interestAfter = interestAfter;

      setHasChanges && setHasChanges(true);

      close();
    } catch (error) {}
  }, [close, form, instance, setHasChanges]);

  return { close, onSaveClick };
};
