import { Col, Row } from "antd";
import { IBatchGet } from "../../../../../../types/Batches";
import { getFormattedCurrency } from "../../../../../../lib/currencyFormatter";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../../../lib/constants";
import { DataValueItem } from "./DataValueItem";
import "./styles.scss";

type SubHeaderProps = {
  data: IBatchGet;
  isWarehouse: boolean;
  setData: (value: IBatchGet) => void;
};

export const SubHeader = ({ data, isWarehouse, setData }: SubHeaderProps) => {
  const updateDataPaymentDate = (value: string): void => {
    setData({ ...data, paymentsDate: value });
  };

  return (
    <Row className="px-8 align-center title justify-space-between header-container">
      <Col className="d-flex align-center">
        <DataValueItem
          header="Batch Date"
          value={dayjs(data.date).format(DATE_FORMAT)}
        />
        {!isWarehouse && (
          <DataValueItem
            header="Payment Date"
            value={dayjs(data.paymentsDate || data.date).format(DATE_FORMAT)}
            editable
            updateDataPaymentDate={updateDataPaymentDate}
            data={data}
          />
        )}
      </Col>
      <Col className="d-flex">
        <DataValueItem
          header="Total Amount"
          value={getFormattedCurrency(data.totalAmount || 0)}
        />
      </Col>
    </Row>
  );
};
