import { useEffect, useState } from "react";
import InfoService from "../../../../../../services/info";

export const useFetchYears = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [years, setYears] = useState<{ value: string; label: number }[]>([]);

  useEffect(() => {
    InfoService.fetchYears()
      .then((res: number[]) => {
        if (res[0] !== 0) {
          const options = res.map((item: number) => ({
            value: item.toString(),
            label: item,
          }));
          setYears(options);
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  }, []);

  return { years, isLoading };
};
