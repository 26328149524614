import { ReactNode, useMemo, useState } from "react";
import { ReasonModal } from "../ReasonModal";

export const useReasonModal = (
  userId: string | null,
  reloadData: () => void
) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const [isBlock, setIsBlock] = useState<boolean>(false);

  const modal: ReactNode = useMemo(() => {
    return (
      <ReasonModal
        isModalOpen={showModal}
        setIsModalOpen={setShowModal}
        isBlock={isBlock}
        userId={userId}
        reloadData={reloadData}
      />
    );
  }, [isBlock, reloadData, showModal, userId]);

  return { modal, setShowModal, setIsBlock };
};
