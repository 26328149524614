import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useGetColumnSortValue = (param = "order") => {
  const [searchParams] = useSearchParams();

  const getColumnSortValue = useCallback(
    (ascVal: string, descVal: string) => {
      const order = searchParams.get(param);

      if (order === ascVal) {
        return "ascend";
      }

      if (order === descVal) {
        return "descend";
      }

      return undefined;
    },
    [searchParams, param]
  );

  return { getColumnSortValue };
};
