import { ReactNode, useMemo, useState } from "react";
import { SideBarContainer } from "../../../../../../components/SideBarContainer";
import { Sidebar } from "../Sidebar";

export const useOpenSidebar = (
  customerId: string | null | undefined,
  isNextPrimary: boolean,
  reloadData: () => void
) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const onCloseSideBar = (): void => setOpen(false);

  const sideBar: ReactNode = useMemo(() => {
    return (
      <SideBarContainer title="Add User" setShowSideBar={setOpen}>
        <Sidebar
          customerId={customerId}
          isNextPrimary={isNextPrimary}
          closeSideBar={onCloseSideBar}
          reloadData={reloadData}
        />
      </SideBarContainer>
    );
  }, [customerId, reloadData, isNextPrimary]);

  return { sideBar, isOpen, setOpen };
};
