import { API_URL } from "../../configs/apiUrl";
import { IPayersPost } from "../../types/Payer";
import authAxios, { authHeader } from "../data";

const fetchAll = async (req: any) => {
  const response = await authAxios.get(API_URL + "ContractPayers", {
    params: req,
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (req: any) => {
  const response = await authAxios.get(API_URL + "ContractPayers/Count", {
    params: req,
    headers: authHeader(),
  });

  return response.data;
};

const fetch = async (id: string) => {
  const response = await authAxios.get(API_URL + "ContractPayers/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const remove = async (id: string) => {
  const response = await authAxios.delete(API_URL + "ContractPayers/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const create = async (req: IPayersPost) => {
  const response = await authAxios.post(API_URL + "ContractPayers", req, {
    headers: authHeader(),
  });

  return response.data;
};

const edit = async (id: string, req: IPayersPost) => {
  const response = await authAxios.put(API_URL + "ContractPayers/" + id, req, {
    headers: authHeader(),
  });

  return response.data;
};

const PayersService = {
  fetchAll,
  fetchCount,
  remove,
  create,
  fetch,
  edit,
};

export default PayersService;
