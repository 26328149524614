import { FormInstance } from "antd";
import { IPayeeDisbursementsBase } from "../../../../../../types/PayeeDisbursements";

export const useSaveRecord = (
  disbursements: IPayeeDisbursementsBase[],
  reset: (value: IPayeeDisbursementsBase[]) => any
) => {
  const saveRecord = async (
    form: FormInstance,
    customerIds: any,
    close: () => void,
    isNew: boolean,
    index: string | null,
    customerName?: string
  ) => {
    try {
      let res = await form.validateFields();

      let updatedDisbursementArr = [...disbursements];

      res = {
        ...res,
        ...customerIds,
        payeeName: customerName,
        payeeNumber: res.payeeId,
      };

      if (!index) {
        updatedDisbursementArr.push(res);
      } else {
        const itemIndex = updatedDisbursementArr.findIndex(
          (item: IPayeeDisbursementsBase) => item.index === index
        );

        if (itemIndex > -1) {
          updatedDisbursementArr[itemIndex] = {
            ...updatedDisbursementArr[itemIndex],
            ...res,
          };
        }
      }

      await reset(updatedDisbursementArr);

      !isNew && close();
    } catch {}
  };

  return { saveRecord };
};
