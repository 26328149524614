import { Button } from "antd";

type SearchMessageProps = {
  activeTab: string;
  onChangeTab: (value: string) => void;
};

export const SearchMessage = ({
  activeTab,
  onChangeTab,
}: SearchMessageProps) => {
  return (
    <div className="ant-form-item-explain-error position-absolute search-message">
      {activeTab === "1"
        ? "The email already exists."
        : "No users with this email."}
      <Button
        type="link"
        className="pl-1 pr-0 px-0 text-sm"
        onClick={() => onChangeTab(activeTab === "1" ? "2" : "1")}
      >
        {activeTab === "1" ? "Select from existing" : "Create new"}
      </Button>
    </div>
  );
};
