import { Button, Row } from "antd";
import { ITicklerExtended } from "../../../../../types/Tickler";
import { ReactNode, useMemo, useState } from "react";
import { List } from "./List";
import { Modal } from "../../Modal";

type TicklersProps = {
  ticklers: ITicklerExtended[] | null;
};

export const Ticklers = ({ ticklers }: TicklersProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const modalTitle: ReactNode = useMemo(() => {
    return (
      <Row className="align-center">
        <h5 className="h h5 ml-4">Ticklers</h5>
      </Row>
    );
  }, []);

  const modalFooter: ReactNode = useMemo(
    () => (
      <Button
        shape="round"
        type="primary"
        onClick={() => setIsModalOpen(false)}
      >
        Close
      </Button>
    ),
    []
  );

  return (
    <Row className="d-block">
      <List ticklers={ticklers} showAll={false} />
      {ticklers && ticklers.length > 5 && (
        <Row>
          <Button
            type="link"
            className="px-0 py-0 ml-4 text-md"
            size="small"
            onClick={() => setIsModalOpen(true)}
          >
            Show All
          </Button>
        </Row>
      )}
      <Modal title={modalTitle} footer={modalFooter} isOpen={isModalOpen}>
        <List ticklers={ticklers} showAll />
      </Modal>
    </Row>
  );
};
