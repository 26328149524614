import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../actionsCreators/messageActions";
import {
  UPDATE_RECORD_SUCCESS,
} from "../../lib/constants";
import LegalService from "../../services/accounts/legal";

export const updateLegal =
  (contractId: string, req: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await LegalService.edit(contractId, req);

      dispatch(setSuccessMessage(UPDATE_RECORD_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };