import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import { IFeeType } from "../../types/Fees";
import authAxios, { authHeader } from "../data";

const fetchAll = async (req: any) => {
  const response = await authAxios.get(API_URL + "FeeTypes", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async () => {
  const response = await authAxios.get(API_URL + "FeeTypes/Count", {
    headers: authHeader(),
  });

  return response.data;
};

const fetch = async (req: any) => {
  const response = await authAxios.get(API_URL + "FeeTypes/" + req.id, {
    headers: authHeader(),
  });

  return response.data;
};

const create = async (entry: IFeeType) => {
  const response = await authAxios.post(API_URL + "FeeTypes/", entry, {
    headers: authHeader(),
  });

  return response.data;
};

const edit = async (entry: IFeeType, id: string) => {
  const response = await authAxios.put(API_URL + "FeeTypes/" + id, entry, {
    headers: authHeader(),
  });

  return response.data;
};

const deleteFee = async (req: any) => {
  const response = await authAxios.delete(API_URL + "FeeTypes/" + req.id, {
    headers: authHeader(),
  });

  return response.data;
};

const FeesService = {
  fetchAll,
  fetchCount,
  fetch,
  create,
  edit,
  deleteFee,
};

export default FeesService;
