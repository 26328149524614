import { useState, useMemo, useEffect } from "react";
import { Select as SelectAntd } from "antd";
import "./styles.scss";

type SelectProps = {
  isLoading: boolean;
  options: any[];
  onChange: (values: any[]) => void;
  label: string;
  defaultValue?: any[];
  maxTagCount: number | "responsive";
};

export const MultiSelect = ({
  isLoading,
  options,
  onChange,
  label,
  defaultValue,
  maxTagCount,
}: SelectProps) => {
  const selectItems = useMemo(() => {
    return [{ label: "All", value: "All" }, ...options];
  }, [options]);

  const [selected, setSelected] = useState<any[]>(["All"]);

  const [isAll, setIsAll] = useState<boolean>(true);

  useEffect(() => {
    if (defaultValue && defaultValue[0] !== "All") {
      setSelected(defaultValue);
      setIsAll(false);
    }

    if (!defaultValue) {
      setSelected(["All"]);
      setIsAll(true);
    }
  }, [defaultValue]);

  const handleChange = (values: any) => {
    let items: string[] = [];
    if (values.includes("All")) {
      items =
        values[values.length - 1] === "All"
          ? ["All"]
          : values.filter((item: string) => item !== "All");
    } else {
      items =
        values.length === options.length || values.length === 0
          ? ["All"]
          : values;
    }

    setIsAll(!!(items.length === 1 && items[0] === "All"));
    setSelected(items);
    onChange(items);
  };

  return (
    <div className="d-flex align-center select-box position-relative">
      {isAll && <span className="position-absolute all-tag z-100">All</span>}
      <span className="position-absolute z-100 label">{label}:</span>
      <SelectAntd
        mode="multiple"
        value={selected}
        options={selectItems}
        loading={isLoading}
        className={`select-list ${isAll ? "all-selected" : ""}`}
        onChange={handleChange}
        maxTagCount={maxTagCount}
      />
    </div>
  );
};
