import { Form, Input } from "antd";
import { useState, useEffect, useMemo, useCallback, ReactNode } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useGetEmailFormRules } from "../../../../../../lib/hooks/getEmailFormRules";

type ToggleAlternateEmailProps = {
  hasAlternateEmail: boolean;
};

export const ToggleAlternateEmail = ({
  hasAlternateEmail,
}: ToggleAlternateEmailProps) => {
  const [isAddAlternateEmail, setIsAddAternateEmail] = useState<boolean>(false);

  const { rules } = useGetEmailFormRules();

  const formattedRules = useMemo(() => rules.slice(0, -1), [rules]);

  useEffect(() => {
    setIsAddAternateEmail(hasAlternateEmail);
  }, [hasAlternateEmail]);

  const toggleEmail = useCallback((): void => {
    setIsAddAternateEmail(!isAddAlternateEmail);
  }, [isAddAlternateEmail]);

  const label = useMemo((): ReactNode => {
    return (
      <div className="d-flex align-center toggle-label" onClick={toggleEmail}>
        Alternate Email <CloseOutlined className="ml-2" />
      </div>
    );
  }, [toggleEmail]);

  return !isAddAlternateEmail ? (
    <p className="my-0 text-sm expand-btn" onClick={toggleEmail}>
      + Alternate Email
    </p>
  ) : (
    <Form.Item
      label={label}
      name={["alternativeEmail"]}
      className="w-100 mb-0"
      rules={formattedRules}
    >
      <Input />
    </Form.Item>
  );
};
