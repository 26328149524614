import { Card, Row, Table } from "antd";
import { Filters } from "./Filters";
import { useCallback, useMemo, useState } from "react";
import { useColumns } from "./hooks/useColumns";
import { useFetchCount } from "./hooks/fetchCount";
import { SimpleTablePagination } from "../../../../../components/SimpleTablePagination";
import { useDefaultTableScrollProp } from "../../../../Customers/hooks/defaultTableScrollProp";
import { useFetchData } from "./hooks/useFetchData";
import dayjs from "dayjs";

type PaymentsProps = {
  customerId: string;
};

export const Payments = ({ customerId }: PaymentsProps) => {
  const currentYear: string = useMemo(() => dayjs().format("YYYY"), []);

  const [request, setRequest] = useState<any>({
    pageNumber: 1,
    pageSize: 50,
    year: currentYear,
  });

  const { data, isLoading } = useFetchData(customerId, request);

  const { columns } = useColumns();

  const { total, isLoading: isTotalLoading } = useFetchCount(
    customerId,
    request
  );

  const { scroll } = useDefaultTableScrollProp(data);

  const onPageChange = useCallback(
    (pageNumber: number) => {
      const newRequest = { ...request, pageNumber };

      setRequest(newRequest);
    },
    [request]
  );

  return (
    <Row className="py-6 px-8">
      <Row className="w-100">
        <Filters
          isDataLoading={isLoading}
          request={request}
          setRequest={setRequest}
          currentYear={currentYear}
        />
      </Row>
      <Row className="mt-4 w-100">
        <Card className="w-100">
          <Table
            dataSource={data}
            columns={columns}
            loading={isLoading}
            pagination={false}
            rowKey="id"
            scroll={scroll}
            showSorterTooltip={false}
            sticky
          />
          {total > 50 && (
            <SimpleTablePagination
              isLoading={isTotalLoading}
              total={total}
              pageNumber={request.pageNumber}
              onPageChange={onPageChange}
              pageSize={50}
            />
          )}
        </Card>
      </Row>
    </Row>
  );
};
