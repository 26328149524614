import { ColumnsType } from "antd/es/table";
import { useMemo } from "react";
import { useStatusTag } from "../../../../../../Accounts/hooks/statusTag";
import { Link } from "react-router-dom";
import ROUTES from "../../../../../../../configs/routesConfig";

export const useGetAccountsTableColumns = () => {
  const { getStatus } = useStatusTag();

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Account Number",
        dataIndex: "accountNumber",
        ellipsis: true,
        width: "20%",
        render: (value: number, record: any) => (
          <div className="cell-elipsis">
            <Link to={`${ROUTES.ACCOUNTS}/${record.id}`} target="_blank">
              <span className="link-btn">{value}</span>
            </Link>
          </div>
        ),
      },
      {
        title: "Seller",
        dataIndex: "seller",
        ellipsis: true,
        width: "32%",
      },
      {
        title: "Buyer",
        dataIndex: "buyer",
        ellipsis: true,
        width: "32%",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (value: string | number) => getStatus(value),
        width: "16%",
      },
    ],
    [getStatus]
  );

  return { columns };
};
