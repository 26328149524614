import { Form, FormInstance, Row } from "antd";
import { SidePanel } from "./SidePanel";
import { PaymentForm } from "../../../../../../../Payment/components/PaymentForm";
import { Header } from "./Header";
import { useFetchRecord } from "../../hooks/useFetchRecord";
import { useSetPayment } from "../../../../../../../Payment/components/PaymentForm/hooks/useSetPayment";
import { useCallback, useEffect, useState } from "react";
import { useGetInpurSearchIds } from "../../../../../../../Accounts/components/Filters/hooks/getInputSearchIds";
import { useCalculate } from "../../hooks/useCalculate";
import { Loader } from "../../../../../../../../components/Loader";

type TPaymentRecordsProps = {
  backToAccountDetails: () => void;
  accountNumber: number;
  back: () => void;
  paymentId: string | null;
  reloadData: () => void;
  contractId: string;
  openEditMode: boolean;
  setOpenEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PaymentRecord = ({
  accountNumber,
  backToAccountDetails,
  back,
  paymentId,
  reloadData,
  contractId,
  openEditMode,
  setOpenEditMode,
}: TPaymentRecordsProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [instance, setInstance] = useState<any>(null);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [isPaymentEdited, setIsPaymentEdited] = useState<boolean>(openEditMode);

  const { isLoading, initialData, setInitialData, headerData } = useFetchRecord(
    { id: paymentId, contractId, form }
  );

  const { setForm } = useSetPayment(form, setInstance, setInitialData);

  const setInitialForm = useCallback(() => {
    setForm(initialData?.payment, false);
  }, [initialData, setForm]);

  const { hasPayeeId, onCustomerFound, customerIds } = useGetInpurSearchIds();

  const { calculate } = useCalculate(
    form,
    instance,
    contractId,
    setIsDisabled,
    setForm
  );

  useEffect(() => setInitialForm(), [setInitialForm]);

  useEffect(() => {
    if (openEditMode && !isPaymentEdited) {
      setIsPaymentEdited(true);
    }
  }, [isPaymentEdited, openEditMode]);

  return (
    <Row className="position-absolute payment-history-record">
      <Header
        back={back}
        data={headerData}
        isLoading={isLoading}
        reloadData={reloadData}
        paymentId={paymentId}
        setIsPaymentEdited={setIsPaymentEdited}
        isPaymentEdited={isPaymentEdited}
        form={form}
        setIsDisabled={setIsDisabled}
        isDisabled={isDisabled}
        instance={instance}
        customerIds={customerIds}
        setInitialForm={setInitialForm}
        setOpenEditMode={setOpenEditMode}
        contractId={contractId}
      />
      <Row className="w-100 payment-history-record-body-wrapper">
        <Row className="d-flex payment-history-record-body pb-4 justify-center">
          {isPaymentEdited && isLoading ? (
            <Loader fontSize="64px" />
          ) : (
            <>
              <SidePanel
                backToAccountDetails={backToAccountDetails}
                accountNumber={accountNumber}
                instance={instance}
                isPaymentEdited={isPaymentEdited}
                form={form}
                hasPayeeId={hasPayeeId}
                onCustomerFound={onCustomerFound}
                isDisabled={isDisabled}
                paymentId={paymentId}
              />
              <PaymentForm
                isLoading={isLoading || isDisabled}
                isFormBlured={false}
                instance={instance}
                form={form}
                isPaymentPosted={!isPaymentEdited}
                calculate={calculate}
                isHistoryOpened={true}
                isHistoryEdited={isPaymentEdited}
              />
            </>
          )}
        </Row>
      </Row>
    </Row>
  );
};
