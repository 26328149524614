import { FormInstance } from "antd";
import { FloatNumberInput } from "../../../../../../../../../components/FloatNumberInput";

type TFeeOverrideProps = {
  form: FormInstance;
  isFormDisabled: boolean;
};

export const FeeOverridePart = ({
  form,
  isFormDisabled,
}: TFeeOverrideProps) => {
  return (
    <>
      <p className="mt-0 mb-2 text-md w-500">Fees Override</p>
      <p className="mt-0 mb-4">
        Enter Fee Amount if Payee fees are not to be proportional according to
        payment amount.
      </p>
      <div className="d-flex mb-4">
        <FloatNumberInput
          name="overrideFeeAmount"
          label="Fee"
          form={form}
          disabled={isFormDisabled}
        />
        <FloatNumberInput
          name="overrideFeeTax"
          label="Fee Tax"
          form={form}
          disabled={isFormDisabled}
          className="ml-2"
        />
      </div>
    </>
  );
};
