import { Col, Row, Form, Button, Input } from "antd";
import { Header } from "../../../components/SecurityCheck/Header";
import { useValidateOnBlur } from "../../../lib/hooks/validateOnBlur";
import { useGetEmailFormRules } from "../../../lib/hooks/getEmailFormRules";
import { useCallback } from "react";

type SecondStepProps = {
  onBackClicked: () => void;
  setNewEmail: (value: string) => void;
};

export const SecondStep = ({ onBackClicked, setNewEmail }: SecondStepProps) => {
  const [form] = Form.useForm();

  const [validateOnBlur] = useValidateOnBlur(form);

  const onConfirm = useCallback(
    (values: any) => {
      setNewEmail(values.email);
    },
    [setNewEmail]
  );

  const { rules } = useGetEmailFormRules();

  const onBlur = useCallback(
    (e: any) => validateOnBlur(e, "email"),
    [validateOnBlur]
  );

  return (
    <Row className="mt-16 justify-center">
      <Col className="security-check-content mb-8">
        <Header header="Enter your new email" onBackClicked={onBackClicked} />

        <Row className="mt-10 d-flex justify-center">
          <Col className="form-content">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              requiredMark={false}
              className="form__container"
              onFinish={onConfirm}
            >
              <Form.Item
                name="email"
                label="Email"
                className="mb-5"
                rules={rules}
              >
                <Input size="large" onBlur={onBlur} />
              </Form.Item>
              <Form.Item className="mt-3 mb-12">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  block
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
