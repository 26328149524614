import { Row, Tabs, TabsProps } from "antd";
import { useCallback, useMemo, useState } from "react";
import { Details } from "./Details";
import { PaymentHistory } from "./PaymentHistory";
import { SearchHeader } from "../../SearchHeader";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useGetLastUpdate } from "../../../../../lib/hooks/getLastUpdate";
import { useSetLastUpdated } from "../Admin/Details/hooks/setLastUpdated";

type UserViewProps = {
  contractId: string;
};

export const UserView = ({ contractId }: UserViewProps) => {
  const [data, setData] = useState<any>(null);

  const navigate: NavigateFunction = useNavigate();

  const { lastUpdate } = useGetLastUpdate();

  const { lastUpdated } = useSetLastUpdated(data, lastUpdate);

  const tabItems: TabsProps["items"] = useMemo(() => {
    return [
      {
        key: "1",
        label: "Account Details",
        children: (
          <Details accountNumber={contractId} setHeaderData={setData} />
        ),
      },
      {
        key: "2",
        label: "Payment History",
        children: <PaymentHistory accountNumber={contractId} />,
      },
    ];
  }, [contractId]);

  const onBackButtonClicked = useCallback(() => navigate(-1), [navigate]);

  return (
    <div className="logged-in__page position-absolute account-view__container">
      <Row className="py-5 px-8 align-center title justify-space-between header-container">
        <SearchHeader data={data} onBackButtonClicked={onBackButtonClicked} />
      </Row>
      <Row className={`account-tabs user`}>
        <Tabs items={tabItems} tabBarExtraContent={lastUpdated} />
      </Row>
    </div>
  );
};
