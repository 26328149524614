import { useMemo } from "react";
import { KPI_CODES } from "../kpiCodes";

export const useGetTotals = (data: any) => {
  const totalImpound: any = useMemo(() => {
    if (data) {
      const value = data.kpiValues.find(
        (item: any) => item.code === KPI_CODES.IMPOUNDS_TOTAL_BALANCE
      )?.value;

      return value;
    }

    return null;
  }, [data]);

  const paymnentAmounts: any = useMemo(() => {
    if (data) {
      const value = data.kpiValues
        .filter(
          (item: any) =>
            item.code === KPI_CODES.PAYMENTS_THIS_MONTH ||
            item.code === KPI_CODES.PAYMENTS_LAST_DAY
        )
        .map((item: any) => ({
          label:
            item.code === KPI_CODES.PAYMENTS_THIS_MONTH
              ? "thisMonth"
              : "lastDay",
          value: item.value,
        }));

      return value;
    }

    return null;
  }, [data]);

  return { totalImpound, paymnentAmounts };
};
