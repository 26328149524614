import { ColumnsType } from "antd/es/table";
import { ILateChargeExtended } from "../../../../../../../types/LateCharges";
import { useMemo } from "react";
import {
  LATE_CHARGES_ASSESS_METHOD,
  LATE_CHARGES_TYPE,
} from "../../../../../../../lib/constants";
import { getFormattedPercentage } from "../../../../../../../lib/percentageFormatter";
import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";

export const useGetColumns = () => {
  const columns: ColumnsType<ILateChargeExtended> = useMemo(
    () => [
      {
        title: "Assess after",
        dataIndex: "assessAfterDays",
        width: "22%",
        key: "assessAfterDays",
        render: (value: number) => {
          const measure = value === 1 ? "day" : "days";

          return (
            <div>
              {value} {measure}
            </div>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        width: "32%",
        key: "type",
        ellipsis: true,
        render: (value: number) => {
          const finalValue = LATE_CHARGES_TYPE.find(
            (type) => type.value === value
          )?.label;
          return finalValue;
        },
      },
      {
        title: "Amount",
        dataIndex: "amount",
        width: "16%",
        key: "amount",
        align: "right",
        ellipsis: true,
        render: (value: number, record: ILateChargeExtended) => {
          const { type, percentage } = record;
          let finalValue;

          if (type === 2 || type === 3) {
            finalValue = getFormattedPercentage(percentage);
          } else {
            finalValue = getFormattedCurrency(value);
          }

          return finalValue;
        },
      },
      {
        title: "Assess Method",
        dataIndex: "assessMethod",
        width: "30%",
        key: "assessMethod",
        ellipsis: true,
        render: (value: number) => {
          const finalValue = LATE_CHARGES_ASSESS_METHOD.find(
            (method) => method.value === value
          )?.label;

          return finalValue;
        },
      },
    ],
    []
  );

  return { columns };
};
