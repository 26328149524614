import { useCallback, useEffect, useState } from "react";
import { ValidationStep } from "../components/VerificationStep";
import { useNavigate } from "react-router-dom";
import { SecondStep } from "./SecondStep";
import { useAppDispatch } from "../../../store";
import { changeEmail } from "../../../acions/profiles";
import { useGetEmailFromLocation } from "../../../lib/hooks/getEmailfromLocation";
import ROUTES from "../../../configs/routesConfig";

export const ChangeEmail = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [step, setStep] = useState<string>("first");

  const [newEmail, setNewEmail] = useState<string>("");

  const [email] = useGetEmailFromLocation();

  useEffect(() => {
    newEmail && setStep("third");
  }, [newEmail]);

  const onConfirmSuccess = useCallback(() => {
    return Promise.resolve(dispatch(changeEmail(newEmail)));
  }, [dispatch, newEmail]);

  return (
    <>
      {step === "first" && (
        <ValidationStep
          setStep={() => setStep("second")}
          type="email"
          onBack={() => navigate(ROUTES.PROFILE)}
          subHeaderText="We just want to be sure it’s you."
          headerText="Quick security check"
          email={email}
        />
      )}
      {step === "second" && (
        <SecondStep
          onBackClicked={() => setStep("first")}
          setNewEmail={setNewEmail}
        />
      )}
      {step === "third" && (
        <ValidationStep
          setStep={() => navigate(ROUTES.PROFILE)}
          onBack={() => setStep("second")}
          type="email"
          onConfirmSuccess={onConfirmSuccess}
          subHeaderText="Let us know this new email belongs to you."
          headerText="Enter the code from your email"
          email={newEmail}
        />
      )}
    </>
  );
};
