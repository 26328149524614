import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import { ITicklerType } from "../../types/Tickler";
import authAxios, { authHeader } from "../data";

const fetchAll = async (req: any) => {
  const response = await authAxios.get(API_URL + "TicklerTypes", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (req: any) => {
  const response = await authAxios.get(API_URL + "TicklerTypes/Count", {
    headers: authHeader(),
  });

  return response.data;
};

const fetch = async (id: string) => {
  const response = await authAxios.get(API_URL + "TicklerTypes/" + id, {
    headers: authHeader()
  });

  return response.data;
};

const deleteTicklersType = async (id: string) => {
  const response = await authAxios.delete(API_URL + "TicklerTypes/" + id, {
    headers: authHeader()
  });

  return response.data;
};

const createTicklersType = async (req: ITicklerType) => {
  const response = await authAxios.post(API_URL + "TicklerTypes", req, {
    headers: authHeader()
  });

  return response.data;
};

const editTicklersType = async (req: ITicklerType, id: string) => {
  const response = await authAxios.put(API_URL + "TicklerTypes/" + id, req, {
    headers: authHeader()
  });

  return response.data;
};

const TicklersTypeService = {
  fetchAll,
  fetchCount,
  fetch,
  deleteTicklersType,
  createTicklersType,
  editTicklersType,
};

export default TicklersTypeService;