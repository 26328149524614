import { Button, Row } from "antd";

type FormButtonsProps = {
  isFormDisabled: boolean;
  record: any;
  isPaymentPosted: boolean;
  cancel: () => void;
  onSaveClick: (isNew: boolean) => void;
  openDeleteModal: (id: string, onDeleted?: () => void) => void;
};

export const FormButtons = ({
  isFormDisabled,
  record,
  isPaymentPosted,
  cancel,
  onSaveClick,
  openDeleteModal,
}: FormButtonsProps) => {
  return (
    <Row className="default-form-buttons w-100 pa-6 divider d-flex justify-space-between position-absolute">
      <div className="d-flex default-form-buttons-main">
        {!isPaymentPosted && (
          <>
            <Button
              shape="round"
              type="primary"
              disabled={isFormDisabled}
              loading={isFormDisabled}
              onClick={() => onSaveClick(false)}
            >
              {record.index ? "Save" : "Add"}
            </Button>
            {!record.index && (
              <Button
                shape="round"
                disabled={isFormDisabled}
                loading={isFormDisabled}
                onClick={() => onSaveClick(true)}
              >
                Add & New
              </Button>
            )}
          </>
        )}
        <Button shape="round" disabled={isFormDisabled} onClick={cancel}>
          Cancel
        </Button>
      </div>
      <div>
        {record.index && !isPaymentPosted && (
          <Button
            type="link"
            disabled={isFormDisabled}
            onClick={() => openDeleteModal(record.index, cancel)}
          >
            Remove
          </Button>
        )}
      </div>
    </Row>
  );
};
