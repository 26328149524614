const DOCUMENTS_SORTING = {
  date: {
    ascend: 1,
    descend: 2,
  },
  number: {
    ascend: 3,
    descend: 4,
  },
  name: {
    ascend: 5,
    descend: 6,
  },
  isHolding: {
    ascend: 7,
    descend: 8,
  },
};

export const useOnSortChange = (
  setRequest: React.Dispatch<any>,
  request: any
) => {
  const handleSortChange = (sorter: any): void => {
    const { field, order } = sorter;

    const newRequest = { ...request };

    if (!order) {
      newRequest["sortBy"] = 2;
    } else {
      const column = DOCUMENTS_SORTING[field as keyof typeof DOCUMENTS_SORTING];

      const reqOrder = column[order as keyof typeof column];

      newRequest["sortBy"] = reqOrder;
    }

    setRequest(newRequest);
  };

  const onSorterChange = (
    _pagination: any,
    _filter: any,
    sorter: any
  ): void => {
    handleSortChange(sorter);
  };

  return { onSorterChange };
};
