import { useDefaultDeleteModal } from "../../../../../../../../../../../lib/hooks/defaultDeleteModal";
import { deleteImpoundActivity } from "../../../../../../../../../../../acions/accounts/impounds/activities";
import { useCallback } from "react";

export const useDelete = (reloadData: () => void) => {
  const { openDeleteModal } = useDefaultDeleteModal(
    deleteImpoundActivity,
    reloadData
  );

  const onDeleteBtnClick = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Payment History Record");
    },
    [openDeleteModal]
  );

  return { onDeleteBtnClick };
};
