import { FormInstance } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  DISBURSEMENTS_METHOD_TYPES,
  DISBURSEMENTS_STATUS,
  PAYEE_TYPES,
} from "../../../../../../lib/constants";

export const useSetInitialValues = (record: any, form: FormInstance) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const disbursementMethodState = useMemo(() => {
    return record && record.index
      ? {
          isEscrowAcc: record.method === 8,
          isACHMethod: record.method >= 5 && record.method <= 7,
        }
      : { isEscrowAcc: false, isACHMethod: false };
  }, [record]);

  useEffect(() => {
    if (record.index) {
      const { alternativePayeeNumber, payeeNumber } = record;

      form.setFieldsValue({
        ...record,
        payeeId: payeeNumber,
        alternativePayeeId: alternativePayeeNumber,
      });
    } else {
      form.setFieldsValue({
        type: PAYEE_TYPES[0].value,
        method: DISBURSEMENTS_METHOD_TYPES[0].value,
        status: DISBURSEMENTS_STATUS[0].value,
      });
    }
    setIsLoading(false);
  }, [record, form]);

  return { disbursementMethodState, isLoading };
};
