import { Col, Form, FormInstance, Input, Row } from "antd";
import { PhoneInput } from "../../../../../../components/PhoneInput";
import { WRONG_FAX_FORMAT } from "../../../../../../lib/constants";
import { ToggleAlternateEmail } from "./ToggleAlternateEmail";
import { ToggleAlternateAddress } from "./ToggleAlternameAddress";
import { AddressForm } from "./AddressForm";
import { Loader } from "../../../../../../components/Loader";
import { useGetEmailFormRules } from "../../../../../../lib/hooks/getEmailFormRules";
import { useGetUSAStates } from "../../../../../../lib/hooks/getUSAStates";

type ContactsInfoProps = {
  form: FormInstance;
  isFormDisabled: boolean;
  isLoading: boolean;
  selectedItemId: string | null | undefined;
};

export const ContactsInfo = ({
  form,
  isFormDisabled,
  isLoading,
  selectedItemId,
}: ContactsInfoProps) => {
  const { statesList } = useGetUSAStates(false);

  const alternateEmail = Form.useWatch(["alternativeEmail"], {
    form,
    preserve: true,
  });

  const alternateAddress = Form.useWatch(["alternativeAddress"], {
    form,
    preserve: true,
  });

  const { rules: emailRules } = useGetEmailFormRules(false);

  return (
    <>
      {!isLoading ? (
        <>
          <Row className="pb-6">
            <h5 className="h h5">Contacts</h5>
          </Row>
          <Row>
            <Col span={12} className="pr-8">
              <AddressForm
                propName="primaryAddress"
                statesList={statesList}
                selectedItemId={selectedItemId}
                form={form}
              />
              <Row className="w-100">
                <ToggleAlternateAddress
                  hasAlternateAddress={!!alternateAddress}
                  statesList={statesList}
                  selectedItemId={selectedItemId}
                  form={form}
                />
              </Row>
            </Col>
            <Col span={12} className="pl-8">
              <Row className="mb-4 phone-input">
                <PhoneInput
                  form={form}
                  name="phone"
                  size="middle"
                  hideAddon
                  notRequired
                  disabled={isFormDisabled}
                />
              </Row>
              <Row className="mb-4 phone-input">
                <PhoneInput
                  form={form}
                  name="fax"
                  size="middle"
                  hideAddon
                  label="Fax"
                  errorPatternMessage={WRONG_FAX_FORMAT}
                  notRequired
                  disabled={isFormDisabled}
                />
              </Row>
              <Row className="mb-4">
                <Form.Item
                  label="Email"
                  name="primaryEmail"
                  className="w-100 mb-0"
                  rules={emailRules}
                  normalize={(value: string) => value || null}
                >
                  <Input />
                </Form.Item>
              </Row>
              <Row>
                <ToggleAlternateEmail hasAlternateEmail={!!alternateEmail} />
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <Loader fontSize="64px" />
      )}
    </>
  );
};
