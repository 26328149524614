import { FormInstance } from "antd";
import { useCallback } from "react";

type TButtonHandlers = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContentType: React.Dispatch<React.SetStateAction<number>>;
  form: FormInstance;
};

export const useButtonHandlers = ({
  setIsOpen,
  setContentType,
  form,
}: TButtonHandlers) => {
  const onCancelClick = useCallback(() => {
    setIsOpen(false);
    setContentType(0);
    form.resetFields();
  }, [setContentType, setIsOpen, form]);

  return { onCancelClick };
};
