import { useMemo } from "react";
import { EMAIL_REQUIRED, MAX_EMAIL, WRONG_EMAIL_FORMAT } from "../constants";
import { EMAIL_RULE } from "../validationRules";
import { Rule } from "antd/es/form";

export const useGetEmailFormRules = (isRequired = true) => {
  const rules: Rule[] = useMemo(() => {
    const value: Rule[] = [
      {
        pattern: EMAIL_RULE,
        message: WRONG_EMAIL_FORMAT,
        validateTrigger: "onSubmit",
      },
      {
        max: 100,
        message: MAX_EMAIL,
        validateTrigger: "onSubmit",
      },
    ];

    isRequired &&
      value.push({
        required: true,
        message: EMAIL_REQUIRED,
        validateTrigger: "onSubmit",
      });

    return value;
  }, [isRequired]);

  return { rules };
};
