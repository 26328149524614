import { Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useCopy } from "./hooks/useCopy";
import { useCallback } from "react";

type TCopyButtonProps = {
  value: string;
  message: string;
};

export const CopyButton = ({ value, message }: TCopyButtonProps) => {
  const { copy } = useCopy();

  const onCopyClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      copy(e.currentTarget.id, message);
    },
    [copy, message]
  );

  return (
    <Tooltip className="ml-2 copy-icon" title="Copy Number" trigger="hover">
      <CopyOutlined id={value} onClick={onCopyClick} />
    </Tooltip>
  );
};
