import { FormInstance, Row } from "antd";
import { PhoneInput } from "../../../../../../components/PhoneInput";
import { SearchEmail } from "./SeacrhEmail";
import { SearchMessage } from "./SearchMessage";
import { FullNameFormItems } from "./FullNameFormItems";

type CreateNewFormProps = {
  form: FormInstance;
  customerId: string | null | undefined;
  activeTab: string;
  showMessage: boolean;
  search: string;
  debouncedSearch: string;
  isLoading: boolean;
  isFormDisabled: boolean;
  setIsLoading: (value: boolean) => void;
  onSearchChange: (e: any) => void;
  onClearSearch: () => void;
  onDataFetched: (value: any[], show: boolean) => void;
  onChangeTab: (key: string) => void;
};

export const CreateNewForm = ({
  form,
  customerId,
  activeTab,
  showMessage,
  search,
  debouncedSearch,
  isLoading,
  isFormDisabled,
  setIsLoading,
  onSearchChange,
  onClearSearch,
  onDataFetched,
  onChangeTab,
}: CreateNewFormProps) => {
  return (
    <Row className="py-8 px-6 d-block">
      <p className="my-0 pb-4 text-md w-500">Email for login</p>
      <Row>
        <div className="position-relative w-100 search-email">
          <SearchEmail
            form={form}
            tabNumber="1"
            customerId={customerId}
            onDataFetched={onDataFetched}
            search={search}
            debouncedSearch={debouncedSearch}
            onSearchChange={onSearchChange}
            onClearSearch={onClearSearch}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            activeTab={activeTab}
            showMessage={showMessage}
          />
          {showMessage && (
            <SearchMessage activeTab={activeTab} onChangeTab={onChangeTab} />
          )}
        </div>
      </Row>
      <p className="my-0 pb-4 pt-2 text-md w-500">User details</p>

      <FullNameFormItems />

      <Row className="phone-input">
        <PhoneInput
          form={form}
          name={["newUser", "phone"]}
          hideAddon
          notRequired
          size="middle"
          disabled={isFormDisabled}
        />
      </Row>
    </Row>
  );
};
