import { useEffect, useState } from "react";
import { ProfilesService } from "../../../services/profiles";

export const useFetchData = () => {
  const [data, setData] = useState<any>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    ProfilesService.fetchUser()
      .then((res: any) => {
        setData(res);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    data,
    isLoading,
  };
};
