import { useEffect, useState } from "react";
import CustomersService from "../../../../../../../services/customers";

export const useLoadData = (customerId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<any[]>([]);

  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    setIsLoading(true);

    const request = {
      pageNumber: 1,
      pageSize: 100000,
      parentId: customerId,
    };

    CustomersService.fetchCustomerAccounts(request)
      .then((res: any) => {
        if (res) {
          setIsLoading(false);
          setData(res);
          setTotal(res.length);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [customerId]);

  return { data, total, isLoading };
};
