import { Loader } from "../../../../../../components/Loader";
import { Card, Col, Row } from "antd";
import { PastPayments } from "./PastPayments";
import { GeneralInfo } from "./GeneralInfo";
import { NextPayment } from "./NextPayment";
import { useState, useEffect } from "react";
import AccountsService from "../../../../../../services/accounts";
import { Coupon } from "./Coupon";
import { Disclaimer } from "./Disclaimer";
import { NavigateFunction, useNavigate } from "react-router-dom";
import "./styles.scss";

type DetailsProps = {
  accountNumber: string;
  setHeaderData: React.Dispatch<React.SetStateAction<any>>;
};

export const Details = ({ accountNumber, setHeaderData }: DetailsProps) => {
  const navigate: NavigateFunction = useNavigate();

  const [data, setData] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    AccountsService.fetchAccountContracts(accountNumber)
      .then((res) => {
        setData(res);
        setHeaderData({
          status: res.generalInformation.status,
          accountNumber,
        });
        setIsLoading(false);
      })
      .catch((e) => {
        e.response.data.status === 400 && navigate("/not-found");
        setIsLoading(false);
      });
  }, [accountNumber, navigate, setHeaderData]);

  return (
    <div className="tab-content">
      <Coupon accountNumber={accountNumber} data={data} />
      {isLoading ? (
        <Loader fontSize="128px" />
      ) : (
        <div className="info-tablets mb-4" style={{ height: "100%" }}>
          {data && (
            <>
              <Row className="cards-row w-100 justify-center pt-6">
                <Col className="main-info-card">
                  <Card>
                    <GeneralInfo data={data.generalInformation} />
                  </Card>
                </Col>
                <Col className="secondary-info-card">
                  <Card>
                    <NextPayment data={data.nextPayment} />
                  </Card>
                </Col>
              </Row>
              <Row className="cards-row w-100 justify-center mt-4 pb-4">
                <Col className="main-info-card">
                  <Card>
                    <PastPayments data={data.pastPayments} />
                  </Card>
                </Col>
                <Col className="secondary-info-card">
                  <Disclaimer />
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </div>
  );
};
