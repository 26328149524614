import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../actionsCreators/messageActions";
import {
  DELETE_USER_SUCCESS,
  UPDATE_RECORD_SUCCESS,
  PAYOFF_QUOTE_CREATED,
} from "../../lib/constants";
import PayoffQuoteService from "../../services/accounts/payoffQuote";
import { IPayoffQuotePost } from "../../types/PayoffQute";

export const deletePayoffQuote =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await PayoffQuoteService.remove(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const editPayoffQuote =
  (
    req: IPayoffQuotePost,
    id: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await PayoffQuoteService.edit(id, req);

      dispatch(setSuccessMessage(UPDATE_RECORD_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const createPayoffQuote =
  (req: IPayoffQuotePost): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await PayoffQuoteService.create(req);

      dispatch(setSuccessMessage(PAYOFF_QUOTE_CREATED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
