import { Form, Radio, FormInstance, Card, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { LATE_CHARGES_CALCULATIONS } from "../../../lib/constants";
import LateChargesService from "../../../services/administration/latecharges";
import { useButtonHandlers } from "./hooks/useButtonHandlers";

type TCalcForm = {
  reloadData: () => void;
};

export const CalcForm = ({ reloadData }: TCalcForm) => {
  const [isLoading, setisLoading] = useState<boolean>(false);

  const [form]: FormInstance[] = Form.useForm();

  const fetchCalcMethod = useCallback(() => {
    setisLoading(true);

    LateChargesService.fetchLateChargesCalc()
      .then((res: any) => {
        form.setFieldsValue({
          lateChargeCalculation: res.lateChargeCalculation,
        });

        setisLoading(false);
      })
      .catch(() => setisLoading(false));
  }, [form]);

  useEffect(() => fetchCalcMethod(), [fetchCalcMethod]);

  const { onLateChargeCalcChange, isFormDisabled } = useButtonHandlers({
    form,
    reloadData,
  });

  return (
    <Card className="mt-4" loading={isLoading}>
      <Row className="pb-6">
        <h5 className="h h5 ant-table-title">General</h5>
      </Row>
      <Row>
        <Form
          className="pb-5"
          form={form}
          layout="vertical"
          requiredMark={false}
          disabled={isFormDisabled}
        >
          <Form.Item
            label="Late Charge Calculation"
            name="lateChargeCalculation"
          >
            <Radio.Group
              options={LATE_CHARGES_CALCULATIONS}
              className="w-100 d-flex flex-column late-charges-calc-options"
              onChange={onLateChargeCalcChange}
            />
          </Form.Item>
        </Form>
      </Row>
    </Card>
  );
};
