import { ReactNode, useCallback, useMemo } from "react";
import { SideBarContainer } from "../../../../../../../../../components/SideBarContainer";
import { PayersForm } from "../PayersForm";

type TOpenSidebar = {
  id: string | null | undefined;
  setId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  reloadData: () => void;
  primaryBuyerNumber: number;
  parentId: string;
  customSidebarClass?: string;
};

export const useOpenSidebar = ({
  id,
  setId,
  reloadData,
  primaryBuyerNumber,
  customSidebarClass,
  parentId,
}: TOpenSidebar) => {
  const onClose = useCallback(
    (value: boolean) => {
      !value && setId(undefined);
    },
    [setId]
  );

  const formWrapper: ReactNode = useMemo(() => {
    const formTitle = id !== null ? "Payer Details" : "Add Payer";

    return (
      <SideBarContainer
        title={formTitle}
        setShowSideBar={onClose}
        customClass={customSidebarClass}
      >
        <PayersForm
          id={id}
          reloadData={reloadData}
          setId={setId}
          parentId={parentId}
          primaryBuyerNumber={primaryBuyerNumber}
        />
      </SideBarContainer>
    );
  }, [
    customSidebarClass,
    id,
    onClose,
    parentId,
    primaryBuyerNumber,
    reloadData,
    setId,
  ]);

  return { formWrapper };
};
