import { ExclamationCircleFilled } from "@ant-design/icons";
import { useMemo } from "react";

export const useHasChangesModalOptions = (isFormDisabled: boolean) => {
  const options: any = useMemo(
    () => ({
      icon: <ExclamationCircleFilled />,
      okText: "Don't Save",
      okType: "primary",
      cancelText: "Save",
      okButtonProps: {
        shape: "round",
        disabled: isFormDisabled,
        type: "default",
      },
      cancelButtonProps: {
        shape: "round",
        disabled: isFormDisabled,
        type: "primary",
      },
      title: "Unsaved changes on the page",
      content: <div className="text-md mb-1">Save them before leaving?</div>,
    }),
    [isFormDisabled]
  );

  return { options };
};
