import { useCallback } from "react";
import { CustomModal } from "../../../../../../lib/CustomModal";
import { CreatePaymentForm } from "./CreatePaymentForm";

type TCreatePaymentAutoModalProps = {
  batchId: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
};

export const CreatePaymentAutoModal = ({
  batchId,
  isOpen,
  setIsOpen,
  onSuccess,
}: TCreatePaymentAutoModalProps) => {
  const close = useCallback((): void => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <CustomModal
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      titleText="Select Payments Due Date"
      width={300}
      headerClass="text w-500"
      modalClass="create-modal"
      destroyOnClose
    >
      <CreatePaymentForm
        batchId={batchId}
        close={close}
        onSuccess={onSuccess}
      />
    </CustomModal>
  );
};
