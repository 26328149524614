import { Form, FormInstance, Input, InputRef, Tooltip } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import { useSearchCustomer } from "./hooks/searchCustomer";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Rule } from "antd/es/form";
import ROUTES from "../../../../configs/routesConfig";

type CustomerInputSearchProps = {
  form: FormInstance;
  label: string;
  name: string[];
  isFormDisabled: boolean;
  lastSearch?: boolean;
  hasId: boolean;
  onCustomerFound: (number: number | null, name: string[]) => void;
  placeholder?: string;
  additionalClass?: string;
  customRef?: React.RefObject<InputRef>;
  isFieldRequired?: boolean;
};

export const CustomerInputSearch = ({
  form,
  label,
  name,
  isFormDisabled,
  onCustomerFound,
  lastSearch,
  hasId,
  placeholder = "Input Customer#, “Enter” to confirm",
  additionalClass,
  customRef,
  isFieldRequired = true,
}: CustomerInputSearchProps) => {
  const setFieldError = useCallback(
    (errorMessages: string[]): void => {
      form.setFields([
        {
          name: name,
          errors: errorMessages,
        },
      ]);
    },
    [name, form]
  );

  const rules = useMemo(() => {
    return [
      {
        required: isFieldRequired,
      },
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: string) {
          if (!value || hasId) {
            return Promise.resolve("");
          }
          if (value && !hasId) {
            return Promise.reject(
              new Error("Please press Enter to find the user")
            );
          }
          return Promise.reject(new Error("Customer was not found"));
        },
      }),
    ];
  }, [hasId, isFieldRequired]);

  const { customer, setCustomer, isDisabled, search } = useSearchCustomer(
    name,
    setFieldError,
    onCustomerFound
  );

  const onEnterClicked = (e: any): void => {
    const value = +e.target.value;

    if (value && !_.isNaN(value)) {
      search(value);
    } else {
      setCustomer(null);
      setFieldError(["Please enter correct number"]);
    }
  };

  useEffect(() => {
    const value = form.getFieldValue(name);

    if (value) {
      search(+value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = useCallback(() => {
    setCustomer(null);
    onCustomerFound(null, name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const val = Form.useWatch(name, form);

  useEffect(() => {
    !val && onInputChange();
  }, [onInputChange, val]);

  return (
    <div
      className={`
        position-relative
        ${additionalClass || ""}
        ${customer && !lastSearch ? "pb-4" : ""}
      `}
    >
      <Form.Item label={label} name={name} rules={rules}>
        <Input
          placeholder={placeholder}
          disabled={isDisabled || isFormDisabled}
          onPressEnter={(e) => onEnterClicked(e)}
          onChange={onInputChange}
          ref={customRef}
        />
      </Form.Item>
      {customer && (
        <div className="position-absolute customer-name-link elipsis d-flex align-center">
          <Link
            to={`${ROUTES.CUSTOMERS}/${customer.id}`}
            target="_blank"
            className="mr-2"
          >
            <span className="text-md mt-2 mb-0 ant-btn-link">
              {customer.name}
            </span>
          </Link>
          {!customer.hasTaxId && (
            <Tooltip
              placement="top"
              title="Tax ID is not specified"
              overlayClassName="text-sm"
            >
              <ExclamationCircleOutlined className="search-icon logo-cursor" />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};
