import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";

const fetchAll = async (request: any) => {
  const response = await authAxios.get(API_URL + "Contracts", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (request: any) => {
  const response = await authAxios.get(API_URL + "Contracts/Count", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetch = async (id: string) => {
  const response = await authAxios.get(API_URL + "Contracts/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const searchCustomerByNumber = async (number: number) => {
  const response = await authAxios.get(
    API_URL + "Contracts/Customers?Search=" + number,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const getExcel = async (request: any) => {
  const response = await authAxios.get(API_URL + "Contracts/excel", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
    responseType: "blob",
  });

  return response.data;
};

const save = async (request: any) => {
  const response = await authAxios.post(API_URL + "Contracts", request, {
    headers: authHeader(),
  });

  return response.data;
};

const searchByNumber = async (number: number) => {
  const response = await authAxios.get(
    API_URL + "Contracts/ByNumber?Number=" + number,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const remove = async (id: string) => {
  const response = await authAxios.delete(`${API_URL}Contracts/${id}`, {
    headers: authHeader(),
  });

  return response.data;
};

const update = async (id: string, request: any) => {
  const response = await authAxios.put(`${API_URL}Contracts/${id}`, request, {
    headers: authHeader(),
  });

  return response.data;
};

const ContractsService = {
  fetchAll,
  fetchCount,
  fetch,
  searchCustomerByNumber,
  getExcel,
  save,
  searchByNumber,
  remove,
  update,
};

export default ContractsService;
