import { TablePaginationConfig } from "antd";
import { TRequest } from "../types";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { IBatchPayment } from "../../../../../../../types/BatchPayment";

const PAYMENT_SORTING = {
  date: {
    ascend: 1,
    descend: 2,
  },
  paymentNumber: {
    ascend: 3,
    descend: 4,
  },
  contractNumber: {
    ascend: 5,
    descend: 6,
  },
  payerName: {
    ascend: 7,
    descend: 8,
  },
  amount: {
    ascend: 9,
    descend: 10,
  },
  method: {
    ascend: 11,
    descend: 12,
  },
  isPosted: {
    ascend: 13,
    descend: 14,
  },
};

export const useOnSorterChange = (
  request: TRequest,
  setRequest: React.Dispatch<React.SetStateAction<TRequest>>
) => {
  const handleOnSorterChange = (
    sorter: SorterResult<IBatchPayment> | SorterResult<IBatchPayment>[]
  ): void => {
    const { field, order } = sorter as SorterResult<IBatchPayment>;

    const newRequest = { ...request };

    if (!order) {
      newRequest["orderBy"] = 1;
    } else {
      const column = PAYMENT_SORTING[field as keyof typeof PAYMENT_SORTING];

      const reqOrder = column[order as keyof typeof column];

      newRequest["orderBy"] = reqOrder;
    }

    setRequest(newRequest);
  };

  const onSorterChange = (
    _pagination: TablePaginationConfig,
    _filter: Record<string, FilterValue | null>,
    sorter: SorterResult<IBatchPayment> | SorterResult<IBatchPayment>[]
  ): void => {
    handleOnSorterChange(sorter);
  };

  return { onSorterChange };
};
