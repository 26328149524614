import { Row, Skeleton, SkeletonProps } from "antd";
import { useFetchTotal } from "./hooks/useFetchTotal";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../lib/constants";
import { ReactNode, useMemo, useState } from "react";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { TotalItems } from "./TotalItems";
import "./styles.scss";
import { getFormattedCurrency } from "../../../../lib/currencyFormatter";

type ContractTotalsProps = {
  accountId: string;
};

export const ContractTotals = ({ accountId }: ContractTotalsProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const onCollapse = () => setIsCollapsed(!isCollapsed);

  const { data, isLoading, fetchData } = useFetchTotal(accountId);

  const getFormattedDate = (value: string | null): string => {
    return value ? dayjs(value).format(DATE_FORMAT) : "-";
  };

  const header: ReactNode = useMemo(() => {
    const { totalLateCharge, totalAssessedOn, totalNotAssessedOn } = data;

    return (
      <Row className="text-md">
        <span>Total Late Charges: </span>&nbsp;
        <span className="text-md w-500">
          {getFormattedCurrency(totalLateCharge)}.
        </span>
        &nbsp;
        <span>Assessed on </span>&nbsp;
        <span className="text-md w-500">
          {getFormattedDate(totalAssessedOn)},
        </span>
        &nbsp;
        <span>Not Assessed on </span>&nbsp;
        <span className="text-md w-500">
          {getFormattedDate(totalNotAssessedOn)}
        </span>
      </Row>
    );
  }, [data]);

  const loaderProps: SkeletonProps = useMemo(
    () => ({
      active: true,
      paragraph: isCollapsed ? false : { rows: 2 },
    }),
    [isCollapsed]
  );

  return (
    <Row className="mt-6 late-charges-total">
      {isLoading ? (
        <Skeleton {...loaderProps} />
      ) : (
        <Row className="d-block w-100">
          <Row className="align-center">
            {header}
            <div className="text-md ml-2 logo-cursor" onClick={onCollapse}>
              {isCollapsed ? <RightOutlined /> : <DownOutlined />}
            </div>
          </Row>
          {!isCollapsed && (
            <TotalItems
              data={data}
              accountId={accountId}
              reloadData={fetchData}
            />
          )}
        </Row>
      )}
    </Row>
  );
};
