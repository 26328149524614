import { Button } from "antd";

type TModalFooterProps = {
  onSaveClicked: () => Promise<void>;
  closeModal: () => void;
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  isBorderNeeded?: boolean;
};

export const ModalFooter = ({
  onSaveClicked,
  closeModal,
  isFormDisabled,
  isPaymentPosted,
}: TModalFooterProps) => {
  return (
    <>
      {!isPaymentPosted && (
        <Button
          shape="round"
          type="primary"
          className="mr-4"
          onClick={onSaveClicked}
          loading={isFormDisabled}
          disabled={isFormDisabled}
        >
          Save
        </Button>
      )}
      <Button
        shape="round"
        onClick={closeModal}
        disabled={isFormDisabled}
        loading={isFormDisabled}
      >
        Cancel
      </Button>
    </>
  );
};
