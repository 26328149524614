import { useCallback, useState } from "react";
import { SecondStep } from "./SecondStep";
import { ValidationStep } from "../components/VerificationStep";
import { useGetEmailFromLocation } from "../../../lib/hooks/getEmailfromLocation";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../configs/routesConfig";

export const ChangePassword = () => {
  const navigate = useNavigate();

  const [isFirstStep, setIsFirstStep] = useState<boolean>(true);

  const [email] = useGetEmailFromLocation();

  const onBackClicked = useCallback(() => {
    setIsFirstStep(true);
  }, []);

  return (
    <>
      {isFirstStep ? (
        <ValidationStep
          setStep={() => setIsFirstStep(false)}
          type="email"
          onBack={() => navigate(ROUTES.PROFILE)}
          subHeaderText="We just want to be sure it’s you."
          headerText="Quick security check"
          email={email}
        />
      ) : (
        <SecondStep onBackClicked={onBackClicked} />
      )}
    </>
  );
};
