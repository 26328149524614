import { Button, Row, Switch } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useMemo, useState } from "react";
import { getFormatted } from "../../../../../../lib/PhoneFormatter";
import { CloseOutlined } from "@ant-design/icons";
import { useRemovePortalAccess } from "./removePortalAccess";
import { useUpdatePortalAccess } from "./updatePortalAccess";
import { Link } from "react-router-dom";
import { useSidebarHeader } from "../../../../../../lib/hooks/sidebarHeader";

export const useGetTableColumns = (reloadData: () => void) => {
  const { getStatus } = useSidebarHeader();

  const [isDisabled, setIsDisabled] = useState(false);

  const { remove } = useRemovePortalAccess();

  const { onUpdate } = useUpdatePortalAccess(reloadData, setIsDisabled);

  const onRemoveClicked = useCallback(
    (id: number) => {
      remove(id, setIsDisabled, reloadData);
    },
    [reloadData, remove]
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "userFullName",
        render: (value: string, record: any) => (
          <div className="cell-elipsis">
            <Link
              to={`/user-management?userId=${record.userId}`}
              target={"_blank"}
            >
              <span className="link-btn">{value}</span>
            </Link>
          </div>
        ),
        width: "26%",
      },
      {
        title: "Email",
        dataIndex: "userEmail",
        render: (value: string) => (
          <div className="cell-elipsis" title={value}>
            {value}
          </div>
        ),
        width: "26%",
      },
      {
        title: "Phone",
        dataIndex: "userPhone",
        render: (value: string) => (value ? getFormatted(value).slice(2) : "-"),
        width: "16%",
      },
      {
        title: "Primary",
        dataIndex: "isPrimary",
        render: (value: boolean, record: any) => (
          <Row className="align-center">
            <Switch
              className="mr-2"
              checked={value}
              size="small"
              onChange={(e) => onUpdate(record.id, e)}
              disabled={isDisabled || value}
            />
            <span>{value ? "Yes" : "No"}</span>
          </Row>
        ),
        width: "12%",
      },
      {
        title: "Status",
        dataIndex: "userStatus",
        render: (value: number) => getStatus(value),
        width: "14%",
      },
      {
        render: (_value: unknown, record: any) => (
          <Row className="table-actions">
            <Button
              className="px-0 py-0"
              type="link"
              icon={<CloseOutlined className="text-md title" />}
              disabled={isDisabled}
              onClick={() => onRemoveClicked(record.id)}
            />
          </Row>
        ),
        width: "6%",
      },
    ],
    [getStatus, isDisabled, onRemoveClicked, onUpdate]
  );

  return { columns };
};
