import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { SendVerificationCodeRequest } from "../types/SendVerificationCodeRequest";
import { ProfilesService } from "../services/profiles";
import {
  CODE_SEND,
  RESET_EMAIL_SECCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PHONE_SUCCESS,
} from "../lib/constants";
import { setSuccessMessage, setErrorMessage } from "./actionsCreators/messageActions";

export const sendVerificationCode =
  (
    request: SendVerificationCodeRequest
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await ProfilesService.sendVerificationCode(request);

      dispatch(setSuccessMessage(CODE_SEND));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, false));

      return await Promise.reject(error);
    }
  };

export const confirmVerificationCode =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      const response = await ProfilesService.confirmVerificationCode(request);

      return await Promise.resolve(response);
    } catch (err: any) {
      dispatch(setErrorMessage(err.response.data.title, false));

      return await Promise.reject(err);
    }
  };

export const changePassword =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      const response = await ProfilesService.changePassword(request);

      dispatch(setSuccessMessage(RESET_PASSWORD_SUCCESS));

      return await Promise.resolve(response);
    } catch (err: any) {
      dispatch(setErrorMessage(err.response.data.title, false));

      return await Promise.reject(err);
    }
  };

export const changePhone =
  (phone: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      const response = await ProfilesService.changePhone(phone);

      dispatch(setSuccessMessage(RESET_PHONE_SUCCESS));

      return await Promise.resolve(response);
    } catch (err: any) {
      dispatch(setErrorMessage(err.response.data.title, false));

      return await Promise.reject(err);
    }
  };

export const changeEmail =
  (email: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      const response = await ProfilesService.changeEmail(email);

      dispatch(setSuccessMessage(RESET_EMAIL_SECCESS));

      return await Promise.resolve(response);
    } catch (err: any) {
      dispatch(setErrorMessage(err.response.data.title, false));

      return await Promise.reject(err);
    }
  };
