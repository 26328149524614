import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../../../../../../../../../store";
import {
  calculateBalancesToLastTrans,
  updateImpoundWithHistBalance,
  updateImpoundWithYTDInAndOut,
} from "../../../../../../../../../../../acions/accounts/impounds/activities";

export const useRecalculate = (reloadData: () => void) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const onSuccess = useCallback(() => {
    setIsDisabled(false);
    reloadData();
  }, [reloadData, setIsDisabled]);

  const calculateBalances = useCallback(
    async (id: string) => {
      try {
        setIsDisabled(true);

        await Promise.resolve(dispatch(calculateBalancesToLastTrans(id)));

        onSuccess();
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, onSuccess]
  );

  const updateImpoundWithBalance = useCallback(
    async (id: string) => {
      try {
        setIsDisabled(true);

        await Promise.resolve(dispatch(updateImpoundWithHistBalance(id)));

        onSuccess();
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, onSuccess]
  );

  const updateImpoundWithYTD = useCallback(
    async (id: string) => {
      try {
        setIsDisabled(true);

        await Promise.resolve(dispatch(updateImpoundWithYTDInAndOut(id)));

        onSuccess();
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, onSuccess]
  );

  return {
    calculateBalances,
    updateImpoundWithBalance,
    updateImpoundWithYTD,
    isDisabled,
  };
};
