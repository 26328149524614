import { Tag } from "antd";
import { ReactNode } from "react";
import { useGetFullName } from "./getFullName";

export const useSidebarHeader = () => {
  const { getFullName } = useGetFullName();

  const getStatus = (value: number): ReactNode => {
    let color = "blue";
    let label = "Non-Activated";

    if (value === 1) {
      color = "green";
      label = "Approved";
    }

    if (value === 2) {
      color = "red";
      label = "Blocked";
    }
    if (value === 0) {
      color = "orange";
      label = "Pending";
    }

    return <Tag color={color}>{label}</Tag>;
  };

  const getHeader = (record: any): ReactNode => {
    const fullName = getFullName(record);

    return (
      <div className="d-flex align-center">
        <h4 className="my-0 title mr-4 elipsis" title={fullName}>
          {fullName}
        </h4>
        <div>{getStatus(record.status)}</div>
      </div>
    );
  };

  return { getStatus, getHeader };
};
